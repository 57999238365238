import { dispatcher, store } from '../redux/redux';
import getPositionFromParent from './getPositionFromParent';
import checkValueOfEmpty from './checkValueOfEmpty';

const checkPosition = ({ target, parent, key, ...props }) => {
    const { windows } = store.getState();
    const { centers = {}, watchesProps } = props;

    let [left, top] = getPositionFromParent({ target, parent });

    left += target.offsetWidth * (centers.left || 0);
    top += target.offsetHeight * (centers.top || 0);

    if (props.left) {
        left = props.left;
    }
    if (props.top) {
        top = props.top;
    }

    if (windows[key]) {
        if (watchesProps) {
            if (watchesProps.left) {
                windows[key].left = left;
            }
            if (watchesProps.top) {
                windows[key].top = top;
            }
        } else {
            windows[key].left = left;
            windows[key].top = top;
        }

        dispatcher({ type: 'windows', data: windows });
    }
};

const timersIdsHide = [];
const timersIdsShow = [];

const hide = (e, key) => {
    const { windows } = store.getState();
    const windowsKeys = checkValueOfEmpty(key) ? [key] : Object.keys(windows);
    let isChange = false;

    windowsKeys.forEach((keyLoop) => {
        const windowElem = document.querySelector(`.body__window[data-key="${keyLoop}"]`);

        if (
            windowElem &&
            windows[keyLoop].isShow &&
            ((e.target !== windowElem && !windowElem.contains(e.target)) || !e.target)
        ) {
            windows[keyLoop].isShow = false;
            isChange = true;

            if (windows[keyLoop].callback) {
                windows[keyLoop].callback();
            }
        }
    });

    if (isChange) {
        timersIdsShow.forEach((timer) => {
            clearTimeout(timer);
        });

        dispatcher({ type: 'windows', data: windows }).then(() => {
            timersIdsHide.push(
                setTimeout(() => {
                    const { windows: windowsUpdate } = store.getState();

                    windowsKeys.forEach((keyLoop) => {
                        if (!windowsUpdate[keyLoop].isShow) {
                            delete windowsUpdate[keyLoop];
                        }
                    });

                    dispatcher({ type: 'windows', data: windowsUpdate });
                }, 300),
            );
        });
    }
};

const watchResize = ({ target, parentResize, parent, key, ...props }) => {
    if (parentResize) {
        const observer = new ResizeObserver(() => {
            const { windows } = store.getState();

            if (windows[key]) {
                if (props.isHideFromResize) {
                    hide({ target: null }, key);
                } else {
                    checkPosition({ target, parentResize, parent, key, ...props });
                }
            } else {
                observer.unobserve(parentResize);
            }
        });

        observer.observe(parentResize);
    }
};

const watchScroll = ({ target, parentScroll, parent, key, ...props }) => {
    if (parentScroll) {
        parentScroll.addEventListener('scroll', () => {
            const { windows } = store.getState();
            const window = windows[key];

            if (window?.isCheckScrollPosition) {
                checkPosition({ target, parentScroll, parent, key, ...props });
            }

            if (window && window.isHideFromScroll) {
                hide({ target: null }, key);
            }
        });
    }
};

export function updateWindow({ key, ...props }) {
    const { windows } = store.getState();

    return new Promise((resolve) => {
        if (windows[key]) {
            if (!windows[key].counterUpdate) {
                windows[key].counterUpdate = 1;
            } else {
                windows[key].counterUpdate += 1;
            }

            Object.keys(props).forEach((keyProp) => {
                windows[key][keyProp] = props[keyProp];
            });

            dispatcher({ type: 'windows', data: windows }).then(resolve);
        } else {
            resolve();
        }
    });
}

const checkContainsInWindow = ({ windowTarget, windowInstance }) => {
    const { x, y } = windowTarget.getBoundingClientRect();
    const { offsetWidth, offsetHeight } = windowTarget;

    if (x < 20) {
        windowInstance.left -= x - 20;
    }

    if (x + offsetWidth > document.documentElement.clientWidth) {
        windowInstance.left += document.documentElement.clientWidth - (x + offsetWidth) - 20;
    }

    if (y < 20) {
        windowInstance.top -= y - 20 - offsetHeight;
    }
};

export default function handlerWindow({ parent, parentResize, target, action, ...props }) {
    const { windows } = store.getState();
    const { uniqKey } = props;

    if (
        action === 'show' &&
        !Object.values(windows).find((window) => window.uniqKey === uniqKey && window.isShow)
    ) {
        const { centers = {}, isNotReverse = false, isCheckScrollPosition = true } = props;
        let [left, top] = getPositionFromParent({ target, parent });

        left += target.offsetWidth * (centers.left || 0);
        top += target.offsetHeight * (centers.top || 0);

        if (props.left) {
            left = props.left;
        }

        if (props.top) {
            top = props.top;
        }

        const key = uniqKey || new Date().getTime();
        const newWindow = {
            isShow: false,
            top,
            left,
            parent,
            parentResize,
            target,
            isCheckScrollPosition,
            updatePosition: ({ watchesProps }) => {
                checkPosition({ target, parent, key, centers, watchesProps });
            },
            ...props,
        };

        Object.keys(windows).forEach((keyLoop) => {
            windows[keyLoop].isShow = false;

            if (windows[keyLoop].callback) {
                windows[keyLoop].callback();
            }
        });

        windows[key] = newWindow;

        watchResize({ target, parentResize, parent, key, ...props });
        watchScroll({ target, parentResize, parent, key, ...props });

        timersIdsHide.forEach((timer) => {
            clearTimeout(timer);
        });

        dispatcher({ type: 'windows', data: windows }).then(() => {
            setTimeout(() => {
                windows[key].isShow = true;

                dispatcher({ type: 'windows', data: windows }).then(() => {
                    setTimeout(() => {
                        const { windows: windowsUpdate } = store.getState();

                        const windowElem = document.querySelector(
                            `.body__window[data-key="${key}"]`,
                        );

                        if (
                            windowElem.getBoundingClientRect().y + windowElem.offsetHeight >
                                document.documentElement.clientHeight &&
                            !isNotReverse
                        ) {
                            windowsUpdate[key].isReverseY = true;
                        }

                        checkContainsInWindow({
                            windowTarget: windowElem,
                            windowInstance: windowsUpdate[key],
                        });

                        dispatcher({ type: 'windows', data: windowsUpdate }).then(() => {
                            timersIdsShow.push(
                                setTimeout(() => {
                                    const { windows: windowsUpdateInner } = store.getState();

                                    Object.keys(windowsUpdateInner).forEach((keyLoop) => {
                                        if (!windowsUpdateInner[keyLoop].isShow) {
                                            delete windowsUpdateInner[keyLoop];
                                        }
                                    });

                                    dispatcher({ type: 'windows', data: windowsUpdateInner });
                                }, 300),
                            );
                        });
                    }, 10);
                });
            }, 10);
        });
    } else {
        hide({ target: null });
    }

    if (action === 'hide') {
        hide({ target: null });
    }
}

// document.addEventListener('continuePropagationClick', hide);

document.addEventListener('click', hide);

document.addEventListener('changeHeightWindow', () => {
    const { windows } = store.getState();

    Object.keys(windows).forEach((key) => {
        const windowElem = document.querySelector(`.body__window[data-key="${key}"]`);

        if (windowElem && !windows[key].isNotReverse) {
            if (
                windowElem.getBoundingClientRect().y + windowElem.offsetHeight >
                document.documentElement.clientHeight
            ) {
                windows[key].isReverseY = true;
            } else {
                windows[key].isReverseY = false;
            }
        }
    });

    dispatcher({ type: 'windows', data: windows });
});
