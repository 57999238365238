import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import File from '../../../../classes/File';

import ListAbsoluteMain from '../../../ListAbsoluteMain.jsx';
import setSpacesInText from '../../../../functions/setSpacesInText';
import Button from '../../../Button.jsx';
import BorderDashed from '../../../BorderDashed.jsx';
import Icon from '../../../Icon.jsx';

import getHeaders from '../../../../functions/getHeaders';
import getUpdateFormData from '../../../../functions/getUpdateFormData';
import Loader from '../../../Loader.jsx';
import Animate from '../../../Animate.jsx';

class OrderDocsInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: 'start',
        };

        this.renderBlock = this.renderBlock.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.getCurrentBlock = this.getCurrentBlock.bind(this);
        this.renderFile = this.renderFile.bind(this);
    }

    blocksOrder = ['start', 'files'];

    infos = {
        docs: {
            title: 'Сделайте фото<br/>документов',
            startDescription:
                'Сделайте фотографию документов<br/>на этой точке, нажав на кнопку ниже',
            button: 'Сделать фото документов',
        },
        images: {
            title: 'Теперь сделайте<br/>фото груза',
            startDescription: 'Примите груз у клиента и сделайте фотографию, нажав на кнопку ниже',
            button: 'Сделать фото груза',
        },
    };

    getDoc() {
        const { point, name } = this.props;
        const doc = point.docs.find((innerDoc) => innerDoc.key === `${name}-on-point`);

        return doc;
    }

    getCurrentBlock() {
        const doc = this.getDoc();
        let currentBlock = 'start';

        if (doc.files.length) {
            currentBlock = 'files';
        }

        return currentBlock;
    }

    getBlocks() {
        return [{ key: this.getCurrentBlock() }];
    }

    getFiles() {
        const doc = this.getDoc();

        return [{ _id: 'input' }, ...doc.files];
    }

    renderFile({ item, prop: id }) {
        const { loadingKey } = this.props;
        const isLoading = loadingKey === `${id}-docs`;

        return (
            <div className="appOrderDocs__file">
                <div className="appOrderDocs__fileInner">
                    {id === 'input' ? (
                        <>
                            <label
                                className={`appOrderDocs__fileUpload _col ${
                                    isLoading ? '_loading' : ''
                                }`}
                            >
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={({ target }) => this.uploadFiles({ target, id })}
                                />
                                <Animate
                                    className="appOrderDocs__fileUploadLoader _loader"
                                    isShow={isLoading}
                                >
                                    <div className="appOrderDocs__fileUploadLoaderItem _loaderItem">
                                        <Loader className="_main" />
                                    </div>
                                </Animate>
                                <div className="appOrderDocs__fileUploadBorder">
                                    <BorderDashed className="_upload" />
                                </div>
                                <div className="appOrderDocs__fileUploadContent _col">
                                    <i className="appOrderDocs__fileUploadIcon">
                                        <Icon name="add" />
                                    </i>
                                    <p className="appOrderDocs__fileUploadDescription">Ещё фото</p>
                                </div>
                            </label>
                        </>
                    ) : (
                        <>
                            <div className="appOrderDocs__filePreview">
                                <img
                                    src={`${process.env.REACT_APP_STATIC}/docs-of-point/${item.path}`}
                                    alt=""
                                    className="appOrderDocs__filePreviewImage"
                                />
                                <i className="appOrderDocs__filePreviewDone">
                                    <Icon name="app-done" />
                                </i>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }

    handlerFile = new File({});

    uploadFiles({ target, id }) {
        const { order, handlerLoading } = this.props;
        const doc = this.getDoc();

        const formData = new FormData();

        handlerLoading(id ? `${id}-docs` : 'upload-docs').then(() => {
            this.handlerFile
                .uploadFiles({ target, formData, getName: (key) => `${doc._id}-image${key}` })
                .then(() => {
                    formData.set('id', order._id);
                    formData.set('type', 'docs-of-points');

                    axios
                        .patch(`${process.env.REACT_APP_API}/order`, getUpdateFormData(formData), {
                            headers: getHeaders(),
                        })
                        .then(
                            (res) => {
                                const { success } = res.data;

                                console.log(success);

                                handlerLoading(null);
                            },
                            () => null,
                        );
                });
        });
    }

    renderBlock({ prop: key }) {
        const { name, handlerCurrentBlock, loadingKey } = this.props;
        const info = this.infos[name];
        const doc = this.getDoc();

        return (
            <div className="appOrderDocs__block _col">
                {key === 'start' && (
                    <>
                        <p
                            className="appOrderDocs__description"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(info.startDescription),
                            }}
                        ></p>
                        <label className="appOrderDocs__button">
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={this.uploadFiles}
                            />
                            <Button
                                icon={{ type: 'end', name: 'app-order-camera' }}
                                showLoader={loadingKey === 'upload-docs'}
                            >
                                {info.button}
                            </Button>
                        </label>
                    </>
                )}
                {key === 'files' && (
                    <>
                        <p className="appOrderDocs__description">
                            Загружено фотографий: {doc.files.length}
                            <br />
                            Максимум: 8
                        </p>
                        <div className="appOrderDocs__files">
                            <ListAbsoluteMain
                                className="appOrderDocs__filesInner"
                                items={this.getFiles()}
                                renderItem={this.renderFile}
                                classNameItem="appOrderDocs__file"
                                prop="_id"
                                styles={['width']}
                            />
                        </div>
                        <div className="appOrderDocs__button">
                            <Button
                                icon={{ type: 'end', name: 'arrow-next-2' }}
                                onClick={() => {
                                    if (doc.files.length) {
                                        handlerCurrentBlock({
                                            currentBlock: name === 'docs' ? 'images' : 'code',
                                        });
                                    }
                                }}
                            >
                                Далее
                            </Button>
                        </div>
                    </>
                )}
            </div>
        );
    }

    render() {
        const { name, setUpdateKey } = this.props;
        const info = this.infos[name];

        return (
            <div className="appOrderDocs _col">
                <div
                    className="appOrderDocs__title"
                    dangerouslySetInnerHTML={{ __html: info.title }}
                ></div>
                <ListAbsoluteMain
                    className="appOrderDocs__blocks"
                    items={this.getBlocks()}
                    renderItem={this.renderBlock}
                    classNameItem="appOrderDocs__block"
                    prop="key"
                    paramsParent={{ width: true }}
                    styles={['height']}
                    isNotParamsItem={true}
                    callback={({ type, isInit }) => {
                        if (type === 'parent' || isInit) {
                            setUpdateKey();
                        }
                    }}
                    currentItemKey={this.getCurrentBlock()}
                    allItems={this.blocksOrder}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(OrderDocsInner);

OrderDocsInner.propTypes = {
    user: PropTypes.object,
    setUpdateKey: PropTypes.func,
    name: PropTypes.string,
    handlerCurrentBlock: PropTypes.func,
    point: PropTypes.object,
    order: PropTypes.object,
    loadingKey: PropTypes.string,
    handlerLoading: PropTypes.func,
};
