import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import File from '../../classes/File';

import removeTransition from '../../functions/removeTransition.ts';
import handlerPopup from '../../functions/handlerPopup';
import getDataForImage from '../../functions/getDataForImage';
import getPositionFromParent from '../../functions/getPositionFromParent';

import PopupMobile from '../PopupMobile.jsx';
import Icon from '../Icon.jsx';
import BorderDashed from '../BorderDashed.jsx';
import AnimateChange from '../AnimateChange.jsx';
import Loader from '../Loader.jsx';
import Animate from '../Animate.jsx';
import handlerErrorRequest from '../../functions/handlerErrorRequest';
import setNotification from '../../functions/setNotification';
import changePage from '../../functions/changePage';

class PopupCargoImages extends PopupMobile {
    constructor(props) {
        super({ ...props, name: 'popupOrderCargoImages' });
        this.props = props;
        this.state = {
            widthInner: 0,
            widthImage: 0,
            preview: {
                state: -1,
                position: [0, 0],
            },
            files: [
                // {
                //     path: require('../../img/image.jpeg'),
                //     stateOfShow: 1,
                // },
            ],
        };

        this.setFiles = this.setFiles.bind(this);
        this.handlerUpload = this.handlerUpload.bind(this);

        this.inner = React.createRef();
        this.parent = React.createRef();
        this.scrollAreaX = React.createRef();
    }

    setFiles(files) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };

                newState.files = files;

                return newState;
            }, resolve);
        });
    }

    setLoader(isLoad) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };

                newState.isLoad = isLoad;

                return newState;
            }, resolve);
        });
    }

    counterFiles = 0;

    handlerUpload({ target }) {
        const { addItems, fromContent } = this.props;
        const formData = new FormData();

        this.setLoader(true).then(() => {
            this.handlerFile.uploadFiles({ target, formData }).then(
                ({ resultFiles: resultStartFiles }) => {
                    axios.put(`${process.env.REACT_APP_API}/google-items`, formData).then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                const { result: resultFiles, groupId } = data;
                                const items = resultFiles.reduce(
                                    (prev, cur) => prev.concat(...(cur.items || [])),
                                    [],
                                );

                                const getDataUrls = resultFiles.map(
                                    (file, key) =>
                                        new Promise((resolveData) => {
                                            getDataForImage({
                                                path: `${process.env.REACT_APP_STATIC}/google-items/${file.image}`,
                                                name: file.image,
                                            }).then((result) => {
                                                resolveData({
                                                    ...result,
                                                    object: resultStartFiles[key].object,
                                                });
                                            });
                                        }),
                                );

                                if (fromContent) {
                                    if (groupId) {
                                        changePage({
                                            href: 'content-googleVision-inner',
                                            id: groupId,
                                        });

                                        handlerPopup({
                                            action: 'hide',
                                            name: 'popupOrderCargoImages',
                                        });

                                        setNotification({
                                            notification: 'success-googleVision-image',
                                        });
                                    } else {
                                        setNotification({
                                            notification: 'error-googleVision-image',
                                        });
                                    }
                                }

                                Promise.all(getDataUrls).then((resultDataUrls) => {
                                    removeTransition({ item: '.popup__images', isCurrent: true });

                                    // new File([resultDataUrls[0].data], 'test', {
                                    //     type: resultDataUrls[0].type,
                                    // });

                                    if (addItems) {
                                        addItems({ items, images: resultDataUrls });

                                        this.setFiles([
                                            ...this.state.files,
                                            ...resultFiles.map((file, keyFile) => ({
                                                ...resultDataUrls[keyFile],
                                                stateOfShow: 0,
                                                id: this.counterFiles++,
                                            })),
                                        ]).then(() => {
                                            setTimeout(() => {
                                                const { files } = this.state;

                                                files.forEach((file, key) => {
                                                    if (file.stateOfShow === 0) {
                                                        files[key].stateOfShow = 1;
                                                    }
                                                });

                                                this.setFiles(files).then(() =>
                                                    this.setLoader(false),
                                                );
                                            }, 10);
                                        });
                                    } else {
                                        this.setLoader(false);
                                    }
                                });
                            } else {
                                handlerErrorRequest(res);
                            }
                        },
                        () => null,
                    );
                },
                () => this.setLoader(false),
            );
        });
    }

    deleteFile({ key, name }) {
        const { files } = this.state;
        const { deleteImage } = this.props;

        files[key].isDelete = true;

        deleteImage({ name });

        this.setFiles(files).then(() => {
            setTimeout(() => {
                files.splice(key, 1);

                this.setFiles(files);
            }, 500);
        });
    }

    setWidthInner() {
        const widthInner = this.parent.current?.querySelector('.popup__innerContent')?.clientWidth;

        if (widthInner && widthInner !== this.state.widthInner) {
            this.setState((state) => {
                const newState = { ...state };

                newState.widthInner = widthInner;

                return newState;
            });
        }
    }

    offsetImage = 6;

    setWidthImage() {
        const widthImage = this.parent.current
            ?.querySelector('.popup__images')
            ?.querySelector('.popup__imageItem')?.clientWidth;

        if (widthImage && widthImage !== this.state.widthImage) {
            this.setState((state) => {
                const newState = { ...state };

                newState.widthImage = widthImage;

                return newState;
            });
        }
    }

    setPreviewFile({ props }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const { preview } = newState;

                Object.keys(props).forEach((key) => {
                    preview[key] = props[key];
                });

                newState.preview = preview;

                return newState;
            }, resolve);
        });
    }

    handlerPreviewFile({ target, action, ...props }) {
        const { preview } = this.state;
        const parent = this.parent.current?.querySelector('.popup__inner');
        const position = getPositionFromParent({ target, parent });

        if (action === 'show') {
            const { clientWidth: widthParent, clientHeight: heightParent } = parent;
            const { clientWidth: widthTarget, clientHeight: heightTarget } = target;

            this.setPreviewFile({
                props: {
                    position,
                    positionSave: position,
                    state: 0,
                    width: widthTarget,
                    widthSave: widthTarget,
                    height: heightTarget,
                    heightSave: heightTarget,
                    ...props,
                },
            }).then(() => {
                setTimeout(() => {
                    this.setPreviewFile({
                        props: {
                            position: [0, 0],
                            state: 1,
                            width: widthParent,
                            height: heightParent,
                        },
                    });
                }, 10);
            });
        }

        if (action === 'hide') {
            this.setPreviewFile({
                props: {
                    state: 0,
                    position: preview.positionSave,
                    width: preview.widthSave,
                    height: preview.heightSave,
                },
            }).then(() => {
                setTimeout(() => {
                    this.setPreviewFile({
                        props: {
                            state: -1,
                            positionSave: null,
                            widthSave: null,
                            heightSave: null,
                        },
                    });
                }, 300);
            });
        }
    }

    renderImage({ key = null, path, name }) {
        return (
            <div className="popup__image">
                <div
                    className="popup__imageInner"
                    onClick={(e) => {
                        if (key !== null) {
                            this.handlerPreviewFile({
                                target: e.target,
                                action: 'show',
                                path,
                                key,
                            });
                        }
                    }}
                >
                    {key !== null && (
                        <div
                            className="popup__imageAction"
                            onClick={(e) => {
                                e.stopPropagation();

                                if (key !== null) {
                                    this.deleteFile({ key, name });
                                }
                            }}
                        >
                            <div className="action _col _click _delete">
                                <i className="action__icon">
                                    <Icon name="delete" />
                                </i>
                            </div>
                        </div>
                    )}
                    <img src={path} alt="" className="popup__imageItem" />
                    {key === null && (
                        <i
                            className="popup__imageClose _click"
                            onClick={() => this.handlerPreviewFile({ action: 'hide' })}
                        >
                            <Icon name="delete-circle" />
                        </i>
                    )}
                </div>
            </div>
        );
    }

    isInitDrag = false;

    initDrag() {
        if (!this.isInitDrag) {
            const area = this.parent.current.querySelector('.popup__upload');

            const handlerOver = () => this.setState({ dragIsActive: true });
            const handlerLeave = () => this.setState({ dragIsActive: false });
            const handlerDrop = (files) => {
                this.handlerUpload({ target: { files } });
                this.setState({ dragIsActive: false });
            };

            this.handlerFile.setDrag({ area, handlerOver, handlerLeave, handlerDrop });

            this.isInitDrag = true;
        }
    }

    componentDidMountOther() {
        const input = this.parent.current.querySelector('.popup__uploadInput');

        this.handlerFile = new File({
            input,
            checkDisabledDrag: () => this.state.isLoad,
        });

        const { images } = this.props;

        removeTransition({ item: '.popup__upload' });
        this.setWidthInner();
        this.setWidthImage();
        this.initDrag();

        if (images) {
            removeTransition({ item: '.popup__images' });
            this.setFiles(
                images.map((file) => ({
                    ...file,
                    stateOfShow: 1,
                    id: this.counterFiles++,
                })),
            );
        }
    }

    componentDidUpdate() {
        this.setWidthInner();
        this.setWidthImage();
    }

    render() {
        const { files, widthInner, widthImage, isLoad, preview, dragIsActive } = this.state;
        const { state, device, fromContent } = this.props;
        const findDelete = files.filter((file) => file.isDelete);
        const lenFiles = files.filter((file) => !file.isDelete).length;
        const widthImages = widthImage && (widthImage + this.offsetImage) * lenFiles;
        const widthInnerResult =
            widthImage && lenFiles !== 0 ? widthInner - widthImage - this.offsetImage : widthInner;

        return (
            <div className={`body__popup _cargoImages ${state === 1 ? '_show' : ''} `}>
                <div
                    ref={this.parent}
                    className={`popup _col _cargoImages ${preview.state === 1 ? '_showPreview' : ''}`}
                >
                    <div ref={this.inner} className="popup__inner _col">
                        {device === 'desktop' && (
                            <i
                                className="popup__close _click"
                                onClick={() => {
                                    handlerPopup({ action: 'hide', name: 'popupOrderCargoImages' });
                                }}
                            >
                                <Icon name="delete-circle" />
                            </i>
                        )}
                        <div className="popup__innerBox _col">
                            {preview.state > -1 && (
                                <div
                                    className={`popup__preview ${
                                        preview.state === 1 ? '_show' : ''
                                    }`}
                                    style={{
                                        left: `${preview.position[0]}px`,
                                        top: `${preview.position[1]}px`,
                                        width: `${preview.width}px`,
                                        height: `${preview.height}px`,
                                    }}
                                >
                                    {this.renderImage({ path: preview.path })}
                                </div>
                            )}
                            <div className="popup__innerContent">
                                <p className="popup__support">ЗАГРУЗИТЕ ФОТО ГРУЗА</p>
                                <h3 className="popup__title">
                                    Не знаете размеры вашего&nbsp;груза?
                                </h3>
                                <p className="popup__description">
                                    Если вы не знаете размеры вашего груза, то просто загрузите его
                                    фотографию — нейросеть автоматически рассчитает габариты
                                    и&nbsp;занимаемое пространство
                                </p>
                                <div ref={this.scrollAreaX} className="popup__actions">
                                    <div
                                        className={`popup__actionsInner ${
                                            findDelete ? '_isDelete' : ''
                                        }`}
                                    >
                                        <label
                                            className={`popup__upload ${
                                                dragIsActive ? '_drag' : ''
                                            }`}
                                            style={{ width: `${widthInnerResult}px` }}
                                        >
                                            <input
                                                type="file"
                                                className="popup__uploadInput"
                                                onChange={this.handlerUpload}
                                                disabled={isLoad}
                                                multiple={!fromContent}
                                            />
                                            <div className="popup__uploadBack">
                                                <BorderDashed isFull={true} className="_upload" />
                                            </div>
                                            <Animate
                                                className="popup__uploadView _col _click"
                                                isShow={!dragIsActive}
                                            >
                                                <div className="popup__uploadIcon">
                                                    <AnimateChange
                                                        prop={isLoad}
                                                        className={`popup__uploadIconItem`}
                                                        type="_loader"
                                                    >
                                                        <>
                                                            {isLoad ? (
                                                                <div className="popup__uploadIconItem _isLoad">
                                                                    <Loader className="_main" />
                                                                </div>
                                                            ) : (
                                                                <i className="popup__uploadIconItem">
                                                                    <Icon name="file-upload" />
                                                                </i>
                                                            )}
                                                        </>
                                                    </AnimateChange>
                                                </div>
                                                <p className="popup__uploadDescription">
                                                    Загрузите <br />
                                                    или перетащите <br />
                                                    сюда <span>фото груза</span>
                                                </p>
                                            </Animate>
                                            <Animate
                                                className="popup__uploadDrag _col"
                                                isShow={dragIsActive}
                                            >
                                                <div className="popup__uploadIcon">
                                                    <i className="popup__uploadIconItem">
                                                        <Icon name="file-drag" />
                                                    </i>
                                                </div>
                                                <p className="popup__uploadDescription">
                                                    <span>Отпустите</span> <br />
                                                    курсор мыши <br />
                                                    для загрузки файла
                                                </p>
                                            </Animate>
                                        </label>
                                        <div
                                            className="popup__images _row"
                                            style={{ width: `${widthImages}px` }}
                                        >
                                            {files.map((file, key) => {
                                                let left =
                                                    widthImage &&
                                                    widthImage * key + this.offsetImage * (key + 1);
                                                const keyOfDelete = files.findIndex(
                                                    (item) => item.isDelete,
                                                );

                                                if (keyOfDelete !== -1 && keyOfDelete < key) {
                                                    left -= widthImage + this.offsetImage;
                                                }

                                                return (
                                                    <div
                                                        className={`popup__imagesItem _click ${
                                                            file.stateOfShow === 1 ? '_show' : ''
                                                        } ${file.isDelete ? '_delete' : ''}`}
                                                        style={{
                                                            transform: `translate(${left}px,0px)`,
                                                        }}
                                                        key={file.id}
                                                    >
                                                        {this.renderImage({ key, ...file })}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(PopupCargoImages);

PopupCargoImages.propTypes = {
    state: PropTypes.number,
    addItems: PropTypes.func,
    images: PropTypes.array,
    deleteImage: PropTypes.func,
    device: PropTypes.string,
    fromContent: PropTypes.bool,
};
