export default function getInfoRoute(distance = 0, durationRes = 0) {
    const time = +durationRes.toFixed(0);
    const hours = Math.floor(time / 60);
    const minutes = time - Math.floor(time / 60) * 60;
    const duration = time;

    return {
        distance: Math.round(+distance / 1000),
        hours,
        minutes,
        duration,
    };
}
