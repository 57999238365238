import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Order from './Order.jsx';

class OrderPopups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderPopup = this.renderPopup.bind(this);
    }

    getPopups() {
        const { pagesStore, levels, appOrdersPopups } = this.props;

        return appOrdersPopups
            .filter((id) => !(pagesStore['order-details'].isShow && levels[1] === id))
            .map((id) => ({ id }));
    }

    renderPopup({ prop: id }) {
        return (
            <div className="body__appPopup">
                <Order id={id} />
            </div>
        );
    }

    render() {
        return (
            <ListAbsoluteMain
                className="body__appPopups"
                items={this.getPopups()}
                renderItem={this.renderPopup}
                classNameItem="body__appPopup"
                prop="id"
                paramsParent={{ width: true }}
                styles={[]}
                isNotParams={true}
                isNotParamsItem={true}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        appOrdersPopups: state.appOrdersPopups,
        pagesStore: state.pages,
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(OrderPopups);

OrderPopups.propTypes = {
    appOrdersPopups: PropTypes.array,
    pagesStore: PropTypes.object,
    levels: PropTypes.array,
};
