import pages from '../redux/pages';

const pagesIndexes = {};

pages.forEach((page) => {
    pagesIndexes[page.name] = page;
});

export default function getPage({ name }) {
    return pagesIndexes[name];
}
