import { store } from '../redux/redux';
import getCurrentPage from './getCurrentPage';

export default function setTheme(
    theme = localStorage.getItem('theme') || 'light',
    pages = store.getState().pages,
    isHideStartPopup = store.getState().isHideStartPopup,
) {
    const themeMeta = document.querySelector('meta[name="theme-color"]');

    localStorage.setItem('theme', theme);
    document.querySelector('html').setAttribute('data-theme', theme);

    const currentPage = getCurrentPage({ pages, filter: (page) => !page.level });
    let color = theme === 'dark' ? '#1C1F21' : '#fff';

    if (process.env.REACT_APP_SYSTEM === 'app') {
        color = theme === 'dark' ? '#2B2F33' : '#fff';

        if (currentPage === 'index') {
            color = theme === 'dark' ? '#1C1F21' : '#f5f7fa';
        }

        if (['corporations-list', 'start', 'auth'].includes(currentPage)) {
            if (theme === 'dark') {
                color = '#1C1F21';
            }
        }

        if (!isHideStartPopup) {
            color = theme === 'dark' ? '#1C1F21' : '#fff';
        }
    }

    themeMeta.setAttribute('content', color);
}
