import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';

class AuthCode extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.sendAgain = this.sendAgain.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'authCodePopup', isShow: false });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    sendAgain() {
        const { checkPhone } = this.props;

        this.handlerLoading('check').then(() => {
            checkPhone().then(
                () => {
                    this.handlerLoading(null);

                    this.hide();
                },
                () => {
                    this.handlerLoading(null);
                },
            );
        });
    }

    render() {
        const { loadingKey } = this.state;
        const { phone, changePhone } = this.props;

        return (
            <div ref={this.parent} className="appPopup _col">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <img
                                src={require('../../../img/emodzi/man-shrugging.png')}
                                alt=""
                                className="appPopup__icon"
                            />
                            <div className="appPopup__title">
                                Не пришёл код
                                <br />
                                подтверждения?
                            </div>
                            <p className="appPopup__description">
                                Проверьте, верно ли введён ваш номер&nbsp;телефона: <b>{phone}</b>
                                <br />
                                Если всё верно — нажмите кнопку «Отправить код повторно»
                            </p>
                            <div className="appPopup__button">
                                <Button
                                    onClick={this.sendAgain}
                                    showLoader={loadingKey === 'check'}
                                >
                                    Отправить код повторно
                                </Button>
                            </div>
                            <div className="appPopup__link">
                                <div
                                    className="link _click"
                                    onClick={() => {
                                        changePhone();

                                        this.hide();
                                    }}
                                >
                                    Изменить номер телефона
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(AuthCode);

AuthCode.propTypes = {
    phone: PropTypes.string,
    changePhone: PropTypes.func,
    checkPhone: PropTypes.func,
    heightWindow: PropTypes.number,
};
