import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import Avatar from '../../Avatar.jsx';
import toSignContract from '../../../functions/app/toSignContract';

class Contract extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.toSignContract = this.toSignContract.bind(this);
        this.hide = this.hide.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appContractPopup', isShow: false });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    async toSignContract() {
        const { appContractPopup } = this.props;
        const { corporation } = appContractPopup;
        const { id } = corporation;

        await this.handlerLoading('sign');

        toSignContract({ corporationId: id }).then(() => {
            this.hide();
            this.handlerLoading(null);
        });
    }

    render() {
        const { loadingKey } = this.state;
        const { appContractPopup } = this.props;
        const { corporation } = appContractPopup;
        const { logo, name } = corporation;

        return (
            <div ref={this.parent} className="appPopup _col _joinCorporation">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <Avatar
                                src={
                                    logo?.path &&
                                    `${process.env.REACT_APP_STATIC}/corporations/${logo.path}`
                                }
                                holder={{
                                    text: name?.[0]?.toUpperCase(),
                                }}
                                className="appPopup__icon"
                                withText={true}
                                iconType={logo?.type}
                            />
                            <div className="appPopup__title">{name}</div>
                            <p className="appPopup__description">
                                Для выполнения заказов необходимо подписать договор
                            </p>
                            <div className="appPopup__button">
                                <Button
                                    onClick={this.toSignContract}
                                    showLoader={loadingKey === 'sign'}
                                >
                                    Подписать договор
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
        appContractPopup: state.appContractPopup,
    };
}

export default connect(mapStateToProps)(Contract);

Contract.propTypes = {
    heightWindow: PropTypes.number,
    appContractPopup: PropTypes.object,
};
