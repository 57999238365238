import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Back from '../../components/app/Back.jsx';
import Icon from '../../components/Icon.jsx';

import getFormatPrice from '../../functions/getFormatPrice';
import Button from '../../components/Button.jsx';
import changePage from '../../functions/changePage';

import changeCorporation from '../../functions/app/changeCorporation';

class CorporationsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            afterReg: localStorage.getItem('afterReg'),
        };

        this.getContentHeight = this.getContentHeight.bind(this);
        this.setCorporation = this.setCorporation.bind(this);

        this.parent = React.createRef();
    }

    changeCorporation(idOfCurrentCorporation) {
        this.setState({ idOfCurrentCorporation });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    setCorporation() {
        const { idOfCurrentCorporation } = this.state;
        const { user } = this.props;

        if (idOfCurrentCorporation === user.idOfCurrentCorporation) {
            localStorage.removeItem('afterReg');

            changePage({ href: '' });
        } else {
            this.handlerLoading(true).then(() => {
                changeCorporation({ id: idOfCurrentCorporation }).then(() => {
                    this.handlerLoading(null);
                });
            });
        }
    }

    getContentHeight() {
        const { heightWindow } = this.props;

        if (this.parent.current) {
            const head = this.parent.current.querySelector('.corporationsPopup__head');
            const foot = this.parent.current.querySelector('.corporationsPopup__foot');

            return heightWindow - 40 - head.offsetHeight - foot.offsetHeight;
        }

        return 0;
    }

    componentDidMount() {
        const { user } = this.props;

        this.setState({ idOfCurrentCorporation: user.idOfCurrentCorporation });
    }

    render() {
        const { idOfCurrentCorporation, afterReg, loadingKey } = this.state;
        const { user } = this.props;

        return (
            <div ref={this.parent} className="corporationsPopup">
                <div className="corporationsPopup__inner _col">
                    <div className="corporationsPopup__head _col">
                        <div className="corporationsPopup__back">
                            <Back
                                isShow={!afterReg}
                                handler={() => {
                                    changePage({ href: 'settings-profile' });
                                }}
                            />
                        </div>

                        <img
                            src={require('../../img/emodzi/arms-up.png')}
                            alt=""
                            className="corporationsPopup__icon"
                        />
                        <div className="corporationsPopup__title">Выберите компанию</div>
                        <p className="corporationsPopup__description">
                            Вы всегда можете сменить
                            <br />
                            компанию в настройках профиля
                        </p>
                    </div>
                    <div className="corporationsPopup__content">
                        <div
                            className="corporationsPopup__cards"
                            style={{ maxHeight: this.getContentHeight() }}
                        >
                            {(user?.corporations || [])
                                .filter((item) => item.id !== 'admin')
                                .map((corporation) => {
                                    const { name, balance } = corporation;

                                    return (
                                        <div
                                            className={`corporationsPopup__card ${
                                                corporation.id === idOfCurrentCorporation
                                                    ? '_current'
                                                    : ''
                                            }`}
                                            key={corporation.id}
                                            onClick={() => {
                                                this.changeCorporation(corporation.id);
                                            }}
                                        >
                                            <div className="corporationsPopup__cardName">
                                                {name}
                                            </div>
                                            <div className="corporationsPopup__cardBalance">
                                                Баланс: {getFormatPrice(balance)}₽
                                            </div>
                                            <div className="corporationsPopup__cardPoint _col">
                                                <i className="corporationsPopup__cardPointIcon">
                                                    <Icon name="done" />
                                                </i>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div className="corporationsPopup__foot">
                        <div className="corporationsPopup__button">
                            <Button
                                icon={{ type: 'end', name: 'arrow-next-2' }}
                                onClick={this.setCorporation}
                                showLoader={loadingKey}
                            >
                                Далее
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        heightWindow: state.heightWindow,
        windowIsReady: state.windowIsReady,
    };
}

export default connect(mapStateToProps)(CorporationsPopup);

CorporationsPopup.propTypes = {
    user: PropTypes.object,
    heightWindow: PropTypes.number,
    windowIsReady: PropTypes.bool,
};
