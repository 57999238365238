import React from 'react';
import PropTypes from 'prop-types';

class HeadPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setContentHeight = this.setContentHeight.bind(this);
        this.renderTabs = this.renderTabs.bind(this);
        this.setPositionCurrentTab = this.setPositionCurrentTab.bind(this);

        this.parent = React.createRef();
    }

    setPositionCurrentTab() {
        const tabBack = this.parent.current.querySelector('.pageHead__tabsBack');

        if (tabBack) {
            const currentTab = this.parent.current
                .querySelector('.pageHead__tabInput:checked')
                .closest('.pageHead__tab');
            const { offsetLeft: left, offsetWidth: width } = currentTab;

            tabBack.style.transform = `translate(${left}px,0px)`;
            tabBack.style.width = `${width}px`;
        }
    }

    renderTabs(settings) {
        const widthAuto = settings?.widthAuto;

        return (
            <div
                className={`pageHead__tabs _row ${widthAuto ? '_widthAuto' : ''} ${
                    settings?.className || ''
                }`}
            >
                <div className="pageHead__tabsInner _row">
                    <div className="pageHead__tabsBack"></div>
                    {this.tabsOrder.map((name) => {
                        const tab = this.tabs[name];
                        const content =
                            typeof tab.content === 'function'
                                ? tab.content.call(this)
                                : tab.content;

                        return (
                            <label className="pageHead__tab" key={name}>
                                <input
                                    type="radio"
                                    className="pageHead__tabInput"
                                    checked={this.checkCurrentTab(name)}
                                    onChange={() => this.changeTab(name)}
                                />
                                <div className="pageHead__tabView _row">{content}</div>
                            </label>
                        );
                    })}
                </div>
            </div>
        );
    }

    setContentHeight() {
        const head = this.parent.current.querySelector('.headPage__head');
        const inner = this.parent.current.querySelector('.headPage__inner');
        const contentHeight = this.parent.current.offsetHeight - head.offsetHeight + 20;

        inner.style.height = `${contentHeight}px`;

        if (this.contentHeight !== contentHeight) {
            this.setState({ updateHeightKey: new Date().getTime() });
        }

        this.contentHeight = contentHeight;
    }

    componentDidMount() {
        this.setContentHeight();
        this.setPositionCurrentTab();

        document.addEventListener('changeHeightWindow', this.setContentHeight);
    }

    componentDidUpdate() {
        this.setContentHeight();
        this.setPositionCurrentTab();
    }

    componentWillUnmount() {
        document.removeEventListener('changeHeightWindow', this.setContentHeight);
    }
}

export default HeadPage;

HeadPage.propTypes = {
    user: PropTypes.object,
};
