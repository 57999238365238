import React from 'react';
import PropTypes from 'prop-types';

import MobilePopup from '../classes/MobilePopup';

import handlerPopup from '../functions/handlerPopup';

class PopupMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        const { inner, name } = props;

        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.handlerDisabledEvent = this.handlerDisabledEvent.bind(this);
        this.hide = this.hide.bind(this);

        this.name = name;
        this.inner = inner;
    }

    hide() {
        const { callbackHide } = this.props;

        if (callbackHide && typeof callbackHide === 'function') {
            setTimeout(() => {
                callbackHide();
            }, 300);
        } else if (this.callbackHide && typeof this.callbackHide === 'function') {
            setTimeout(() => {
                this.callbackHide();
            }, 300);
        }

        if (this.mobilePopup) {
            this.mobilePopup.hide();
        }

        handlerPopup({
            action: 'hide',
            name: this.name,
        });
    }

    handlerDisabledEvent(isDisabledEvent) {
        this.setState({ isDisabledEvent });
    }

    initPopup() {
        const { device } = this.props;

        setTimeout(() => {
            this.setState({ isInit: true });
        }, 700);

        if (device === 'mobile') {
            this.mobilePopup = new MobilePopup({
                parent: this.inner.current,
                getCondForChange: () =>
                    !this.state.isDisabledEvent && this.state.isInit && !this.isScrollAreaX,
                handler: (action) => {
                    const { isDisabledEvent } = this.state;

                    if (action === 'hide' && !isDisabledEvent && !this.props.isProccessChangePage) {
                        this.hide();
                    }
                },
            });

            this.mobilePopup.init();
        }
    }

    handlerMissClick({ target }) {
        const { isInit, isDisabledEvent } = this.state;
        const inner = this.inner.current;

        if (!isDisabledEvent && isInit && inner && target !== inner && !inner.contains(target)) {
            this.hide();
        }
    }

    startY = 0;

    moveY = 0;

    startMove = false;

    notScroll = false;

    counterMove = 0;

    isScrollAreaX = false;

    timerIdScrollAreaX = null;

    componentDidMount() {
        this.initPopup();

        if (this.scrollArea) {
            this.scrollArea.current.addEventListener('touchstart', (e) => {
                this.startY = e.changedTouches[0].pageY;
            });

            this.scrollArea.current.addEventListener(
                'touchmove',
                (e) => {
                    this.moveY = e.changedTouches[0].pageY - this.startY;

                    if (
                        (this.notScroll && this.counterMove > 1) ||
                        (this.scrollArea.current.offsetHeight + this.scrollArea.current.scrollTop >=
                            this.scrollArea.current.scrollHeight - 2 &&
                            this.moveY < 0)
                    ) {
                        e.preventDefault();
                    }

                    if (this.scrollArea.current.scrollTop > 0 || this.startMove) {
                        if (!this.startMove) {
                            this.startMove = true;
                        }

                        e.stopPropagation();
                    } else {
                        this.counterMove += 1;

                        this.notScroll = true;
                    }
                },
                { passive: false },
            );

            this.scrollArea.current.addEventListener('touchend', () => {
                this.startMove = false;
                this.notScroll = false;
                this.counterMove = 0;
            });
        }

        if (this.scrollAreaX) {
            this.scrollAreaX.current.addEventListener('scroll', () => {
                if (this.timerIdScrollAreaX) {
                    clearTimeout(this.timerIdScrollAreaX);
                }
                this.isScrollAreaX = true;

                this.timerIdScrollAreaX = setTimeout(() => {
                    this.isScrollAreaX = false;
                }, 500);
            });
        }

        if (this.componentDidMountOther && typeof this.componentDidMountOther === 'function') {
            this.componentDidMountOther();
        }

        this.parent.current.addEventListener(
            'touchmove',
            (e) => {
                if (
                    (!this.scrollArea ||
                        (this.scrollArea.current &&
                            this.scrollArea.current !== e.target &&
                            !this.scrollArea.current.contains(e.target))) &&
                    (!this.scrollAreaX ||
                        (this.scrollAreaX.current &&
                            this.scrollAreaX.current !== e.target &&
                            !this.scrollAreaX.current.contains(e.target)))
                ) {
                    e.preventDefault();
                }
            },
            { passive: false },
        );

        document.addEventListener('mouseup', this.handlerMissClick);
    }

    componentWillUnmount() {
        if (this.mobilePopup) {
            this.mobilePopup.remove();
        }

        if (this.componentWillUnmountOther) {
            this.componentWillUnmountOther();
        }

        document.removeEventListener('mouseup', this.handlerMissClick);
    }
}

export default PopupMobile;

PopupMobile.propTypes = {
    state: PropTypes.number,
    device: PropTypes.string,
    inner: PropTypes.object,
    name: PropTypes.string,
    scrollArea: PropTypes.object,
    callbackHide: PropTypes.func,
    isProccessChangePage: PropTypes.bool,
};
