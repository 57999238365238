import pages from '../redux/pages';
import getPage from './getPage';

import getPageLevel from './getPageLevel';

const pagesIndexes = {
    levels: {},
    parents: {},
};

pages.forEach((page) => {
    const level = getPageLevel(page);

    if (!pagesIndexes.levels[level]) {
        pagesIndexes.levels[level] = [];
    }

    pagesIndexes.levels[level].push(page);

    if (page.parentName) {
        if (!pagesIndexes.parents[page.parentName]) {
            pagesIndexes.parents[page.parentName] = {};
        }

        if (!pagesIndexes.parents[page.parentName][level]) {
            pagesIndexes.parents[page.parentName][level] = [];
        }

        pagesIndexes.parents[page.parentName][level].push(page);
    }
});

export default function getPageInfo({ name }) {
    const page = getPage({ name });

    let nearChilds = [];
    let childs = [];
    let nearParent;
    const parents = [];
    let parent;

    if (page) {
        const level = getPageLevel(page);

        nearChilds = pagesIndexes.parents[page.name]?.[level + 1] || [];

        // nearChilds = pages.filter(
        //     (loopPage) => loopPage.parentName === page.name && getPageLevel(loopPage) - 1 === level,
        // );

        if (level) {
            nearParent = getPage({ name: page.parentName });
        }

        let currentChilds = [...nearChilds];
        childs = [...nearChilds];

        while (currentChilds.length !== 0) {
            const thisChilds = [];

            currentChilds.forEach((child) => {
                const { childs: nextChilds } = getPageInfo({
                    name: child.name,
                });

                thisChilds.push(...nextChilds);
            });

            childs.push(...thisChilds);

            currentChilds = thisChilds;
        }

        if (level) {
            const { parentName } = page;
            let parentPage = getPage({ name: parentName });

            parents.push(parentPage.name);

            while (parentPage.parentName) {
                parentPage = getPage({ name: parentPage.parentName });

                parents.push(parentPage.name);
            }

            parent = parentPage;
        }
    }

    // console.count('rend');

    return {
        nearChilds,
        childs,
        nearParent,
        parent,
        parents,
    };
}
