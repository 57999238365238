import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import Button from '../../Button.jsx';
import removeTransition from '../../../functions/removeTransition.ts';
import DragBox from '../../DragBox.jsx';
import Ymaps from '../../Ymaps.jsx';

import setHeightItems from '../../../functions/setHeightItems';
import Point from './orderPoint/Point.jsx';
import getEndText from '../../../functions/getEndText';

import getHeaders from '../../../functions/getHeaders';
import OptimizationBtn from '../../order/OptimizationBtn.jsx';
import { optimizationRoutes } from '../../../functions/handlerYmap';

class NewPoints extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightItems = this.setHeightItems.bind(this);
        this.getPoints = this.getPoints.bind(this);
        this.addPoint = this.addPoint.bind(this);
        this.callbackOrder = this.callbackOrder.bind(this);
        this.editPoint = this.editPoint.bind(this);
        this.save = this.save.bind(this);
        this.setOptimization = this.setOptimization.bind(this);
        this.mapBootstrap = this.mapBootstrap.bind(this);

        this.hide = this.hide.bind(this);
    }

    offset = 3;

    classItem = '.appPopupPoint__point';

    classItemStatic = '.appPopupPoint__pointWrapper';

    setHeightItems() {
        const { points } = this.state;

        setHeightItems.call(this, {
            parent: this.parent.current,
            items: points,
            classItem: this.classItem,
            classItemStatic: this.classItemStatic,
            prop: '_id',
        });
    }

    callbackOrder({ newOrder }) {
        this.setState((state) => {
            const newState = { ...state };
            const points = JSON.parse(JSON.stringify(newState.points));
            const newRoute = [];
            let isChange = false;

            newOrder.forEach((newIndex, key) => {
                newRoute[newIndex] = points[key];

                if (newIndex !== key) {
                    isChange = true;
                }
            });

            newState.points = newRoute;

            if (isChange) {
                newState.updateKey = new Date().getTime();
                newState.isOptimize = false;
            }

            return newState;
        });
    }

    editPoint({ id }) {
        const { points } = this.state;
        const point = points.find((innerPoint) => innerPoint._id === id);

        handlerPopup({
            name: 'appNewPointPopup',
            isShow: true,
            point,
            addPoint: this.addPoint,
        });
    }

    addPoint({ point, deletedId }) {
        if (deletedId && this.state.points.length === 1) {
            this.hide();
        } else {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const points = JSON.parse(JSON.stringify(newState.points));

                    const index = points.findIndex(
                        (innerPoint) => innerPoint._id === (deletedId || point._id),
                    );

                    if (deletedId) {
                        points.splice(index, 1);
                    } else if (index !== -1) {
                        points[index] = {
                            ...points[index],
                            ...point,
                        };
                    } else {
                        points.push(point);
                    }

                    newState.isOptimize = false;

                    newState.points = points;

                    return newState;
                },
                () => {
                    setTimeout(() => {
                        this.setState({ updateKey: new Date().getTime() });
                    }, 300);
                },
            );
        }
    }

    getPoints() {
        const { points = [] } = this.state;

        return points.map((point, index) => ({ ...point, index }));
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appNewPointsPopup', isShow: false });
    }

    mapBootstrap({ ymaps, map }) {
        this.setState({ ymaps, map });
    }

    setOptimization() {
        const { ymaps, map, points = [], isOptimize } = this.state;
        const { lastPoint } = this.props;
        const newPoints = [];
        const newOrder = [];

        if (ymaps && map && points.length > 1) {
            this.handlerLoadingKey('optimization').then(() => {
                if (isOptimize) {
                    this.state.oldOrder.forEach((index, key) => {
                        newPoints[index] = points[key];
                    });

                    this.setState(
                        { newOrder: this.state.oldOrder, oldOrder: null, isOptimize: false },
                        () => {
                            this.handlerLoadingKey(null);

                            setTimeout(() => {
                                this.setState({
                                    points: newPoints,
                                    updateKey: new Date().getTime(),
                                    newOrder: null,
                                });
                            }, 300);
                        },
                    );
                } else {
                    optimizationRoutes(ymaps, map, [lastPoint, ...points], true).then(
                        ({ points: pointsUpdate }) => {
                            points.forEach((point, key) => {
                                const newIndex = pointsUpdate.findIndex(
                                    (item) => item._id === point._id,
                                );

                                newOrder[key] = newIndex;
                                newPoints[newIndex] = points[key];
                            });

                            const oldOrder = [];

                            newPoints.forEach((newPoint, key) => {
                                const oldIndex = points.findIndex(
                                    (innerPoint) => innerPoint._id === newPoint._id,
                                );

                                oldOrder[key] = oldIndex;
                            });

                            this.setState({ newOrder, oldOrder, isOptimize: true }, () => {
                                this.handlerLoadingKey(null);

                                setTimeout(() => {
                                    this.setState({
                                        points: newPoints,
                                        updateKey: new Date().getTime(),
                                        newOrder: null,
                                    });
                                }, 300);
                            });
                        },
                    );
                }
            });
        }
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    save() {
        const { orderId } = this.props;
        const { points } = this.state;

        this.handlerLoadingKey('save').then(() => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/order`,
                    {
                        type: 'add-points',
                        id: orderId,
                        points,
                    },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        const { success } = res.data;

                        if (!success) {
                            this.hide();
                        }
                    },
                    () => null,
                );
        });
    }

    componentDidMount() {
        super.componentDidMount();

        const { point } = this.props;

        this.setState({ points: [point] });
    }

    render() {
        const { updateKey, heightItems, loadingKey, isOptimize, newOrder } = this.state;
        const points = this.getPoints();

        return (
            <div ref={this.parent} className="appPopup _col _withFixFoot">
                <Ymaps forCalc={true} callback={this.mapBootstrap} />
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appPopup__title">Вы добавили точку</div>
                            <p className="appPopup__description">
                                {points.length}{' '}
                                {getEndText(points.length, ['адрес', 'адреса', 'адресов'])}. Можно
                                ехать дальше:
                            </p>
                            <div className="appPopupPoint">
                                <div className="appPopupPoint__points _margin">
                                    <DragBox
                                        className="appPopupPoint__pointsInner"
                                        classItem={this.classItem}
                                        classItemStatic={this.classItemStatic}
                                        key={updateKey}
                                        items={points}
                                        setHeightItems={this.setHeightItems}
                                        heightItems={heightItems}
                                        offset={this.offset}
                                        prop="_id"
                                        callbackInit={() => {
                                            removeTransition({
                                                item: '.appPopupPoint__pointsInner,.appPopupPoint__point',
                                            });
                                        }}
                                        sort={newOrder}
                                    >
                                        {points.map((innerPoint, pointIndex) => (
                                            <div
                                                className="appPopupPoint__point"
                                                data-id={innerPoint._id}
                                                key={innerPoint._id}
                                            >
                                                <Point
                                                    point={innerPoint}
                                                    isDrag={points.length > 1}
                                                    offset={this.offset}
                                                    index={pointIndex}
                                                    callbackOrder={this.callbackOrder}
                                                    editPoint={this.editPoint}
                                                />
                                            </div>
                                        ))}
                                    </DragBox>
                                </div>
                            </div>
                            <div className="appPopup__foot _col">
                                <div className="appPopup__button">
                                    <OptimizationBtn
                                        isForce={points.length > 1}
                                        isOptimize={isOptimize}
                                        handler={this.setOptimization}
                                        isProccessOptimization={loadingKey === 'optimization'}
                                    />
                                </div>
                                <div className="appPopup__button">
                                    <Button
                                        className="_blue"
                                        onClick={() => {
                                            handlerPopup({
                                                name: 'appNewPointPopup',
                                                isShow: true,
                                                addPoint: this.addPoint,
                                            });
                                        }}
                                    >
                                        + Добавить ещё точку
                                    </Button>
                                </div>
                                <div className="appPopup__button">
                                    <Button onClick={this.save} showLoader={loadingKey === 'save'}>
                                        Поехали!
                                    </Button>
                                </div>
                                <div className="appPopup__link">
                                    <div className="link _click _alert _bold" onClick={this.hide}>
                                        Отменить
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(NewPoints);

NewPoints.propTypes = {
    orderId: PropTypes.string,
    point: PropTypes.object,
    lastPoint: PropTypes.object,
};
