import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Drag from '../../../../classes/Drag';

import InputRunning from '../../../InputRunning.jsx';
import Icon from '../../../Icon.jsx';

class Point extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    initDrag() {
        const { offset, index, callbackOrder } = this.props;

        this.handlerPoint = new Drag({
            name: 'app-order-point',
            key: index,
            card: this.parent.current.closest('.appPopupPoint__point'),
            dragBtn: this.parent.current.querySelector('.appPopupPoint__pointDrag'),
            classCard: '.appPopupPoint__point',
            classParent: '.appPopupPoint__pointsInner',
            // getNewOrder: handlerRoute.getNewOrder,
            callback: callbackOrder,
            offset,
            // indexCurrentItem: point.index,
            callbackMove: (isProccessDrag) => this.setState({ isProccessDrag }),
            // getCondForMove: () => !this.props.isProccessSetRoute,
        });

        this.handlerPoint.init();
    }

    componentDidMount() {
        // const { isCurrent } = this.props;

        // if (isCurrent) {
        this.initDrag();
        // }
    }

    render() {
        const { point, isCurrent, isDrag, editPoint } = this.props;
        const condForFrag = isCurrent || isDrag;

        return (
            <div
                ref={this.parent}
                className={`appPopupPoint__pointWrapper ${condForFrag ? '_withDrag' : ''}`}
            >
                <div className="appPopupPoint__pointInner">
                    <InputRunning
                        type="text"
                        className="appPopupPoint__pointAddress"
                        value={point?.address}
                        autoComplete="off"
                        readOnly={true}
                        isRun={true}
                    />
                    <div className="appPopupPoint__pointDescription">
                        {point?.sk || point?.ttk ? <>· {point.sk ? 'СК' : 'ТТК'}</> : <></>}

                        {editPoint ? (
                            <>
                                <span onClick={() => editPoint({ id: point._id })}>
                                    Редактировать
                                </span>
                            </>
                        ) : (
                            <>
                                <span>Точка {point?.index === 0 ? 'погрузки' : 'выгрузки'}</span>
                            </>
                        )}
                    </div>
                </div>
                {condForFrag && (
                    <div className="appPopupPoint__pointDrag _col">
                        <div className="appPopupPoint__pointDragIcon">
                            <Icon name="app-point-drag" />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Point);

Point.propTypes = {
    point: PropTypes.object,
    isCurrent: PropTypes.bool,
    offset: PropTypes.number,
    index: PropTypes.number,
    callbackOrder: PropTypes.func,
    isDrag: PropTypes.bool,
    editPoint: PropTypes.func,
};
