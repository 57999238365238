import axios from 'axios';

import getHeaders from '../getHeaders';

import logoutActions from './logoutActions';

export default function logout() {
    return new Promise((resolve) => {
        axios
            .patch(
                `${process.env.REACT_APP_API}/login-app`, { action: 'logout' }, { headers: getHeaders() },
            )
            .then(
                (res) => {
                    const { success } = res.data;

                    if (success) {
                        logoutActions();
                    }

                    resolve();
                },
                () => null,
            );
    });
}