import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Pagination from '../Pagination.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Inner from './docs/Inner.jsx';
import Back from '../Back.jsx';
import Code from './docs/Code.jsx';
import Qr from './docs/Qr.jsx';

class OrderDocs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: null,
        };

        this.renderBlock = this.renderBlock.bind(this);
        this.setUpdateKey = this.setUpdateKey.bind(this);
        this.handlerCurrentBlock = this.handlerCurrentBlock.bind(this);
    }

    getBlocksOrder() {
        const { order } = this.props;

        return order?.deliveryType === 'sampling' ? ['qr', 'code'] : ['docs', 'images', 'code'];
    }

    getCurrentBlock() {
        const { currentBlock } = this.state;

        return currentBlock;
    }

    handlerCurrentBlock({ currentBlock }) {
        this.setState({ currentBlock });
    }

    getBlocks() {
        return [{ key: this.getCurrentBlock() }];
    }

    blocks = {
        qr: {
            render() {
                const { order, point, loadingKey, handlerLoading } = this.props;

                return (
                    <Qr
                        point={point}
                        order={order}
                        loadingKey={loadingKey}
                        handlerLoading={handlerLoading}
                        handlerCurrentBlock={this.handlerCurrentBlock}
                    />
                );
            },
        },
        docs: {
            render() {
                const { order, point, loadingKey, handlerLoading } = this.props;

                return (
                    <Inner
                        name="docs"
                        setUpdateKey={this.setUpdateKey}
                        handlerCurrentBlock={this.handlerCurrentBlock}
                        point={point}
                        order={order}
                        loadingKey={loadingKey}
                        handlerLoading={handlerLoading}
                    />
                );
            },
        },
        images: {
            render() {
                const { order, point, loadingKey, handlerLoading } = this.props;

                return (
                    <Inner
                        name="images"
                        setUpdateKey={this.setUpdateKey}
                        handlerCurrentBlock={this.handlerCurrentBlock}
                        point={point}
                        order={order}
                        loadingKey={loadingKey}
                        handlerLoading={handlerLoading}
                    />
                );
            },
        },
        code: {
            render() {
                const {
                    order,
                    handlerCurrentBlock,
                    handlerPoint,
                    loadingKey,
                    errors,
                    handlerErrors,
                } = this.props;

                return (
                    <Code
                        order={order}
                        setUpdateKey={this.setUpdateKey}
                        handlerCurrentBlock={handlerCurrentBlock}
                        handlerPoint={handlerPoint}
                        errors={errors}
                        handlerErrors={handlerErrors}
                        loadingKey={loadingKey}
                    />
                );
            },
        },
    };

    renderBlock({ prop: key }) {
        const block = this.blocks[key];

        return <div className="appOrder__docsBlock _col">{block?.render.call(this)}</div>;
    }

    renderHead() {
        const { order } = this.props;
        const currentBlock = this.getCurrentBlock();

        return (
            <div className="appOrder__docsHead _row">
                <AnimateChangeUp className="appOrder__docsHeadInfo" renderKey={currentBlock}>
                    {['qr', 'docs'].includes(currentBlock) ? (
                        <>
                            <div className="appOrder__docsHeadInfoSupport">Вы на месте</div>
                        </>
                    ) : (
                        <>
                            <Back
                                handler={() => {
                                    this.handlerCurrentBlock({
                                        currentBlock:
                                            currentBlock === 'code'
                                                ? order.deliveryType === 'sampling'
                                                    ? 'qr'
                                                    : 'images'
                                                : 'docs',
                                    });
                                }}
                            />
                        </>
                    )}
                </AnimateChangeUp>
                <div className="appOrder__docsHeadPagination">
                    <Pagination items={this.getBlocksOrder()} currentName={currentBlock} />
                </div>
            </div>
        );
    }

    setUpdateKey() {
        this.setState({ updateKey: new Date().getTime() });
    }

    setStartCurrent() {
        const { order, point } = this.props;
        let currentBlock = order?.deliveryType === 'sampling' ? 'qr' : 'docs';
        const docsDoc = point.docs.find((doc) => doc.key === 'docs-on-point');
        const imagesDoc = point.docs.find((doc) => doc.key === 'images-on-point');

        if (docsDoc?.files.length) {
            currentBlock = 'images';
        }

        if (imagesDoc?.files.length) {
            currentBlock = 'code';
        }

        this.setState({ currentBlock });
    }

    componentDidMount() {
        this.setStartCurrent();
    }

    render() {
        const { updateKey } = this.state;
        const { renderActions, setUpdateKey, point } = this.props;

        return (
            <div className="appOrder__docs">
                {this.renderHead()}
                <ListAbsoluteMain
                    className="appOrder__docsBlocks"
                    items={this.getBlocks()}
                    renderItem={this.renderBlock}
                    classNameItem="appOrder__docsBlock"
                    prop="key"
                    paramsParent={{ width: true }}
                    styles={['height']}
                    isNotParamsItem={true}
                    keyRender={updateKey}
                    callback={({ type, isInit }) => {
                        if (type === 'parent' || isInit) {
                            setUpdateKey();
                        }
                    }}
                    currentItemKey={this.getCurrentBlock()}
                    allItems={this.getBlocksOrder()}
                />
                <div className="appOrder__docsActions">{renderActions({ point })}</div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(OrderDocs);

OrderDocs.propTypes = {
    user: PropTypes.object,
    setUpdateKey: PropTypes.func,
    renderActions: PropTypes.func,
    handlerCurrentBlock: PropTypes.func,
    point: PropTypes.object,
    order: PropTypes.object,
    handlerPoint: PropTypes.func,
    loadingKey: PropTypes.string,
    errors: PropTypes.array,
    handlerErrors: PropTypes.func,
    handlerLoading: PropTypes.func,
};
