import axios from 'axios';
import getHeaders from './getHeaders';
import downloadFile from './downloadFile';

export default function download({ files }) {
    return new Promise((resolve) => {
        if (files) {
            axios
                .post(
                    `${process.env.REACT_APP_API}/download`, {
                        files,
                    }, {
                        responseType: 'blob',
                        headers: getHeaders(),
                    },
                )
                .then(
                    (res) => {
                        res.data.text().then(
                            (result) => {
                                try {
                                    const { success } = JSON.parse(result);

                                    resolve(success);
                                } catch (error) {
                                    const filename =
                                        files.length > 1 ?
                                        'Архив_Crm_Livecargo.zip' :
                                        files[0].name || files[0].src;

                                    downloadFile({ filename, data: res.data });

                                    resolve();
                                }
                            },
                            () => null,
                        );
                    },
                    () => null,
                );
        } else {
            resolve();
        }
    });
}