import pages from '../redux/pages';
import { dispatcher, store } from '../redux/redux';
import checkErrorPages from './checkErrorPages';
import getPage from './getPage';
import getPageInfo from './getPageInfo';
import getPageLevel from './getPageLevel';
import getPageLink from './getPageLink';
import getStartUrl from './getStartUrl';

const getShowPages = ({ page, pagesIds, levels }) => {
    const showPages = [];

    let checkEmptyNextPage = false;
    let popup = null;

    while (page) {
        const pageInfo = getPageInfo({ name: page.name });
        const level = getPageLevel(page);
        const { nearChilds } = pageInfo;
        let findPage = false;

        const newPage = { name: page.name };

        if (page.links.length === 0) {
            pagesIds[page.name] = levels[level];

            newPage.id = levels[level];
        }

        if (popup === null && page.isPopup) {
            popup = page.name;
        }

        showPages.push(newPage);

        if (!findPage) {
            nearChilds.forEach((nearChild) => {
                const childLevel = getPageLevel(nearChild);
                const currentLevel = levels[childLevel];

                if (nearChild.links.includes(currentLevel)) {
                    page = nearChild;
                    findPage = true;
                }
            });
        }

        if (!findPage) {
            nearChilds.forEach((nearChild) => {
                if (nearChild.links.length === 0 && levels[nearChild.level]) {
                    page = nearChild;
                    findPage = true;
                }
            });
        }

        if (!findPage) {
            page = null;
        }

        if (!page && !checkEmptyNextPage) {
            const lastPageName = showPages[showPages.length - 1];
            const { nearChilds: nextPageNearChilds } = getPageInfo({ name: lastPageName.name });

            nextPageNearChilds.forEach((nextPage) => {
                if (nextPage.links.find((link) => ['', undefined].includes(link))) {
                    showPages.push({ name: nextPage.name });
                }
            });

            checkEmptyNextPage = true;
        }
    }

    return { showPages, popup };
};

export default function changePage({ href, isPopstate = false, storePages, changeIsHard }) {
    if (href === '/') {
        href = 'auth';
    }

    let levels = href.split('/');
    const [firstLevel] = levels;

    const currentCorporation = store
        .getState()
        .user?.corporations.find((item) => item.id === firstLevel)?.id;

    if (currentCorporation && currentCorporation !== 'admin') {
        localStorage.setItem('saveNotAdminCorporationId', currentCorporation);
    }

    let page =
        pages.find((loopPage) => !loopPage.level && loopPage.links.includes(firstLevel)) ||
        pages.find((loopPage) => !loopPage.level && loopPage.links.length === 0);

    if (!page) {
        page = pages.find((innerPage) =>
            process.env.REACT_APP_SYSTEM === 'app'
                ? innerPage.name === 'index'
                : innerPage.name === 'auth',
        );
    }

    const resultStorePages = storePages || JSON.parse(JSON.stringify(store.getState().pages));
    const pagesIds = storePages ? {} : JSON.parse(JSON.stringify(store.getState().pagesIds));

    let findNotPopupPage;
    let replaceUrl;

    if (typeof page.getCond === 'function') {
        const { condition } = page.getCond(store.getState());

        if (!condition) {
            const redirectName = page.getRedirect?.(store.getState()).name || 'corporation';

            href = getPageLink({ name: redirectName });

            page = getPage({ name: redirectName });

            levels = href.split('/');

            replaceUrl = href;
        }
    }

    if (page?.name === 'corporation') {
        const user = store.getState().user;

        if (user) {
            const hrefItems = href.split('/');
            const corporationId = hrefItems[0];

            if (!user.corporations.find((item) => item.id === corporationId)) {
                hrefItems[0] = user.corporations[0]?.id || 'wait';

                href = hrefItems.join('/');

                levels = href.split('/');

                replaceUrl = href;
            }
        }
    }

    if (page?.isPopup) {
        findNotPopupPage = Object.keys(resultStorePages).find(
            (pageKey) =>
                !getPage({ name: pageKey }).level &&
                !getPage({ name: pageKey }).isPopup &&
                resultStorePages[pageKey].isShow,
        );

        if (!findNotPopupPage) {
            findNotPopupPage =
                (typeof page.mainPage === 'function'
                    ? page.mainPage(store.getState())
                    : page.mainPage) ||
                (process.env.REACT_APP_SYSTEM === 'app' ? 'index' : 'public');

            getPageInfo({ name: findNotPopupPage }).parents.forEach((parent) => {
                resultStorePages[parent].isShow = true;
            });

            resultStorePages[findNotPopupPage].isShow = true;

            const { nearChilds } = getPageInfo({ name: findNotPopupPage });

            const withIndexPageChild = nearChilds.find((child) => child.links.includes(undefined));

            if (withIndexPageChild) {
                resultStorePages[withIndexPageChild.name].isShow = true;
            }
        }
    }

    const infoShowPages = getShowPages({ page, pagesIds, levels });
    let { showPages } = infoShowPages;
    const { popup } = infoShowPages;

    // console.log(infoShowPages);

    // console.log(popup);

    const hidePagesNames = Object.keys(resultStorePages).filter(
        (pageKey) =>
            resultStorePages[pageKey].isShow &&
            (!popup ||
                getPageInfo({ name: pageKey }).parents.includes(popup) ||
                getPageLevel(pageKey) < getPageLevel(popup)),
    );

    // console.log(hidePagesNames, getPageInfo({ name: 'reports-registry' }).parents);

    hidePagesNames.forEach((nameHidePage) => {
        resultStorePages[nameHidePage].isShow = false;
        // resultStorePages[nameHidePage].id = null;
    });

    if (showPages.length === 1 && showPages[0].name === 'corporation') {
        showPages.push({ name: 'corporation-index' });
    }

    if (showPages.length === 1 && showPages[0].name === 'public') {
        showPages.push({ name: 'public-executors' });
    }

    let findInnerRedirect;

    showPages.forEach(({ name: nameShowPage }) => {
        if (!findInnerRedirect) {
            const showPage = getPage({ name: nameShowPage });

            if (typeof showPage.getCond === 'function') {
                const { condition } = showPage.getCond(store.getState());

                if (!condition) {
                    findInnerRedirect = true;

                    const redirectName =
                        showPage.getRedirect?.(store.getState()).name || 'corporation';

                    href = getPageLink({ name: redirectName });

                    levels = href.split('/');

                    replaceUrl = href;
                }
            }
        }
    });

    if (findInnerRedirect) {
        const { showPages: innerShowPages } = getShowPages({ page, pagesIds, levels });

        showPages = innerShowPages;
    }

    let findPopupPage;

    showPages.forEach(({ name }) => {
        const innerPage = getPage({ name });

        if (innerPage.isPopup && !findPopupPage) {
            findPopupPage = name;

            const mainPage =
                typeof innerPage.mainPage === 'function'
                    ? innerPage.mainPage(store.getState())
                    : innerPage.mainPage;
            const mainLevels = getPageLink({ name: mainPage }).split('/');
            const { showPages: innerShowPages } = getShowPages({
                page: getPage({ name: mainPage }),
                pagesIds,
                levels: mainLevels,
            });

            showPages.push(...innerShowPages);
        }
    });

    showPages.forEach(({ name, id }) => {
        resultStorePages[name].isShow = true;

        if (id) {
            resultStorePages[name].id = id;
        }
    });

    // console.log(showPages);

    const resultHidePagesNames = hidePagesNames.filter(
        (hidePageName) => !showPages.find((showPage) => showPage.name === hidePageName),
    );

    if (resultStorePages.projects?.isShow === false) {
        pagesIds['projects-inner'] = null;
        pagesIds['projects-guide-inner'] = null;
    }

    if (storePages) {
        const { levels: newLevels } = checkErrorPages({ storePages: resultStorePages, levels });

        if (replaceUrl !== undefined) {
            window.history.pushState(null, null, `/${replaceUrl}`);
        }

        return { storePages: resultStorePages, levels: newLevels || levels, pagesIds };
    }

    document.dispatchEvent(
        new CustomEvent('changePage', {
            detail: {
                hidePages: resultHidePagesNames.map((hidePageName) =>
                    getPage({ name: hidePageName }),
                ),
                changeIsHard,
            },
        }),
    );

    const { isError, levels: newLevels } = checkErrorPages({
        storePages: resultStorePages,
        levels,
    });

    if (!isPopstate && !isError) {
        let historyHref = `/${href === '' || href[href.length - 1] === '/' ? href : `${href}/`}`;

        if (historyHref[0] === '/' && historyHref[1] === '/') {
            historyHref = historyHref.slice(1);
        }

        window.history.pushState(null, null, historyHref);
    }

    return new Promise((resolve) => {
        // setTimeout(() => {
        // log(2);

        dispatcher({
            type: '',
            levels: newLevels || levels,
            pagesIds,
            pages: resultStorePages,
        }).then(() => {
            // log(3);

            resolve();
        });

        // }, 10);
    });
}

window.onpopstate = async (e) => {
    e.preventDefault();

    const href = getStartUrl(window.location.pathname.slice(1));

    const corporationId = href.split('/')[0];
    const user = store.getState().user;
    let isChange;

    if (user) {
        const currentCorporation = user.corporations.find((item) => item.id === corporationId);

        if (currentCorporation) {
            isChange = user.idOfCurrentCorporation !== corporationId;

            user.idOfCurrentCorporation = corporationId;

            await dispatcher({ type: 'user', data: user });
        }
    }

    changePage({
        href,
        isPopstate: true,
    }).then(() => {
        if (isChange) {
            document.dispatchEvent(
                new CustomEvent('changeCorporation', { detail: { id: corporationId } }),
            );
            document.dispatchEvent(new CustomEvent('changeUser', { detail: {} }));
        }
    });
};
