import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Loader from '../../../components/Loader.jsx';

class JoinCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { renderTelegram } = this.props;

        return (
            <div className="join__blockInner _col">
                <div className="join__blockLoader">
                    <Loader className="_main" />
                </div>
                <div className="join__blockTitle">
                    Проверяем ваши
                    <br />
                    документы
                </div>
                <div className="join__blockText">
                    В рабочее время это занимает до часа. Результаты проверки появятся на этой
                    странице.
                </div>
                {renderTelegram()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinCheck);

JoinCheck.propTypes = {
    user: PropTypes.object,
    renderField: PropTypes.func,
    renderButton: PropTypes.func,
    renderTelegram: PropTypes.func,
};
