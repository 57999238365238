import React, { ChangeEvent, MouseEvent } from 'react';

import getRealParams from '../../functions/getRealParams.ts';
import getFormatedNumber from '../../functions/getFormatedNumber.ts';

type ConcatT = {
    text: string;
    position: 'end' | 'start';
    exp?: RegExp;
};

type PropsT = {
    value: string | undefined;
    name: string;
    support?: string;
    onChange: (data: { name: string; value: string | number }) => void;
    textarea?: boolean;
    disabled?: boolean;
    onClick?: (e: MouseEvent) => void;
    reg?: string;
    returnTemplate?: boolean;
    className?: string;
    error?: boolean;
    setFocus?: (data: boolean) => void;
    calcHeight?: boolean;
    calcHeightCb?: () => void;
    minHeight?: () => number;
    updatedKey?: any;
    concat?: ConcatT;
    dateWithPast?: boolean;
    max?: number;
    typeValue?: string;
    withoutLabel?: boolean;
};

type StateT = {
    value?: string;
    startPos?: number | null;
    endPos?: number | null;
    isFocus?: boolean;
    id?: string;
};

class Input extends React.Component<PropsT, StateT> {
    input: React.RefObject<HTMLInputElement & HTMLTextAreaElement>;
    parent: React.RefObject<HTMLDivElement>;
    isInit?: boolean;
    updatedKey?: any;

    constructor(props: PropsT) {
        super(props);
        this.state = {};

        this.resize = this.resize.bind(this);

        this.input = React.createRef();
        this.parent = React.createRef();
    }

    regs: {
        [s: string]: {
            template: string;
            char: string;
            exp: RegExp;
        };
    } = {
        date: {
            template: '__/__/____',
            char: '_',
            exp: /[^\d]/gi,
        },
        time: {
            template: '__:__',
            char: '_',
            exp: /[^\d]/gi,
        },
        phone: {
            template: '7-___-___-__-__',
            char: '_',
            exp: /[^\d]/gi,
        },
        code: {
            template: '_ _ _ _ _',
            char: '_',
            exp: /[^\d]/gi,
        },
        pointTime: {
            template: 'Доставка в __:__',
            char: '_',
            exp: /[^\d]/gi,
        },
    };

    savePos(): void {
        const input = this.input.current;

        if (input) {
            this.setState({
                startPos: input.selectionStart,
                endPos: input.selectionEnd,
            });
        }
    }

    validateReg(value: string): string {
        const { reg, dateWithPast } = this.props;

        if (reg === 'date') {
            const resultItems: string[] = value.split('/');
            const [day, month, year] = resultItems;

            if (day?.replace(/[^\d]/gi, '').length === 2) {
                if (+day < 1) {
                    resultItems[0] = '01';
                }

                if (+day > 31) {
                    resultItems[0] = '31';
                }
            }

            if (month?.replace(/[^\d]/gi, '').length === 2) {
                if (+month < 1) {
                    resultItems[1] = '01';
                }

                if (+month > 12) {
                    resultItems[1] = '12';
                }
            }

            if (year?.replace(/[^\d]/gi, '').length === 4) {
                if (+year < new Date().getUTCFullYear() - 5) {
                    resultItems[2] = (new Date().getUTCFullYear() - 5).toString();
                }

                if (+year > new Date().getUTCFullYear() + 50) {
                    resultItems[2] = (new Date().getUTCFullYear() + 50).toString();
                }
            }

            if (!dateWithPast) {
                const date = new Date();

                date.setUTCFullYear(+resultItems[2]);
                date.setUTCDate(1);
                date.setUTCMonth(+resultItems[1] - 1);
                date.setUTCDate(+resultItems[0]);

                const nowDate = new Date();

                nowDate.setUTCHours(0, 0, 0, 0);

                if (date.getTime() < nowDate.getTime()) {
                    resultItems[0] = getFormatedNumber(nowDate.getUTCDate()).toString();
                    resultItems[1] = getFormatedNumber(nowDate.getUTCMonth() + 1).toString();
                    resultItems[2] = getFormatedNumber(nowDate.getFullYear()).toString();
                }
            }

            return resultItems.join('/');
        }

        if (reg === 'time') {
            const resultItems = value.split(':');
            const [hours, minutes] = resultItems;

            if (hours?.replace(/[^\d]/gi, '').length === 2) {
                if (+hours > 23) {
                    resultItems[0] = '23';
                }
            }

            if (minutes?.replace(/[^\d]/gi, '').length === 2) {
                if (+minutes > 59) {
                    resultItems[1] = '59';
                }
            }

            return resultItems.join(':');
        }

        if (reg === 'pointTime') {
            const resultItems = value.split(':');
            const [hours, minutes] = resultItems;

            if (hours.replace(/[^\d]/gi, '').length === 2) {
                if (+hours.replace(/[^\d]/gi, '') > 23) {
                    resultItems[0] = `Доставка в 23`;
                }
            }

            if (minutes.replace(/[^\d]/gi, '').length === 2) {
                if (+minutes > 59) {
                    resultItems[1] = '59';
                }
            }

            return resultItems.join(':');
        }

        return value;
    }

    changeReg({ value }: { value: string }): { value: string; position?: number } {
        const { reg, concat, max } = this.props;

        if (reg) {
            const regInfo = this.regs[reg];

            const savedValue = this.state.value || regInfo.template;

            let resultPos;
            let action = 'add';

            let resultValue = value;

            if (savedValue.length >= value.length) {
                const chgLen = Math.abs(savedValue.length - value.length);

                action = 'delete';

                const valueItems = value.split('');

                if (
                    typeof this.state.startPos === 'number' &&
                    typeof this.state.endPos === 'number'
                ) {
                    for (let i = this.state.startPos; i < this.state.endPos - chgLen; i++) {
                        if (regInfo.template[i] !== regInfo.char) {
                            valueItems.splice(i, 1, regInfo.template[i]);
                        }
                    }

                    for (let i = this.state.endPos - chgLen; i < this.state.endPos; i++) {
                        valueItems.splice(i, 0, regInfo.template[i]);
                    }

                    resultValue = valueItems.join('');

                    resultPos = this.state.endPos - chgLen;
                }
            } else {
                const realChgLen = Math.abs(savedValue.length - value.length);
                const chgLen = Math.abs(
                    savedValue.replace(regInfo.exp, '').length -
                        value.replace(regInfo.exp, '').length,
                );

                const valueItems = savedValue.split('');
                let offset = 0;
                let valOffset = 0;

                if (
                    typeof this.state.startPos === 'number' &&
                    typeof this.state.endPos === 'number'
                ) {
                    const chgValue = value.slice(
                        this.state.startPos,
                        this.state.startPos + realChgLen,
                    );

                    if (reg === 'phone' && [0, 1, 2].includes(this.state.startPos)) {
                        if (chgValue[0] === '+' && chgValue.length === 12) {
                            valOffset += 1;

                            if (chgValue[1] === '7') {
                                valOffset += 1;
                            }
                        }

                        if (['7', '8'].includes(chgValue[0]) && chgValue.length === 11) {
                            valOffset += 1;
                        }
                    }

                    for (let i = this.state.startPos; i < this.state.startPos + chgLen; i++) {
                        while (
                            regInfo.template[i + offset] &&
                            regInfo.template[i + offset] !== regInfo.char
                        ) {
                            offset += 1;
                        }

                        while (value[i + valOffset] && value[i + valOffset].match(regInfo.exp)) {
                            valOffset += 1;
                        }

                        let replacedChar = value[i + valOffset];

                        if (regInfo.template[i + offset] !== regInfo.char) {
                            replacedChar = regInfo.template[i + offset];
                        }

                        if (replacedChar) {
                            valueItems.splice(i + offset, 1, replacedChar);
                        }
                    }

                    resultValue = valueItems.join('');

                    resultPos = this.state.startPos + chgLen + offset;
                }

                if (chgLen > 1 && 0) {
                    resultValue = this.setValue(resultValue);
                }
            }

            if (typeof resultPos === 'number') {
                if (
                    regInfo.template[resultPos - 1] &&
                    regInfo.template[resultPos - 1] !== regInfo.char &&
                    action === 'delete'
                ) {
                    resultPos -= 1;
                }

                if (
                    regInfo.template[resultPos] &&
                    regInfo.template[resultPos] !== regInfo.char &&
                    action === 'add'
                ) {
                    resultPos += 1;
                }

                resultValue = resultValue.slice(0, regInfo.template.length);

                resultValue = this.validateReg(resultValue);
            }

            return { value: resultValue, position: resultPos };
        }

        if (concat) {
            if (typeof max === 'number' && +value.replace(/[^\d]/gi, '') > max) {
                value = `${max.toString()}`;
            }

            return { value: this.setValue(value) };
        }

        return { value };
    }

    setAreaHeight(): void {
        const { calcHeight, textarea, calcHeightCb, minHeight } = this.props;

        if (textarea && calcHeight) {
            const area = this.parent.current as HTMLElement;

            let { scrollHeight: areaHeight } = getRealParams({
                parent: area,
                elem: '.v2Input__field',
                width: area.offsetWidth,
                isClearStyles: true,
            });

            const resultMinHeight = typeof minHeight === 'function' ? minHeight() : 32;

            if ((areaHeight as number) < resultMinHeight) {
                areaHeight = resultMinHeight;
            }

            if ((areaHeight as number) > 120) {
                areaHeight = 120;
            }

            area.style.height = `${areaHeight}px`;

            const field = area.querySelector('.v2Input__field') as HTMLElement;

            if (field) {
                field.scrollTop = areaHeight as number;
            }

            if (typeof calcHeightCb === 'function') {
                calcHeightCb();
            }
        }
    }

    change(e: ChangeEvent): void {
        const { name, onChange, returnTemplate, typeValue } = this.props;
        const { target } = e;
        const { value } = target as HTMLInputElement;

        if (typeof onChange === 'function') {
            const { value: resultValue, position } = this.changeReg({ value });
            let returnedValue: string | number = resultValue;

            if (!returnTemplate) {
                returnedValue = this.clearValue(resultValue);
            }

            if (typeValue === 'number') {
                returnedValue = +returnedValue;
            }

            onChange({
                name,
                value: returnedValue,
            });

            this.setState({ value: resultValue }, () => {
                const input = this.input.current;

                if (input && position !== undefined) {
                    input.selectionStart = position;
                    input.selectionEnd = position;
                }

                this.setAreaHeight();
            });

            this.savePos();
        }
    }

    focus(isFocus: boolean): void {
        const { name, value, reg, onChange, returnTemplate, setFocus, concat } = this.props;
        const resultValue = this.setValue((value || '').toString());
        const regInfo = this.regs[reg as string];
        const input = this.input.current;
        let isFresh = false;

        this.setState({ isFocus, ...(!value ? { value: isFocus ? resultValue : '' } : {}) }, () => {
            if (!isFocus && returnTemplate && value === regInfo.template) {
                isFresh = true;

                onChange({
                    name,
                    value: '',
                });

                this.setState({ value: '' });
            }

            if (isFocus && returnTemplate && !value) {
                onChange({
                    name,
                    value: regInfo.template,
                });
            }

            if (!isFocus && concat && value === concat.text) {
                onChange({
                    name,
                    value: '',
                });

                this.setState({ value: '' });
            }

            if (typeof setFocus === 'function') {
                setFocus(isFocus);
            }

            if (input) {
                setTimeout(() => {
                    if (!value && regInfo && !isFresh) {
                        input.selectionStart =
                            regInfo.template.split('').findIndex((char) => char === regInfo.char) ||
                            0;
                        input.selectionEnd =
                            regInfo.template.split('').findIndex((char) => char === regInfo.char) ||
                            0;
                    }

                    if (!value && concat && concat.position === 'end' && !isFresh) {
                        input.selectionStart = 0;
                        input.selectionEnd = 0;
                    }

                    this.savePos();
                }, 10);
            }
        });
    }

    clearValue(value: string): string {
        const { reg, concat } = this.props;
        const regInfo = this.regs[reg as string];

        if (!value) {
            return '';
        }

        if (regInfo) {
            let resulValue = '';

            value.split('').forEach((char, index) => {
                if (char !== regInfo.template[index]) {
                    resulValue += char;
                }
            });

            return resulValue;
        }

        if (concat && concat.exp) {
            const resultValue = value.replace(concat.exp, '');

            return resultValue;
        }

        return value;
    }

    setValue(value: string): string {
        const { reg, concat } = this.props;
        const regInfo = this.regs[reg as string];

        if (regInfo) {
            const regValue = this.clearValue(value);

            const items = regValue.split('');
            let resultValue = '';

            regInfo.template.split('').forEach((char) => {
                if (char === regInfo.char) {
                    const currentChar = items.shift() || undefined;

                    resultValue += currentChar ?? regInfo.char;
                } else {
                    resultValue += char;
                }
            });

            return resultValue;
        }

        if (concat) {
            let resultValue = '';

            if (concat.exp) {
                resultValue = (value || '').replace(concat.exp, '');
            }

            if (concat.position === 'start') {
                resultValue = `${concat.text}${resultValue}`;
            }

            if (concat.position === 'end') {
                resultValue = `${resultValue}${concat.text}`;
            }

            return resultValue;
        }

        return value;
    }

    init(force?: boolean): void {
        const { value } = this.props;

        if (value !== null && value !== undefined && (!this.isInit || force)) {
            const resultValue = value ? this.setValue(value.toString()) : '';

            this.setState({ value: resultValue }, this.setAreaHeight.bind(this));

            this.isInit = true;
        }
    }

    resize(): void {
        this.setAreaHeight();
    }

    componentDidMount(): void {
        const { name, updatedKey, calcHeight } = this.props;

        this.updatedKey = updatedKey;

        this.init();

        this.setState({ id: `${name}-${new Date().getTime().toString().slice(-3)}` });

        setTimeout(() => {
            this.setAreaHeight();
        }, 10);

        if (calcHeight) {
            window.addEventListener('resize', this.resize);
        }
    }

    componentDidUpdate(): void {
        const { updatedKey } = this.props;

        this.init();

        if (updatedKey !== this.updatedKey) {
            this.updatedKey = updatedKey;

            this.init(true);
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.resize);
    }

    render() {
        const { isFocus, id } = this.state;
        const {
            textarea,
            support,
            disabled,
            onClick,
            className = '',
            error,
            withoutLabel,
        } = this.props;
        const InputTag = textarea ? 'textarea' : 'input';
        const LabelTag = withoutLabel ? 'div' : 'label';
        const value = this.props.reg || this.props.concat ? this.state.value : this.props.value;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`v2Input ${isFocus ? '_focus' : ''} ${
                        value === null || value === undefined || value === '' ? '_empty' : ''
                    } ${textarea ? '_area' : ''} ${className} ${error ? '_error' : ''}`}
                >
                    {support && (
                        <LabelTag className="v2Input__support" htmlFor={id}>
                            {support}
                        </LabelTag>
                    )}
                    <InputTag
                        type="text"
                        onFocus={this.focus.bind(this, true)}
                        onBlur={this.focus.bind(this, false)}
                        onChange={this.change.bind(this)}
                        value={value}
                        id={id}
                        className="v2Input__field _NOSCROLL"
                        disabled={disabled}
                        onClick={onClick}
                        ref={this.input}
                        onKeyDown={() => {
                            this.savePos();
                        }}
                        rows={1}
                    />
                </div>
            </>
        );
    }
}

export default Input;

export type { ConcatT as InputConcatT };
