import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import { addRoute } from '../../functions/handlerYmap';

import Popup from '../../components/app/popups/Popup.jsx';
import changePage from '../../functions/changePage';
import getOrders from '../../requests/getOrders';
import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';
import getFormatedDate from '../../functions/getFormatedDate';
import OrderStatus from '../../components/app/OrderStatus.jsx';
import InputRunning from '../../components/InputRunning.jsx';
import Icon from '../../components/Icon.jsx';
import getEndText from '../../functions/getEndText';
import ListAbsoluteMain from '../../components/ListAbsoluteMain.jsx';
import getInfoRoute from '../../functions/order/getInfoRoute';
import getFormatPrice from '../../functions/getFormatPrice';
import Ymaps from '../../components/Ymaps.jsx';
import getOrderTimeType from '../../functions/app/getOrderTimeType';

import getHeaders from '../../functions/getHeaders';
import AnimateChangeUp from '../../components/AnimateChangeUp.jsx';
import handlerOrderPopup from '../../functions/app/handlerOrderPopup';
import getNotPopupPage from '../../functions/getNotPopupPage';
import createChat from '../../functions/app/createChat';
import LinkBtn from '../../components/app/LinkBtn.jsx';
import { getDiffTime, getTimeToOrder } from '../../functions/app/getTimeToOrder';
import checkAuth from '../../functions/app/checkAuth';
import getExecutorMapIcon from '../../functions/getExecutorMapIcon';
import getRealParams from '../../functions/getRealParams.ts';
import Avatar from '../../components/Avatar.jsx';
import handlerPopup from '../../functions/app/handlerPopup';
import getPageLink from '../../functions/getPageLink';

const paysInfo = require('../../infos/order/pays.json');

class OrderDetails extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            isInit: false,
            showOtherAddresses: false,
        };

        this.renderPoint = this.renderPoint.bind(this);
        this.handlerOtherAddresses = this.handlerOtherAddresses.bind(this);
        this.renderButton = this.renderButton.bind(this);
        this.mapBootstrap = this.mapBootstrap.bind(this);
        this.updateOrder = this.updateOrder.bind(this);
        this.openChat = this.openChat.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.getGps = this.getGps.bind(this);

        this.hide = this.hide.bind(this);
    }

    mapInfo = {
        center: [55.879541, 37.66556],
        zoom: 16,
        controls: [],
    };

    getInfoFirstPoint() {
        const { order, ymaps, map } = this.state;
        const { user, appCoords } = this.props;

        if (ymaps && map && order && appCoords && !['complete', 'cancel'].includes(order.status)) {
            const points = order.route.map((point) => point.coords);

            if (!this.isGetInfoFirstPoint) {
                this.isGetInfoFirstPoint = true;

                addRoute({
                    ymaps,
                    map,
                    points: [appCoords, points[0]],
                    isForCalc: true,
                    isClear: true,
                }).then(
                    (info) => {
                        this.setState({
                            timeToFirstPoint: info.clearedDuration,
                        });
                    },
                    () => null,
                );

                // if (this.executorPoint) {
                //     map.geoObjects.remove(this.executorPoint);
                // }

                this.executorPoint = new ymaps.Placemark(
                    appCoords,
                    {},
                    {
                        iconLayout: 'default#image',
                        iconImageHref: require(`../../img/${getExecutorMapIcon({
                            type: user.type,
                        })}`),
                        iconImageSize: [38, 38],
                        iconImageOffset: [-16, -16],
                    },
                );

                map.geoObjects.add(this.executorPoint);
            }
        }
    }

    getGps() {
        this.getInfoFirstPoint();
    }

    setRoute() {
        const { order, ymaps, map } = this.state;

        if (ymaps && map && order) {
            const points = order.route.map((point) => point.coords);

            addRoute({ ymaps, map, points }).then(
                () => {
                    this.getInfoFirstPoint();
                },
                () => null,
            );
        }
    }

    mapBootstrap({ ymaps, map }) {
        this.setState({ ymaps, map }, this.setRoute);
    }

    checkHideDisabled() {
        const { order } = this.state;

        if (order) {
            const crewExecutor = this.getCrewExecutor();

            if (
                order?.deliveryType === 'market' &&
                order?.status === 'choice-crew' &&
                !crewExecutor
            ) {
                return false;
            }

            if (
                (crewExecutor?.isAccept || order?.deliveryType === 'market') &&
                (getOrderTimeType({ order }) === 'wait' || getOrderTimeType({ order }) === null)
            ) {
                return false;
            }
        }

        return true;
    }

    hide(isChangePage) {
        const { order } = this.state;
        const { type } = this.props;

        super.hide();

        if (type === 'popup') {
            handlerOrderPopup({ id: this.getOrderId() });
        } else if (isChangePage !== false) {
            changePage({
                href:
                    getNotPopupPage().href ||
                    getPageLink({
                        name: ['complete', 'cancel'].includes(order.status)
                            ? `orders-completed`
                            : `orders-wait`,
                    }),
            });
        }
    }

    renderPoint({ item: point, isStatic }) {
        const { order, timeToFirstPoint } = this.state;
        const { route, status, idOfCurrentPoint } = order;
        const { _id, address, ttk, sk } = point;
        let areaStr = '';
        let otherInfo;

        if (sk) {
            areaStr = ' · СК';
        } else if (ttk) {
            areaStr = ' · ТТК';
        }

        const pointIndex = route.findIndex((innerPoint) => innerPoint._id === _id);
        const currentPointIndex = route.findIndex(
            (innerPoint) => innerPoint._id === idOfCurrentPoint,
        );
        const isFirst = pointIndex === 0 && isStatic;

        if (status === 'complete') {
            otherInfo = isFirst ? 'Принят' : 'Доставлен';
        } else if (status === 'cancel') {
            otherInfo = 'Отменён';
        } else if (isFirst && timeToFirstPoint) {
            let resultTime = '';

            if (timeToFirstPoint < 60) {
                resultTime = '< 1 мин';
            } else if (timeToFirstPoint < 3_600) {
                resultTime = `${+(timeToFirstPoint / 60).toFixed(0)} мин`;
            } else if (timeToFirstPoint < 3_600 * 60) {
                resultTime = `${+(timeToFirstPoint / 3_600).toFixed(0)} ч`;
            } else {
                resultTime = '> 1 дня';
            }

            otherInfo = `ехать ${resultTime}`;
        }

        const pointType = isFirst ? 'погрузки' : 'выгрузки';

        let pointNumber;

        if (!isFirst && route.length > 2) {
            pointNumber = ` ${pointIndex}`;
        }

        let isPointComplete =
            pointIndex <= currentPointIndex || status === 'complete' || pointIndex === 0;

        if (status === 'cancel') {
            isPointComplete = false;
        }

        return (
            <div
                className={`orderDetails__routePoint ${
                    isFirst && route.length > 1 ? '_firstPoint' : ''
                } ${isStatic ? '_static' : ''}`}
            >
                <div
                    className={`orderDetails__routePointIcon _col ${isPointComplete ? '_complete' : ''}`}
                >
                    <i className="orderDetails__routePointIconHome">
                        <Icon name="app-order-home" />
                    </i>
                    <div className="orderDetails__routePointIconDot"></div>
                    <div className="orderDetails__routePointIconDot"></div>
                    <div className="orderDetails__routePointIconDot"></div>
                </div>
                <InputRunning
                    type="text"
                    className="orderDetails__routePointAddress"
                    value={address}
                    autoComplete="off"
                    readOnly={true}
                    isRun={true}
                />
                <div className="orderDetails__routePointInfo _row">
                    Точка {pointType}
                    {pointNumber}
                    {areaStr}{' '}
                    {otherInfo ? (
                        <>
                            <AnimateChangeUp
                                className="orderDetails__routePointInfoOther"
                                renderKey={otherInfo}
                            >
                                <>
                                    {' '}
                                    – <span>{otherInfo}</span>
                                </>
                            </AnimateChangeUp>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    }

    getOtherAddresses() {
        const { order, showOtherAddresses } = this.state;

        if (order && showOtherAddresses) {
            return order.route.filter((point, key) => key !== 0 && key !== order.route.length - 1);
        }

        return [];
    }

    handlerOtherAddresses(showOtherAddresses = !this.state.showOtherAddresses) {
        this.setState({ showOtherAddresses });
    }

    getServices() {
        const { order } = this.state;

        if (order) {
            const services = [];

            order.services
                // .filter((item) => !['tariff'].includes(item.key) && item.name)
                .forEach((item) => {
                    const name = order.infoAmount.find(
                        (amountItem) => amountItem.key === item.key,
                    )?.name;

                    services.push(name);
                });

            const servicesString = services.filter((service) => service).join(', ');

            return servicesString
                ? `${servicesString[0].toUpperCase()}${servicesString.slice(1).toLowerCase()}`
                : 'Нет';
        }

        return '';
    }

    sortInfoAmount(infoAmount) {
        return JSON.parse(JSON.stringify(infoAmount)).sort((a, b) => b.price - a.price);
    }

    disabledMap() {
        const map = this.parent.current.querySelector('.orderDetails__routeMapInner');

        map.addEventListener(
            'touchstart',
            (e) => {
                e.stopPropagation();
                e.preventDefault();
            },
            { passive: false },
        );
    }

    getCrewExecutor() {
        const { order } = this.state;
        const { user } = this.props;

        return order?.crew.find((item) => item.id === user._id);
    }

    checkMarketExecutors() {
        const { order } = this.state;
        const { user } = this.props;

        if (
            order?.deliveryType === 'market' &&
            order.marketExecutors.find((marketExecutor) => marketExecutor.id === user._id)
        ) {
            return true;
        }

        return false;
    }

    getButtons() {
        const { order } = this.state;

        if (order) {
            const crewExecutor = this.getCrewExecutor();

            if (crewExecutor?.isAccept) {
                if (getOrderTimeType({ order }) === 'wait') {
                    return [
                        {
                            key: 'wait',
                            timeToOrder: getTimeToOrder({ order }),
                            timeToStartOrder: getTimeToOrder({ order, isStart: true }),
                        },
                    ];
                }

                return [{ key: 'start', timeToOrder: getTimeToOrder({ order }) }];
            }

            if (this.checkMarketExecutors()) {
                return [{ key: 'market-wait' }];
            }

            return [{ key: 'accept' }];
        }

        return [];
    }

    handlerButton(key) {
        const { order } = this.state;
        const id = this.getOrderId();

        this.handlerLoadingKey(key).then(() => {
            if (key === 'accept') {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/order`,
                        { type: 'actions', action: 'accept', id },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                if (getOrderTimeType({ order }) !== 'fast') {
                                    handlerOrderPopup({ id: order._id, dispatch: false });
                                }
                            } else {
                                const { message } = data;

                                if (message === 'You hasnt access for corporation') {
                                    handlerPopup({
                                        name: 'appJoinCorporationPopup',
                                        isShow: true,
                                        corporation: {
                                            id: order.corporationId,
                                            logo: order.corporationLogo,
                                            name: order.corporationName,
                                            description: order.corporationDescription,
                                        },
                                    });
                                } else if (message === 'Wait confirm from corporation') {
                                    changePage({
                                        href: getPageLink({ name: 'corporations-main' }),
                                    });
                                } else if (message === 'Contract not sign') {
                                    handlerPopup({
                                        name: 'appContractPopup',
                                        isShow: true,
                                        corporation: {
                                            id: order.corporationId,
                                            logo: order.corporationLogo,
                                            name: order.corporationName,
                                        },
                                    });
                                }
                            }

                            this.handlerLoadingKey(null);
                        },
                        () => {
                            this.handlerLoadingKey(null);
                        },
                    );
            }

            if (key === 'cancel') {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/order`,
                        { type: 'actions', action: 'cancel', id },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                document.dispatchEvent(new CustomEvent('changeOrders'));

                                handlerOrderPopup({ id });

                                this.hide();

                                checkAuth(false);
                            }

                            this.handlerLoadingKey(null);
                        },
                        () => {
                            this.handlerLoadingKey(null);
                        },
                    );
            }

            if (key === 'start') {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/order`,
                        { type: 'actions', action: 'start', id },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                this.hide(false);

                                changePage({
                                    href: getPageLink({ name: 'order-inner', ids: { 1: id } }),
                                });

                                checkAuth(false);
                            }

                            this.handlerLoadingKey(null);
                        },
                        () => {
                            this.handlerLoadingKey(null);
                        },
                    );
            }
        });
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    getButtonStatus(key) {
        const { order } = this.state;
        const diffMinutes = getDiffTime({ order });

        if (
            key === 'accept' &&
            order?.deliveryType === 'market' &&
            order?.corporationConfirm === false
        ) {
            return '_disabled';
        }

        if (getOrderTimeType({ order }) === 'wait') {
            return '_disabled';
        }

        if (diffMinutes < 60) {
            return '_warn';
        }

        if (diffMinutes < 60 * 2) {
            return '_alert';
        }

        return '';
    }

    renderButton({ item, prop: key }) {
        const { order, loadingKey } = this.state;
        const { timeToStartOrder, timeToOrder } = item;

        return (
            <div
                className={`orderDetails__button _col _${key} ${this.getButtonStatus(key)}`}
                onClick={() => this.handlerButton(key)}
            >
                <Animate className="orderDetails__buttonLoader _loader" isShow={loadingKey === key}>
                    <div className="orderDetails__buttonLoaderItem _loaderItem">
                        <Loader />
                    </div>
                </Animate>
                {key === 'market-wait' && (
                    <>
                        <div className="orderDetails__buttonLoader _static">
                            <Loader />
                        </div>
                        <div className="orderDetails__buttonContent _row">Заявка на рассмотрении</div>
                    </>
                )}
                {key === 'accept' && (
                    <>
                        <div className="orderDetails__buttonContent _row">
                            {order.deliveryType === 'market'
                                ? order.corporationConfirm === false
                                    ? 'Ожидайте подключения'
                                    : 'Откликнуться на этот заказ'
                                : 'Принять заказ'}
                        </div>
                    </>
                )}
                {key === 'wait' && (
                    <>
                        <div className="orderDetails__buttonContent _row">
                            Начало: через
                            <AnimateChangeUp
                                className="orderDetails__buttonContentItem"
                                renderKey={timeToStartOrder}
                            >
                                {timeToStartOrder}
                            </AnimateChangeUp>
                        </div>
                        <p className="orderDetails__buttonDescription _row">
                            До подачи:
                            <AnimateChangeUp
                                className="orderDetails__buttonDescriptionItem"
                                renderKey={timeToOrder}
                            >
                                {timeToOrder}
                            </AnimateChangeUp>
                        </p>
                    </>
                )}
                {key === 'start' && (
                    <>
                        <div className="orderDetails__buttonContent _row _withIcon">
                            Начать выполнение
                            <i className="orderDetails__buttonContentIcon">
                                <Icon name="arrow-next-2" />
                            </i>
                        </div>
                        <p className="orderDetails__buttonDescription _row">
                            До подачи:
                            <AnimateChangeUp
                                className="orderDetails__buttonDescriptionItem"
                                renderKey={timeToOrder}
                            >
                                {timeToOrder}
                            </AnimateChangeUp>
                        </p>
                    </>
                )}
            </div>
        );
    }

    getOrderId() {
        const { levels, id } = this.props;

        return id || levels[1];
    }

    timeUpdateIntervalId;

    setInnerHeight() {
        let { height: innerBoxHeight } = getRealParams({
            parent: this.parent.current,
            elem: '.appPopup__content',
            width: this.parent.current.offsetWidth,
            classNames: ['_static'],
        });

        if (innerBoxHeight > this.getMaxHeight()) {
            innerBoxHeight = this.getMaxHeight();
        }

        if (innerBoxHeight !== this.state.innerBoxHeight) {
            this.setState({ innerBoxHeight });
        }
    }

    getOrder() {
        const id = this.getOrderId();

        getOrders({ id }).then(
            ({ order }) => {
                this.setState({ order }, () => {
                    this.setInnerHeight();

                    this.setRoute();

                    this.disabledMap();

                    setTimeout(() => {
                        this.setState({ isInit: true });
                    }, 300);

                    setTimeout(() => {
                        this.setState({ isRemoveHeight: true });
                    }, 1000);

                    if (order.timeType) {
                        this.timeUpdateIntervalId = setInterval(() => {
                            this.setState({ timeUpdateKey: new Date().getTime() });
                        }, 5_000);
                    }
                });
            },
            (err) => {
                if (err?.data?.actionCode === 404) {
                    this.hide();
                }
            },
        );
    }

    openChat() {
        const { order } = this.state;
        const { idOfChat } = order;

        return new Promise((resolve) => {
            this.handlerLoadingKey('chat').then(() => {
                createChat({ id: idOfChat, idOfOrder: order._id }).then(
                    () => {
                        this.handlerLoadingKey(null).then(resolve);
                    },
                    () => null,
                );
            });
        });
    }

    updateOrder({ fields, initiatorUserId }) {
        const { user } = this.props;
        const { crew } = fields;
        const id = this.getOrderId();

        if (fields.status === 'cancel') {
            handlerOrderPopup({ id });

            checkAuth();
        } else if (
            user.orders.find((order) => order.id !== id && (order.isProccess || !order.isAccept))
        ) {
            handlerOrderPopup({ id });

            checkAuth();
        } else {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const order = JSON.parse(JSON.stringify(newState.order));

                    Object.keys(fields).forEach((key) => {
                        order[key] = fields[key];
                    });

                    newState.order = order;

                    return newState;
                },
                () => {
                    if (crew && user._id === initiatorUserId) {
                        this.handlerLoadingKey(null);

                        checkAuth();
                    }
                },
            );
        }
    }

    handlerSocket({ detail }) {
        const { order } = this.state;
        const { name, data } = detail;

        if (
            order &&
            name === 'order' &&
            data?.idOfOrder === order._id &&
            data?.type === 'changeInfo'
        ) {
            const { fields, initiatorUserId } = data;

            this.updateOrder({ fields, initiatorUserId });
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.getOrder();

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('getGps', this.getGps);
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('getGps', this.getGps);
    }

    render() {
        const {
            order,
            isInit,
            showOtherAddresses,
            innerBoxHeight = 0,
            isRemoveHeight,
            timeUpdateKey,
            loadingKey,
        } = this.state;
        const { type } = this.props;
        const durationInfo = getInfoRoute(order?.distance, order?.duration);
        const isComplete = ['complete', 'cancel'].includes(order?.status);
        // const crewExecutor = this.getCrewExecutor();
        const deliveryType = order?.deliveryType;
        const condForCancel = deliveryType === 'market' ? this.checkMarketExecutors() : true;
        // const condForAmount = !isComplete && (deliveryType !== 'market' || !!crewExecutor);
        const condForAmount = !!order?.amount;

        return (
            <div ref={this.parent} className="appPopup  _col_orderDetails">
                <div className="appPopup__inner">
                    <Animate className="appPopup__loader _loader" isShow={!isInit}>
                        <div className="appPopup__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <div
                        className="appPopup__innerBox"
                        style={
                            isRemoveHeight
                                ? {}
                                : isInit
                                ? { height: `${innerBoxHeight}px` }
                                : { height: '400px' }
                        }
                    >
                        <div className="appPopup__content">
                            <div
                                className={`orderDetails ${isInit ? '_init' : ''} _${
                                    order?.status
                                } ${type ? `_${type}` : ''} ${
                                    deliveryType ? `_${deliveryType}` : ''
                                }`}
                            >
                                <div className="orderDetails__inner">
                                    <div className="orderDetails__head">
                                        <div className="orderDetails__title">
                                            {deliveryType === 'market' ? (
                                                <>Заказ ID {order?.number}</>
                                            ) : (
                                                <>
                                                    {!isComplete ? (
                                                        <>Новый заказ:</>
                                                    ) : (
                                                        <>ID {order?.number}</>
                                                    )}
                                                </>
                                            )}
                                        </div>

                                        <div className="orderDetails__date">
                                            Подача{' '}
                                            {getFormatedDate({
                                                date: new Date(order?.dateOfOrder),
                                                type: 'full',
                                            })}
                                        </div>
                                        {order && (
                                            <div className="orderDetails__status">
                                                <OrderStatus
                                                    name={
                                                        getOrderTimeType({ order }) || order.status
                                                    }
                                                >
                                                    {getOrderTimeType({ order }) === 'wait'
                                                        ? `через ${getTimeToOrder({
                                                              order,
                                                              isShort: true,
                                                          })}`
                                                        : null}
                                                </OrderStatus>
                                            </div>
                                        )}
                                    </div>
                                    {order && (
                                        <div
                                            className={`orderDetails__route _col ${
                                                showOtherAddresses ? '_showDrop' : ''
                                            }`}
                                        >
                                            {order.deliveryType === 'market' && (
                                                <div className="orderDetails__corporation _row">
                                                    <Avatar
                                                        src={
                                                            order.corporationLogo?.path &&
                                                            `${process.env.REACT_APP_STATIC}/corporations/${order.corporationLogo.path}`
                                                        }
                                                        holder={{
                                                            text: order.corporationName?.[0]?.toUpperCase(),
                                                        }}
                                                        className="orderDetails__corporationLogo"
                                                        withText={true}
                                                        iconType={order.corporationLogo.type}
                                                    />
                                                    <div className="orderDetails__corporationName">
                                                        {order.corporationName}{' '}
                                                        <span>(Заказчик)</span>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="orderDetails__routeInner">
                                                <div className="orderDetails__routeMap">
                                                    <div className="orderDetails__routeMapInner">
                                                        <div className="orderDetailsMap__contentMap">
                                                            {isRemoveHeight && (
                                                                <Ymaps
                                                                    {...this.mapInfo}
                                                                    callback={this.mapBootstrap}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="orderDetails__routeMapInfo">
                                                        {order.route.length}{' '}
                                                        {getEndText(order.route.length, [
                                                            'адрес',
                                                            'адреса',
                                                            'адресов',
                                                        ])}
                                                    </div>
                                                </div>
                                                <div className="orderDetails__routePoints">
                                                    <div className="orderDetails__routePointsInner">
                                                        {this.renderPoint({
                                                            item: order?.route[0],
                                                            isStatic: true,
                                                        })}
                                                        {order.route.length > 2 && (
                                                            <>
                                                                <ListAbsoluteMain
                                                                    className="orderDetails__routePointsDrop"
                                                                    items={this.getOtherAddresses()}
                                                                    renderItem={this.renderPoint}
                                                                    classNameItem="orderDetails__routePoint"
                                                                    prop="_id"
                                                                    paramsParent={{ width: true }}
                                                                    styles={['height']}
                                                                />
                                                            </>
                                                        )}
                                                        {order?.route?.length > 1 &&
                                                            this.renderPoint({
                                                                item: order?.route[
                                                                    order?.route.length - 1
                                                                ],
                                                                isStatic: true,
                                                            })}
                                                    </div>
                                                </div>
                                                {order.route.length > 2 && (
                                                    <div
                                                        className="orderDetails__routeBtn _row"
                                                        onClick={() => this.handlerOtherAddresses()}
                                                    >
                                                        Ещё
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className="orderDetails__info _row">
                                        {condForAmount && !isComplete ? (
                                            <>
                                                <div className="orderDetails__infoItem _pay">
                                                    <div className="orderDetails__infoItemValue">
                                                        ≈{getFormatPrice(order?.amount)}₽
                                                    </div>
                                                    <p className="orderDetails__infoItemSupport">
                                                        {paysInfo.find(
                                                            (item) => item.key === order?.pay,
                                                        )?.short || '–'}
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <div className="orderDetails__infoItem _distance">
                                            <div className="orderDetails__infoItemValue">
                                                {order?.distance}км
                                            </div>
                                            <p className="orderDetails__infoItemSupport">Пробег</p>
                                        </div>
                                        <div className="orderDetails__infoItem _duration">
                                            <div className="orderDetails__infoItemValue">
                                                {order?.status !== 'cancel' ? (
                                                    <>
                                                        {durationInfo.hours}ч {durationInfo.minutes}
                                                        м
                                                    </>
                                                ) : (
                                                    <>0ч 0м</>
                                                )}
                                            </div>
                                            <p className="orderDetails__infoItemSupport">
                                                В работе
                                            </p>
                                        </div>
                                        {isComplete ? (
                                            <>
                                                <div className="orderDetails__infoItem _date">
                                                    <div className="orderDetails__infoItemValue">
                                                        {order?.status === 'complete' ? (
                                                            <>
                                                                в{' '}
                                                                {getFormatedDate({
                                                                    date: new Date(order?.timeEnd),
                                                                    type: 'time',
                                                                })}
                                                            </>
                                                        ) : (
                                                            <>–</>
                                                        )}
                                                    </div>
                                                    <p className="orderDetails__infoItemSupport">
                                                        Завершён
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <div className="orderDetails__infoItem _services">
                                            <div className="orderDetails__infoItemValue">
                                                {this.getServices()}
                                            </div>
                                            <p className="orderDetails__infoItemSupport">
                                                Дополнительные услуги к заказу
                                            </p>
                                        </div>
                                    </div>
                                    {isComplete ? (
                                        <>
                                            <div className="orderDetails__details">
                                                <div className="orderDetails__detailsBack">
                                                    {['top', 'bottom'].map((key) => (
                                                        <div
                                                            className={`orderDetails__detailsBackLine _col _${key}`}
                                                            key={key}
                                                        >
                                                            <div className="orderDetails__detailsBackLineInner _row">
                                                                {new Array(24)
                                                                    .fill(1)
                                                                    .map((item, itemKey) => (
                                                                        <div
                                                                            className="orderDetails__detailsBackLineItem"
                                                                            key={itemKey}
                                                                        />
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div className="orderDetails__detailsBackBox">
                                                        <div className="orderDetails__detailsBackInner">
                                                            <div className="orderDetails__detailsDot _top _left">
                                                                <div className="orderDetails__detailsDotInner"></div>
                                                            </div>
                                                            <div className="orderDetails__detailsDot _top _right">
                                                                <div className="orderDetails__detailsDotInner"></div>
                                                            </div>
                                                            <div className="orderDetails__detailsDot _bottom _left">
                                                                <div className="orderDetails__detailsDotInner"></div>
                                                            </div>
                                                            <div className="orderDetails__detailsDot _bottom _right">
                                                                <div className="orderDetails__detailsDotInner"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="orderDetails__detailsHead _row">
                                                    <div className="orderDetails__detailsTotal">
                                                        Сумма: {getFormatPrice(order?.amount)}₽
                                                    </div>
                                                    <div className="orderDetails__detailsStatus">
                                                        {order?.status === 'complete' ? (
                                                            <>Оплачено</>
                                                        ) : (
                                                            <>Не оплачено</>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="orderDetails__detailsItems">
                                                    {this.sortInfoAmount(
                                                        order?.infoAmount || [],
                                                    ).map((item) => {
                                                        const { key, name, price } = item;

                                                        return (
                                                            <div
                                                                className="orderDetails__detailsItem _row"
                                                                key={key}
                                                            >
                                                                <div className="orderDetails__detailsItemCol">
                                                                    {name}
                                                                </div>
                                                                <div className="orderDetails__detailsItemCol _value">
                                                                    {getFormatPrice(price)}₽
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div className="orderDetails__support">
                                                <LinkBtn
                                                    className="_alert _bold"
                                                    showLoader={loadingKey === 'chat'}
                                                    handler={this.openChat}
                                                >
                                                    Нужна помощь
                                                </LinkBtn>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <ListAbsoluteMain
                                                className="orderDetails__buttons"
                                                items={this.getButtons()}
                                                renderItem={this.renderButton}
                                                classNameItem="orderDetails__button"
                                                prop="key"
                                                paramsParent={{ width: true }}
                                                styles={['height']}
                                                isNotParams={true}
                                                isNotParamsItem={true}
                                                keyRender={timeUpdateKey}
                                                propsForUpdate={['timeToOrder', 'timeToStartOrder']}
                                                callback={({ isChangeLen }) => {
                                                    if (isChangeLen) {
                                                        this.setInnerHeight();
                                                    }
                                                }}
                                            />
                                            {condForCancel && (
                                                <div className="orderDetails__support">
                                                    <LinkBtn
                                                        className="_alert _bold"
                                                        showLoader={loadingKey === 'cancel'}
                                                        handler={() => {
                                                            this.handlerButton('cancel');
                                                        }}
                                                    >
                                                        Отказаться
                                                    </LinkBtn>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        heightWindow: state.heightWindow,
        levels: state.levels,
        appCoords: state.appCoords,
    };
}

export default connect(mapStateToProps)(OrderDetails);

OrderDetails.propTypes = {
    user: PropTypes.object,
    heightWindow: PropTypes.number,
    levels: PropTypes.array,
    id: PropTypes.string,
    type: PropTypes.string,
    appCoords: PropTypes.array,
};
