import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';

import getFormatedDate from '../../../functions/getFormatedDate';
import Link from '../../Link.jsx';
import Avatar from '../../Avatar.jsx';

class OrderPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            _id,
            dateOfOrder,
            status,
            number,
            route = [],
            deliveryType,
            corporationLogo,
            corporationName,
        } = this.props;
        let iconName =
            status === 'complete'
                ? 'app-order-complete'
                : status === 'cancel'
                ? 'app-order-cancel'
                : 'app-order-wait';

        if (deliveryType === 'market') {
            iconName = 'app-order-market';
        }

        return (
            <Link className="orderPreview" pageName="order-details-inner" ids={{ 1: _id }}>
                <div className="orderPreview__inner">
                    <div className="orderPreview__content _row">
                        <div className="orderPreview__number">
                            <i className="orderPreview__numberIcon">
                                <Icon name={iconName} />
                            </i>
                            ID {number}
                        </div>
                        <div className="orderPreview__addresses">
                            <div className="orderPreview__address">
                                {route[0].address}
                                {route.length > 1 ? <>&nbsp;→</> : ''}
                            </div>
                            {route.length > 1 && (
                                <div className="orderPreview__address">
                                    {route[route.length - 1].address}
                                </div>
                            )}
                        </div>

                        <div className="orderPreview__date">
                            {getFormatedDate({ date: new Date(dateOfOrder), type: 'full' })}
                        </div>
                        <i className="orderPreview__icon">
                            <Icon name="app-touch" />
                        </i>
                    </div>

                    {corporationName && (
                        <div className="orderPreview__corporation _row">
                            <Avatar
                                src={
                                    corporationLogo?.path &&
                                    `${process.env.REACT_APP_STATIC}/corporations/${corporationLogo.path}`
                                }
                                holder={{
                                    text: corporationName?.[0]?.toUpperCase(),
                                }}
                                className="orderPreview__corporationLogo"
                                withText={true}
                                iconType={corporationLogo.type}
                            />
                            <div className="orderPreview__corporationName">
                                {corporationName} <span>(Заказчик)</span>
                            </div>
                        </div>
                    )}
                </div>
            </Link>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(OrderPreview);

OrderPreview.propTypes = {
    _id: PropTypes.string,
    dateOfOrder: PropTypes.string,
    number: PropTypes.string,
    route: PropTypes.array,
    status: PropTypes.string,
    corporationLogo: PropTypes.object,
    corporationName: PropTypes.string,
    deliveryType: PropTypes.string,
};
