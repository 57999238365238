export default function getVariables() {
    if (process.env.REACT_APP_SYSTEM === 'site') {
        return {
            popupOffsetTablet: {
                value: 24,
                type: 'px',
            },
        };
    }
    if (process.env.REACT_APP_SYSTEM === 'crm') {
        return {
            heightSideBarCompanyListItem: {
                value: 70,
                type: 'px',
            },
            paddingWidget: {
                value: 22,
                type: 'px',
            },
        };
    }
    return {};
}
