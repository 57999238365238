import { dispatcher, store } from '../../redux/redux';
import getCurrentPage from '../getCurrentPage';

export default function handlerPopup({ name, ...props }) {
    const { user, pages } = store.getState();
    const currentPage = getCurrentPage({ pages, filter: (page) => !page.level });

    if (
        name === 'appSignPayPopup' &&
        user.organization === 'SMZ' &&
        user.isJurStatusActive !== true
    ) {
        name = 'appFnsActivateInfoPopup';
    }

    if (
        name === 'appInstructionApplicationPopup' &&
        (localStorage.getItem('showInstructionApplication') || ['join'].includes(currentPage))
    ) {
        return;
    }

    dispatcher({ type: name, data: { ...props } });
}
