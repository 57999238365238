import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon.jsx';
import ListAbsoluteMain from '../ListAbsoluteMain.jsx';

class OrderStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderItem = this.renderItem.bind(this);
    }

    statuses = {
        complete: {
            icon: 'app-order-completed-light',
            content: 'Выполнен',
        },
        cancel: {
            icon: 'app-order-cancel-light',
            content: 'Отменён',
        },
        fast: {
            icon: 'app-order-fast',
            content: 'Срочный',
        },
        wait: {
            icon: 'app-order-wait',
            content: 'ЧЕРЕЗ 2 ДНЯ',
        },
    };

    childrens = {};

    getItems() {
        const { name, children } = this.props;

        this.childrens[name] = children;

        return [{ key: name }];
    }

    renderItem({ prop: key }) {
        const status = this.statuses[key];

        return (
            <div className={`appOrderStatus__item _row _${key}`}>
                <i className="appOrderStatus__itemIcon">
                    <Icon name={status?.icon} />
                </i>
                {this.childrens[key] || status?.content}
            </div>
        );
    }

    render() {
        const { name, children, className = '' } = this.props;

        return (
            <div className={`appOrderStatus _row _${name} ${className}`}>
                <ListAbsoluteMain
                    className="appOrderStatus__inner"
                    items={this.getItems()}
                    renderItem={this.renderItem}
                    classNameItem="appOrderStatus__item"
                    prop="key"
                    paramsParent={{}}
                    styles={['width', 'height']}
                    isNotParamsItem={true}
                    keyRender={children}
                />
            </div>
        );
    }
}

export default OrderStatus;

OrderStatus.propTypes = {
    name: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
};
