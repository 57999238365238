import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import showStartPopups from '../../functions/app/showStartPopups';

import Back from '../../components/app/Back.jsx';
import HeadPage from '../../components/app/HeadPage.jsx';

import Inner from './chat/Inner.jsx';
import changePage from '../../functions/changePage';
import getPageLink from '../../functions/getPageLink';

class Chat extends HeadPage {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerBack = this.handlerBack.bind(this);
        this.setOrderId = this.setOrderId.bind(this);
    }

    handlerBack() {
        const { orderId } = this.state;
        const { user } = this.props;
        const order = user.orders.find((innerOrder) => innerOrder.id === orderId);

        changePage({
            href: getPageLink({
                name: order?.isProccess ? 'order-inner' : 'order-details-inner',
                ids: { 1: orderId },
            }),
        });
    }

    setOrderId(id) {
        this.setState({ orderId: id });
    }

    componentDidMount() {
        super.componentDidMount();

        showStartPopups();
    }

    render() {
        const { updateHeightKey } = this.state;

        return (
            <div ref={this.parent} className="headPage">
                <div className="headPage__head">
                    <div className="pageHead _col">
                        <div className="pageHead__top _row _notMargin">
                            <div className="pageHead__back">
                                <Back handler={this.handlerBack} />
                            </div>
                            <div className="pageHead__title">Чат поддержки</div>
                        </div>
                    </div>
                </div>
                <div className="headPage__inner">
                    <Inner updateHeightKey={updateHeightKey} setOrderId={this.setOrderId} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        windowIsReady: state.windowIsReady,
        pagesStore: state.pages,
        counterChangePage: state.counterChangePage,
        pagesOrder: state.pagesOrder,
    };
}

export default connect(mapStateToProps)(Chat);

Chat.propTypes = {
    user: PropTypes.object,
    windowIsReady: PropTypes.bool,
    pagesStore: PropTypes.object,
    counterChangePage: PropTypes.number,
    pagesOrder: PropTypes.array,
};
