import React from 'react';
import { connect } from 'react-redux';

const imageLoader = require('../img/logo-animate.png');

class LoaderAnimate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
        };
        this.icon = React.createRef();
        this.iconWrap = React.createRef();
        this.handlerGif = this.handlerGif.bind(this);
    }

    interval = null;

    flagEnd = false;

    flagClear = true;

    speed = 30;

    index = 0;

    shot = 48;

    handlerGif() {
        if (true) {
            if (!this.flagClear && this.index === 0) {
                return false;
            }
            this.flagEnd = false;
            this.flagClear = false;
            if (this.interval) {
                clearInterval(this.interval);
            }
            this.interval = setInterval(() => {
                if (this.index === this.shot - 1) {
                    this.index = 0;
                    if (this.flagEnd) {
                        clearInterval(this.interval);
                        this.flagClear = true;
                    }
                }
                this.index += 1;
                this.icon.current.style.transform = `translate3d(0,${
                    -this.state.step * this.index
                }px,0)`;
            }, this.speed);
        }

        return true;
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidMount() {
        const icon = new Image();
        const iconCur = this.icon.current;

        icon.src = imageLoader;

        icon.onload = () => {
            const height = iconCur.offsetHeight;
            const step = height / this.shot;

            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.step = step;

                    return newState;
                },
                () => {
                    this.handlerGif();
                },
            );
        };
    }

    render() {
        return (
            <span ref={this.iconWrap} className="logoAnimate">
                <img ref={this.icon} src={imageLoader} alt="" className="logoAnimate__icon" />
            </span>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(LoaderAnimate);
