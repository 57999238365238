import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { dispatcher } from '../redux/redux';

import connectSockets from '../functions/connectSockets';
import setVariables from '../functions/setVariables';
import { deleteCookie, getCookie, setCookie } from '../functions/handlerCookies';
import getUser from '../functions/getUser';

import Theme from '../components/Theme.jsx';
import SupportCursor from '../components/SupportCursor.jsx';
import Galery from '../components/Galery.jsx';

import Alerts from './Alerts.jsx';

import PopupCargoImages from '../components/order/PopupCargoImages.jsx';
import PopupUploadTemplate from '../components/order/PopupUploadTemplate.jsx';
import checkSeoMode from '../functions/checkSeoMode';
import CustomHead from '../components/CustomHead.jsx';
import checkIos from '../functions/checkIos';
import getCurrentPage from '../functions/getCurrentPage';
import changePage from '../functions/changePage';
import getPageLink from '../functions/getPageLink';

const Crm = process.env.REACT_APP_SYSTEM === 'crm' && require('./crm/App.jsx').default;
const App = process.env.REACT_APP_SYSTEM === 'app' && require('./app/App.jsx').default;

class Root extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightContent = this.setHeightContent.bind(this);
        this.checkAfk = this.checkAfk.bind(this);

        this.parent = React.createRef();
    }

    setHeightContent() {
        const page = this.parent.current.querySelector('.body__page._current');
        const footer = this.parent.current.querySelector('.footer');

        if (page) {
            let heightContent = page.clientHeight;

            if (footer) {
                heightContent += footer.clientHeight;
            }

            this.setState({ heightContent });
        }
    }

    timerAfk = null;

    checkAfk() {
        if (this.timerAfk) {
            clearTimeout(this.timerAfk);
            dispatcher({ type: 'afk', data: false });
        }

        this.timerAfk = setTimeout(() => {
            dispatcher({ type: 'afk', data: true });
        }, 5000);
    }

    checkPage() {
        const { pagesStore } = this.props;

        const currentPage = getCurrentPage({ pages: pagesStore, filter: (page) => !page.level });

        if (currentPage !== this.currentPage) {
            this.currentPage = currentPage;

            document.querySelector('html').setAttribute('data-page', currentPage);
        }
    }

    async componentDidMount() {
        const { pagesStore } = this.props;
        const currentPage = getCurrentPage({ page: pagesStore, filter: (page) => !page.level });

        if (process.env.REACT_APP_SYSTEM === 'site') {
            if (getCookie('hashOrderInfo')) {
                deleteCookie('joinHash');
                deleteCookie(process.env.REACT_APP_HASH);
            }

            if (getCookie('joinHash')) {
                deleteCookie('hashOrderInfo');
                deleteCookie(process.env.REACT_APP_HASH);
            }

            if (getCookie(process.env.REACT_APP_HASH)) {
                deleteCookie('joinHash');
                deleteCookie('hashOrderInfo');
            }
        }

        if (process.env.REACT_APP_SYSTEM === 'crm' && currentPage !== 'pdf') {
            const activateHash = getCookie('activateHash');
            const activateId = getCookie('activateId');

            if (activateHash && activateId) {
                setCookie(process.env.REACT_APP_HASH, activateHash);

                try {
                    await getUser(activateHash, true, false, activateId);

                    changePage({ href: getPageLink({ name: 'corporation' }) });
                } catch (error) {
                    Promise.resolve();
                }
            } else {
                if (!getCookie(process.env.REACT_APP_HASH)) {
                    connectSockets();
                }

                try {
                    await getUser(getCookie(process.env.REACT_APP_HASH));
                } catch (error) {
                    Promise.resolve();
                }
            }
        }

        setVariables();
        this.checkAfk();

        this.checkPage();

        document.addEventListener('visibilitychange', () => {
            document.dispatchEvent(
                new CustomEvent('visibilityDocChange', { detail: { isActive: !document.hidden } }),
            );
        });
    }

    componentDidUpdate() {
        setVariables();

        this.checkPage();
    }

    render() {
        const {
            theme,
            stateTheme,
            popupOrderCargoImages,
            popupOrderUploadTemplate,
            popupGalery,
            isFix,
            windowIsLoad,
            heightWindow,
            windowIsReady,
        } = this.props;

        return (
            <div
                ref={this.parent}
                className={`body__content _col _${theme} ${isFix ? '_fix' : ''} _${
                    process.env.REACT_APP_SYSTEM
                } ${windowIsLoad ? '_isLoad' : ''} ${checkIos() ? '_ios' : ''}`}
                onMouseMove={() => this.checkAfk()}
                key={process.env.REACT_APP_SYSTEM === 'crm' ? '' : ''}
                style={{ minHeight: `${heightWindow}px`, display: checkSeoMode() ? 'none' : null }}
            >
                <CustomHead
                    title="CRM LIVECARGO"
                    description="Цифровизация вашего бизнеса от найма – до выплат"
                    preview={`${process.env.REACT_APP_DOMEN}${require('../img/preview.jpg')}`}
                />
                {popupGalery.state > -1 && <Galery />}
                {<Alerts />}
                <SupportCursor />
                {[0, 1].indexOf(stateTheme) !== -1 && <Theme />}
                {popupOrderCargoImages.state > -1 && (
                    <PopupCargoImages {...popupOrderCargoImages} />
                )}
                {popupOrderUploadTemplate.state > -1 && (
                    <PopupUploadTemplate {...popupOrderUploadTemplate} />
                )}
                {Crm && <Crm />}
                {App && <App />}
                {!windowIsReady && (
                    <div className="body__hidden">
                        {[1, 2, 3, 4].map((key) => (
                            <p className={`body__hiddenText _${key}`} key={key}>
                                абвгдеёжзиклмнопрстуфхцшщячьъэюя АБВГДЕЁЖЗИКЛМНОПРСТУФХЦШЩЯЧЬЪЭЮЯ
                                abcdefghijklmnopqrstuvwxyz ABCDEFGHIJKLMNOPQRSTUVWXYZ
                            </p>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme,
        stateTheme: state.stateTheme,
        popupOrderCargoImages: state.popupOrderCargoImages,
        popupGalery: state.popupGalery,
        popupOrderUploadTemplate: state.popupOrderUploadTemplate,
        device: state.device,
        isFix: state.isFix,
        popupPay: state.popupPay,
        windowIsLoad: state.windowIsLoad,
        heightWindow: state.heightWindow,
        windowIsReady: state.windowIsReady,
        pagesStore: state.pages,
        popupPolicy: state.popupPolicy,
    };
}

export default connect(mapStateToProps)(Root);

Root.propTypes = {
    theme: PropTypes.string,
    stateTheme: PropTypes.number,
    popupOrderCargoImages: PropTypes.object,
    popupGalery: PropTypes.object,
    popupOrderUploadTemplate: PropTypes.object,
    device: PropTypes.string,
    isFix: PropTypes.bool,
    windowIsLoad: PropTypes.bool,
    heightWindow: PropTypes.number,
    windowIsReady: PropTypes.bool,
    pagesStore: PropTypes.object,
    popupPolicy: PropTypes.object,
};
