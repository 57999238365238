import React from 'react';
import PropTypes from 'prop-types';

class JoinStart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { renderButton, corporationName, renderTelegram } = this.props;

        return (
            <div className="join__blockInner _col">
                <img
                    src={require('../../../img/emodzi/victory-hand.png')}
                    alt=""
                    className="join__blockIcon"
                />
                <div className="join__blockTitle">
                    «{corporationName}» приглашает вас к&nbsp;сотрудничеству
                </div>
                <div className="join__blockText">Через CRM LIVECARGO</div>
                <div className="join__blockButton">
                    {renderButton({ name: 'start', content: 'Подключиться' })}
                </div>
                {renderTelegram()}
            </div>
        );
    }
}

export default JoinStart;

JoinStart.propTypes = {
    renderButton: PropTypes.func,
    corporationName: PropTypes.string,
    renderTelegram: PropTypes.func,
};
