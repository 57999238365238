import getEndText from '../getEndText';

const getDiffTime = ({ order, isStart }) => {
    const delta = isStart ? 119 : 0;

    if (order) {
        const date = new Date();
        const dateOfOrder = new Date(order.dateOfOrder);

        return (dateOfOrder.getTime() - date.getTime()) / (1_000 * 60) - delta;
    }

    return 0;
};

const getTimeToOrder = ({ order, isStart, isShort }) => {
    if (order) {
        const diffMinutes = getDiffTime({ order, isStart });

        if (diffMinutes <= 0) {
            if(isShort){
                return '< 1 мин'
            }

            return 'меньше минуты';
        }

        let time = '';

        if (diffMinutes > 60 * 24) {
            const days = Math.floor(diffMinutes / (60 * 24));

            time += `${days} ${getEndText(days, ['день', 'дня', 'дней'])} `;
        } else {
            if (diffMinutes > 60) {
                const hours = Math.floor(diffMinutes / 60);

                time += `${hours}ч `;
            }

            const minutes = Math.floor(diffMinutes % 60);

            if (minutes) {
                time += `${minutes}${isShort ? 'м' : 'мин'}`;
            }
        }

        return time;
    }

    return '–';
};

export { getDiffTime, getTimeToOrder };
