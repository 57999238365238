import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import InputRunning from '../../InputRunning.jsx';
import Button from '../../Button.jsx';
import Icon from '../../Icon.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import getFormatedNumber from '../../../functions/getFormatedNumber.ts';
import handlerPopup from '../../../functions/app/handlerPopup';

class OrderMove extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: 'start',
        };

        this.renderHeadBlock = this.renderHeadBlock.bind(this);
        this.renderFoot = this.renderFoot.bind(this);
        this.changePoint = this.changePoint.bind(this);
    }

    blocksOrder = ['start', 'move'];

    getCurrentBlock() {
        const { point } = this.props;
        let currentBlock = 'start';

        if (point.status !== 'wait') {
            currentBlock = 'move';
        }

        return currentBlock;
    }

    getBlocks() {
        return [{ key: this.getCurrentBlock() }];
    }

    getDescription() {
        const { point } = this.props;

        let description = '';

        description += point.index === 0 ? 'Точка погрузки' : 'Точка доставки';

        if (point.sk) {
            description += ' · СК';
        } else if (point.ttk) {
            description += ' · ТТК';
        }

        return description;
    }

    getProgress() {
        const { roadInfo } = this.props;
        let progress = 0;

        if (roadInfo) {
            const { allRoute, currentRoute } = roadInfo;

            progress = +(1 - currentRoute.distance / allRoute.distance).toFixed(4) * 100;

            if (progress < 0) {
                progress = 0;
            }
        }

        return {
            progress,
        };
    }

    getTime() {
        const { order, point, roadInfo } = this.props;
        const { timeToPoint } = point;
        let currentTime = '– мин';
        let allTime;
        let toPointTime = '';

        if (order) {
            const dateOfStart = new Date(order.dateOfStart);
            const date = new Date();

            const time = +((date.getTime() - dateOfStart.getTime()) / 1000).toFixed(0);
            const hours = getFormatedNumber(Math.floor(time / 3600));
            const minutes = getFormatedNumber(Math.floor((time - hours * 3600) / 60));
            const seconds = getFormatedNumber(time - hours * 3600 - minutes * 60);

            allTime = `${hours}:${minutes}:${seconds}`;
        }

        let time;

        if (timeToPoint) {
            const secondsDiff = (new Date(timeToPoint).getTime() - new Date().getTime()) / 1_000;

            time = secondsDiff;
        }

        if (roadInfo) {
            const { currentRoute, allRoute } = roadInfo;
            time = currentRoute.time;

            const { time: allRouteTime } = allRoute;

            if (allRouteTime < 60) {
                toPointTime = '~ 1 мин';
            } else if (allRouteTime < 3600) {
                toPointTime = `${+(allRouteTime / 60).toFixed(0)} мин`;
            } else {
                toPointTime = `${+(allRouteTime / 3600).toFixed(0)} ч`;
            }
        }

        if (time) {
            if (time < 60) {
                currentTime = '~ 1 мин';
            } else if (time < 3600) {
                currentTime = `${+(time / 60).toFixed(0)} мин`;
            } else {
                currentTime = `${+(time / 3600).toFixed(0)} ч`;
            }
        }

        return {
            currentTime,
            allTime,
            toPointTime,
        };
    }

    renderHeadBlock({ prop: key }) {
        const { point } = this.props;
        const description = this.getDescription();
        const { progress } = this.getProgress();
        const { currentTime, allTime, toPointTime } = this.getTime();

        return (
            <div className="appOrder__moveHeadBlock">
                {key === 'start' && (
                    <>
                        <div className="appOrder__moveInfo _col">
                            <div className="appOrder__moveInfoNumber _col">{point.index + 1}</div>
                            <InputRunning
                                type="text"
                                className="appOrder__moveInfoTitle"
                                value={point.address}
                                autoComplete="off"
                                readOnly={true}
                                isRun={true}
                            />
                            <div className="appOrder__moveInfoDescription _row">
                                {description} —
                                <AnimateChangeUp
                                    className="appOrder__moveInfoDescriptionItem"
                                    renderKey={toPointTime}
                                >
                                    {toPointTime ? <> ехать {toPointTime}</> : <></>}
                                </AnimateChangeUp>
                            </div>
                            <div className="appOrder__moveInfoSub">
                                №{point.cargo[0]?.number} · Доставка
                            </div>
                        </div>
                    </>
                )}
                {key === 'move' && (
                    <>
                        <div className="appOrder__moveInfo _col _move">
                            <div className="appOrder__moveInfoNumber _col _active">
                                {point.index + 1}
                            </div>
                            <div className="appOrder__moveInfoTitle _bold">
                                В пути на точку {point.index + 1}
                            </div>
                            <div className="appOrder__moveInfoDescription _row">
                                {point.address}
                            </div>
                            <div className="appOrder__moveInfoSub">{description}</div>
                            <div className="appOrder__moveInfoTimes _col">
                                <div className="appOrder__moveInfoTimesAll">{allTime}</div>
                                <AnimateChangeUp
                                    className="appOrder__moveInfoTimesCurrent"
                                    renderKey={currentTime}
                                >
                                    ещё {currentTime}
                                </AnimateChangeUp>
                            </div>
                        </div>
                        <div className="appOrder__moveRoad _row">
                            <div className="appOrder__moveRoadInner">
                                <div className="appOrder__moveRoadLine">
                                    <div
                                        className="appOrder__moveRoadLineProgress"
                                        style={{ width: `${progress}%` }}
                                    />
                                </div>
                                <i
                                    className="appOrder__moveRoadCursor"
                                    style={{ left: `${progress}%` }}
                                >
                                    <Icon name="app-order-cursor" />
                                </i>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    changePoint() {
        const { order, point } = this.props;

        handlerPopup({ name: 'appOrderPointPopup', isShow: true, order, point });
    }

    renderFoot({ prop: key }) {
        const { handlerPoint, point, loadingKey } = this.props;
        const status = point.status === 'wait' ? 'move-to-point' : 'on-place';

        return (
            <div className="appOrder__moveFootBlock _col">
                <div className="appOrder__moveButton">
                    <Button
                        onClick={() => {
                            handlerPoint({
                                status,
                            });
                        }}
                        showLoader={loadingKey === status}
                    >
                        {key === 'start' ? <>Поехали!</> : <>Я на месте</>}
                    </Button>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.timerId = setInterval(() => {
            this.setState({ updateKey: new Date().getTime() });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    render() {
        const { order, setUpdateKey, renderActions, point } = this.props;

        return (
            <div className="appOrder__move">
                <ListAbsoluteMain
                    className="appOrder__moveHead"
                    items={this.getBlocks()}
                    renderItem={this.renderHeadBlock}
                    classNameItem="appOrder__moveHeadBlock"
                    prop="key"
                    paramsParent={{ width: true }}
                    styles={['height']}
                    isNotParamsItem={true}
                    callback={({ type, isInit }) => {
                        if (type === 'parent' || isInit) {
                            setUpdateKey();
                        }
                    }}
                    currentItemKey={this.getCurrentBlock()}
                    allItems={this.blocksOrder}
                />
                <div className="appOrder__moveContent _col">
                    <div className="appOrder__moveActions">
                        {renderActions({ className: '_white', point })}
                    </div>
                    <ListAbsoluteMain
                        className="appOrder__moveFoot"
                        items={this.getBlocks()}
                        renderItem={this.renderFoot}
                        classNameItem="appOrder__moveFootBlock"
                        prop="key"
                        styles={['height']}
                        isNotParamsItem={true}
                        callback={({ type, isInit }) => {
                            if (type === 'parent' || isInit) {
                                setUpdateKey();
                            }
                        }}
                        currentItemKey={this.getCurrentBlock()}
                        allItems={this.blocksOrder}
                    />
                    {order.isAcceptChangeRoute && point.index !== order.route.length - 1 ? (
                        <>
                            <div className="appOrder__moveFootAction">
                                <div className="link _click _bold" onClick={this.changePoint}>
                                    Перенести/отменить точку
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        windowIsReady: state.windowIsReady,
    };
}

export default connect(mapStateToProps)(OrderMove);

OrderMove.propTypes = {
    user: PropTypes.object,
    setUpdateKey: PropTypes.func,
    renderActions: PropTypes.func,
    handlerPoint: PropTypes.func,
    order: PropTypes.object,
    point: PropTypes.object,
    loadingKey: PropTypes.string,
    windowIsReady: PropTypes.bool,
    roadInfo: PropTypes.object,
};
