import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../../../functions/setSpacesInText';
import getFormatedDate from '../../../functions/getFormatedDate';
import Icon from '../../Icon.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';

class NotificationPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getInfo() {
        const { type } = this.props;

        if (type === 'system') {
            return {
                typeTitle: 'Livecargo',
                icon: 'livecargo',
            };
        }

        if (type === 'fns') {
            return {
                typeTitle: 'ФНС',
                icon: 'fns',
            };
        }

        if (type === 'pay') {
            return {
                typeTitle: 'Акты',
                icon: 'app-main-pays',
            };
        }

        return {};
    }

    render() {
        const {
            createdDate,
            status,
            notificationsType,
            setCompletedNotifications,
            type,
            name,
            data,
            corporationId,
        } = this.props;
        let { title, content } = this.props;
        const info = this.getInfo();

        if (data?.fnsType) {
            if (data.fnsType === 'unbound') {
                title =
                    'У Livecargo CRM отозвано разрешение на осуществление действий от Вашего имени';
                content = `Вы отозвали у Livecargo CRM полномочия на действия в рамках налогового режима от Вашего имени. Чтобы подключиться снова, нажмите на кнопку ниже.`;
            }

            if (data.fnsType === 'unregistered') {
                title = 'Вы были отвязаны от компании Livecargo CRM, в связи с утратой статуса НПД';
                content = 'Ваш аккаунт был снят с учета НПД.';
            }
        }

        if (type === 'system' && name === 'inactiveContract') {
            content =
                'Договор был расторгнут со стороны корпорации. Если вы желаете вновь сотрудничать с данной компанией — отправьте запрос на подключение.';
        }

        return (
            <div
                className={`notificationPreview _${type} ${
                    notificationsType === 'new' ? '_new' : ''
                }`}
                onClick={() => {
                    handlerPopup({
                        name: 'appNotificationsPopup',
                        isShow: true,
                        date: createdDate,
                        type,
                        key: name,
                        title,
                        content,
                        status,
                        data,
                        corporationId,
                        ...info,
                        setCompletedNotifications,
                    });
                }}
            >
                <div className="notificationPreview__content">
                    <div className="notificationPreview__title">
                        {info.image ? (
                            <img
                                src={require(`../../../img/${info.image}`)}
                                alt=""
                                className="notificationPreview__titleIcon _image"
                            />
                        ) : (
                            <div className="notificationPreview__titleIcon">
                                <Icon name={info.icon} />
                            </div>
                        )}

                        {info.typeTitle}
                    </div>
                    <div className="notificationPreview__date">
                        {getFormatedDate({ date: new Date(createdDate), type: 'full' })}
                    </div>
                    <div
                        className="notificationPreview__description"
                        dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
                    ></div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(NotificationPreview);

NotificationPreview.propTypes = {
    _id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    createdDate: PropTypes.string,
    notificationsType: PropTypes.string,
    content: PropTypes.string,
    status: PropTypes.string,
    data: PropTypes.object,
    corporationId: PropTypes.string,
    setCompletedNotifications: PropTypes.func,
};
