import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import CrmListPopup from '../../crm/popups/ListPopup.jsx';

class Countries extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    name = 'listPopup';

    hide() {
        const { popupName } = this.props;

        super.hide();

        handlerPopup({ name: popupName, isShow: false });
    }

    render() {
        const { currentId, callback, popupName, currentName, data } = this.props;

        return (
            <div ref={this.parent} className="appPopup _col">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <CrmListPopup
                            popupName={popupName}
                            currentName={currentName}
                            currentId={currentId}
                            callback={callback}
                            data={data}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(Countries);

Countries.propTypes = {
    heightWindow: PropTypes.number,
    hideCallback: PropTypes.func,
    currentId: PropTypes.string,
    callback: PropTypes.func,
    data: PropTypes.object,
};
