import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../../../functions/setSpacesInText';

class JoinDocs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { renderButton, renderDoc, setCurrentStep, docs = [] } = this.props;

        return (
            <div className="join__blockInner _col">
                <div className="join__blockTitle">
                    Загрузка
                    <br />
                    документов
                </div>
                {docs.length === 0 ? (
                    <>
                        <div
                            className="join__blockText"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(
                                    'Для вашего сценария документы не требуются.',
                                ),
                            }}
                        />
                        <div className="join__blockFields" />
                    </>
                ) : (
                    <>
                        <div
                            className="join__blockText"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(
                                    'Загрузите фото перечисленных документов, чтобы мы вас идентифицировали и выпустили электронную подпись. Она нужна для подписания договоров и актов.',
                                ),
                            }}
                        />
                        <div className="join__blockFields">{docs.map((doc) => renderDoc(doc))}</div>
                    </>
                )}

                {renderButton({ name: 'docs', content: 'Продолжить' })}
                <div
                    className="join__blockBack"
                    onClick={() => {
                        setCurrentStep('anket');
                    }}
                >
                    Вернуться к основной информации
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinDocs);

JoinDocs.propTypes = {
    user: PropTypes.object,
    renderDoc: PropTypes.func,
    renderButton: PropTypes.func,
    filesOrder: PropTypes.object,
    setCurrentStep: PropTypes.func,
    docs: PropTypes.array,
};
