import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';

class JoinCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderError = this.renderError.bind(this);
        this.renderWallet = this.renderWallet.bind(this);
    }

    walletInfo = {
        account: {
            content: 'Реквизиты счёта',
        },
        card: {
            content: 'Номер карты',
        },
    };

    getWallet() {
        const { currentWalletType } = this.props;

        return [{ key: currentWalletType }];
    }

    renderWallet({ prop: key }) {
        const { renderField } = this.props;

        return (
            <div className="join__blockWalletBlock">
                {key === 'card' ? (
                    <>{renderField({ name: 'numberCard' })}</>
                ) : (
                    <>
                        <div className="join__blockBank">
                            <div className="join__blockBankInner">
                                <div className="join__blockBankTitle">
                                    Счет обязательно должен принадлежать вам
                                </div>
                                <div className="join__blockBankDescription">
                                    Если указать реквизиты другого человека, деньги не дойдут
                                </div>
                            </div>
                        </div>

                        {['bic', 'bankAccount', 'addressatName'].map((fieldName) =>
                            renderField({ name: fieldName }),
                        )}
                    </>
                )}
            </div>
        );
    }

    getErrors() {
        const { error } = this.props;

        return [{ key: error }];
    }

    renderError({ prop: key }) {
        return (
            <div className="join__blockError">
                {key === 'corporate' && (
                    <>
                        Вы указали корпоративную карту,
                        <br />
                        введите другую
                    </>
                )}
                {key === 'luhn' && <>Номер карты неверный</>}
            </div>
        );
    }

    render() {
        const {
            renderButton,
            setCurrentStep,
            setUpdateKey,
            currentWalletType,
            changeWalletType,
            joinScript,
        } = this.props;
        const { executorOrganization } = joinScript;

        return (
            <div className="join__blockInner _col">
                {['FL', 'FL-A'].includes(executorOrganization) ? (
                    <>
                        <div className="join__blockTitle">Укажите номер банковской карты</div>
                        <p className="join__blockText">Она нужна, чтобы получать оплату.</p>
                    </>
                ) : (
                    <>
                        <div className="join__blockTitle">
                            Укажите банковские
                            <br />
                            реквизиты
                        </div>
                        <p className="join__blockText">
                            Реквизиты нужны, чтобы получать оплату. Их можно узнать в&nbsp;свойствах
                            счёта в&nbsp;приложении вашего банка.
                        </p>
                        <div className="join__blockWalletTabs _row">
                            {['account', 'card'].map((key) => {
                                const walletInfo = this.walletInfo[key];

                                return (
                                    <label className="join__blockWalletTab _click" key={key}>
                                        <input
                                            type="radio"
                                            className="join__blockWalletTabInput"
                                            checked={currentWalletType === key}
                                            onChange={() => {
                                                changeWalletType({ type: key });
                                            }}
                                        />
                                        <div className="join__blockWalletTabView _col">
                                            {walletInfo.content}
                                        </div>
                                    </label>
                                );
                            })}
                        </div>
                    </>
                )}

                <ListAbsoluteMain
                    className="join__blockWalletBlocks"
                    items={this.getWallet()}
                    renderItem={this.renderWallet}
                    classNameItem="join__blockWalletBlock"
                    prop="key"
                    paramsParent={{ width: true }}
                    styles={['height']}
                    isNotParamsItem={true}
                    callback={({ type }) => {
                        if (type === 'parent') {
                            setUpdateKey();
                        }
                    }}
                    allItems={['account', 'card']}
                    currentItemKey={currentWalletType}
                />
                <div className="join__blockFields">
                    <ListAbsoluteMain
                        className="join__blockErrors"
                        items={this.getErrors()}
                        renderItem={this.renderError}
                        classNameItem="join__blockError"
                        prop="key"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isNotParamsItem={true}
                        callback={({ type }) => {
                            if (type === 'parent') {
                                setUpdateKey();
                            }
                        }}
                    />
                </div>
                {renderButton({ name: 'card', content: 'Завершить', withArrow: false })}
                <div
                    className="join__blockBack"
                    onClick={() => {
                        setCurrentStep('docs');
                    }}
                >
                    Вернуться к документам
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinCard);

JoinCard.propTypes = {
    user: PropTypes.object,
    renderField: PropTypes.func,
    renderButton: PropTypes.func,
    setCurrentStep: PropTypes.func,
    error: PropTypes.string,
    setUpdateKey: PropTypes.func,
    joinScript: PropTypes.object,
    currentWalletType: PropTypes.string,
    changeWalletType: PropTypes.func,
};
