import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFile from '../../classes/File';
import getChat from '../../requests/getChat';
import getRealParams from '../../functions/getRealParams.ts';
import setAnimate from '../../functions/setAnimate';
import getUpdateFormData from '../../functions/getUpdateFormData';

import ListAbsoluteMain from '../ListAbsoluteMain.jsx';
import Icon from '../Icon.jsx';
import Rate from './Rate.jsx';

import getHeaders from '../../functions/getHeaders';
import removeTransition from '../../functions/removeTransition.ts';
import Loader from '../Loader.jsx';
import Animate from '../Animate.jsx';
import ListScroll from '../ListScroll.jsx';
import getCurrentCorporation from '../../functions/app/getCurrentCorporation';
import getFormatedDate from '../../functions/getFormatedDate';
import Message from './Message.jsx';
import Table from '../crm/manual/Table.jsx';

import setNotification from '../../functions/setNotification';
import handlerErrorRequest from '../../functions/handlerErrorRequest';
import FileItem from './File.jsx';
import WindowTop from './WindowTop.jsx';
import WindowTags from './WindowTags.jsx';
import { getCookie } from '../../functions/handlerCookies';
import Link from '../Link.jsx';
import { dispatcher } from '../../redux/redux';
import VoiceBar from './VoiceBar.jsx';
import handlerPopup from '../../functions/app/handlerPopup';
import handlerWindow from '../../functions/handlerWindow';

class Inner extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.setContentHeight = this.setContentHeight.bind(this);
        this.handlerMessage = this.handlerMessage.bind(this);
        this.setAreaHeight = this.setAreaHeight.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.addMessage = this.addMessage.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.updateChat = this.updateChat.bind(this);
        this.setReadMessage = this.setReadMessage.bind(this);
        this.readMessages = this.readMessages.bind(this);
        this.updateMessagesForRead = this.updateMessagesForRead.bind(this);
        this.updateMessage = this.updateMessage.bind(this);
        this.renderFile = this.renderFile.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.setRate = this.setRate.bind(this);
        this.openChat = this.openChat.bind(this);
        this.setRateCallback = this.setRateCallback.bind(this);
        this.handlerLoadingKey = this.handlerLoadingKey.bind(this);
        this.handlerMove = this.handlerMove.bind(this);
        this.handlerKeyDownInput = this.handlerKeyDownInput.bind(this);
        this.handlerKeyUpInput = this.handlerKeyUpInput.bind(this);
        this.cardNumberCallback = this.cardNumberCallback.bind(this);

        this.checkMe = this.checkMe.bind(this);
        this.changeHeightWindow = this.changeHeightWindow.bind(this);
        this.startVoice = this.startVoice.bind(this);
        this.proccessVoice = this.proccessVoice.bind(this);
        this.endVoice = this.endVoice.bind(this);
        this.sendVoice = this.sendVoice.bind(this);
        this.handlerFootMove = this.handlerFootMove.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);
        this.deleteMessages = this.deleteMessages.bind(this);
        this.editMessage = this.editMessage.bind(this);
        this.checkChangeChat = this.checkChangeChat.bind(this);
        this.setJoinCode = this.setJoinCode.bind(this);
        this.rotateCallback = this.rotateCallback.bind(this);

        this.handlerSocket = this.handlerSocket.bind(this);

        this.parent = React.createRef();
    }

    classNameItem = '.chatV2Window__contentMessage';

    stepCounter = 50;

    supports = ['Не поступили средства', 'У меня проблемы с актом', 'Перезвоните мне'];

    formDataRead = new FormData();

    setReadMessage() {
        const { chat } = this.state;

        if (chat && this.stackRead.length) {
            this.formDataRead.set('action', 'set-read');
            this.formDataRead.set('id', chat._id);
            this.formDataRead.set('readMessages', JSON.stringify(this.stackRead));

            axios
                .patch(`${process.env.REACT_APP_API}/chat`, this.formDataRead, {
                    headers: getHeaders(),
                })
                .then(
                    (res) => {
                        const { success } = res.data;

                        if (success === true) {
                            this.stackRead = [];
                        } else {
                            handlerErrorRequest(res);
                        }
                    },
                    () => null,
                );
        }
    }

    stackRead = [];

    timerRead = null;

    readMessages() {
        if (this.parent.current && !this.getCondForDisabledSend()) {
            const content = this.parent.current.querySelector('.chatV2Window__content');

            this.parent.current
                .querySelectorAll(`.chatV2Message[data-notread="true"]`)
                .forEach((item) => {
                    const offset =
                        item.getBoundingClientRect().top - content.getBoundingClientRect().top;
                    const id = item.getAttribute('data-id') || item.getAttribute('data-_id');

                    if (
                        !this.stackRead.includes(id) &&
                        offset > 0 &&
                        offset < content.clientHeight
                    ) {
                        this.stackRead.push(id);

                        if (this.timerRead) {
                            clearTimeout(this.timerRead);
                        }

                        this.timerRead = setTimeout(() => {
                            this.setReadMessage();
                        }, 300);
                    }
                });
        }
    }

    updateMessagesForRead({ messages }) {
        this.setState((state) => {
            const newState = { ...state };
            const items = [...newState.items];

            messages.forEach((message) => {
                const indexMessage = items.findIndex((item) => item._id === message._id);

                if (indexMessage !== -1) {
                    items[indexMessage].usersWhoRead = message.usersWhoRead;
                    items[indexMessage].isReadFromResponsible = message.isReadFromResponsible;
                }
            });

            newState.items = items;
            newState.chatUpdateKey = new Date().getTime();

            return newState;
        });
    }

    handlerFile = new HandlerFile({ typeOfFiles: 'docs' });

    filesCounter = 0;

    uploadFiles({ target }) {
        this.handlerFile
            .uploadFiles({
                target,
                formData: this.formData,
                getName: (key) => `${key}-${this.filesCounter}`,
            })
            .then(
                ({ resultFiles }) => {
                    this.formData = new FormData();

                    const files = this.state.files || [];
                    const newFiles = resultFiles
                        .filter((file) => !files.find((innerFile) => innerFile.name === file.name))
                        .filter((file, key) => files.length + key < 4)
                        .map((file, key) => {
                            const fileKey = `file-${key}-${this.filesCounter}`;

                            this.formData.set(fileKey, file.object);

                            if (file.width && file.height) {
                                this.formData.set(`width-${fileKey}`, file.width);
                                this.formData.set(`height-${fileKey}`, file.height);
                            }

                            return {
                                ...file,
                                path: null,
                                object: null,
                            };
                        });
                    const finalFiles = files.concat(...newFiles);

                    this.filesCounter += newFiles.length;

                    this.setState({
                        files: finalFiles,
                    });
                },
                () => null,
            );
    }

    deleteFile({ key }) {
        this.setState((state) => {
            const newState = { ...state };
            const files = JSON.parse(JSON.stringify(newState.files));

            files.splice(key, 1);

            this.formData.delete(key);

            newState.files = files;

            return newState;
        });
    }

    getFiles() {
        const { files = [] } = this.state;

        return files;
    }

    renderFile({ item: file }) {
        return (
            <div className="chatV2Window__file">
                <div className="chatV2Window__fileInner">
                    <FileItem file={file} deleteFile={this.deleteFile} />
                </div>
            </div>
        );
    }

    renderFiles() {
        return (
            <ListAbsoluteMain
                className={`chatV2Window__files _${process.env.REACT_APP_SYSTEM}`}
                items={this.getFiles()}
                renderItem={this.renderFile}
                classNameItem="chatV2Window__file"
                prop="key"
                paramsParent={{ width: true }}
                styles={['height']}
                callback={() => {
                    if (this.setContentHeight) {
                        this.setContentHeight();
                    }
                }}
            />
        );
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    openChat() {
        const { chat, loadingKey } = this.state;

        if (!loadingKey) {
            this.handlerLoadingKey('open').then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/chat`,
                        { id: chat._id, action: 'open' },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            handlerErrorRequest(res);
                        },
                        () => null,
                    );
            });
        }
    }

    setRate({ _id, result }) {
        const { chat } = this.state;
        const formData = new FormData();

        formData.set('id', chat._id);
        formData.set('rate', result);
        formData.set('action', 'set-rate');
        formData.set('messageId', _id);

        axios
            .patch(`${process.env.REACT_APP_API}/chat`, formData, {
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    const { success, data } = res.data;

                    if (!success) {
                        const { message } = data;

                        if (message === 'Chat already complete') {
                            setNotification({ notification: 'chat-rate-already-complete' });
                        }

                        handlerErrorRequest(res);
                    }
                },
                () => null,
            );
    }

    setRateCallback() {
        this.setState({ isSetRate: true });
    }

    setContentHeight(isRemove, isGet) {
        if (this.parent.current) {
            const { offsetHeight: pageHeight } = this.parent.current;
            const { height: footHeight } = getRealParams({
                parent: this.parent.current,
                elem: '.chatV2Window__foot',
                width: this.parent.current.offsetWidth,
            });

            let otherHeight = 0;

            const top = this.parent.current.querySelector('.chatV2Window__top');

            if (top) {
                otherHeight += top.offsetHeight;
            }

            const head = this.parent.current.querySelector('.chatV2Window__head');

            if (head) {
                otherHeight += head.offsetHeight;
            }

            const contentHeight = pageHeight - otherHeight - footHeight;

            if (!isGet) {
                const content = this.parent.current.querySelector('.chatV2Window__contentWrapper');

                if (isRemove) {
                    removeTransition({ item: '.chatV2Window__contentWrapper' });
                }

                content.style.height = `${contentHeight}px`;
            }

            return contentHeight;
        }

        return 0;
    }

    setAreaHeight() {
        const area = this.parent.current.querySelector('.chatV2Window__area');

        let { scrollHeight: areaHeight } = getRealParams({
            parent: area,
            elem: '.chatV2Window__areaInput',
            width: area.offsetWidth,
            isClearStyles: true,
        });

        const { offsetHeight: minHeight } = this.parent.current.querySelector(
            '.chatV2Window__footAction',
        );

        if (areaHeight < minHeight) {
            areaHeight = minHeight;
        }

        if (areaHeight > 120) {
            areaHeight = 120;
        }

        area.style.height = `${areaHeight}px`;

        area.querySelector('.chatV2Window__areaInput').scrollTop = areaHeight;

        this.setContentHeight();
    }

    handlerMessage({ target }) {
        this.setState({ message: target.value }, this.setAreaHeight);
    }

    getMessages() {
        const { chat, items = [] } = this.state;
        const { user } = this.props;
        const corporation =
            process.env.REACT_APP_SYSTEM === 'app' && getCurrentCorporation({ user });

        if (chat) {
            const messages = [];
            const reverseItems = JSON.parse(JSON.stringify(items)).reverse();

            reverseItems.forEach((message, key) => {
                if (message.type === 'closed') {
                    messages.push({
                        _id: message._id,
                        messageType: 'rate',
                        createrId: 'appSystem',
                        rate: message.rate,
                        createdDate: message.createdDate,
                    });
                } else if (['start', 'end'].includes(message.type)) {
                    messages.push({
                        ...message,
                        messageType: 'date',
                        createrId: 'appSystem',
                    });
                } else {
                    const prevMessage = reverseItems[key - 1];

                    if (prevMessage) {
                        const prevDate = new Date(prevMessage.createdDate);
                        const date = new Date(message.createdDate);

                        if (prevDate.getDate() !== date.getDate()) {
                            messages.push({
                                _id: `date-${message._id}`,
                                isDate: true,
                                createdDate: message.createdDate,
                            });
                        }
                    }

                    if (message.files) {
                        message.files.forEach((file, fileKey) => {
                            messages.push({
                                ...message,
                                messageId: message._id,
                                _id: `${message._id}-${fileKey}`,
                                file,
                            });
                        });
                    }

                    if (message.content) {
                        messages.push(message);
                    } else if (message.files?.length) {
                        const fileMessage = messages.find(
                            (innerMessage) => innerMessage._id === `${message._id}-0`,
                        );

                        if (fileMessage) {
                            fileMessage._id = message._id;
                        }
                    }
                }
            });

            if (process.env.REACT_APP_SYSTEM === 'app') {
                const startMessageIndex = messages.findIndex((message) => message.type === 'start');
                const startDate = new Date(
                    messages[startMessageIndex]?.createdDate || chat.createdDate,
                );

                startDate.setSeconds(startDate.getSeconds() + 1);

                let corporationName = corporation?.name ? ` ${corporation?.name}` : '';

                if (chat.type === 'support') {
                    corporationName = ' CRM LIVECARGO';
                }

                const appMessage = {
                    _id: 'start',
                    createrId: 'appSystem',
                    content: `<b>Здравствуйте! Это поддержка исполнителей${corporationName}. Вы можете:</b><br/>— Выбрать тему из списка ниже<br/>— Описать проблему сообщением<br/>— Запросить обратный звонок`,
                    createdDate: startDate.toString(),
                };

                messages.splice(startMessageIndex + 1, 0, appMessage);
            }

            messages.forEach((message, key) => {
                const prevMessage = key !== 0 && messages[key - 1];

                if (prevMessage?.createrId !== message.createrId) {
                    message.withCreater = true;
                }
            });

            if (chat.anyInfo.integrationDisable) {
                messages.push({
                    _id: 'disabled',
                    createrId: 'appSystem',
                });
            }

            return messages;
        }

        return [];
    }

    checkMe(message) {
        const { chat, user } = this.state;

        if (message && user) {
            if (process.env.REACT_APP_SYSTEM === 'crm') {
                if (chat.type === 'support' && chat.entity.id === user._id) {
                    return message.createrId === user._id;
                }

                return message.createrId !== chat.entity.id;
            }

            return message.createrId === user._id;
        }

        return false;
    }

    getInfoNumber() {
        const { chat } = this.state;
        const linkOrder =
            process.env.REACT_APP_SYSTEM === 'site' ? 'order-details-inner' : 'order-details-main';

        if (chat) {
            const { type } = chat;

            if (type === 'order') {
                return (
                    <Link
                        className="chatV2Window__infoNumberLink"
                        pageName={linkOrder}
                        ids={{ 2: chat?.anyInfo.idOfOrder }}
                    >
                        Заказ №{chat?.anyInfo.numberOfOrder}
                    </Link>
                );
            }

            if (type === 'orderInfo') {
                if (process.env.REACT_APP_SYSTEM === 'crm') {
                    return (
                        <>
                            Клиент на точке,{' '}
                            <Link
                                className="chatV2Window__infoNumberLink"
                                pageName={linkOrder}
                                ids={{ 2: chat?.anyInfo.idOfOrder }}
                            >
                                заказ №{chat?.anyInfo.numberOfOrder}
                            </Link>
                            , точка №{chat?.anyInfo.numberOfPoint}
                        </>
                    );
                }

                return `Заказ №${chat?.anyInfo.numberOfOrder}, точка №${chat?.anyInfo.numberOfPoint}`;
            }

            return `Обращение №${chat?.number}`;
        }

        return '';
    }

    renderMessage(message, key, messages) {
        const _id = message._id;
        const { chat, loadingKey, editedMessageId } = this.state;
        const { user, getParent } = this.props;
        const idDisabled = editedMessageId && editedMessageId !== _id;
        let className = '';

        if (message.isHide || message.isDeleteHide) {
            className += '_hide';
        }

        if (messages.filter((item) => !item.isHide).length - 1 === key) {
            className += ' _last';
        }

        const props = { key: _id, 'data-_id': _id };

        if (message.messageType === 'rate') {
            return (
                <div
                    className={`chatV2Window__contentMessage _col _info _rate ${
                        idDisabled ? '_inActive' : ''
                    }`}
                    {...props}
                >
                    <div className="chatV2Window__contentMessageInner">
                        <div className="chatV2Window__rate _col">
                            <div className="chatV2Window__rateInner">
                                <Rate
                                    chat={chat}
                                    message={message}
                                    openChat={this.openChat}
                                    setRate={this.setRate}
                                    setRateCallback={this.setRateCallback}
                                    loadingKey={loadingKey}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (['date'].includes(message.messageType)) {
            const prevMessage = messages[key - 1];

            return (
                <div
                    className={`chatV2Window__contentMessage _col _info _date ${
                        prevMessage?.messageType === 'date' ? '_prevDate' : ''
                    } ${!prevMessage?.messageType ? '_nextDate' : ''} ${
                        idDisabled ? '_inActive' : ''
                    } ${className}`}
                    {...props}
                >
                    <div className="chatV2Window__contentMessageInner">
                        <div className="chatV2Window__session _row">
                            <div className="chatV2Window__sessionInner">
                                <b>Обращение №{message.anyInfo?.number}</b> —{' '}
                                {message.type === 'start' ? 'Начато ' : 'Завершено '}
                                {getFormatedDate({
                                    date: new Date(message.createdDate),
                                    type: 'text',
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (_id === 'disabled') {
            return (
                <div
                    className={`chatV2Window__contentMessage _info _date _disabled ${className}`}
                    {...props}
                >
                    <div className="chatV2Window__contentMessageInner">
                        <div className="chatV2Window__session _row">
                            <div className="chatV2Window__sessionInner">
                                Чат заблокирован пользователем
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (message.isDate) {
            return (
                <div
                    className={`chatV2Window__contentMessage _col _info ${
                        idDisabled ? '_inActive' : ''
                    } ${className}`}
                    {...props}
                >
                    <div className="chatV2Window__contentMessageInner">
                        <div className="chatV2Window__date _col">
                            <div className="chatV2Window__dateInner">
                                {getFormatedDate({
                                    date: new Date(message.createdDate),
                                    type: 'text',
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const isMe = this.checkMe(message);
        const userId = getCookie('hashOrderInfo') || user?._id;
        const notRead =
            !isMe &&
            (chat?.entity.id === userId
                ? message.usersWhoRead?.indexOf(userId) === -1
                : !message.isReadFromResponsible);

        return (
            <div
                className={`chatV2Window__contentMessage _col ${
                    message.file ? `_file _${message.messageId}` : ''
                } ${isMe ? '_me' : ''} ${idDisabled ? '_inActive' : ''} ${className}`}
                data-notread={notRead}
                {...props}
            >
                <div className="chatV2Window__contentMessageInner">
                    <Message
                        chat={chat}
                        message={message}
                        notRead={notRead}
                        checkMe={this.checkMe}
                        getParent={getParent}
                        editMessage={this.editMessage}
                        rotateCallback={this.rotateCallback}
                        cardNumberCallback={this.cardNumberCallback}
                    />
                </div>
            </div>
        );
    }

    formData = new FormData();

    cardNumberCallback() {
        if (!this.getCondForDisabledSend()) {
            this.setState({ message: 'Номер карты успешно изменён!' }, () => {
                this.focusInput();
            });
        }
    }

    sendMessage(otherMessage, isVoice) {
        const { files = [], editedMessageId, template, loadingKey } = this.state;
        const chatId = this.getChatId();
        const message = otherMessage || this.state.message;

        if (!loadingKey && (message || files.length || isVoice)) {
            this.formData.set('id', chatId);

            if (!isVoice && message) {
                this.formData.set('content', message);
            }

            if (editedMessageId) {
                this.formData.set('editedMessageId', editedMessageId);
            }

            if (template) {
                this.formData.set('templateId', template._id);
            }

            handlerWindow({
                action: 'hide',
                name: 'emodziList',
            });

            this.handlerLoadingKey(otherMessage || 'send').then(() => {
                axios
                    .put(`${process.env.REACT_APP_API}/chat`, getUpdateFormData(this.formData), {
                        headers: getHeaders(),
                    })
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                this.formData = new FormData();

                                this.setTimerLoading();

                                this.voice = [];
                            }
                        },
                        () => null,
                    );
            });
        }
    }

    timerLoading;

    setTimerLoading() {
        if (this.timerLoading) {
            clearTimeout(this.timerLoading);
        }

        this.timerLoading = setTimeout(() => {
            this.getChat();
        }, 5_000);
    }

    deleteHideMessages() {
        setTimeout(() => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const items = [...newState.items];

                    items
                        .filter((item) => item.isHide)
                        .forEach((item) => {
                            const message = this.parent.current.querySelector(
                                `.chatV2Window__contentMessage[data-_id="${item._id}"]`,
                            );
                            const messageInner = message.querySelector(
                                '.chatV2Window__contentMessageInner',
                            );

                            item.isHide = false;

                            message.style.height = `${messageInner.offsetHeight}px`;
                        });

                    newState.items = items;

                    return newState;
                },
                () => {
                    setTimeout(() => {
                        this.parent.current
                            .querySelectorAll('.chatV2Window__contentMessage')
                            .forEach((message) => {
                                message.style.height = null;
                            });
                    }, 300);
                },
            );
        }, 10);
    }

    deleteMessages({ deletedMessages = [] }) {
        deletedMessages.forEach((id) => {
            const message = this.parent.current.querySelector(
                `.chatV2Window__contentMessage[data-_id="${id}"]`,
            );
            const messageInner = message.querySelector('.chatV2Window__contentMessageInner');

            message.style.height = `${messageInner.offsetHeight}px`;
        });

        setTimeout(() => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const items = [...newState.items];

                    deletedMessages.forEach((id) => {
                        const item = items.find((innerItem) => innerItem._id === id);
                        const message = this.parent.current.querySelector(
                            `.chatV2Window__contentMessage[data-_id="${id}"]`,
                        );
                        item.isDeleteHide = true;

                        message.style.height = `0px`;
                    });

                    newState.items = items;

                    return newState;
                },
                () => {
                    setTimeout(() => {
                        this.setState(
                            (state) => {
                                const newState = { ...state };
                                const items = [...newState.items];

                                deletedMessages.forEach((id) => {
                                    const index = items.findIndex(
                                        (innerMessage) => innerMessage._id === id,
                                    );

                                    if (index !== -1) {
                                        items.splice(index, 1);
                                    }
                                });

                                newState.items = items;

                                return newState;
                            },
                            () => {
                                this.scrollToEnd({});
                            },
                        );
                    }, 300);
                },
            );
        }, 10);
    }

    showHideMessages() {
        setTimeout(() => {
            this.setState((state) => {
                const newState = { ...state };
                const items = [...newState.items];

                items
                    .filter((item) => item.isHide)
                    .forEach((item) => {
                        const message = this.parent.current.querySelector(
                            `.chatV2Window__contentMessage[data-_id="${item._id}"]`,
                        );
                        const messageInner = message.querySelector(
                            '.chatV2Window__contentMessageInner',
                        );

                        item.isHide = false;

                        message.style.height = `${messageInner.offsetHeight}px`;
                    });

                newState.items = items;

                return newState;
            });
        }, 10);
    }

    addMessage({ messages = [], deletedMessages = [], anyInfo, initiatorUserId }) {
        const { user } = this.props;
        const userId = getCookie('hashOrderInfo') || user?._id;

        this.setState(
            (state) => {
                const newState = { ...state };
                const items = [...newState.items];
                const chat = JSON.parse(JSON.stringify(newState.chat));

                items.unshift(...messages.reverse().map((item) => ({ ...item, isHide: true })));

                if (userId === initiatorUserId) {
                    newState.loadingKey = null;
                    newState.message = '';
                    newState.files = [];
                    newState.editedMessageId = null;
                    newState.template = null;

                    if (this.timerLoading) {
                        clearTimeout(this.timerLoading);
                    }
                }

                if (anyInfo) {
                    chat.anyInfo = anyInfo;
                }

                deletedMessages.forEach((id) => {
                    const index = items.findIndex((innerMessage) => innerMessage._id === id);

                    if (index !== -1) {
                        items.splice(index, 1);
                    }
                });

                newState.isNewMessage = true;

                newState.chat = chat;
                newState.items = items;

                return newState;
            },
            () => {
                this.showHideMessages();
                this.setAreaHeight();
                this.readMessages();

                if (userId === initiatorUserId) {
                    this.focusInput();
                }
            },
        );
    }

    editMessage({ id }) {
        const { items } = this.state;
        const message = items.find((innerMessage) => innerMessage._id === id);

        this.setState({ editedMessageId: id, message: message?.content }, () => {
            this.setAreaHeight();
            this.focusInput();

            setTimeout(() => {
                this.scrollToMessage({ id });
            }, 10);
        });
    }

    scrollToMessage({ id, prevTop = 0 }) {
        const message = this.parent.current.querySelector(
            `.chatV2Window__contentMessage[data-_id="${id}"]`,
        );

        const content = this.parent.current.querySelector('.chatV2Window__content');
        const contentInner = this.parent.current.querySelector('.chatV2Window__contentInner');

        if (message) {
            let messageTop = message.getBoundingClientRect().y - content.getBoundingClientRect().y;

            if (prevTop) {
                messageTop -= prevTop;
            }

            contentInner.scrollTo({
                top: contentInner.scrollTop + messageTop,
            });
        }
    }

    scrollToEnd({ isStart }) {
        const contentInner = this.parent.current.querySelector('.chatV2Window__contentInner');

        if (contentInner) {
            const { scrollHeightArea: areaHeight } = getRealParams({
                parent: this.parent.current,
                elem: '.chatV2Window__contentInner',
                width: this.parent.current.offsetWidth,
                clearStyleElems: [
                    '.chatV2Window__contentMessages',
                    '.chatV2Window__contentMessage',
                ],
                // isNotRemove: true,
            });

            if (isStart) {
                contentInner.scrollTop = areaHeight;
            } else {
                const { scrollTop } = contentInner;
                const scrollDiff = areaHeight - scrollTop;

                this.setState({ isNewMessage: false });

                setAnimate({
                    draw: (progress) => {
                        contentInner.scrollTo({
                            top: scrollTop + progress * scrollDiff,
                        });
                    },
                    duration: 300,
                });
            }
        }
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    getSortMessages(messages) {
        return JSON.parse(JSON.stringify(messages)).sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        );
    }

    scrollToLastMessage() {
        const contentInner = this.parent.current.querySelector('.chatV2Window__contentInner');
        const { moreLastMessageId, moreLastMessageTop = 0 } = this.state;

        const lastMessage = this.parent.current.querySelector(
            `.chatV2Window__contentMessage[data-_id="${moreLastMessageId}"]`,
        );

        const top = lastMessage.getBoundingClientRect().y;

        contentInner.scrollTop = top - moreLastMessageTop;

        this.parent.current
            .querySelector('.chatV2Window__contentInner')
            .classList.remove('_hidden');

        this.setState({ moreLastMessageId: null, moreLastMessageTop: null });
    }

    updateChat({ fields }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const items = [...newState.items];
                    const chat = JSON.parse(JSON.stringify(newState.chat));

                    Object.keys(fields).forEach((prop) => {
                        if (prop === 'closedMessage') {
                            if (fields.closedMessage.rate) {
                                const messageIndex = items.findIndex(
                                    (message) => message._id === fields.closedMessage._id,
                                );

                                if (messageIndex !== -1) {
                                    items[messageIndex].rate = fields.closedMessage.rate;
                                }
                            }
                        } else if (prop === 'editedMessage') {
                            const messageIndex = items.findIndex(
                                (message) => message._id === fields.editedMessage._id,
                            );

                            if (messageIndex !== -1) {
                                items[messageIndex].content = fields.editedMessage.content;
                            }

                            newState.editedMessageId = null;
                            newState.chatUpdateKey = new Date().getTime();
                        } else {
                            chat[prop] = fields[prop];
                        }
                    });

                    newState.chat = chat;
                    newState.items = items;

                    if (fields.closedMessage) {
                        newState.isNewMessage = true;
                    }

                    if (fields.editedMessage) {
                        newState.message = '';
                    }

                    return newState;
                },
                () => {
                    if (fields.anyInfo) {
                        this.handlerLoadingKey(null);
                    }

                    if (fields.editedMessage) {
                        this.handlerLoadingKey(null);
                        this.setAreaHeight();

                        setTimeout(() => {
                            this.setState({ chatUpdateParamsKey: new Date().getTime() });
                        }, 10);
                    }

                    if (fields.responsible) {
                        this.readMessages();
                    }

                    if (fields.editedMessage) {
                        clearTimeout(this.timerLoading);
                    }

                    resolve();
                },
            );
        });
    }

    updateMessage({ fields }) {
        this.setState((state) => {
            const newState = { ...state };
            const items = [...newState.items];

            const messageIndex = items.findIndex((message) => message._id === fields._id);

            if (messageIndex !== -1) {
                Object.keys(fields).forEach((key) => {
                    items[messageIndex][key] = fields[key];
                });

                newState.chatUpdateKey = new Date().getTime();
            }

            newState.items = items;

            return newState;
        });
    }

    getChatId() {
        const { levels, type, currentChat } = this.props;

        if (type === 'fix') {
            return currentChat;
        }

        if (process.env.REACT_APP_SYSTEM === 'app') {
            return levels[1];
        }

        return levels[3];
    }

    focusInput(selectionEnd) {
        const input = this.parent.current.querySelector('.chatV2Window__areaInput');

        if (input && process.env.REACT_APP_SYSTEM !== 'app') {
            if (selectionEnd) {
                input.selectionEnd = selectionEnd;
            }

            input.focus();
        }
    }

    getChat() {
        const { handlerLoader, setOrderId } = this.props;
        const idOfChat = this.getChatId();

        return new Promise((resolve) => {
            getChat({ idOfChat }).then(
                ({ chat }) => {
                    if (setOrderId && chat?.anyInfo?.idOfOrder) {
                        setOrderId(chat?.anyInfo?.idOfOrder);
                    }

                    this.setState(
                        {
                            chat,
                            loadingKey: null,
                            message: '',
                            files: [],
                            // isNewMessage,
                            updatedKey: new Date().getTime(),
                            template: null,
                            counterScroll: this.stepCounter,
                        },
                        () => {
                            this.stackRead = [];

                            removeTransition({
                                item: '.chatV2Window__contentMessage',
                                isCurrent: true,
                            });

                            setTimeout(() => {
                                this.setState({ isChatInit: true });
                                this.readMessages();
                            }, 300);

                            if (handlerLoader && typeof handlerLoader === 'function') {
                                handlerLoader(false);
                            }

                            setTimeout(() => {
                                this.focusInput();
                            }, 10);

                            this.getItems().then(resolve);
                        },
                    );
                },
                () => null,
            );
        });
    }

    getCondForDisabledSend() {
        const { chat, loadingKey, isChangeChat, user } = this.state;

        if (process.env.REACT_APP_SYSTEM === 'crm') {
            if (chat?.type === 'support' && chat.entity.id === user?._id) {
                return false;
            }

            return chat?.responsible !== user?._id || chat?.anyInfo.integrationDisable;
        }

        // if (process.env.REACT_APP_SYSTEM === 'app') {
        //     return chat?.isClose === true;
        // }

        return loadingKey || isChangeChat;
    }

    getCondForTags() {
        const { chat } = this.state;

        return !this.getCondForDisabledSend() || !!chat?.tags.length;
    }

    checkLevels() {
        const { levels } = this.props;

        if (process.env.REACT_APP_SYSTEM === 'site') {
            return levels[1] === 'chat';
        }

        return levels[1] === 'chat';
    }

    checkChangeChat(isForce) {
        const { type } = this.props;
        const chatId = this.getChatId();

        if ((type !== 'fix' && this.checkLevels() && chatId && chatId !== this.chatId) || isForce) {
            this.chatId = chatId;

            this.setState({ isChangeChat: true }, () => {
                setTimeout(() => {
                    this.setState({ items: [] });

                    this.getChat().then(
                        () => {
                            this.setState({
                                isChangeChat: false,
                                message: '',
                                editedMessageId: null,
                            });
                        },
                        () => null,
                    );
                }, 300);
            });
        }
    }

    timerMoveRead;

    handlerMove() {
        if (this.timerMoveRead) {
            clearTimeout(this.timerMoveRead);
        }

        this.timerMoveRead = setTimeout(() => {
            this.readMessages();
        }, 300);
    }

    isInitPress = false;

    handlerKeyDownInput(e) {
        if (e.keyCode === 16) {
            this.pressShift = true;
        }

        if (!window.isAppDevice && e.keyCode === 13 && this.pressShift !== true) {
            e.preventDefault();

            this.sendMessage();
        }
    }

    handlerKeyUpInput(e) {
        if (e.keyCode === 16) {
            this.pressShift = false;
        }
    }

    handlerPressShift(isRemove) {
        const input = this.parent.current.querySelector('.chatV2Window__areaInput');

        if (input) {
            input.removeEventListener('keydown', this.handlerKeyDownInput);
            input.removeEventListener('keyup', this.handlerKeyUpInput);

            if (!isRemove) {
                input.addEventListener('keydown', this.handlerKeyDownInput);
                input.addEventListener('keyup', this.handlerKeyUpInput);
            }
        }
    }

    voice = [];

    startVoice() {
        if (!this.state.message && !this.state.isVoiceProccess && navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(
                (stream) => {
                    this.stream.getTracks().forEach((track) => {
                        track.stop();
                    });

                    this.stream = stream;
                    this.mediaRecorder = new MediaRecorder(stream);

                    this.mediaRecorder.ondataavailable = this.proccessVoice;
                    this.mediaRecorder.onstop = this.sendVoice;

                    this.mediaRecorder.start(500);

                    this.setState({ isVoiceProccess: true });
                },
                () => null,
            );
        }
    }

    proccessVoice(e) {
        this.voice.push(e.data);
    }

    endVoice(isCancel) {
        if (this.state.isVoiceProccess) {
            this.mediaRecorder.stop();

            if (typeof isCancel === 'boolean') {
                this.isCancel = isCancel;
            }

            this.stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
    }

    sendVoice() {
        if (this.state.isVoiceProccess) {
            const voiceFile = new File(this.voice, 'audio.ogg', {
                type: 'audio/ogg',
            });

            if (voiceFile.size > 15_000 && !this.isCancel) {
                this.formData.set('voice', voiceFile);

                this.sendMessage(null, true);
            } else {
                this.voice = [];
                this.isCancel = false;
            }

            this.setState({ isVoiceProccess: false });
        }
    }

    initVoice() {
        if (navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(
                (stream) => {
                    this.stream = stream;

                    this.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                },
                () => null,
            );
        }
    }

    visibilityDocChange({ detail: { isActive } }) {
        if (!isActive && this.state.isVoiceProccess) {
            this.endVoice(true);
        }
    }

    handlerFootMove(e) {
        if (this.state.isVoiceProccess) {
            const voiceAction = this.parent.current.querySelector(
                '.chatV2Window__footAction._voice',
            );
            const x = e.changedTouches[0].pageX;
            const y = e.changedTouches[0].pageY;

            const actionX = voiceAction.getBoundingClientRect().x;
            const actionY = voiceAction.getBoundingClientRect().y;

            if (
                !(
                    x > actionX &&
                    x < actionX + voiceAction.offsetWidth &&
                    y > actionY &&
                    y < actionY + voiceAction.offsetHeight
                )
            ) {
                this.endVoice(true);
            }
        }
    }

    handlerSocket({ detail }) {
        const { chat } = this.state;
        const { name, data } = detail;

        if (chat && name === 'chat') {
            const { idOfChat, fields, type, initiatorUserId } = data;

            if (idOfChat === chat._id) {
                if (type === 'newMessage') {
                    const { newMessages, deletedMessages, anyInfo } = fields;

                    this.addMessage({
                        messages: newMessages,
                        deletedMessages,
                        initiatorUserId,
                        anyInfo,
                    });

                    this.updateChat({ fields, initiatorUserId });
                }

                if (type === 'changeInfo') {
                    if (fields.user) {
                        this.checkChangeChat(true);
                    }

                    this.updateChat({ fields, initiatorUserId });
                }

                if (type === 'changeMessage') {
                    this.updateMessage({ fields, initiatorUserId });
                }

                if (type === 'updateCounter') {
                    const { messages } = data;

                    if (messages) {
                        this.updateMessagesForRead({ messages });
                    }
                }

                if (type === 'deleteMessages') {
                    this.deleteMessages(fields);
                }
            }
        }
    }

    showTemplates() {
        const callback = ({ template }) =>
            new Promise((resolve) => {
                this.setState(
                    {
                        template,
                        message: template.content,
                    },
                    () => {
                        this.setAreaHeight();
                        this.focusInput();
                        this.scrollToEnd({});

                        resolve();
                    },
                );
            });

        handlerPopup({ name: 'chatTemplatesPopup', isShow: true, callback });
    }

    showEmodziList({ target }) {
        const { getParent } = this.props;
        const parent = getParent();
        const area = this.parent.current.querySelector('.chatV2Window__areaInput');

        const addEmodzi = (emodzi) => {
            const { message } = this.state;

            const currentPosition = area?.selectionEnd || message?.length || 0;
            let resultMessage = emodzi;

            if (currentPosition > 0) {
                resultMessage = message.slice(0, currentPosition);

                resultMessage += emodzi;

                resultMessage += message.slice(currentPosition);
            }

            this.setState({ message: resultMessage }, () => {
                this.focusInput(currentPosition + emodzi.length);
            });
        };

        handlerWindow({
            parent,
            parentResize: parent,
            target: target.closest('.chatV2Window__areaEmodzi'),
            action: 'show',
            name: 'emodziList',
            className: '_emodzi',
            uniqKey: `emodziList-chat`,
            addEmodzi,
            centers: {
                left: 0.5,
                top: 1,
            },
            area,
            startPosition: area?.selectionEnd || this.state.message?.length || 0,
        });
    }

    rotateCallback({ id, file }) {
        this.setState(
            (state) => {
                const newState = { ...state };
                const items = [...newState.items];
                const index = items.findIndex((item) => item._id === id);

                if (index !== -1) {
                    items[index].files[0] = file;
                    items[index].files[0].updatedKey = new Date().getTime();
                }

                newState.items = items;

                newState.chatUpdateKey = new Date().getTime();

                return newState;
            },
            () => {
                setTimeout(() => {
                    this.setState({
                        chatUpdateParamsKey: new Date().getTime(),
                    });
                }, 10);
            },
        );
    }

    getItems() {
        const query = this.getQueryForRequest();

        query.params.push({ key: 'type', value: 'messages' });

        return new Promise((resolve) => {
            const messages = this.getMessages();
            const lastMessageId = messages[0]?._id;
            let messageTop;

            if (lastMessageId) {
                const message = this.parent.current.querySelector(
                    `.chatV2Window__contentMessage[data-_id="${lastMessageId}"]`,
                );
                const content = this.parent.current.querySelector('.chatV2Window__content');
                messageTop = message.getBoundingClientRect().y - content.getBoundingClientRect().y;
            }

            getChat({ idOfChat: this.getChatId(), ...query }).then(
                ({ messages: items, isLimit }) => {
                    this.setItems(items, false, isLimit).then(() => {
                        if (!this.state.isInit) {
                            this.setState({ isInit: true });
                        } else if (lastMessageId) {
                            this.scrollToMessage({ id: lastMessageId, prevTop: messageTop });
                        }

                        resolve();
                    });
                },
            );
        });
    }

    setJoinCode({ code }) {
        this.setState({ message: `Ваш код подключения: ${code}` }, () => {
            this.focusInput();
        });
    }

    changeHeightWindow() {
        const { isInputFocus } = this.props;

        if (process.env.REACT_APP_SYSTEM !== 'app' || !isInputFocus) {
            this.setContentHeight(true);
        }
    }

    componentDidMount() {
        this.setState({ user: this.props.user });

        this.chatId = this.getChatId();

        this.getChat();

        this.setContentHeight();
        this.handlerPressShift();

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('mousemove', this.handlerMove);
        document.addEventListener('changeHeightWindow', this.changeHeightWindow);
        document.addEventListener('touchmove', this.handlerFootMove);
        document.addEventListener('visibilityDocChange', this.visibilityDocChange);

        this.initVoice();
    }

    componentDidUpdate() {
        this.setContentHeight();
        this.checkChangeChat();
    }

    componentWillUnmount() {
        if (this.timerMoveRead) {
            clearTimeout(this.timerMoveRead);
        }

        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('mousemove', this.handlerMove);
        document.removeEventListener('changeHeightWindow', this.changeHeightWindow);
        document.removeEventListener('touchmove', this.handlerFootMove);
        document.removeEventListener('visibilityDocChange', this.visibilityDocChange);

        this.handlerPressShift(true);
    }

    render() {
        const {
            chat,
            message,
            loadingKey,
            isInit,
            isDisabledScroll,
            isListHide,
            isChatInit = false,
            isChangeChat = false,
            isVoiceProccess,
            updatedKey,
            editedMessageId,
            user,
            isLimit,
            isShowLoaderList,
        } = this.state;
        const { type, typeParent, getParent, checkRights, setChat, heightWindow } = this.props;
        const messages = this.getMessages();
        const isLoading = !chat || !isChatInit;
        const voiceProps =
            process.env.REACT_APP_SYSTEM === 'app'
                ? {
                      onTouchStart: () => {
                          this.startVoice();
                      },
                      onTouchEnd: () => {
                          this.endVoice();
                      },
                  }
                : {
                      onMouseDown: () => this.startVoice(),
                      onMouseLeave: () => this.endVoice(true),
                      onMouseUp: () => this.endVoice(),
                  };
        const condForTags =
            type !== 'fix' && (chat?.type !== 'support' || chat?.entity.id !== user?._id);

        return (
            <div
                ref={this.parent}
                className={`chatV2Window _col _${process.env.REACT_APP_SYSTEM} ${
                    condForTags && process.env.REACT_APP_SYSTEM === 'crm' ? '_withTags' : ''
                } ${isLoading ? '_loading' : ''} ${type ? `_${type}` : ''} ${
                    chat?.type === 'support' && chat?.entity.id === user?._id ? '_support' : ''
                }`}
            >
                {type === 'fix' && typeParent !== 'separate' && (
                    <div className="chatV2Window__head _row">
                        <div
                            className="chatV2Window__back _row _click"
                            onClick={() => setChat(null)}
                        >
                            <i className="chatV2Window__backIcon">
                                <Icon name="arrow-prev" />
                            </i>
                            <p className="chatV2Window__backContent">К списку чатов</p>
                        </div>
                    </div>
                )}
                {process.env.REACT_APP_SYSTEM === 'crm' && (
                    <>
                        <div className="chatV2Window__top">
                            <WindowTop
                                chat={chat}
                                getParent={getParent}
                                setContentHeight={this.setContentHeight}
                                type={type}
                                checkRights={checkRights}
                                checkChangeChat={this.checkChangeChat}
                                setJoinCode={this.setJoinCode}
                            />
                        </div>
                        {condForTags && (
                            <div className="chatV2Window__tags">
                                <WindowTags
                                    isShow={this.getCondForTags()}
                                    chat={chat}
                                    setContentHeight={this.setContentHeight}
                                    isDisabled={this.getCondForDisabledSend()}
                                />
                            </div>
                        )}
                    </>
                )}
                <div
                    className={`chatV2Window__contentWrapper ${
                        isLoading || isChangeChat ? '_hide' : ''
                    }`}
                >
                    <Animate
                        className="chatV2Window__contentLoader _loader"
                        isShow={isLoading || isChangeChat}
                        style={
                            process.env.REACT_APP_SYSTEM === 'app'
                                ? { height: `${heightWindow - 38}px` }
                                : {}
                        }
                    >
                        <div className="chatV2Window__contentLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <div className={`chatV2Window__content`}>
                        <Animate
                            className="chatV2Window__contentScrollLoader _loaderScroll _white"
                            isShow={!!isShowLoaderList}
                        >
                            <div className="chatV2Window__contentScrollLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                        <div
                            className={`chatV2Window__contentInner ${
                                isDisabledScroll ? '_hidden' : ''
                            }`}
                            onScroll={() => {
                                this.readMessages(true);
                            }}
                            key={`${chat?._id}${updatedKey}`}
                        >
                            <ListScroll
                                isInit={isInit}
                                getParent={() =>
                                    this.parent.current?.querySelector(
                                        '.chatV2Window__contentInner',
                                    )
                                }
                                callback={this.getMoreItems}
                                startCounter={this.stepCounter}
                                stepCounter={this.stepCounter}
                                maxCounter={Infinity}
                                lengthCurrent={messages.length}
                                handlerLoaderList={this.handlerLoaderList}
                                isLimit={isLimit}
                                isDisabledScroll={isDisabledScroll || !isInit || isListHide}
                                keyUpdate={messages.length}
                                isReverse={true}
                            >
                                <div className="chatV2Window__contentMessages _col">
                                    {messages.map(this.renderMessage)}
                                </div>
                            </ListScroll>
                        </div>
                    </div>
                </div>

                <div
                    className={`chatV2Window__foot ${
                        this.getCondForDisabledSend() ? '_disabled' : ''
                    }`}
                >
                    {process.env.REACT_APP_SYSTEM === 'app' && (
                        <Animate
                            className="chatV2Window__footSupports"
                            isShow={chat && messages.filter((item) => !item.type).length === 1}
                        >
                            {this.supports.map((support) => (
                                <div
                                    className="chatV2Window__footSupport _row"
                                    key={support}
                                    onClick={() => {
                                        this.sendMessage(support);
                                    }}
                                >
                                    <Animate
                                        className="chatV2Window__footSupportLoader _loader"
                                        isShow={loadingKey === support}
                                    >
                                        <div className="chatV2Window__footSupportLoaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                    {support}
                                </div>
                            ))}
                        </Animate>
                    )}
                    {this.renderFiles()}
                    <div className="chatV2Window__footInner _row">
                        <div
                            className={`chatV2Window__area ${message ? '_active' : ''} ${
                                isVoiceProccess ? '_voices' : ''
                            }`}
                        >
                            <Animate className="chatV2Window__areaVoice" isShow={isVoiceProccess}>
                                <VoiceBar isActive={isVoiceProccess} endVoice={this.endVoice} />
                            </Animate>
                            <textarea
                                className="chatV2Window__areaInput"
                                placeholder="Напишите что-нибудь…"
                                onChange={this.handlerMessage}
                                value={message}
                                readOnly={this.getCondForDisabledSend() || loadingKey === 'send'}
                                onFocus={() => {
                                    dispatcher({ type: 'isInputFocus', data: true });
                                }}
                                onBlur={() => {
                                    dispatcher({ type: 'isInputFocus', data: false });
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                            {process.env.REACT_APP_SYSTEM !== 'app' && (
                                <Animate
                                    className="chatV2Window__areaEmodzi _click"
                                    onClick={({ target }) => this.showEmodziList({ target })}
                                    isShow={!this.getCondForDisabledSend()}
                                >
                                    <Icon name="emodzi" />
                                </Animate>
                            )}
                        </div>
                        <div className="chatV2Window__footActions _row">
                            {process.env.REACT_APP_SYSTEM === 'crm' && type !== 'fix' && (
                                <div
                                    className={`chatV2Window__footAction _col _templates ${
                                        editedMessageId ? '_disabled' : ''
                                    }`}
                                    onClick={() => {
                                        if (!editedMessageId) {
                                            this.showTemplates();
                                        }
                                    }}
                                >
                                    <div className="chatV2Window__footActionInner _col _click">
                                        <i className="chatV2Window__footActionIcon">
                                            <Icon name="chat-templates" />
                                        </i>
                                    </div>
                                </div>
                            )}
                            <div
                                className={`chatV2Window__footAction _col _voice ${
                                    isVoiceProccess ? '_active' : ''
                                }`}
                                {...voiceProps}
                            >
                                <Animate
                                    className="chatV2Window__footAction _col _send"
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        this.sendMessage();
                                    }}
                                    isShow={!!message || this.getFiles().length}
                                >
                                    <div className="chatV2Window__footActionInner _col _click">
                                        <Animate
                                            className="chatV2Window__footActionLoader _loader"
                                            isShow={loadingKey === 'send'}
                                        >
                                            <i className="chatV2Window__footActionLoaderItem _loaderItem">
                                                <Loader />
                                            </i>
                                        </Animate>
                                        <i className="chatV2Window__footActionIcon">
                                            <Icon name="chat-send" />
                                        </i>
                                    </div>
                                </Animate>
                                {(process.env.REACT_APP_SYSTEM === 'app'
                                    ? [1, 2, 3, 4]
                                    : [1, 2, 3]
                                ).map((item) => (
                                    <div
                                        className={`chatV2Window__footActionBack _${item}`}
                                        key={item}
                                    ></div>
                                ))}
                                <div className="chatV2Window__footActionInner _col _click">
                                    <Animate
                                        className="chatV2Window__footActionLoader _loader"
                                        isShow={loadingKey === 'send'}
                                    >
                                        <i className="chatV2Window__footActionLoaderItem _loaderItem">
                                            <Loader />
                                        </i>
                                    </Animate>
                                    <i className="chatV2Window__footActionIcon">
                                        <Icon name="chat-voice" />
                                    </i>
                                </div>
                            </div>

                            <label
                                className={`chatV2Window__footAction _col ${
                                    editedMessageId ? '_disabled' : ''
                                }`}
                            >
                                <div className="chatV2Window__footActionInner _col _click">
                                    <input
                                        type="file"
                                        className="chatV2Window__footActionInput"
                                        style={{ display: 'none' }}
                                        multiple
                                        accept="image/*,application/*"
                                        onChange={this.uploadFiles}
                                        disabled={!!editedMessageId}
                                    />
                                    <i className="chatV2Window__footActionIcon">
                                        <Icon name="chat-attach" />
                                    </i>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        windowIsReady: state.windowIsReady,
        levels: state.levels,
        isInputFocus: state.isInputFocus,
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(Inner);

Inner.propTypes = {
    user: PropTypes.object,
    windowIsReady: PropTypes.bool,
    levels: PropTypes.array,
    isSetHeight: PropTypes.bool,
    type: PropTypes.string,
    getParent: PropTypes.func,
    checkRights: PropTypes.func,
    currentChat: PropTypes.string,
    typeParent: PropTypes.string,
    setChat: PropTypes.func,
    handlerLoader: PropTypes.func,
    isInputFocus: PropTypes.bool,
    setOrderId: PropTypes.func,
    heightWindow: PropTypes.number,
};
