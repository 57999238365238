import React from 'react';
import PropTypes from 'prop-types';
import removeTransition from '../../../functions/removeTransition.ts';

class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerStart = this.handlerStart.bind(this);
        this.handlerMove = this.handlerMove.bind(this);
        this.handlerEnd = this.handlerEnd.bind(this);

        this.parent = React.createRef();
    }

    startY = 0;
    moveY = 0;
    lastY = 0;
    isProccess = false;
    deltaY = null;

    getInner() {
        return this.parent.current.querySelector('.appPopup__inner');
    }

    getScroll() {
        const inner = this.getInner();
        let innerScroll = inner.querySelector('.appPopup__innerBox');

        if (this.name === 'listPopup') {
            innerScroll = inner.querySelector('.listPopup__contentInner');
        }

        if (innerScroll) {
            return innerScroll.scrollTop;
        }

        return 0;
    }

    getDownBreak() {
        const inner = this.getInner();

        if (this.name === 'order') {
            const head = this.parent.current.querySelector('.appOrder__moveHead');

            if (head) {
                return inner.offsetHeight - head.offsetHeight;
            }
        }

        if (this.name === 'details') {
            const head = this.parent.current.querySelector('.appOrderShortDetails__head');

            if (head) {
                return inner.offsetHeight - head.offsetHeight;
            }
        }

        return inner.offsetHeight + 12;
    }

    hide() {
        const inner = this.getInner();

        inner.style.transform = null;
    }

    disabledDocument(isDisabled) {
        const body = document.querySelector('body');

        body.classList[isDisabled ? 'add' : 'remove']('_isDrag');
    }

    move(isTrust = true) {
        const inner = this.getInner();
        const downBreak = this.getDownBreak();

        if (this.moveY > 0) {
            this.moveY = 0;
        }

        if (-this.moveY > downBreak) {
            this.moveY = -downBreak;
        }

        if (!isTrust && this.moveY === 0 && this.state.moveDirection !== 'start') {
            this.setState({ moveDirection: 'start' });
        }

        document.dispatchEvent(
            new CustomEvent('popup', {
                detail: { action: 'move', name: this.name, move: this.moveY },
            }),
        );

        inner.style.transform = `translate(0,${-this.moveY}px)`;
    }

    handlerStart(e) {
        const { isInit } = this.state;

        if (!this.isProccess && this.getScroll() < 3 && (isInit === true || isInit === undefined)) {
            this.isProccess = true;

            this.startY = e.changedTouches[0].pageY;

            this.disabledDocument(true);
        }
    }

    handlerMove(e) {
        const inner = this.getInner();

        if (this.isProccess) {
            this.moveY = this.startY - e.changedTouches[0].pageY + this.lastY;

            if (Math.abs(this.moveY) > 3 && this.deltaY === null) {
                this.deltaY = this.moveY < 0 ? -1 : 1;
            }

            if (this.getScroll() < 3 && this.deltaY === -1) {
                e.preventDefault();

                inner.classList.add('_isDrag');

                this.move();
            }
        }
    }

    handlerEnd(isForce) {
        const inner = this.getInner();

        if (this.isProccess || isForce === true) {
            this.isProccess = false;
            let moveDirection = this.state.moveDirection || 'start';

            inner.classList.remove('_isDrag');

            if (this.deltaY === -1 || isForce === true) {
                const downBreak = this.getDownBreak();

                if (-this.moveY < downBreak / 3) {
                    this.moveY = 0;

                    moveDirection = 'start';

                    setTimeout(() => {
                        inner.style.transform = null;
                    }, 300);
                } else {
                    this.moveY = -downBreak;

                    moveDirection = 'end';

                    if (this.hide) {
                        this.hide();
                    }

                    // setTimeout(() => {
                    //     inner.style.transform = null;
                    //     this.moveY = 0;
                    //     this.lastY = 0;
                    // }, 300);
                }

                this.move();

                this.lastY = this.moveY;
            } else {
                this.moveY = 0;
            }

            document.dispatchEvent(
                new CustomEvent('popup', {
                    detail: { action: 'end', name: this.name, move: this.moveY },
                }),
            );

            this.setState({ moveDirection });

            this.startY = 0;
            this.moveY = 0;
            this.deltaY = null;

            this.disabledDocument(false);
        }
    }

    getMaxHeight() {
        const { heightWindow } = this.props;

        if (this.isFull) {
            return heightWindow;
        }

        return +(heightWindow - 48).toFixed(0);
    }

    setMaxHeight() {
        const { heightWindow } = this.props;
        const inner = this.getInner();

        if (heightWindow && inner) {
            const innerScroll = inner.querySelector('.appPopup__innerBox');

            innerScroll.style.maxHeight = `${this.getMaxHeight()}px`;
        }
    }

    handlerEvents(isAdd) {
        const inner = this.getInner();

        if (inner) {
            if (isAdd) {
                if (!this.checkHideDisabled || !this.checkHideDisabled()) {
                    inner.addEventListener('touchstart', this.handlerStart, { passive: false });
                    document.addEventListener('touchmove', this.handlerMove, { passive: false });
                    document.addEventListener('touchend', this.handlerEnd);
                }
            } else {
                inner.removeEventListener('touchstart', this.handlerStart);
                document.removeEventListener('touchmove', this.handlerMove);
                document.removeEventListener('touchend', this.handlerEnd);
            }
        }
    }

    checkChangeDisabled() {
        if (this.checkHideDisabled && this.checkHideDisabled() !== this.isDisabled) {
            this.isDisabled = this.checkHideDisabled();

            this.handlerEvents(!this.checkHideDisabled());
        }
    }

    checkShow() {
        const { isShow } = this.props;

        if (isShow) {
            this.moveY = 0;
            this.lastY = 0;

            this.move();
        }
    }

    componentDidMount() {
        if (this.checkHideDisabled) {
            this.isDisabled = this.checkHideDisabled();
        }

        this.handlerEvents(true);

        this.setMaxHeight();
        this.checkChangeDisabled();

        if (this.isDefaultHide) {
            removeTransition({ item: '.appPopup._details' });

            this.moveY = -this.getDownBreak();
            this.lastY = -this.getDownBreak();

            this.move();

            this.setState({ moveDirection: this.isDefaultHide ? 'end' : '' });
        }
    }

    componentDidUpdate() {
        this.setMaxHeight();
        this.checkChangeDisabled();
        this.checkShow();
    }

    componentWillUnmount() {
        this.handlerEvents(false);
    }
}

export default Popup;

Popup.propTypes = {
    heightWindow: PropTypes.number,
    isShow: PropTypes.bool,
};
