import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getChat from '../../../requests/getChat';
import getStatus from '../../../functions/chat/getStatus';

import Table from '../../../components/crm/manual/Table.jsx';
import ListScroll from '../../../components/ListScroll.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';
import getEndText from '../../../functions/getEndText';
import getFormatedDate from '../../../functions/getFormatedDate';
import CounterNotRead from '../../../components/chat/CounterNotRead.jsx';
import Link from '../../../components/Link.jsx';
import removeTransition from '../../../functions/removeTransition.ts';
import Loader from '../../../components/Loader.jsx';
import Animate from '../../../components/Animate.jsx';
import getCurrentCorporation from '../../../functions/app/getCurrentCorporation';
import Avatar from '../../../components/Avatar.jsx';

class Index extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.getItems = this.getItems.bind(this);
        this.renderCard = this.renderCard.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.sortCards = this.sortCards.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.updatePreview = this.updatePreview.bind(this);
        this.updatePreviews = this.updatePreviews.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 6;

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getChat(query).then(
                ({ chats, isLimit }) => {
                    if (!this.state.isInit) {
                        setTimeout(() => {
                            this.setState({ isInit: true });
                        }, 300);
                    }

                    this.setItems(chats, false, isLimit).then(() => {
                        removeTransition({ item: '.appChat__card', isCurrent: true });

                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    sortCards(previews) {
        const previewsSort = JSON.parse(JSON.stringify(previews));

        const getDate = (chat) => {
            const { lastMessage } = chat;

            return lastMessage ? lastMessage.date : chat.date;
        };

        previewsSort.sort((a, b) => new Date(getDate(b)) - new Date(getDate(a)));

        const weightsStatuses = {
            important: 3,
            proccess: 2,
            complete: 1,
        };

        previewsSort.sort((a, b) => {
            const weightA = weightsStatuses[getStatus(a)];
            const weightB = weightsStatuses[getStatus(b)];

            return weightB - weightA;
        });

        previewsSort.sort((a, b) => {
            const weightA = a.type === 'support' ? 1 : 0;
            const weightB = b.type === 'support' ? 1 : 0;

            return weightB - weightA;
        });

        return previewsSort;
    }

    getCards() {
        const { items = [] } = this.state;

        return this.sortCards(items);
    }

    renderCard({ item, prop: _id }) {
        const { user, theme } = this.props;
        const currentCorporation = getCurrentCorporation({ user });
        const { type, lastMessage, isClose, anyInfo, corporation } = item;
        let message = '–';
        let previewType =
            type === 'order' ? `Обращение по заказу №${anyInfo.numberOfOrder}` : `Обращение`;
        const date = lastMessage?.date || item.date;
        const dateStr = getFormatedDate({ date: new Date(date), type: 'time' });
        let title = !currentCorporation
            ? 'Техническая поддержка'
            : `Поддержка ${currentCorporation.name}`;

        if (type === 'support') {
            title = 'CRM LIVECARGO';
            previewType = 'Поддержка';
            message = 'Начать чат';
        }

        if (lastMessage) {
            if (user._id === lastMessage.creater) {
                message = 'Вы: ';
            } else {
                message = '';
            }

            message +=
                lastMessage.content ||
                `${lastMessage.files.length} ${getEndText(lastMessage.files.length, [
                    'файл',
                    'файла',
                    'файлов',
                ])}`;
        }

        return (
            <Link className="appChat__card" pageName="chat-inner" ids={{ 1: _id }}>
                <div
                    className={`appChat__preview _row ${isClose ? '_close' : ''} ${
                        item.type === 'support' ? '_support' : ''
                    }`}
                >
                    <div className="appChat__previewAvatar">
                        <Avatar
                            src={
                                type === 'support'
                                    ? require(`../../../img/crm/logo-center${
                                          theme === 'dark' ? '-white' : ''
                                      }.svg`)
                                    : corporation.logo?.path &&
                                      `${process.env.REACT_APP_STATIC}/corporations/${corporation.logo.path}`
                            }
                            holder={{
                                text: corporation?.name?.[0]?.toUpperCase(),
                            }}
                            withText={true}
                            iconType={
                                type === 'support' ? 'image/svg+xml' : corporation?.logo?.type
                            }
                        />
                    </div>
                    <div className="appChat__previewContent">
                        <div className="appChat__previewTitle">{title}</div>
                        <div className="appChat__previewMessage">{message}</div>
                        <div className="appChat__previewType">{previewType}</div>
                        <div className="appChat__previewCounter">
                            <CounterNotRead id={_id} />
                        </div>
                        <div className="appChat__previewDate">{dateStr}</div>
                    </div>
                </div>
            </Link>
        );
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    updatePreview({ id, props }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const items = JSON.parse(JSON.stringify(newState.items));
                    const itemIndex = items.findIndex((preview) => preview._id === id);

                    if (itemIndex !== -1) {
                        Object.keys(props).forEach((key) => {
                            items[itemIndex][key] = props[key];
                        });
                    }

                    newState.items = items;

                    return newState;
                },
                () => {
                    resolve();
                },
            );
        });
    }

    updatePreviews({ preview }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const items = [...newState.items];

                    items.push({ ...preview, isNew: true });

                    newState.items = items;

                    return newState;
                },
                () => {
                    resolve();
                },
            );
        });
    }

    handlerSocket({ detail }) {
        const { items } = this.state;
        const { name, data } = detail;

        if (name === 'chat') {
            const { fields, type, idOfChat } = data;

            if (items?.find((preview) => preview._id === idOfChat)) {
                if (type === 'newMessage') {
                    const { newMessages } = fields;

                    const newMessage = newMessages[newMessages.length - 1];

                    this.updatePreview({
                        id: idOfChat,
                        props: {
                            lastMessage: newMessage,
                        },
                    });
                }
                if (type === 'changeInfo') {
                    this.updatePreview({
                        id: idOfChat,
                        props: fields,
                    });
                }

                this.setState({ updatePreviewKey: new Date().getTime() });
            }

            if (type === 'newChat') {
                const { preview } = data;

                this.updatePreviews({ preview });
            }
        }
    }

    componentDidMount() {
        this.getItems();

        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
    }

    render() {
        const { isInit, isLimit, isDisabledScroll, isShowLoaderList, updatePreviewKey } =
            this.state;
        const items = this.getCards();

        return (
            <div ref={this.parent} className={`appChat ${isInit ? '_init' : ''}`}>
                <Animate className="appChat__loader _loader" isShow={!isInit}>
                    <div className="appChat__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate className="appChat__scrollLoader _loaderScroll" isShow={isShowLoaderList}>
                    <div className="appChat__scrollLoaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate className="appChat__empty" isShow={isInit && items.length === 0}>
                    <div className="empty _col _notBack _block">
                        <div className="empty__inner">
                            <div className="empty__title">У вас пока нет чатов</div>
                            <p className="empty__content">
                                Создайте обращение
                                <br />
                                по кнопке «+ Чат»
                            </p>
                        </div>
                    </div>
                </Animate>
                <div className="appChat__list">
                    {isInit && (
                        <ListScroll
                            isInit={isInit}
                            getParent={() => this.parent.current?.querySelector('.appChat__list')}
                            callback={this.getMoreItems}
                            startCounter={this.stepCounter}
                            stepCounter={this.stepCounter}
                            maxCounter={Infinity}
                            lengthCurrent={items.length}
                            handlerLoaderList={this.handlerLoaderList}
                            isLimit={isLimit}
                            isDisabledScroll={isDisabledScroll || !isInit}
                        >
                            <ListAbsoluteMain
                                className="appChat__listInner _col"
                                items={items}
                                renderItem={this.renderCard}
                                classNameItem="appChat__card"
                                prop="_id"
                                paramsParent={{ width: true }}
                                styles={['height']}
                                sort={this.sortCards}
                                propsForUpdate={['isClose', 'type', 'counterNotRead']}
                                keyUpdateItem={updatePreviewKey}
                            />
                        </ListScroll>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        windowIsReady: state.windowIsReady,
        theme: state.theme,
    };
}

export default connect(mapStateToProps)(Index);

Index.propTypes = {
    user: PropTypes.object,
    windowIsReady: PropTypes.bool,
    theme: PropTypes.string,
};
