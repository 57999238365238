import getCurrentCorporation from './getCurrentCorporation';

const mainRoles = ['holder'];

export default function getPermissions(
    user,
    { notAdminCorporation, adminCorporation, forHolder, key, items = [] },
) {
    if (!user) {
        return false;
    }

    const corporation = getCurrentCorporation({ user });

    if (!corporation) {
        return false;
    }

    const { role, roleSections = [] } = corporation;

    if (notAdminCorporation && corporation.isAdmin) {
        return false;
    }

    if (adminCorporation && !corporation.isAdmin) {
        return false;
    }

    if (forHolder && role !== 'holder') {
        return false;
    }

    if (mainRoles.includes(role)) {
        return true;
    }

    const section = roleSections.find((item) => item.key === key);

    if (!section || !section.isActive) {
        return false;
    }

    let denied = false;

    items.forEach((item) => {
        const roleItem = section.items.find((innerItem) => innerItem.key === item.key);

        if (!roleItem) {
            denied = true;
        } else if (
            item.rules?.find((rule) => !roleItem.rules.includes(rule)) ||
            item.actions?.find((action) => !roleItem.actions.includes(action))
        ) {
            denied = true;
        }
    });

    if (denied) {
        return false;
    }

    return true;
}
