import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import ListAbsoluteMain from '../../components/ListAbsoluteMain.jsx';

import { inputValidate } from '../../functions/inputValidate';
import { dispatcher } from '../../redux/redux';
import { setCookie } from '../../functions/handlerCookies';

import getHeaders from '../../functions/getHeaders';
import changePage from '../../functions/changePage';

import Phone from '../../components/app/auth/Phone.jsx';
import Code from '../../components/app/auth/Code.jsx';
import Back from '../../components/app/Back.jsx';
import Pagination from '../../components/app/Pagination.jsx';
import checkAuth from '../../functions/app/checkAuth';
import getPageLink from '../../functions/getPageLink';

class Auth extends React.Component {
    constructor(props) {
        super(props);

        let currentBlock = 'phone';

        if (localStorage.getItem('appAuthPhone')) {
            currentBlock = 'code';
        }

        this.state = {
            currentBlock,
        };

        this.renderBlock = this.renderBlock.bind(this);
        this.checkPhone = this.checkPhone.bind(this);
        this.handlerErrors = this.handlerErrors.bind(this);
        this.handlerField = this.handlerField.bind(this);
        this.handlerBlock = this.handlerBlock.bind(this);
        this.login = this.login.bind(this);
    }

    initFields() {
        this.setState((state) => {
            const newState = { ...state };
            const fields = {};

            ['phone', 'code'].forEach((name) => {
                fields[name] = {
                    value: '',
                };
            });

            if (localStorage.getItem('appAuthPhone')) {
                fields.phone.value = localStorage.getItem('appAuthPhone');
            }

            newState.fields = fields;

            return newState;
        });
    }

    handlerBlock(currentBlock) {
        return new Promise((resolve) => {
            this.setState({ currentBlock }, () => {
                if (currentBlock === 'phone') {
                    localStorage.removeItem('appAuthPhone');

                    this.handlerField({ action: 'change', name: 'phone', value: '' });
                }

                resolve();
            });
        });
    }

    handlerField({ action, name, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState((state) => {
                    const newState = { ...state };
                    const fields = JSON.parse(JSON.stringify(newState.fields));

                    fields[name].value = value;
                    fields[name].error = null;

                    newState.fields = fields;

                    return newState;
                }, resolve);
            }
        });
    }

    getBlocks() {
        const { currentBlock } = this.state;

        return [{ name: currentBlock }];
    }

    blocksOrder = ['phone', 'code'];

    blocks = {
        phone: {
            render() {
                const { fields, loadingKey } = this.state;

                return (
                    <Phone
                        loadingKey={loadingKey}
                        phone={fields?.phone}
                        checkPhone={this.checkPhone}
                        handlerField={this.handlerField}
                    />
                );
            },
        },
        code: {
            render() {
                const { fields, loadingKey } = this.state;

                return (
                    <Code
                        loadingKey={loadingKey}
                        phone={fields?.phone}
                        code={fields?.code}
                        handlerField={this.handlerField}
                        login={this.login}
                        handlerBlock={this.handlerBlock}
                        checkPhone={this.checkPhone}
                    />
                );
            },
        },
    };

    renderBlock({ prop: name }) {
        const block = this.blocks[name];

        return <div className="auth__block _col">{block?.render.call(this)}</div>;
    }

    handlerErrors({ action, errors }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const fields = JSON.parse(JSON.stringify(newState.fields));

                if (!action) {
                    errors.forEach(({ name, error }) => {
                        fields[name].error = error;
                    });
                } else if (action === 'clear') {
                    (errors || Object.keys(fields)).forEach((name) => {
                        fields[name].error = null;
                    });
                }

                newState.fields = fields;

                return newState;
            }, resolve);
        });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    login() {
        const { fields } = this.state;
        const phone = fields?.phone?.value;
        const code = fields?.code?.value;

        return new Promise((resolve) => {
            if (code.length !== 5) {
                this.handlerErrors({
                    errors: [{ name: 'code', error: 'Код должен содержать 5 символов' }],
                });

                resolve();
            } else {
                this.handlerLoading('code').then(() => {
                    axios
                        .post(
                            `${process.env.REACT_APP_API}/v2/login-app`,
                            { phone, code },
                            { headers: getHeaders() },
                        )
                        .then(
                            (res) => {
                                const { success, data } = res.data;

                                if (success) {
                                    const { hash, id } = data;

                                    dispatcher({ type: 'isProccessLogin', data: true }).then(() => {
                                        setCookie(process.env.REACT_APP_HASH, hash);

                                        localStorage.removeItem('appAuthPhone');
                                        localStorage.setItem('afterReg', true);

                                        checkAuth(undefined, { id }).then(
                                            ({ user }) => {
                                                changePage({
                                                    href: getPageLink({
                                                        name:
                                                            user.corporations.filter(
                                                                (item) => item.id !== 'admin',
                                                            ).length > 1
                                                                ? 'corporations-list'
                                                                : 'index',
                                                    }),
                                                }).then(() => {
                                                    dispatcher({
                                                        type: 'isProccessLogin',
                                                        data: false,
                                                    });
                                                });

                                                this.handlerLoading(null).then(resolve);
                                            },
                                            () => {
                                                dispatcher({
                                                    type: 'isProccessLogin',
                                                    data: false,
                                                });

                                                this.handlerLoading(null).then(resolve);
                                            },
                                        );
                                    });
                                } else {
                                    const { message } = data;

                                    if (message === 'Code is incorrect') {
                                        this.handlerErrors({
                                            errors: [
                                                {
                                                    name: 'code',
                                                    error: 'Код подтверждения неверный',
                                                },
                                            ],
                                        });
                                    }

                                    this.handlerLoading(null).then(resolve);
                                }
                            },
                            () => null,
                        );
                });
            }
        });
    }

    checkPhone() {
        const { fields } = this.state;
        const phone = fields?.phone?.value;

        return new Promise((resolve) => {
            if (!inputValidate({ name: 'phone', value: phone })) {
                this.handlerErrors({
                    errors: [{ name: 'phone', error: 'Телефон заполнен неверно' }],
                });
            } else {
                this.handlerLoading('phone').then(() => {
                    axios
                        .post(
                            `${process.env.REACT_APP_API}/v2/login-app`,
                            { phone },
                            { headers: getHeaders() },
                        )
                        .then(
                            (res) => {
                                this.handlerLoading(null).then(resolve);

                                const { success, data } = res.data;

                                if (success) {
                                    this.handlerBlock('code');

                                    localStorage.setItem('appAuthPhone', phone);
                                } else {
                                    const { message } = data;

                                    if (message === 'Executor not found') {
                                        this.handlerErrors({
                                            errors: [
                                                { name: 'phone', error: 'Пользователь не найден' },
                                            ],
                                        });
                                    }
                                }
                            },
                            () => null,
                        );
                });
            }
        });
    }

    componentDidMount() {
        this.initFields();
    }

    render() {
        const { currentBlock } = this.state;
        const { heightWindow } = this.props;

        return (
            <div className="auth">
                <div className="auth__top _row">
                    <div className="auth__topBack">
                        <Back
                            handler={() => {
                                this.handlerBlock('phone');
                            }}
                            isShow={currentBlock === 'code'}
                        />
                    </div>

                    <div className="auth__topPagination">
                        <Pagination items={this.blocksOrder} currentName={currentBlock} />
                    </div>
                </div>
                <div className="auth__inner _row">
                    <ListAbsoluteMain
                        className="JSTransVertical auth__blocks _col"
                        items={this.getBlocks()}
                        renderItem={this.renderBlock}
                        classNameItem="auth__block"
                        prop="name"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isNotParamsItem={true}
                        minHeight={heightWindow - 40}
                        currentItemKey={currentBlock}
                        allItems={this.blocksOrder}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(Auth);

Auth.propTypes = {
    user: PropTypes.object,
    heightWindow: PropTypes.number,
};
