import checkValueOfEmpty from '../functions/checkValueOfEmpty';

export default class Drag {
    constructor({
        id,
        name,
        key,
        card,
        classCard,
        classParent,
        dragBtn,
        callback,
        offset,
        getNewOrder = () => new Promise((resolve) => resolve()),
        indexCurrentItem,
        callbackMove = () => null,
        getCondForMove = () => true,
    }) {
        this.id = id;
        this.name = name;
        this.key = key;
        this.card = card;
        this.classCard = classCard;
        this.classParent = classParent;
        this.dragBtn = dragBtn;
        this.callback = callback;
        this.getNewOrder = getNewOrder;
        this.offset = offset || 0;
        this.indexCurrentItem = indexCurrentItem;
        this.callbackMove = callbackMove;
        this.getCondForMove = getCondForMove;

        this.startVal = 0;
        this.moveVal = 0;
        this.endVal = 0;

        this.newKey = this.key;

        this.condForMove = false;

        this.init = this.init.bind(this);
        this.moveFromOut = this.moveFromOut.bind(this);
    }

    getHeightCards() {
        const heights = [];

        document.querySelectorAll(this.classCard).forEach((card, key) => {
            heights[key] = {
                height: card.offsetHeight,
            };
        });

        return heights;
    }

    getTopCard(key) {
        const heights = this.getHeightCards();
        let top;

        if (key > this.key) {
            top =
                heights
                .filter((item, keyItem) => keyItem > this.key && keyItem < key)
                .reduce((prev, cur) => cur.height + this.offset + prev, 0) + this.offset;
        }

        if (key < this.key) {
            top =
                heights
                .filter((item, keyItem) => keyItem < this.key && keyItem > key)
                .reduce((prev, cur) => -(cur.height + this.offset) + prev, 0) - this.offset;
        }

        return top;
    }

    getHeightOfBox() {
        return (
            this.getHeightCards().reduce((prev, cur) => prev + cur.height + this.offset, 0) -
            this.offset
        );
    }

    getStopLine() {
        const heights = this.getHeightCards();

        const topLine = heights
            .filter(
                (item, key) =>
                key < this.key &&
                ((checkValueOfEmpty(this.indexCurrentItem) && key > this.indexCurrentItem) ||
                    !checkValueOfEmpty(this.indexCurrentItem)),
            )
            .reduce((prev, cur) => cur.height + this.offset + prev, 0);

        const bottomLine = heights
            .filter((item, key) => key > this.key)
            .reduce((prev, cur) => cur.height + this.offset + prev, 0);

        return { topLine, bottomLine };
    }

    blokCard(isBlok = true) {
        if (isBlok === true) {
            this.card.setAttribute('data-blok', '');
            this.card.removeAttribute('data-trans');
            document.querySelector(this.classParent).setAttribute('data-blok', '');
        } else {
            this.card.removeAttribute('data-blok');
            document.querySelector(this.classParent).removeAttribute('data-blok');
        }
    }

    moveCard({ val, isStatic = false }) {
        if (isStatic === true) {
            this.blokCard();
            this.card.setAttribute('data-notTrans', true);
        } else {
            this.card.removeAttribute('data-notTrans');
        }

        this.card.style.transform = `translate3d(0,${-val}px,0)`;
    }

    getEvent(e) {
        return e.changedTouches ? e.changedTouches[0] : e;
    }

    start(e) {
        if (this.getCondForMove() && this.condForMove === false) {
            this.condForMove = true;

            e.stopPropagation();

            this.startVal = this.getEvent(e).pageY;

            this.callbackMove(true);
        }
    }

    condForScroll = true;

    move(e) {
        if (this.condForMove === true) {
            const heights = this.getHeightCards();

            e.preventDefault();
            e.stopPropagation();

            const currentHeight = heights[this.key].height + this.offset;
            const { topLine, bottomLine } = this.getStopLine();
            let valueFromOut = 0;

            if (this.startVal - this.getEvent(e).pageY + this.endVal > topLine) {
                this.moveVal = topLine;
            } else if (this.startVal - this.getEvent(e).pageY + this.endVal < -bottomLine) {
                this.moveVal = -bottomLine;
            } else {
                this.condForScroll = true;
                if (this.condForScroll) {
                    if (
                        this.card.clientHeight + this.card.getBoundingClientRect().y >
                        document.documentElement.clientHeight - 100
                    ) {
                        this.condForScroll = false;
                    }
                    if (this.card.getBoundingClientRect().y < 200) {
                        this.condForScroll = false;
                    }
                }

                this.moveVal = this.startVal - this.getEvent(e).pageY + this.endVal;
            }

            document.querySelectorAll(this.classCard).forEach((card, key) => {
                const top = this.getTopCard(key);

                const cardIsChange = card.hasAttribute('data-change');

                if (key !== this.key) {
                    if (key > this.key && top >= 0) {
                        const value =
                            this.moveVal < -(top + heights[key].height / 2) ? currentHeight : 0;

                        valueFromOut = this.moveVal < -(top + heights[key].height / 2) ? 1 : 0;

                        if (value !== 0 && cardIsChange === false) {
                            this.newKey = key;
                            card.setAttribute('data-change', '');

                            document.dispatchEvent(
                                new CustomEvent('moveDrag', {
                                    detail: {
                                        name: this.name,
                                        key,
                                        value: valueFromOut,
                                        currentKey: this.key,
                                    },
                                }),
                            );
                        }

                        if (value === 0 && cardIsChange === true) {
                            this.newKey = key - 1;
                            card.removeAttribute('data-change');

                            document.dispatchEvent(
                                new CustomEvent('moveDrag', {
                                    detail: {
                                        name: this.name,
                                        key,
                                        value: valueFromOut,
                                        currentKey: this.key,
                                    },
                                }),
                            );
                        }
                    }
                    if (key < this.key > 0 && top <= 0) {
                        const value =
                            this.moveVal > -(top - heights[key].height / 2) ? -currentHeight : 0;
                        valueFromOut = this.moveVal > -(top - heights[key].height / 2) ? -1 : 0;

                        if (value !== 0 && cardIsChange === false) {
                            this.newKey = key;
                            card.setAttribute('data-change', '');
                            document.dispatchEvent(
                                new CustomEvent('moveDrag', {
                                    detail: {
                                        name: this.name,
                                        key,
                                        value: valueFromOut,
                                        currentKey: this.key,
                                    },
                                }),
                            );
                        }

                        if (value === 0 && cardIsChange === true) {
                            this.newKey = key + 1;
                            card.removeAttribute('data-change');
                            document.dispatchEvent(
                                new CustomEvent('moveDrag', {
                                    detail: {
                                        name: this.name,
                                        key,
                                        value: valueFromOut,
                                        currentKey: this.key,
                                    },
                                }),
                            );
                        }
                    }
                }
            });

            this.moveCard({ val: this.moveVal, isStatic: true });
        }
    }

    end(e) {
        if (this.condForMove === true) {
            this.condForMove = false;

            e.stopPropagation();

            // this.moveCard({ val: topNew, fromOut: true });
            document.dispatchEvent(
                new CustomEvent('moveDrag', {
                    detail: {
                        name: this.name,
                        key: this.key,
                        currentKey: this.key,
                        isCurrent: true,
                        isEnd: true,
                        newKey: this.newKey,
                    },
                }),
            );

            this.endVal = this.moveVal;
            this.moveVal = 0;

            const newOrder = [];

            document.querySelectorAll(this.classCard).forEach((card, key) => {
                newOrder[key] = key;
            });

            newOrder[this.key] = this.newKey;

            document.querySelectorAll(this.classCard).forEach((card, key) => {
                if (this.key !== key) {
                    if (this.newKey > this.key && this.key < key) {
                        newOrder[key] = key <= this.newKey ? key - 1 : key;
                    }
                    if (this.newKey < this.key) {
                        newOrder[key] = key >= this.newKey && key < this.key ? key + 1 : key;
                    }
                }
            });

            this.getNewOrder({ newOrder }).then(
                () => {
                    setTimeout(() => {
                        this.callback({ newOrder });
                        this.blokCard(false);
                    }, 300);
                },
                () => null,
            );

            this.callbackMove(false);
        }
    }

    moveFromOut(e) {
        const { detail } = e;
        const {
            name,
            key,
            currentKey,
            isStatic = false,
            isCurrent,
            heightOfBox,
            isEnd = false,
            newKey,
        } = detail;
        let { value } = detail;
        const heights = this.getHeightCards();
        const height = ((heights[currentKey] && heights[currentKey].height) || 0) + this.offset;
        const { topLine, bottomLine } = this.getStopLine();
        const heightOfBoxThis = this.getHeightOfBox();
        const currentHeight = (heights[currentKey] && heights[currentKey].height) || 0;
        const delta = (heightOfBoxThis - currentHeight) / heightOfBox;

        if (name === this.name && key === this.key) {
            if (!isEnd) {
                if (isStatic) {
                    value *= delta;
                }
                if (isStatic || isCurrent) {
                    if (value >= topLine) {
                        value = topLine;
                    }
                    if (value <= -bottomLine) {
                        value = -bottomLine;
                    }
                }

                this.moveCard({
                    val: isStatic || isCurrent ? value : value * height,
                    isStatic,
                });
            } else {
                const topWas = heights
                    .filter((item, keyItem) =>
                        newKey > this.key ? keyItem <= this.key : keyItem < this.key,
                    )
                    .reduce((prev, cur) => cur.height + this.offset + prev, 0);
                const topNew =
                    topWas -
                    heights
                    .filter((item, keyItem) =>
                        newKey > this.key ? keyItem <= newKey : keyItem < newKey,
                    )
                    .reduce((prev, cur) => cur.height + this.offset + prev, 0);

                this.moveCard({
                    val: topNew,
                    isStatic,
                });
            }
        }
    }

    startBind = this.start.bind(this);

    moveBind = this.move.bind(this);

    endBind = this.end.bind(this);

    init() {
        if (this.dragBtn) {
            this.dragBtn.addEventListener('mousedown', this.startBind);
            this.dragBtn.addEventListener('touchstart', this.startBind);
        }

        document.addEventListener('mousemove', this.moveBind, { passive: false });
        document.addEventListener('touchmove', this.moveBind, { passive: false });

        document.addEventListener('mouseup', this.endBind);
        document.addEventListener('touchend', this.endBind);

        document.addEventListener('moveDrag', this.moveFromOut);
    }

    remove() {
        if (this.dragBtn) {
            this.dragBtn.removeEventListener('mousedown', this.startBind);
            this.dragBtn.removeEventListener('touchstart', this.startBind);
        }

        document.removeEventListener('mousemove', this.moveBind);
        document.removeEventListener('touchmove', this.moveBind);

        document.removeEventListener('mouseup', this.endBind);
        document.removeEventListener('touchend', this.endBind);

        document.removeEventListener('moveDrag', this.moveFromOut);
    }
}