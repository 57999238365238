import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ImageLazy from './ImageLazy.jsx';
import ListAbsoluteMain from './ListAbsoluteMain.jsx';
import getClassTypeImage from '../functions/getClassTypeImage';
import checkNotUnicode from '../functions/checkNotUnicode';

class Avatar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderResultItem = this.renderResultItem.bind(this);

        this.parent = React.createRef();
    }

    getClassTypeIcon({ iconType }) {
        const { holder, type } = this.props;
        let classType = iconType ? getClassTypeImage(iconType) : '_contain';

        if (type === 'company' && holder?.avatar?.path) {
            classType = getClassTypeImage(holder.avatar.type);
        }

        if (type === 'executor') {
            classType = '';
        }

        if (['image/svg+xml'].includes(iconType) || holder?._id === 'system') {
            classType += ' _svg';
        }

        return classType;
    }

    getSrc() {
        const { holder, type } = this.props;
        let { src } = this.props;

        if (holder?._id === 'system') {
            return require('../img/crm/logo-center.svg').default;
        }

        if (holder?.avatar?.src) {
            return holder?.avatar?.src;
        }

        if (holder?.avatar?.path) {
            if (type === 'company') {
                src = `${process.env.REACT_APP_STATIC}/companies/${holder.avatar.path}`;
            }

            if (type === 'executor') {
                src = `${process.env.REACT_APP_STATIC}/executors/${holder.avatar.path}`;
            }

            if (type === 'corporation') {
                src = `${process.env.REACT_APP_STATIC}/corporations/${holder.avatar.path}`;
            }
        }

        return src;
    }

    getResultItems() {
        const { holder, type } = this.props;
        let { iconType } = this.props;
        const src = this.getSrc();

        if (holder?.avatar) {
            iconType = holder.avatar.type;
        }

        if (holder) {
            // if (holder?.avatar && src) {
            //     return [{ type: 'image', src, value: src }];
            // }

            let text = '';

            text += checkNotUnicode(holder.firstName)
                ? holder.firstName?.[0] || ''
                : holder.firstName || '';
            text += checkNotUnicode(holder.secondName)
                ? holder.secondName?.[0] || ''
                : holder.secondName || '';

            if (type === 'company') {
                text = ((holder.shortName || holder.fullName || holder.name)?.[0]).toUpperCase();
            }

            text =
                holder.text ||
                (holder.name
                    ? checkNotUnicode(holder.name)
                        ? holder.name?.[0]
                        : holder.name
                    : null) ||
                text;

            if (src || holder?.icon) {
                return [{ type: 'icon', src, value: holder.icon?.toString() || src, iconType }];
            }

            if (text) {
                return [{ type: 'text', value: text.toUpperCase() }];
            }

            return [{ type: 'empty' }];
        }

        return [];
    }

    renderResultItem({ item }) {
        const { setStateLoader, stateLoader, type, children, withText = true } = this.props;
        const src = item.src;
        const classNameIcon = this.getClassTypeIcon(item);

        let folder = 'users';

        if (type === 'client') {
            folder = 'clients';
        }

        if (type === 'company') {
            folder = 'companies';
        }

        return (
            <div
                className={`avatar__item _col ${
                    item.type === 'text' && !withText ? '_empty' : ''
                } ${classNameIcon}`}
            >
                {item.type === 'icon' && (
                    <>
                        <ImageLazy
                            className="avatar__icon"
                            src={src || require(`../img/icons/${folder}/icon-${item.value}.png`)}
                            setStateLoader={setStateLoader}
                            stateLoader={stateLoader}
                        />
                    </>
                )}
                {withText && item.type === 'text' && <>{item.value.toUpperCase()}</>}
                {item.type === 'empty' && <>{children || 'Un'}</>}
            </div>
        );
    }

    render() {
        const {
            name,
            className = '',
            isNotParams = true,
            isNotParamsItem = true,
            callback,
            updatedRenderKey,
        } = this.props;

        return (
            <ListAbsoluteMain
                className={`avatar ${className}`}
                items={this.getResultItems()}
                renderItem={this.renderResultItem}
                classNameItem="avatar__item"
                prop="value"
                isNotParams={isNotParams}
                isNotParamsItem={isNotParamsItem}
                name={name}
                callback={callback}
                isSmoothShow={true}
                keyUpdateItem={updatedRenderKey}
            />
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Avatar);

Avatar.propTypes = {
    src: PropTypes.string,
    className: PropTypes.string,
    iconType: PropTypes.string,
    holder: PropTypes.object,
    setStateLoader: PropTypes.func,
    stateLoader: PropTypes.any,
    type: PropTypes.string,
    children: PropTypes.node,
    withText: PropTypes.bool,
    isNotParams: PropTypes.bool,
    isNotParamsItem: PropTypes.bool,
    name: PropTypes.string,
    callback: PropTypes.func,
    updatedRenderKey: PropTypes.any,
};
