import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import setSpacesInText from '../../../functions/setSpacesInText';
import Icon from '../../Icon.jsx';
import LinkBtn from '../LinkBtn.jsx';
import createChat from '../../../functions/app/createChat';

class CoordsOrder extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
        this.openChat = this.openChat.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appCoordsOrderPopup', isShow: false });
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    openChat() {
        const { idOfOrder } = this.props;

        return new Promise((resolve) => {
            this.handlerLoadingKey('chat').then(() => {
                createChat({ idOfOrder }).then(
                    () => {
                        this.handlerLoadingKey(null).then(() => {
                            this.hide();

                            resolve();
                        });
                    },
                    () => null,
                );
            });
        });
    }

    render() {
        const { loadingKey } = this.state;

        return (
            <div ref={this.parent} className="appPopup _col _changeOrder">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <i className="appPopup__icon">
                                <Icon name="app-change-order-coords" />
                            </i>
                            <div className="appPopup__title">
                                Вы находитесь
                                <br />
                                слишком далеко
                            </div>
                            <p
                                className="appPopup__description"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(
                                        'Вы не можете закрыть точку<br/>пока вы не доехали до неё. Нажмите «Закрыть адрес», когда будете на месте.',
                                    ),
                                }}
                            ></p>
                            <div className="appPopup__button">
                                <Button
                                    onClick={() => {
                                        this.hide();
                                    }}
                                >
                                    Понятно
                                </Button>
                            </div>
                            <div className="appPopup__link">
                                <LinkBtn
                                    className="_alert _bold"
                                    showLoader={loadingKey === 'chat'}
                                    handler={this.openChat}
                                >
                                    Нужна помощь
                                </LinkBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(CoordsOrder);

CoordsOrder.propTypes = {
    heightWindow: PropTypes.number,
    idOfOrder: PropTypes.string,
};
