import { getCookie } from './handlerCookies';
import { store } from '../redux/redux';
import getSalt from './getSalt';

export default function getHeaders(settings) {
    const user = store.getState().user;
    const userId = user?._id || settings?.id;
    const headers = {
        path: window.location.pathname.slice(1),
        typeOfSystem: process.env.REACT_APP_SYSTEM,
        device: store.getState().device,
        userAgent: navigator.userAgent,
    };

    if (getCookie(process.env.REACT_APP_HASH)) {
        headers.hash = getCookie(process.env.REACT_APP_HASH);
        headers.salt = getSalt({ id: userId });
    }

    if (process.env.REACT_APP_SYSTEM === 'crm') {
        headers.siteorigin = process.env.REACT_APP_CRM_DOMEN;
    }

    if (process.env.REACT_APP_SYSTEM === 'app') {
        headers.siteorigin = process.env.REACT_APP_APP_DOMEN;
    }

    if (user) {
        headers.userId = userId;

        if (process.env.REACT_APP_SYSTEM === 'crm') {
            headers.version = user.systemVersion;
            headers.idOfCurrentCorporation = user.idOfCurrentCorporation;
        }

        if (process.env.REACT_APP_SYSTEM === 'app') {
            headers.version = user.systemVersion;
        }
    }

    headers.timezoneOffset = new Date().getTimezoneOffset() / 60;

    return headers;
}
