import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from '../popups/Popup.jsx';
import InputRunning from '../../InputRunning.jsx';
import Icon from '../../Icon.jsx';
import setSpacesInText from '../../../functions/setSpacesInText';
import getFormatPrice from '../../../functions/getFormatPrice';
import getInfoRoute from '../../../functions/order/getInfoRoute';
import removeTransition from '../../../functions/removeTransition.ts';

class OrderDetails extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerHead = this.handlerHead.bind(this);
        this.popupMove = this.popupMove.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
    }

    name = 'details';

    isDefaultHide = true;

    handlerHead() {
        const { moveDirection } = this.state;

        if (moveDirection === 'end') {
            this.moveY = 0;
            this.lastY = 0;
        } else {
            this.moveY = -this.getDownBreak();
            this.lastY = -this.getDownBreak();
        }

        this.handlerEnd(true);
    }

    getServices() {
        const { order } = this.props;

        if (order) {
            const services = [];

            order.infoAmount
                .filter((item) => !['tariff'].includes(item.key) && item.name)
                .forEach((item) => {
                    const name = item.name;

                    services.push(name);
                });

            const servicesString = services.join(', ');

            return servicesString
                ? `${servicesString[0].toUpperCase()}${servicesString.slice(1).toLowerCase()}`
                : 'Нет';
        }

        return '';
    }

    popupMove({ detail }) {
        const { action, name, move } = detail;

        if (name === 'order') {
            const popup = this.parent.current;

            if (action === 'move') {
                popup.classList.add('_isDrag');
            }

            popup.style.transform = `translate(0,${-move}px)`;

            if (action === 'end') {
                popup.classList.remove('_isDrag');
            }
        }
    }

    handlerSocket({ detail }) {
        const { order } = this.props;
        const { name, data } = detail;

        if (
            order &&
            name === 'order' &&
            data?.idOfOrder === order._id &&
            data?.type === 'changeInfo'
        ) {
            const { fields } = data;

            if (fields.actionChangeRoute || fields.idOfCurrentPoint) {
                removeTransition({ item: '.appPopup._details' });

                this.moveY = -this.getDownBreak();
                this.lastY = -this.getDownBreak();

                this.handlerEnd(true);
            }
        }
    }

    componentDidMount() {
        super.componentDidMount();

        document.addEventListener('popup', this.popupMove);
        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        document.removeEventListener('popup', this.popupMove);
        document.removeEventListener('getSocketData', this.handlerSocket);
    }

    render() {
        const { moveDirection } = this.state;
        const { order } = this.props;
        const durationInfo = getInfoRoute(order?.distance, order?.duration);

        return (
            <div
                ref={this.parent}
                className={`appPopup _col _details ${moveDirection === 'end' ? '_withOutLine' : ''}`}
            >
                <div
                    className={`appPopup__back _dark ${moveDirection === 'end' ? '_hide' : ''}`}
                ></div>
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div
                                className={`appOrderShortDetails ${
                                    moveDirection === 'end' ? '' : '_full'
                                }`}
                            >
                                <div
                                    className="appOrderShortDetails__head _row"
                                    onClick={this.handlerHead}
                                >
                                    <p className="appOrderShortDetails__headTitle">
                                        Детали заказа и маршрут
                                    </p>
                                </div>
                                <div className="appOrderShortDetails__content">
                                    <div className="appOrderShortDetails__points">
                                        {order.route.map((point, pointIndex) => {
                                            const { address, ttk, sk, cargo } = point;
                                            const comment = cargo[0]?.comment;
                                            let areaStr = '';
                                            let otherInfo;
                                            let className = '';

                                            if (sk) {
                                                areaStr = ' · СК';
                                            } else if (ttk) {
                                                areaStr = ' · ТТК';
                                            }

                                            const currentPointIndex = order.route.findIndex(
                                                (innerPoint) =>
                                                    innerPoint._id === order.idOfCurrentPoint,
                                            );

                                            if (pointIndex < currentPointIndex) {
                                                className = '_prev';
                                                otherInfo =
                                                    pointIndex === 0
                                                        ? 'Погрузка завершена'
                                                        : 'Доставка завершена';
                                            } else if (pointIndex > currentPointIndex) {
                                                className = '_next';
                                                otherInfo = 'Следующая точка';
                                            } else {
                                                className = '_current';
                                                otherInfo = 'Текущая точка';
                                            }

                                            const isFirst = pointIndex === 0;

                                            const pointType = isFirst ? 'погрузки' : 'выгрузки';

                                            let pointNumber;

                                            if (!isFirst && order.route.length > 2) {
                                                pointNumber = ` ${pointIndex}`;
                                            }

                                            return (
                                                <div
                                                    className={`appOrderShortDetails__point ${className}`}
                                                    key={point._id}
                                                >
                                                    <div className="appOrderShortDetails__pointInner _col">
                                                        <InputRunning
                                                            type="text"
                                                            className="appOrderShortDetails__pointAddress"
                                                            value={address}
                                                            autoComplete="off"
                                                            readOnly={true}
                                                            isRun={true}
                                                        />
                                                        <div className="appOrderShortDetails__pointDescription">
                                                            Точка {pointType}
                                                            {pointNumber}
                                                            {areaStr}{' '}
                                                            {otherInfo ? (
                                                                <>
                                                                    – <span>{otherInfo}</span>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                        {comment && (
                                                            <div className="appOrderShortDetails__pointOther _col">
                                                                {comment && (
                                                                    <div className="appOrderShortDetails__pointComment">
                                                                        <i className="appOrderShortDetails__pointCommentIcon">
                                                                            <Icon name="app-order-comment" />
                                                                        </i>
                                                                        <div
                                                                            className="appOrderShortDetails__pointCommentInner"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: setSpacesInText(
                                                                                    comment,
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="appOrderShortDetails__info _row">
                                        <div className="appOrderShortDetails__infoItem _pay">
                                            <div className="appOrderShortDetails__infoItemValue">
                                                ≈{getFormatPrice(order?.amount)}₽
                                            </div>
                                            <p className="appOrderShortDetails__infoItemSupport">
                                                Наличными
                                            </p>
                                        </div>

                                        <div className="appOrderShortDetails__infoItem _distance">
                                            <div className="appOrderShortDetails__infoItemValue">
                                                {order?.distance}км
                                            </div>
                                            <p className="appOrderShortDetails__infoItemSupport">
                                                Пробег
                                            </p>
                                        </div>
                                        <div className="appOrderShortDetails__infoItem _duration">
                                            <div className="appOrderShortDetails__infoItemValue">
                                                {order?.status !== 'cancel' ? (
                                                    <>
                                                        {durationInfo.hours}ч {durationInfo.minutes}
                                                        м
                                                    </>
                                                ) : (
                                                    <>0ч 0м</>
                                                )}
                                            </div>
                                            <p className="appOrderShortDetails__infoItemSupport">
                                                В работе
                                            </p>
                                        </div>
                                        <div className="appOrderShortDetails__infoItem _services">
                                            <div className="appOrderShortDetails__infoItemValue">
                                                {this.getServices()}
                                            </div>
                                            <p className="appOrderShortDetails__infoItemSupport">
                                                Дополнительные услуги к заказу
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(OrderDetails);

OrderDetails.propTypes = {
    order: PropTypes.object,
    heightWindow: PropTypes.number,
};
