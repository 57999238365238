import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import changePage from '../functions/changePage';
import getPageLink from '../functions/getPageLink';
import handlerWindow from '../functions/handlerWindow';

class Link extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerContextMenu = this.handlerContextMenu.bind(this);

        this.parent = React.createRef();
    }

    checkCurrent() {
        const { storePages, pageName } = this.props;

        return storePages?.[pageName]?.isShow || null;
    }

    getHref(href) {
        if (!href) {
            return '';
        }

        return href === '' || href[href.length - 1] === '/' ? href : `${href}/`;
    }

    setHref() {
        const { pageName, storePages, ids } = this.props;
        const href = getPageLink({ name: pageName, storePages, ids });

        this.setState({
            href,
        });
    }

    handlerContextMenu(e) {
        const { windows } = this.props;
        const ref = this.props.ref || this.parent.current;

        if (
            process.env.REACT_APP_SYSTEM === 'crm' &&
            (ref === e.target || ref.contains(e.target))
        ) {
            if (!windows[this.windowId]) {
                const href = this.getHref(this.props.href ?? this.state.href);
                const parent = document.querySelector('.body__content');

                handlerWindow({
                    parent,
                    parentResize: parent,
                    target: {
                        offsetWidth: 0,
                        offsetHeight: 0,
                        getBoundingClientRect: () => ({ x: e.pageX, y: e.pageY }),
                    },
                    action: 'show',
                    name: 'contextMenu',
                    className: '_actions _center',
                    uniqKey: this.windowId,
                    checkContainsInWindow: true,
                    isHideFromResize: true,
                    link: href,
                    centers: {
                        left: 0.5,
                        top: 0,
                    },
                });
            } else {
                handlerWindow({
                    name: 'contextMenu',
                    action: 'hide',
                });
            }
        }
    }

    checkChange() {
        const { user, ids } = this.props;

        const corporationId = user?.idOfCurrentCorporation ?? 'notAuth';

        let isChange = false;

        if (ids && this.ids) {
            Object.keys(this.ids).forEach((key) => {
                if (ids[key] !== this.ids[key]) {
                    isChange = true;
                }
            });
        }

        // if (isChange) {
        //     console.log(ids);
        // }

        if (corporationId !== this.idOfCurrentCorporation || isChange) {
            this.ids = ids;
            this.idOfCurrentCorporation = corporationId;

            const href = this.getHref(this.props.href ?? this.state.href);

            if (!this.windowId) {
                this.windowId = `contextMenu-${href}-${new Date().getTime()}`;
            }

            this.setHref();
        }
    }

    componentDidMount() {
        this.checkChange();

        document.addEventListener('contextmenu', this.handlerContextMenu);
    }

    componentDidUpdate() {
        this.checkChange();
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this.handlerContextMenu);
    }

    render() {
        const {
            children,
            className = '',
            callback,
            // pageName,
            // storePages,
            prevActions,
            prevPromise = async () => true,
            // levels,
            changeIsHard,
            onClick,
            tag,
            isStopPropagation,
            // windows,
            ...otherProps
        } = this.props;
        const LinkTag = tag || 'div';
        const href = this.getHref(this.props.href ?? this.state.href);
        const isCurrent = (this.props.isCurrent ?? this.checkCurrent()) || null;

        return (
            <LinkTag
                className={`${className} _click`}
                onClick={(e) => {
                    if (isStopPropagation) {
                        e.stopPropagation();
                    }

                    if (typeof onClick === 'function') {
                        onClick();
                    } else {
                        if (prevActions) {
                            prevActions();
                        }

                        prevPromise().then(
                            () => {
                                changePage({ href, changeIsHard }).then(() => {
                                    if (callback) {
                                        callback();
                                    }
                                });
                            },
                            () => null,
                        );
                    }
                }}
                data-current={isCurrent}
                ref={this.parent}
                {...otherProps}
                href={href?.[0] === '/' ? href : `/${href}`}
            >
                {children}
            </LinkTag>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        storePages: state.pages,
        windows: state.windows,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Link);

Link.propTypes = {
    href: PropTypes.string,
    pageName: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    callback: PropTypes.func,
    isCurrent: PropTypes.bool,
    storePages: PropTypes.object,
    prevActions: PropTypes.func,
    prevPromise: PropTypes.func,
    levels: PropTypes.array,
    changeIsHard: PropTypes.bool,
    onClick: PropTypes.func,
    ids: PropTypes.object,
    windows: PropTypes.object,
    ref: PropTypes.object,
    tag: PropTypes.string,
    user: PropTypes.object,
    isStopPropagation: PropTypes.bool,
};
