import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';

import createId from '../../../requests/createId';

import handlerPopup from '../../../functions/app/handlerPopup';
import Button from '../../Button.jsx';
import Field from '../../Field.jsx';
import { findPointWords, geocode, pointInArea } from '../../../functions/handlerYmap';
import Ymaps from '../../Ymaps.jsx';
import { inputValidate } from '../../../functions/inputValidate';
import Loader from '../../Loader.jsx';
import Animate from '../../Animate.jsx';

class NewPoint extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            coords: null,
            addresses: [],
        };

        this.handlerField = this.handlerField.bind(this);
        this.mapBootstrap = this.mapBootstrap.bind(this);
        this.getAddresses = this.getAddresses.bind(this);
        this.getCoords = this.getCoords.bind(this);
        this.save = this.save.bind(this);
        this.deletePoint = this.deletePoint.bind(this);

        this.hide = this.hide.bind(this);
    }

    fieldsOrder = ['address', 'phone'];

    fieldsInfo = {
        address: {
            keyName: 'pointAddress',
        },
    };

    mapBootstrap({ ymaps, map }) {
        this.setState({ ymaps, map }, this.getAddresses);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appNewPointPopup', isShow: false, point: undefined });
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    getAddresses() {
        const { ymaps, fields } = this.state;

        if (ymaps && fields.address?.value?.length > 2) {
            findPointWords(ymaps, fields.address?.value).then((items) => {
                this.setState({ addresses: items.map((item) => item.value.trim()) });
            });
        }
    }

    getCoords(address) {
        const { ymaps, map } = this.state;

        this.handlerLoadingKey('address').then(() => {
            this.handlerField({ action: 'change', name: 'address', value: address }).then(() => {
                geocode(ymaps, address).then(
                    (res) => {
                        const { _coordinates: coords } = res.geometry;

                        const { ttk, sk } = pointInArea(ymaps, map, coords);

                        this.setState({ coords, ttk, sk }, () => {
                            this.handlerLoadingKey(null);
                        });
                    },
                    () => null,
                );
            });
        });
    }

    handlerField({ action, name, value }) {
        const handler = (resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const fields = { ...newState.fields };

                    if (!fields[name]) {
                        fields[name] = {
                            value: '',
                            error: null,
                        };
                    }

                    if (action === 'change') {
                        fields[name].value = value;
                        fields[name].error = null;

                        if (name === 'address') {
                            newState.coords = null;
                            newState.ttk = null;
                            newState.sk = null;

                            if (value.length <= 2) {
                                newState.addresses = [];
                            }
                        }
                    } else {
                        fields[name].isFocus = action === 'focus';
                    }

                    newState.fields = fields;

                    return newState;
                },
                () => {
                    if (action === 'change' && name === 'address' && value.length > 2) {
                        this.getAddresses();
                    }

                    resolve();
                },
            );
        };

        return new Promise((resolve) => {
            if (action === 'blur') {
                setTimeout(() => {
                    handler(resolve);
                }, 10);
            } else {
                handler(resolve);
            }
        });
    }

    deletePoint() {
        const { id } = this.state;
        const { addPoint } = this.props;

        this.hide();

        addPoint({
            deletedId: id,
        });
    }

    save() {
        const { fields, coords, loadingKey, ttk, sk, id } = this.state;
        const { addPoint } = this.props;
        const errors = [];

        if (!coords) {
            errors.push('address');
        }

        if (!inputValidate({ name: 'phone', value: fields?.phone?.value })) {
            errors.push('phone');
        }

        if (loadingKey) {
            return;
        }

        if (errors.length) {
            this.setState((state) => {
                const newState = { ...state };
                const resultFields = { ...newState.fields };

                errors.forEach((error) => {
                    if (!resultFields[error]) {
                        resultFields[error] = {
                            value: '',
                            error: null,
                        };
                    }

                    resultFields[error].error = error;
                });

                newState.fields = resultFields;

                return newState;
            });
        } else {
            this.handlerLoadingKey('save').then(() => {
                if (id) {
                    this.hide();

                    addPoint({
                        point: {
                            _id: id,
                            address: fields?.address?.value,
                            coords,
                            phone: fields?.phone?.value,
                            ttk,
                            sk,
                        },
                    });
                } else {
                    createId().then(
                        ({ id: newId }) => {
                            this.hide();

                            addPoint({
                                point: {
                                    _id: newId,
                                    address: fields?.address?.value,
                                    coords,
                                    phone: fields?.phone?.value,
                                    ttk,
                                    sk,
                                },
                            });
                        },
                        () => null,
                    );
                }
            });
        }
    }

    componentDidMount() {
        super.componentDidMount();

        const { point } = this.props;

        if (point) {
            this.setState({
                id: point._id,
                ttk: point.ttk,
                sk: point.sk,
                coords: point.coords,
                fields: {
                    address: {
                        value: point.address,
                    },
                    phone: {
                        value: point.phone,
                    },
                },
            });
        }
    }

    render() {
        const { fields, addresses = [], loadingKey, id } = this.state;

        return (
            <div ref={this.parent} className="appPopup _col">
                <Ymaps callback={this.mapBootstrap} forCalc={true} />
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appPopup__title">
                                {id ? <>Изменить адрес</> : <>Новая точка</>}
                            </div>
                            <p className="appPopup__description">
                                {id ? (
                                    <>
                                        Вы можете изменить данные
                                        <br />
                                        адреса или удалить его из маршрута
                                    </>
                                ) : (
                                    <>
                                        Введите адрес точки
                                        <br />и контактные данные
                                    </>
                                )}
                            </p>
                            <div className="appPopup__fields">
                                {this.fieldsOrder.map((name) => {
                                    const field = fields[name];
                                    const fieldInfo = this.fieldsInfo[name];

                                    return (
                                        <div className="appPopup__field" key={name}>
                                            {name === 'address' && (
                                                <Animate
                                                    className="appPopup__fieldLoader _loader"
                                                    isShow={loadingKey === 'address'}
                                                >
                                                    <div className="appPopup__fieldLoaderItem _loaderItem">
                                                        <Loader className="_main" />
                                                    </div>
                                                </Animate>
                                            )}
                                            <Field
                                                type="appNewPoint"
                                                keyName={fieldInfo?.keyName || name}
                                                name={name}
                                                value={field?.value}
                                                error={field?.error}
                                                callback={this.handlerField}
                                            />
                                            {field?.isFocus &&
                                                name === 'address' &&
                                                addresses.length > 0 && (
                                                    <div className="appPopup__fieldDrop">
                                                        {addresses.map((address, key) => (
                                                            <div
                                                                className="appPopup__fieldDropItem"
                                                                key={key}
                                                                onClick={() => {
                                                                    this.getCoords(address);
                                                                }}
                                                            >
                                                                {address}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                        </div>
                                    );
                                })}
                            </div>
                            {id && (
                                <div className="appPopup__button">
                                    <Button
                                        className="_alertLight _notShadow"
                                        onClick={this.deletePoint}
                                    >
                                        Удалить адрес из маршрута
                                    </Button>
                                </div>
                            )}
                            <div className="appPopup__button">
                                <Button onClick={this.save} showLoader={loadingKey === 'save'}>
                                    Сохранить точку
                                </Button>
                            </div>
                            <div className="appPopup__link">
                                <div className="link _click _alert _bold" onClick={this.hide}>
                                    Отменить
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(NewPoint);

NewPoint.propTypes = {
    addPoint: PropTypes.func,
    point: PropTypes.object,
};
