import axios from 'axios';

import getHeaders from '../getHeaders';
import connectSockets from '../connectSockets';

import { dispatcher } from '../../redux/redux';
import handlerFastOrder from './handlerFastOrder';
import logoutActions from './logoutActions';
import checkActualCar from './checkActualCar';
import handlerPopup from './handlerPopup';
import checkWebPush from '../checkWebPush';

export default function checkAuth(withConnect = true, settings) {
    return new Promise((resolve) => {
        axios
            .get(`${process.env.REACT_APP_API}/v2/login-app`, { headers: getHeaders(settings) })
            .then(
                (res) => {
                    const { success, data } = res.data;

                    if (success) {
                        const { user } = data;

                        localStorage.setItem('user', JSON.stringify(user));

                        if (
                            process.env.REACT_APP_ENV !== 'local' &&
                            +process.env.REACT_APP_APP_VERSION < user.systemVersion
                        ) {
                            handlerPopup({ name: 'appNewVersionPopup', isShow: true });
                        }

                        dispatcher({ type: 'user', data: user }).then(() => {
                            if (withConnect) {
                                connectSockets(true);
                            }

                            if (0) {
                                checkActualCar({});
                            }

                            handlerFastOrder({ user });

                            checkWebPush({ fromAuth: true }).then(
                                () => null,
                                () => null,
                            );

                            setTimeout(() => {
                                if (!localStorage.getItem('appWebPushPopup')) {
                                    handlerPopup({ name: 'appWebPushPopup', isShow: true });
                                }
                            }, 1_000);

                            resolve({ user });
                        });
                    } else {
                        logoutActions();

                        resolve();
                    }
                },
                () => null,
            );
    });
}
