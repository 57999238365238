import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import Icon from '../../Icon.jsx';
import setSpacesInText from '../../../functions/setSpacesInText';
import getFormatedDate from '../../../functions/getFormatedDate';
import LinkBtn from '../LinkBtn.jsx';
import createChat from '../../../functions/app/createChat';

class ChangeOrder extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
        this.openChat = this.openChat.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appChangeOrderPopup', isShow: false });
    }

    getPointDescription() {
        const { order } = this.props;
        const { action } = order;

        if (action === 'change-route') {
            return '';
        }

        if (order.sk || order.ttk) {
            return ` <span>Выгрузка · ${order.sk ? 'СК' : 'ТТК'}</span>`;
        }

        return '';
    }

    getInfo() {
        const { order } = this.props;
        const { action, dateOfOrder } = order;
        const date = `${getFormatedDate({ date: new Date(dateOfOrder) })} (${getFormatedDate({
            date: new Date(dateOfOrder),
            type: 'time',
        })})`;

        if (action === 'add-point') {
            return {
                icon: 'app-change-order-add',
                title: 'добавлен 1 адрес',
                getDescription() {
                    return `В заказ ID${
                        order.number
                    } на ${date} клиент добавил новую точку маршрута: <b>${
                        order.address
                    }</b><br/>${this.getPointDescription()}`;
                },
            };
        }

        if (action === 'delete-point') {
            return {
                icon: 'app-change-order-delete',
                title: 'удалён 1 адрес',
                getDescription() {
                    return `В заказ ID${
                        order.number
                    } на ${date} клиент удалил одну точку маршрута: <b>${
                        order.address
                    }</b><br/>${this.getPointDescription()}`;
                },
            };
        }

        return {
            icon: 'app-change-order-change',
            title: 'маршрут изменён',
            getDescription() {
                return `В заказе ID${order.number} на ${date}<br/>клиент изменил адреса.`;
            },
        };
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    openChat() {
        const { order } = this.props;

        return new Promise((resolve) => {
            this.handlerLoadingKey('chat').then(() => {
                createChat({ idOfOrder: order._id }).then(() => {
                    this.handlerLoadingKey(null).then(() => {
                        this.hide();

                        resolve();
                    });
                },
                () => null);
            });
        });
    }

    render() {
        const { loadingKey } = this.state;
        const { order } = this.props;
        const info = this.getInfo();

        return (
            <div ref={this.parent} className="appPopup _col _changeOrder">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <i className="appPopup__icon">
                                <Icon name={info.icon} />
                            </i>
                            <div className="appPopup__title">
                                Заказ ID{order.number}:
                                <br />
                                {info.title}
                            </div>
                            <p
                                className="appPopup__description"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(info.getDescription.call(this)),
                                }}
                            ></p>
                            <p className="appPopup__description">Маршрут будет перестроен</p>

                            <div className="appPopup__button">
                                <Button onClick={this.hide}>Понятно</Button>
                            </div>
                            <div className="appPopup__link">
                                <LinkBtn
                                    className="_alert _bold"
                                    showLoader={loadingKey === 'chat'}
                                    handler={this.openChat}
                                >
                                    Поддержка
                                </LinkBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(ChangeOrder);

ChangeOrder.propTypes = {
    heightWindow: PropTypes.number,
    order: PropTypes.object,
};
