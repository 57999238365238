import { store } from '../redux/redux';
import getServerData from './getServerData';

const stateData = {};

export default function setServerData(name) {
    return new Promise((resolve) => {
        const { serverData } = store.getState();

        if (!serverData[name]) {
            if (!stateData[name]) {
                stateData[name] = {
                    timerId: null,
                    _proccess: false,
                    _complete: false,
                    resolves: [],
                };
            }

            // console.log(name, stateData[name]._proccess);

            if (stateData[name]._proccess === false && stateData[name]._complete !== true) {
                stateData[name]._proccess = true;
                
                getServerData(name).then(
                    (res) => {
                        stateData[name]._complete = true;
                        stateData[name]._proccess = false;
                        if (stateData[name].timerId) {
                            clearTimeout(stateData[name].timerId);
                        }

                        resolve(res);

                        stateData[name].resolves.forEach((resolveSave) => {
                            resolveSave(res);
                        });
                    },
                    () => {
                        stateData[name]._proccess = false;
                        stateData[name].resolves.push(resolve);

                        stateData[name].timerId = setTimeout(() => {
                            setServerData(name);
                        }, 500);
                    },
                );
            } else {
                stateData[name].resolves.push(resolve);
                setTimeout(() => {
                    setServerData(name);
                }, 500);
            }
        } else {
            resolve(serverData[name]);
        }
    });
}
