import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Animate from '../Animate.jsx';

import handlerErrorRequest from '../../functions/handlerErrorRequest';
import getHeaders from '../../functions/getHeaders';

import ListAbsoluteMain from '../ListAbsoluteMain.jsx';
import handlerPopup from '../../functions/app/handlerPopup';
import setNotification from '../../functions/setNotification';

class WindowTags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.save = this.save.bind(this);
        this.renderTag = this.renderTag.bind(this);

        this.parent = React.createRef();
    }

    save({ tags }) {
        const { chat } = this.props;

        return new Promise((resolve, reject) => {
            if (chat) {
                const { _id: id } = chat;

                axios
                    .patch(
                        `${process.env.REACT_APP_API}/chat`,
                        { id, action: 'set-tags', tags },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                setNotification({ notification: 'success-change-info' });

                                // this.setState({ updatedTagsKey: new Date().getTime() });
                            } else {
                                handlerErrorRequest(res);
                            }

                            resolve();
                        },
                        () => null,
                    );
            } else {
                reject();
            }
        });
    }

    renderTag({ item: tag }) {
        const { name } = tag;

        return (
            <div className="chatWindowTags__label">
                <div className="tag _full" style={{ background: tag.colorOfGroup }}>
                    {name}
                </div>
            </div>
        );
    }

    sortTags(tags = []) {
        return JSON.parse(JSON.stringify(tags)).sort((a, b) => {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();

            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    }

    getTags() {
        const { chat } = this.props;

        let tags = JSON.parse(JSON.stringify(chat?.tagsInfo || []));

        if (tags.length > 4) {
            tags = [
                ...tags.filter((tag, key) => key <= 4),
                {
                    id: `more${tags.length}`,
                    name: `Ещё ${tags.length - 4}`,
                    colorOfGroup: '#3e6aed',
                },
            ];
        }

        return tags;
    }

    componentDidMount() {
        const { setContentHeight } = this.props;

        setContentHeight();
    }

    render() {
        // const { updatedTagsKey } = this.state;
        const { chat, isShow, isDisabled } = this.props;
        const allTags = chat?.tagsInfo || [];
        const tags = this.getTags();

        return (
            <div ref={this.parent} className="chatWindowTags">
                <div className="chatWindowTags__inner _row">
                    <div className="chatWindowTags__labels">
                        <ListAbsoluteMain
                            className="chatWindowTags__labelsInner"
                            items={tags}
                            renderItem={this.renderTag}
                            classNameItem="chatWindowTags__label"
                            prop="id"
                            paramsParent={{ width: true }}
                            keyRender={isShow}
                            sort={this.sortTags}
                            minHeight={19}
                        />
                    </div>
                    <Animate className="chatWindowTags__actions" isShow={!isDisabled}>
                        <div
                            className="chatWindowTags__link _click"
                            onClick={() => {
                                handlerPopup({
                                    name: 'tagsPopup',
                                    isShow: true,
                                    ids: allTags.map((tag) => tag.id),
                                    callback: (newTags) =>
                                        new Promise((resolve) => {
                                            this.save({ tags: newTags }).then(resolve);
                                        }),
                                });
                            }}
                        >
                            Изменить теги
                        </div>
                    </Animate>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(WindowTags);

WindowTags.propTypes = {
    chat: PropTypes.object,
    setContentHeight: PropTypes.func,
    isShow: PropTypes.bool,
    isDisabled: PropTypes.bool,
};
