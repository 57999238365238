import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import AnimateChange from '../AnimateChange.jsx';
import Animate from '../Animate.jsx';

import handlerErrorRequest from '../../functions/handlerErrorRequest';
import getHeaders from '../../functions/getHeaders';
import checkValueOfEmpty from '../../functions/checkValueOfEmpty';

class CounterNotRead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerSocket = this.handlerSocket.bind(this);
    }

    getCounter() {
        const { id, type, callback, params } = this.props;
        let query = type === 'all' ? '' : `&id=${id}`;

        if (params) {
            Object.keys(params).forEach((prop) => {
                query += `&${prop}=${params[prop]}`;
            });
        }

        if (this.controller) {
            this.controller.abort();
        }

        this.controller = new AbortController();

        return new Promise((resolve) => {
            axios
                .get(`${process.env.REACT_APP_API}/chat?notRead=true${query}`, {
                    signal: this.controller.signal,
                    headers: getHeaders(),
                })
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            const { counter } = data;

                            this.setState({ counter }, () => {
                                if (callback && typeof callback === 'function') {
                                    callback(this.getCounterValue());
                                }

                                resolve();
                            });
                        } else {
                            handlerErrorRequest(res);
                        }
                    },
                    () => null,
                );
        });
    }

    getCounterValue() {
        const { counter } = this.state;

        return counter || 0;
    }

    checkUpdate() {
        if (this.props.keyUpdate !== this.state.keyUpdate) {
            this.setState({ keyUpdate: this.props.keyUpdate }, this.getCounter);
        }
    }

    timerCounter = 0;

    setCheckCounter() {
        this.timerCounter += 1;
    }

    checkCounter() {
        if (this.timerCounter > 0) {
            this.timerCounter = 0;

            this.getCounter();
        }
    }

    handlerSocket({ detail }) {
        const { id, type, callback } = this.props;
        const { name, data } = detail;

        if (name === 'chat') {
            const { idOfChat, counter, type: typeSocket } = data;

            // console.log(data);

            if (typeSocket === 'updateCounter') {
                if (type !== 'all' && id === idOfChat) {
                    this.setState({ counter }, () => {
                        if (callback && typeof callback === 'function') {
                            callback(this.getCounterValue());
                        }
                    });
                }

                if (type === 'all') {
                    this.setCheckCounter();
                }
            }

            if (type === 'all' && (typeSocket === 'newChat' || typeSocket === 'deleteChat')) {
                this.setCheckCounter();
            }
        }
    }

    componentDidMount() {
        const { startCounter } = this.props;

        this.intervalCounterId = setInterval(() => {
            this.checkCounter();
        }, 2_000);

        if (checkValueOfEmpty(startCounter)) {
            this.setState({ counter: startCounter, keyUpdate: this.props.keyUpdate });
        } else if (checkValueOfEmpty(this.props.keyUpdate)) {
            this.checkUpdate();
        } else {
            this.getCounter();
        }

        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentDidUpdate() {
        this.checkUpdate();
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);

        clearInterval(this.intervalCounterId);
    }

    render() {
        const { isHide = false, className } = this.props;
        const counter = this.getCounterValue();

        if (counter > 0) {
            this.counter = counter;
        }

        return (
            !isHide && (
                <Animate
                    className={`notificationCounter ${className || ''}`}
                    isShow={counter !== 0}
                    animateForInit={true}
                >
                    <div className="notificationCounter__box _col">
                        <AnimateChange
                            className="notificationCounter__inner _col"
                            classNameChild="_child"
                            prop={this.counter}
                            type="_translateMedium"
                        >
                            {`${this.counter}`}
                        </AnimateChange>
                    </div>
                </Animate>
            )
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(CounterNotRead);

CounterNotRead.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    isHide: PropTypes.bool,
    callback: PropTypes.func,
    params: PropTypes.object,
    keyUpdate: PropTypes.any,
    startCounter: PropTypes.number,
};
