import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../Icon.jsx';
import Animate from '../Animate.jsx';
import handlerPopup from '../../functions/app/handlerPopup';

class AppGalery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { appGalery, heightWindow } = this.props;

        return (
            <Animate
                className="body__appGalery"
                isShow={appGalery.isShow}
                style={{ height: `${heightWindow}px` }}
            >
                <div className="appGalery">
                    <div
                        className="appGalery__close _col"
                        onClick={() => {
                            handlerPopup({ name: 'appGalery', isShow: false });
                        }}
                    >
                        <i className="appGalery__closeIcon">
                            <Icon name="close" />
                        </i>
                    </div>
                    <div className="appGalery__inner _col">
                        <img src={appGalery.src} alt="" className="appGalery__image" />
                    </div>
                </div>
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        appGalery: state.appGalery,
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(AppGalery);

AppGalery.propTypes = {
    appGalery: PropTypes.object,
    heightWindow: PropTypes.number,
};
