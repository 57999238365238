import io from 'socket.io-client';

import { dispatcher, store } from '../redux/redux';
import { getCookie } from './handlerCookies';
import getSalt from './getSalt';

let connectSocketsThis = false;
let timerId;
let socket;
let isAuth = false;
let saveHash;

let connectCounter = 0;

const saveSocket = (savedSocket) => {
    dispatcher({ type: 'socket', data: savedSocket });
};

export default function connectSockets(isRefresh = false) {
    const { isSocketConnect, user } = store.getState();
    const isOldAuth = isAuth;

    if ((isSocketConnect !== true && !connectSocketsThis) || isRefresh === true) {
        const headers = {};

        let query = '';

        if (getCookie('hashOrderInfo')) {
            query += `hashOrderInfo=${getCookie('hashOrderInfo')}&`;
        }

        if (getCookie(process.env.REACT_APP_HASH)) {
            query += `hash=${getCookie(process.env.REACT_APP_HASH)}&salt=${getSalt({
                id: user._id,
            })}&`;
            isAuth = true;
        } else {
            isAuth = false;
        }

        let isChange = isOldAuth !== isAuth;

        if (getCookie('joinHash')) {
            query += `joinHash=${getCookie('joinHash')}&`;

            isChange = true;
        }

        if (!isChange && saveHash !== getCookie(process.env.REACT_APP_HASH)) {
            isChange = true;
        }

        saveHash = getCookie(process.env.REACT_APP_HASH);

        headers.query = query;

        const getSocketData = (socketData) => {
            document.dispatchEvent(
                new CustomEvent('getSocketData', {
                    detail: { name: socketData.name, data: socketData.data },
                }),
            );
        };

        const connect = () => {
            if (timerId) {
                clearTimeout(timerId);
            }

            saveSocket(socket);

            connectCounter = 0;

            socket.on('initSocket', () => {
                dispatcher({ type: 'isSocketConnect', data: true });
            });
        };

        const error = () => {
            connectCounter += 1;

            if (timerId) {
                clearTimeout(timerId);
            }

            timerId = setTimeout(
                () => {
                    connectSockets(true);
                },
                connectCounter < 3 ? 0 : 500 * connectCounter,
            );
        };

        const disconnect = () => {
            connectCounter += 1;

            saveSocket(null);
            dispatcher({ type: 'isSocketConnect', data: false }).then(() => {
                if (timerId) {
                    clearTimeout(timerId);
                }

                timerId = setTimeout(
                    () => {
                        connectSockets(true);
                    },
                    connectCounter < 3 ? 0 : 500 * connectCounter,
                );
            });
        };

        if (socket) {
            socket.removeAllListeners();
            socket.removeListener('getSocketData');
        }

        if (!socket || isRefresh) {
            if (socket) {
                socket.disconnect();
            }

            socket = io(`${process.env.REACT_APP_SOCKET}`, headers);
        }

        connectSocketsThis = true;

        socket.on('getSocketData', getSocketData);
        socket.on('connect', connect);
        socket.on('connect_error', error);
        socket.on('disconnect', disconnect);
    }
}
