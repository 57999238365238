import { store } from '../../redux/redux';
import getCurrentCar from './getCurrentCar';
import handlerPopup from './handlerPopup';

export default function checkActualCar({ user = store.getState().user, withLocal = true }) {
    const car = getCurrentCar({ user });

    if (car) {
        if (car?.isActual === true) {
            localStorage.setItem('appCarImagesComplete', true);

            localStorage.removeItem('carImagesCurrentBlock');

            handlerPopup({ name: 'appCarImagesPopup', isShow: false });
        }

        if (
            car?.isActual === false ||
            (withLocal && !localStorage.getItem('appCarImagesComplete'))
        ) {
            handlerPopup({ name: 'appCarImagesPopup', isShow: true });

            localStorage.removeItem('appCarImagesComplete');
        }
    }
}
