import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../../components/Icon.jsx';
import AppInstruction from '../../../components/AppInstruction.jsx';

class JoinFinal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { renderButton, hasButton } = this.props;

        return (
            <div className="join__blockInner _col">
                <div className="join__blockDone">
                    <Icon name="success-crew" />
                </div>
                <div className="join__blockTitle">
                    Регистрация
                    <br />
                    пройдена успешно
                </div>
                <div className="join__blockText">
                    Вы успешно прошли регистрацию по&nbsp;номеру телефона{' '}
                    {localStorage.getItem('crmJoinPhone')} и&nbsp;можете выполнять заказы.
                </div>
                {localStorage.getItem('appHashFromJoin') &&
                    process.env.REACT_APP_SYSTEM === 'app' &&
                    (hasButton || process.env.REACT_APP_ENV === 'local') && (
                        <div className="join__blockButton">
                            {renderButton({
                                name: 'to-cabinet',
                                content: 'В личный кабинет',
                                withArrow: false,
                            })}
                        </div>
                    )}
                {false && (
                    <div className="join__instruction _col">
                        <div className="join__instructionLogo">
                            <img
                                src={require('../../../img/crm/joins/app-logo.svg').default}
                                alt=""
                                className="join__instructionLogoIcon"
                            />
                        </div>

                        <div className="join__instructionTitle">
                            Добавьте наше приложение на главный экран смартфона
                        </div>
                        <AppInstruction isAll={true} />
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinFinal);

JoinFinal.propTypes = {
    user: PropTypes.object,
    renderField: PropTypes.func,
    renderButton: PropTypes.func,
    renderTelegram: PropTypes.func,
    joinScript: PropTypes.object,
    hasButton: PropTypes.bool,
};
