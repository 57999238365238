import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Alert from '../components/Alert.jsx';

import alerts from '../infos/alerts.json';
import setNotification from '../functions/setNotification';

class Alerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stack: [],
        };

        this.parent = React.createRef();

        this.setNotification = this.setNotification.bind(this);
        this.updateNotifications = this.updateNotifications.bind(this);
        this.deleteNotification = this.deleteNotification.bind(this);
        this.getTemplateNotification = this.getTemplateNotification.bind(this);
        this.handlerNotification = this.handlerNotification.bind(this);
    }

    intervalId = null;

    counterNotification = 0;

    stack = [];

    offset = 12;

    duration = 5000;

    getTemplateNotification(data) {
        const top = 0;
        const { id, callback, title, description, icon } = data;

        return {
            notification: data.notification,
            data: data.data,
            counter: this.counterNotification,
            _show: false,
            isTremor: false,
            top,
            timerId: null,
            callback,
            title,
            description,
            icon,
            id,
        };
    }

    setNotification(notification) {
        const data = this.getTemplateNotification(notification);
        const { counter } = data;

        this.setState(
            (state) => {
                state.stack.push(data);
                return state;
            },
            () => {
                setTimeout(() => {
                    this.setState(
                        (state) => {
                            const newState = { ...state };

                            newState.stack.find((item) => item.counter === counter)._show = true;
                            if (alerts[notification.notification]?.type !== 'prompt') {
                                newState.stack.find((item) => item.counter === counter).timerId =
                                    setTimeout(() => {
                                        this.deleteNotification(counter);
                                    }, this.duration);
                            }

                            return newState;
                        },
                        () => {
                            this.updateNotifications();
                        },
                    );
                }, 100);
            },
        );
    }

    deleteNotification(counter) {
        let current;

        this.setState(
            (state) => {
                // console.log(counter)
                current = state.stack.find((item) => item.counter === counter);
                if (current) {
                    current._show = false;
                    clearTimeout(current.timerId);
                }
                return state;
            },
            () => {
                this.updateNotifications();
                if (current) {
                    setTimeout(() => {
                        this.setState((state) => {
                            const newState = { ...state };
                            const itemDelete = newState.stack.find(
                                (item) => item.counter === counter,
                            );
                            const indexDelete = newState.stack.indexOf(itemDelete);

                            newState.stack.splice(indexDelete, 1);

                            return newState;
                        });
                    }, 500);
                }
            },
        );
    }

    updateNotifications() {
        this.setState((state) => {
            const newState = { ...state };

            newState.stack.forEach((item, key) => {
                const { counter } = item;
                let top = 0;

                newState.stack
                    .filter(
                        (itemCurrent) =>
                            itemCurrent.counter > counter && itemCurrent._show === true,
                    )
                    .forEach((itemNotCurrent) => {
                        top +=
                            this.parent.current.querySelector(
                                `.alerts__item[data-key="${itemNotCurrent.counter}"]`,
                            ).offsetHeight + this.offset;
                    });
                newState.stack[key].top = top;
            });
            return newState;
        });
    }

    handlerNotification({ detail }) {
        const { stateOfNotification } = this.props;
        const { notification, callback, id } = detail;

        if (
            alerts[notification] &&
            (stateOfNotification ||
                alerts[notification]?.type !== 'success' ||
                notification === 'test-notify')
        ) {
            if (
                (this.stack.find(
                    (item) => (!id || item.id === id) && item.notification === notification,
                ) ||
                    this.state.stack.find(
                        (item) => (!id || item.id === id) && item.notification === notification,
                    )) &&
                ['error', 'prompt'].indexOf(alerts[notification]?.type) !== -1
            ) {
                this.setState(
                    (state) => {
                        const current = state.stack.find(
                            (item) => item.notification === notification,
                        );

                        if (current) {
                            current.isTremor = true;
                            current.callback = callback;

                            clearTimeout(current.timerId);

                            if (alerts[notification]?.type !== 'prompt') {
                                current.timerId = setTimeout(() => {
                                    this.deleteNotification(current.counter);
                                }, this.duration);
                            }
                        }
                        return state;
                    },
                    () => {
                        setTimeout(() => {
                            this.setState((state) => {
                                const newState = { ...state };

                                if (
                                    newState.stack.find(
                                        (item) => item.notification === notification,
                                    )
                                ) {
                                    newState.stack.find(
                                        (item) => item.notification === notification,
                                    ).isTremor = false;
                                }

                                return newState;
                            });
                        }, 1000);
                    },
                );
            } else {
                this.stack.push(detail);
            }
        }
    }

    componentDidMount() {
        this.intervalId = setInterval(() => {
            const reverseStack = this.stack;

            if (reverseStack.length) {
                const current = reverseStack[0];
                this.counterNotification += 1;
                this.setNotification(current);
                reverseStack.splice(0, 1);
                this.stack = reverseStack;
            }
        }, 500);

        document.addEventListener('notification', this.handlerNotification);

        if (0) {
            setTimeout(() => {
                setNotification({
                    notification: 'executor-already-create',
                    description:
                        'Мисюкевич Федор Петрович, <span class="_noWrap">8-913-174-73-80</span>',
                });
            }, 10);
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);

        document.removeEventListener('notification', this.handlerNotification);
    }

    render() {
        const { stack } = this.state;

        return (
            <div className="body__alerts">
                <div ref={this.parent} className="alerts">
                    {stack.map((item) => (
                        <div
                            className={`alerts__item ${item._show === true ? '_show' : ''}`}
                            key={item.counter}
                            data-key={item.counter}
                            style={{ transform: `translate(0,${item.top}px)` }}
                        >
                            <div className="alerts__itemInner">
                                <div className="alerts__itemBox">
                                    <Alert
                                        name={item.notification}
                                        notify={item.data}
                                        isTremor={item.isTremor}
                                        counter={item.counter}
                                        callback={item.callback}
                                        title={item.title}
                                        description={item.description}
                                        icon={item.icon}
                                        deleteNotification={this.deleteNotification}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        stateOfNotification: state.stateOfNotification,
    };
}

export default connect(mapStateToProps)(Alerts);

Alerts.propTypes = {
    stateOfNotification: PropTypes.bool,
};
