import axios from 'axios';

import mkad from '../infos/mkad.json';
import ttkArea from '../infos/ttk.json';
import skArea from '../infos/sk.json';
import getInfoRoute from './order/getInfoRoute';

function geocode(ymaps, coords) {
    return new Promise((resolve, reject) => {
        try {
            if (!ymaps) {
                resolve(null);
            } else {
                ymaps.geocode(coords).then(
                    (result) => {
                        resolve(result.geoObjects.get(0));
                    },
                    () => null,
                );
            }
        } catch (error) {
            reject();
        }
    });
}

function getHouse(res) {
    return res.properties._data?.metaDataProperty?.GeocoderMetaData?.Address?.Components?.find(
        (item) => item.kind === 'house',
    )?.name;
}

function getCity(response) {
    return (
        response.properties._data?.metaDataProperty?.GeocoderMetaData?.Address?.Components?.find(
            (item) => item.kind === 'locality',
        )?.name ||
        response.properties._data?.metaDataProperty?.GeocoderMetaData?.Address?.Components?.find(
            (item) => item.kind === 'area',
        )?.name ||
        response.properties._data?.metaDataProperty?.GeocoderMetaData?.Address?.Components?.find(
            (item) => item.kind === 'province',
        )?.name
    );
}

function findPointWords(ymaps, word) {
    return new Promise((resolve, reject) => {
        if (ymaps) {
            axios
                .get(
                    `https://suggest-maps.yandex.ru/v1/suggest?apikey=4cc31502-76c0-4900-843d-4b56d8ee721f&text=${word}`,
                )
                .then((res) => {
                    if (res?.data?.results) {
                        resolve(
                            res.data.results.map((item) => {
                                let displayName = '';

                                if (item.subtitle?.text) {
                                    displayName = item.subtitle.text;
                                }

                                if (item.title.text) {
                                    if (displayName) {
                                        displayName += ', ';
                                    }

                                    displayName += item.title.text;
                                }

                                return { displayName };
                            }),
                        );
                    } else {
                        reject();
                    }
                });
        }
    });
}

function checkNameInMoscow(nameRes) {
    const name = nameRes.toLowerCase();

    return name.indexOf('москва') !== -1 || name.indexOf('московская') !== -1;
}

function checkInMoscow(ymaps, points, isNew) {
    return new Promise((resolve) => {
        if (!ymaps || !isNew) {
            geocode(ymaps, points[0]).then(
                (response) => {
                    const city = getCity(response);

                    resolve({ city });
                },
                () => null,
            );
        } else {
            const geoAll = [];

            points.forEach((point) => {
                geoAll.push(geocode(ymaps, point));
            });

            Promise.all(geoAll).then(
                (response) => {
                    let inMoscow = false;

                    response.forEach((resp) => {
                        const name = resp.properties._data.text.toLowerCase();

                        if (checkNameInMoscow(name)) {
                            inMoscow = true;
                        }
                    });

                    const city = getCity(response[0]);

                    resolve({ inMoscow, city });
                },
                () => null,
            );
        }
    });
}

function pointInArea(ymaps, map, coords) {
    const ttk = {
        area: ttkArea.coordinates,
        polygon: null,
        include: false,
    };
    const sk = {
        area: skArea.coordinates,
        polygon: null,
        include: false,
    };

    sk.polygon = new ymaps.Polygon(sk.area);
    sk.polygon.options.set('visible', false);

    map.geoObjects.add(sk.polygon);

    sk.include = sk.polygon.geometry.contains(coords);

    map.geoObjects.remove(sk.polygon);

    if (!sk.include) {
        ttk.polygon = new ymaps.Polygon(ttk.area);
        ttk.polygon.options.set('visible', false);

        map.geoObjects.add(ttk.polygon);

        ttk.include = ttk.polygon.geometry.contains(coords);

        map.geoObjects.remove(ttk.polygon);
    }

    return { ttk: ttk.include, sk: sk.include };
}

function setPosition(ymaps, map, points) {
    const marks = [];

    // eslint-disable-next-line
    for (const point in points) {
        marks[point] = new ymaps.Placemark(
            points[point],
            {},
            {
                preset: '',
            },
        );
        map.geoObjects.add(marks[point]);
        map.setBounds(map.geoObjects.getBounds());
    }
    marks.forEach((mark) => {
        map.geoObjects.remove(mark);
    });
}

function checkPointInMkad(ymaps, map, point) {
    return new Promise((resolve) => {
        const moscowPolygon = new ymaps.Polygon(mkad.coordinates);

        moscowPolygon.options.set('visible', false);
        map.geoObjects.add(moscowPolygon);

        resolve({
            coords: point,
            isContain: moscowPolygon.geometry.contains(point),
            coordsForClosestPoint: moscowPolygon.geometry.getClosest(point).position,
        });

        map.geoObjects.remove(moscowPolygon);
    });
}

function getDistanceToMkad(ymaps, map, point) {
    return new Promise((resolve) => {
        const moscowPolygon = new ymaps.Polygon(mkad.coordinates);

        moscowPolygon.options.set('visible', false);
        map.geoObjects.add(moscowPolygon);

        const needPoint = moscowPolygon.geometry.getClosest(point);

        ymaps.route([point, needPoint.position]).then(
            (route) => {
                resolve(route.getLength());
            },
            () => null,
        );

        map.geoObjects.remove(moscowPolygon);
    });
}

function getMkad(ymaps, map, coords) {
    return new Promise((resolve, reject) => {
        let moscowPolygon;

        ymaps.route(coords).then((res) => {
            const pathsObjects = ymaps.geoQuery(res.getPaths());
            const edges = [];

            pathsObjects.each((path) => {
                const coordinates = path.geometry.getCoordinates();

                // eslint-disable-next-line
                for (let i = 1, l = coordinates.length; i < l; i++) {
                    edges.push({
                        type: 'LineString',
                        coordinates: [coordinates[i], coordinates[i - 1]],
                    });
                }
            });
            const routeObjects = ymaps.geoQuery(edges).setOptions('opacity', 1).addToMap(map);

            moscowPolygon = new ymaps.Polygon(mkad.coordinates);
            moscowPolygon.options.set('visible', false);
            map.geoObjects.add(moscowPolygon);

            const objectsInMoscow = routeObjects.searchInside(moscowPolygon);
            const boundaryObjects = routeObjects.searchIntersect(moscowPolygon);
            const arr = routeObjects.remove(objectsInMoscow).remove(boundaryObjects);
            let length = 0;

            arr._objects.forEach((point) => {
                length += point.geometry.getDistance();
            });
            length = parseInt(length / 1000, 10);

            const checkMkad = new Promise((resolveMkad, rejectMkad) => {
                const checkFirstAndLastPoint = new Promise((resolvePoint, rejectPoint) => {
                    Promise.all([
                        checkPointInMkad(ymaps, map, coords[0]),
                        checkPointInMkad(ymaps, map, coords[coords.length - 1]),
                    ]).then((resPoints) => {
                        resolvePoint(resPoints);
                    }, rejectPoint);
                });

                checkFirstAndLastPoint.then((dataOfPoints) => {
                    const findLenToMkad = [];

                    dataOfPoints.forEach((point) => {
                        if (point.isContain === false) {
                            findLenToMkad.push(
                                new Promise((resolveLenToMkad) => {
                                    getDistanceToMkad(ymaps, map, point.coords).then((dist) => {
                                        resolveLenToMkad(parseInt(dist / 1000, 10));
                                    });
                                }),
                            );
                        }
                    });

                    Promise.all(findLenToMkad).then((resFinal) => {
                        const len = resFinal.reduce((sum, item) => sum + item, 0);

                        resolveMkad(len);
                    }, rejectMkad);
                }, rejectMkad);
            });

            checkMkad.then((mkadToPoints) => {
                resolve({ mkadLen: length, mkadToPoints });
                map.geoObjects.remove(moscowPolygon);
                routeObjects.removeFromMap(map);
            }, reject);
        }, reject);
    });
}

function addRoute({
    ymaps,
    map,
    points,
    isForCalc = false,
    routePrev,
    isNew,
    isClear,
    withSetCenter = true,
    strokeWidth = process.env.REACT_APP_SYSTEM === 'app' ? 3 : 2,
    color = '#3e6aed',
    isPointHide,
}) {
    let info;

    return new Promise((resolve, reject) => {
        try {
            if (!ymaps || !map) {
                reject();
            } else if (points.length === 0) {
                if (routePrev) {
                    map.geoObjects.remove(routePrev);
                }
            } else if (points.length === 1) {
                const resYmaps = ymaps;

                const myPlacemark = new resYmaps.Placemark(points[0]);

                if (routePrev) {
                    map.geoObjects.remove(routePrev);
                }

                map.geoObjects.add(myPlacemark);

                if (withSetCenter) {
                    map.setCenter(points[0], 13, {
                        checkZoomRange: true,
                    });
                }

                checkInMoscow(ymaps, points, isNew).then(
                    (response) => {
                        resolve({
                            ...getInfoRoute(0, 0),
                            ...{ inMoscow: response.inMoscow, city: response.city },
                            routePrev: myPlacemark,
                        });
                    },
                    () => null,
                );
            } else {
                ymaps.route(points).then(
                    (route) => {
                        route.getPaths().options.set({
                            strokeColor: color,
                            strokeWidth: isForCalc ? 0 : strokeWidth,
                            opacity: isForCalc ? 0 : 1,
                        });

                        if (isPointHide) {
                            points.forEach((point, key) => {
                                route.getWayPoints().get(key).options.set('visible', false);
                            });
                        }

                        if (isForCalc !== true && routePrev) {
                            map.geoObjects.remove(routePrev);
                        }

                        map.geoObjects.add(route);

                        info = getInfoRoute(
                            route.getLength(),
                            (route.getJamsTime() +
                                ((!isClear && points.length > 1 && points.length * 30 * 60) || 0)) /
                                60,
                        );

                        checkInMoscow(ymaps, points, isNew).then((response) => {
                            info.inMoscow = response.inMoscow;
                            info.city = response.city;
                            info.clearedDuration = +route.getJamsTime().toFixed(0);

                            if (response.inMoscow === true) {
                                getMkad(ymaps, map, points).then((resMkad) => {
                                    const { mkadLen, mkadToPoints } = resMkad;

                                    resolve({
                                        ...info,
                                        distance: info.distance,
                                        mkad: mkadLen,
                                        mkadToPoints,
                                        routePrev: route,
                                    });
                                });
                            } else {
                                resolve({ ...info, routePrev: route });
                            }
                        }, reject);

                        if (isForCalc === true) {
                            map.geoObjects.remove(route);
                        } else if (withSetCenter) {
                            setPosition(ymaps, map, points);
                        }
                    },
                    () => null,
                );
            }
        } catch (error) {
            reject();
        }
    });
}

function optimizationRoutes(ymaps, map, pointsRes, notGetFirst = false) {
    const points = [...pointsRes];

    return new Promise((resolve) => {
        /* eslint-disable */
        function combinate(e) {
            for (var g = [], b = e.length, h = Math.pow(b, b - 2); ; h++) {
                var d = h.toString(b),
                    a,
                    c = d.length;
                if (c > b) break;
                c < b && (d = 0 + d);
                var f = [];
                for (c = 0; c < b; c++) {
                    a = parseInt(d[c], b);
                    f.push(e[a]);
                }
                e.every(function (b) {
                    return 0 <= f.indexOf(b);
                }) && g.push(f);
            }
            return g;
        }

        const getBestRoute = (pointsInner) => {
            const best = { distance: Infinity, points: null };

            return new Promise((resolveInner) => {
                let count = 0;

                pointsInner.forEach((stroke) => {
                    const coordsItems = stroke.map((item) => item.coords);
                    let distance = 0;

                    coordsItems
                        .filter((item, key) => key !== coordsItems.length - 1)
                        .forEach((coords, key) => {
                            const nextCoords = coordsItems[key + 1];

                            distance += Math.sqrt(
                                Math.pow(coords[0] - nextCoords[0], 2) +
                                    Math.pow(coords[1] - nextCoords[1], 2),
                            );
                        });

                    if (distance < best.distance) {
                        best.distance = +distance;
                        best.points = stroke.filter((item, key) => !notGetFirst || key !== 0);
                    }

                    count += 1;

                    if (count === pointsInner.length) {
                        resolveInner(best);
                    }

                    // ymaps.route(stroke.map((item) => item.coords)).then((route) => {
                    //     route.getPaths().options.set({
                    //         strokeColor: '#000',
                    //         opacity: 0,
                    //     });

                    //     map.geoObjects.add(route);
                    //     if (+route.getLength().toFixed(1) < best.distance) {
                    //         best.distance = +route.getLength().toFixed(1);
                    //         best.points = stroke;
                    //     }
                    //     count += 1;
                    //     if (count === pointsInner.length) {
                    //         resolveInner(best);
                    //     }
                    //     map.geoObjects.remove(route);
                    // });
                });
            });
        };

        const firstPoint = points.shift();

        const pointsOrder = [];

        pointsOrder.push(firstPoint);

        for (let i = 0; i < points.length; i++) {
            const best = {
                distance: Infinity,
                point: null,
            };

            points
                .filter((point) => !pointsOrder.find((innerPoint) => innerPoint._id === point._id))
                .forEach((point) => {
                    const lastPoint = pointsOrder[pointsOrder.length - 1];
                    const distance = Math.sqrt(
                        Math.pow(point.coords[0] - lastPoint.coords[0], 2) +
                            Math.pow(point.coords[1] - lastPoint.coords[1], 2),
                    );

                    if (distance < best.distance) {
                        best.distance = distance;
                        best.point = point;
                    }
                });

            pointsOrder.push(best.point);
        }

        console.log(pointsOrder);

        // getBestRoute(pointsOrder).then((response) => resolve(response));
        resolve({ points: pointsOrder });
    });
}

function shortNameaddress(name, stop) {
    let str = '';
    // console.log(name.length, stop);
    if (name.length > stop) {
        // eslint-disable-next-line
        for (let i = 0; i < stop; i++) {
            str += name[i];
        }
        str += '...';
    } else {
        str = name;
    }
    // console.log(str);
    return str;
}

export {
    geocode,
    findPointWords,
    addRoute,
    getMkad,
    setPosition,
    optimizationRoutes,
    checkInMoscow,
    pointInArea,
    shortNameaddress,
    getCity,
    checkPointInMkad,
    getDistanceToMkad,
    checkNameInMoscow,
    getHouse,
};
