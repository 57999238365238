import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import changePage from '../../functions/changePage';
import getCurrentPage from '../../functions/getCurrentPage';

import showStartPopups from '../../functions/app/showStartPopups';

import Back from '../../components/app/Back.jsx';
import HeadPage from '../../components/app/HeadPage.jsx';
import ListAbsoluteMain from '../../components/ListAbsoluteMain.jsx';
import Inner from './notifications/Inner.jsx';
import getPageLink from '../../functions/getPageLink';

class Pays extends HeadPage {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderPage = this.renderPage.bind(this);
    }

    tabsOrder = ['new', 'completed'];

    tabs = {
        new: {
            content: () => {
                const { user } = this.props;

                return `Новые (${user.notificationsCounter || 0})`;
            },
            href: 'notifications-new',
        },
        completed: {
            content: 'Просмотренные',
            href: 'notifications-completed',
        },
    };

    changeTab(name) {
        const tab = this.tabs[name];
        const { href } = tab;

        changePage({ href: getPageLink({ name: href }) });
    }

    checkCurrentTab(name) {
        const { pagesStore } = this.props;
        const currentPage = getCurrentPage({
            page: pagesStore,
            filter: (page) => page.parentName === 'notifications',
        });
        const tab = this.tabs[name];

        const { href } = tab;

        if (currentPage) {
            this.currentPage = currentPage;
        }

        return this.currentPage === href;
    }

    pagesOrder = ['notifications-new', 'notifications-completed'];

    pages = {
        'notifications-new': {
            render() {
                return (
                    <>
                        <Inner name="new" />
                    </>
                );
            },
        },
        'notifications-completed': {
            render() {
                return (
                    <>
                        <Inner name="completed" />
                    </>
                );
            },
        },
    };

    getPages() {
        const { pagesStore } = this.props;
        const currentPage = getCurrentPage({
            page: pagesStore,
            filter: (page) => page.parentName === 'notifications',
        });

        if (currentPage) {
            this.currentPage = currentPage;
        }

        return [{ name: this.currentPage }];
    }

    renderPage({ prop: name }) {
        const page = this.pages[name];

        return <div className="pays__page">{page.render.call(this)}</div>;
    }

    componentDidMount() {
        super.componentDidMount();

        showStartPopups();
    }

    render() {
        const { pagesStore } = this.props;
        const currentPage = getCurrentPage({
            page: pagesStore,
            filter: (page) => page.parentName === 'notifications',
        });

        return (
            <div ref={this.parent} className="headPage">
                <div className="headPage__head">
                    <div className="pageHead _col">
                        <div className="pageHead__top _row">
                            <div className="pageHead__back">
                                <Back
                                    handler={() => {
                                        changePage({ href: '' });
                                    }}
                                />
                            </div>
                            <div className="pageHead__title">Уведомления</div>
                        </div>
                        {this.renderTabs()}
                    </div>
                </div>
                <div className="headPage__inner">
                    <div className="pays">
                        <ListAbsoluteMain
                            className="pays__pages"
                            items={this.getPages()}
                            renderItem={this.renderPage}
                            classNameItem="pays__page"
                            prop="name"
                            paramsParent={{ width: true }}
                            styles={[]}
                            isNotParamsItem={true}
                            currentItemKey={currentPage}
                            allItems={this.pagesOrder}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        pagesStore: state.pages,
    };
}

export default connect(mapStateToProps)(Pays);

Pays.propTypes = {
    user: PropTypes.object,
    pagesStore: PropTypes.object,
};
