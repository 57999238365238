import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getPays from '../../../requests/getPays';

import ListScroll from '../../../components/ListScroll.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';

import Table from '../../../components/crm/manual/Table.jsx';
import Animate from '../../../components/Animate.jsx';
import Loader from '../../../components/Loader.jsx';
import removeTransition from '../../../functions/removeTransition.ts';
import PayPreview from '../../../components/app/pays/PayPreview.jsx';
import Button from '../../../components/Button.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';

class PaysInner extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderCard = this.renderCard.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.changePays = this.changePays.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.setUpdate = this.setUpdate.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 8;

    renderCard({ item }) {
        return (
            <div className="pays__pagePreview">
                <div className="pays__pagePreviewInner _col">
                    <PayPreview setUpdate={this.setUpdate} {...item} />
                </div>
            </div>
        );
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    getItems(isForce = false) {
        const { name } = this.props;
        const query = this.getQueryForRequest();

        if (name !== 'all') {
            query.params.push({ key: 'type', value: name });
        }

        return new Promise((resolve) => {
            getPays(query).then(
                ({ pays, isLimit }) => {
                    if (!this.state.isInit) {
                        setTimeout(() => {
                            this.setState({ isInit: true });
                        }, 300);
                    }

                    this.setItems(pays, isForce, isLimit).then(() => {
                        removeTransition({ item: '.pays__pagePreview', isCurrent: true });

                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    setUpdate() {
        this.setState({
            updatedItemsKey: new Date().getTime(),
        });
    }

    changePays() {
        this.getItems(true);
    }

    handlerSocket({ detail }) {
        const { name, data } = detail;

        if (name === 'pays') {
            const { fields } = data;

            if (fields.item) {
                this.updateItem({ id: fields.item._id, fields: fields.item });
            }

            if (fields?.isUpdatePays) {
                this.changePays();
            }
        }
    }

    componentDidMount() {
        this.getItems();

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changePays', this.changePays);
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('changePays', this.changePays);
    }

    render() {
        const {
            isInit,
            isDisabledScroll,
            items = [],
            isLimit,
            isShowLoaderList = false,
            updatedItemKey,
            updatedItemsKey,
        } = this.state;
        const { user, name } = this.props;
        const withButton = name !== 'completed' && user.pays.length > 1;

        return (
            <div ref={this.parent} className={`pays__pageInner ${isInit ? '_init' : ''}`}>
                <Animate className="pays__pageLoader _loader" isShow={!isInit}>
                    <div className="pays__pageLoaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate className="pays__pageScrollLoader _loaderScroll" isShow={isShowLoaderList}>
                    <div className="pays__pageScrollLoaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate className="pays__pageEmpty _loader" isShow={isInit && items.length === 0}>
                    <div className="empty _col _notBack _block">
                        <div className="empty__inner">
                            <div className="empty__title">На этом пока всё</div>
                            <p className="empty__content">У вас нет актов</p>
                        </div>
                    </div>
                </Animate>
                <Animate className="pays__pageButton" isShow={withButton}>
                    <div className="pays__pageButtonInner">
                        <Button
                            className="_main"
                            onClick={() => {
                                handlerPopup({
                                    name: 'appSignPayPopup',
                                    isShow: true,
                                    payId: 'all',
                                    callback: () => {
                                        document.dispatchEvent(new CustomEvent('changePays'));
                                    },
                                });
                            }}
                        >
                            Подписать все акты
                        </Button>
                    </div>
                </Animate>
                <div className="pays__pageBox">
                    <ListScroll
                        isInit={isInit}
                        getParent={() => this.parent.current?.querySelector('.pays__pageBox')}
                        callback={this.getMoreItems}
                        startCounter={this.stepCounter}
                        stepCounter={this.stepCounter}
                        maxCounter={Infinity}
                        lengthCurrent={items.length}
                        handlerLoaderList={this.handlerLoaderList}
                        isLimit={isLimit}
                        isDisabledScroll={isDisabledScroll || !isInit}
                    >
                        <ListAbsoluteMain
                            className="pays__pagePreviews _col"
                            items={items}
                            renderItem={this.renderCard}
                            classNameItem="pays__pagePreview"
                            prop="_id"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            isSmoothShow={true}
                            keyUpdateItem={updatedItemKey}
                            keyRender={updatedItemsKey}
                            offsetHeight={withButton ? 76 : 0}
                        />
                    </ListScroll>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(PaysInner);

PaysInner.propTypes = {
    user: PropTypes.object,
    name: PropTypes.string,
};
