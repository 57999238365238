import { dispatcher } from '../redux/redux';

export default function handlerTheme(action) {
    switch (action) {
        case 'show':
            dispatcher({ type: 'stateTheme', data: 0 });
            setTimeout(() => {
                dispatcher({ type: 'stateTheme', data: 1 });
            }, 20);
            break;
        case 'hide':
            dispatcher({ type: 'stateTheme', data: 0 });
            setTimeout(() => {
                dispatcher({ type: 'stateTheme', data: -1 });
            }, 500);
            break;

        default:
            break;
    }
}