import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class JoinForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getFieldsOrder() {
        const { joinScript } = this.props;
        const { executorOrganization } = joinScript;

        const fieldsOrder = ['secondName', 'firstName', 'thirdName', 'telegram'];

        if (!['IP', 'SMZ'].includes(executorOrganization)) {
            fieldsOrder.push('inn');
        }

        return fieldsOrder;
    }

    render() {
        const { renderField, renderButton } = this.props;

        return (
            <div className="join__blockInner _col">
                <div className="join__blockTitle">
                    Укажите ваши
                    <br />
                    личные данные
                </div>
                <div className="join__blockText">
                    Заполните все поля по порядку
                    <br />
                    сверху — вниз
                </div>
                <div className="join__blockFields">
                    {this.getFieldsOrder().map((name) => renderField({ name }))}
                </div>
                <div className="join__blockText">Выберите из списка:</div>
                <div className="join__blockFields">
                    {['category', 'city', 'nationality'].map((name) => renderField({ name }))}
                </div>
                {renderButton({ name: 'anket', content: 'Продолжить' })}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinForm);

JoinForm.propTypes = {
    user: PropTypes.object,
    renderField: PropTypes.func,
    renderButton: PropTypes.func,
    joinScript: PropTypes.object,
};
