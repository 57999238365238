import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '../../Button.jsx';

import getFormatPrice from '../../../functions/getFormatPrice';
import getHeaders from '../../../functions/getHeaders';

import handlerPopup from '../../../functions/app/handlerPopup';

class OrderFinal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.completeOrder = this.completeOrder.bind(this);
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    completeOrder() {
        const { order } = this.props;

        return new Promise((resolve) => {
            this.handlerLoading('complete').then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/order`,
                        {
                            type: 'actions',
                            action: 'complete',
                            id: order._id,
                        },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (!success) {
                                this.handlerLoading(null);
                            }

                            resolve();
                        },
                        () => null,
                    );
            });
        });
    }

    getPayDescription() {
        const { order } = this.props;

        if (order.pay === 'cash') {
            return 'наличными';
        }

        if (order.pay === 'noCash') {
            return 'безналичным расчётом';
        }

        if (order.pay === 'card') {
            return 'банковской картой';
        }

        return '';
    }

    componentDidMount() {
        const { isCancelPoint, isReplacePoints, addPointsLen } = this.props;

        this.setState({ isCancelPoint, isReplacePoints, addPointsLen });
    }

    render() {
        const { loadingKey, isCancelPoint, isReplacePoints, addPointsLen } = this.state;
        const { renderActions, nextPoint, handlerCurrentBlock, order, point, addPoint } =
            this.props;

        return (
            <div className="appOrder__final _col">
                {['wait-close', 'complete'].includes(order.status) ? (
                    <>
                        <div className="appOrder__finalTitle">
                            Заказ успешно
                            <br />
                            завершён!
                        </div>
                        <p className="appOrder__finalDescription">
                            Оплата ({getFormatPrice(order.amount)}₽) будет произведена
                            <br />
                            {this.getPayDescription()}.
                        </p>
                        <div className="appOrder__finalButton">
                            <Button
                                onClick={this.completeOrder}
                                showLoader={loadingKey === 'complete'}
                            >
                                Завершить заказ
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        {order.route.length === 1 &&
                        order.isAcceptChangeRoute &&
                        point.status === 'complete' ? (
                            <>
                                <p className="appOrder__finalSupport">Адрес успешно закрыт</p>
                                <div className="appOrder__finalTitle">Добавьте адреса</div>
                                <p className="appOrder__finalDescription">
                                    Самостоятельно добавьте
                                    <br />
                                    необходимые точки на маршрут
                                </p>
                                <div className="appOrder__finalButton">
                                    <Button
                                        onClick={() => {
                                            handlerPopup({
                                                name: 'appNewPointPopup',
                                                isShow: true,
                                                addPoint,
                                            });
                                        }}
                                    >
                                        + Добавить точку
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <p className="appOrder__finalSupport">
                                    {isCancelPoint ? (
                                        <>Адрес успешно отменён</>
                                    ) : isReplacePoints ? (
                                        <>Адрес успешно перенесён</>
                                    ) : addPointsLen ? (
                                        <>
                                            {addPointsLen > 1 ? (
                                                <>Адреса успешно добавлены</>
                                            ) : (
                                                <> Адрес успешно добавлен</>
                                            )}
                                        </>
                                    ) : (
                                        <>Адрес успешно закрыт</>
                                    )}
                                </p>
                                <div className="appOrder__finalTitle">Пора ехать дальше</div>
                                <p className="appOrder__finalDescription">
                                    Отвезите принятый груз по адресу:
                                    <br />
                                    <b>{nextPoint.address}</b>
                                </p>
                                <div className="appOrder__finalButton">
                                    <Button
                                        onClick={() => {
                                            handlerCurrentBlock({ currentBlock: 'move' });
                                        }}
                                    >
                                        Поехали!
                                    </Button>
                                </div>
                            </>
                        )}
                    </>
                )}
                <div className="appOrder__finalActions">
                    {renderActions({ isFinal: true, point })}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(OrderFinal);

OrderFinal.propTypes = {
    user: PropTypes.object,
    renderActions: PropTypes.func,
    nextPoint: PropTypes.object,
    handlerCurrentBlock: PropTypes.func,
    point: PropTypes.object,
    order: PropTypes.object,
    isCancelPoint: PropTypes.bool,
    isReplacePoints: PropTypes.bool,
    addPoint: PropTypes.func,
    addPointsLen: PropTypes.number,
};
