import axios from 'axios';
import getHeaders from '../functions/getHeaders';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function createId() {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API}/id`, { headers: getHeaders() }).then((res) => {
            if (res?.data?.data && typeof res?.data?.data === 'object') {
                const { success, data } = res.data;

                if (success) {
                    const { id } = data;

                    resolve({ id });
                } else {
                    handlerErrorRequest(res);

                    reject();
                }
            } else {
                reject();
            }
        }, reject);
    });
}
