import { store } from '../../redux/redux';
import changePage from '../changePage';
import getPageLink from '../getPageLink';
import getOrderTimeType from './getOrderTimeType';
import handlerOrderPopup from './handlerOrderPopup';
// import handlerPopup from './handlerPopup';

const orderIntervals = {};

const showPopup = (id) => {
    handlerOrderPopup({ action: 'add', id });
};

export default function handlerFastOrder({ user }) {
    const { orders = [] } = user;
    const { appOrdersPopups, pages } = store.getState();

    orders.forEach((order) => {
        const { id } = order;
        const timeType = getOrderTimeType({ order });

        if (order.isProccess) {
            handlerOrderPopup({ id });

            if (!pages['order-chat'].isShow) {
                changePage({ href: getPageLink({ name: 'order-inner', ids: { 1: id } }) });
            }
        } else if (order.isImportant && !appOrdersPopups.includes(id) && !pages.order.isShow) {
            showPopup(id);
        } else if (order.dateOfOrder && timeType && (!pages.order.isShow || timeType !== 'fast')) {
            if (timeType === 'fast') {
                clearInterval(orderIntervals[id]);

                showPopup(id);
            } else {
                if (orderIntervals[id]) {
                    clearInterval(orderIntervals[id]);
                }

                orderIntervals[id] = setInterval(() => {
                    if (getOrderTimeType({ order }) === 'fast') {
                        clearInterval(orderIntervals[id]);

                        showPopup(id);
                    }
                }, 5_000);
            }
        }
    });
}
