import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import File from '../../classes/File';

import handlerPopup from '../../functions/handlerPopup';
import getUpdateFormData from '../../functions/getUpdateFormData';
import handlerErrorRequest from '../../functions/handlerErrorRequest';
import setNotification from '../../functions/setNotification';

import Icon from '../Icon.jsx';
import BorderDashed from '../BorderDashed.jsx';
import AnimateChange from '../AnimateChange.jsx';
import Loader from '../Loader.jsx';
import Animate from '../Animate.jsx';

class PopupCargoImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            widthInner: 0,
            widthImage: 0,
            preview: {
                state: -1,
                position: [0, 0],
            },
            files: [
                // {
                //     path: require('../../img/image.jpeg'),
                //     stateOfShow: 1,
                // },
            ],
        };

        this.setFiles = this.setFiles.bind(this);
        this.handlerUpload = this.handlerUpload.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);

        this.parent = React.createRef();
    }

    exampleLink = `${process.env.REACT_APP_STATIC}/order-example/example.xlsx`;

    setFiles(files) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };

                newState.files = files;

                return newState;
            }, resolve);
        });
    }

    setLoader(isLoad) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };

                newState.isLoad = isLoad;

                return newState;
            }, resolve);
        });
    }

    counterFiles = 0;

    handlerUpload({ target }) {
        const { type, callback } = this.props;

        const formData = new FormData();

        formData.set('type', type);

        this.setLoader(true).then(() => {
            this.handlerFile.uploadFiles({ target, formData }).then(
                () => {
                    axios
                        .put(`${process.env.REACT_APP_API}/order`, getUpdateFormData(formData))
                        .then(
                            (res) => {
                                const { success, data } = res.data;

                                if (success) {
                                    const { order } = data;

                                    callback({ order }).then(() => {
                                        setNotification({
                                            notification: 'set-order-from-template',
                                        });
                                        handlerPopup({
                                            action: 'hide',
                                            name: 'popupOrderUploadTemplate',
                                        });
                                        this.setLoader(false);
                                    });
                                } else {
                                    const { message } = data;

                                    if (message === 'Template is incorrect') {
                                        setNotification({
                                            notification: 'incorrect-template-excel',
                                        });
                                    }

                                    this.setLoader(false);

                                    handlerErrorRequest(res);
                                }
                            },
                            () => null,
                        );
                },
                () => this.setLoader(false),
            );
        });
    }

    handlerMissClick({ target }) {
        const inner = this.parent.current.querySelector('.popup__inner');

        if (inner && target !== inner && !inner.contains(target)) {
            handlerPopup({
                action: 'hide',
                name: 'popupOrderUploadTemplate',
            });
        }
    }

    isInitDrag = false;

    initDrag() {
        if (!this.isInitDrag) {
            const area = this.parent.current.querySelector('.popup__upload');

            const handlerOver = () => this.setState({ dragIsActive: true });
            const handlerLeave = () => this.setState({ dragIsActive: false });
            const handlerDrop = (files) => {
                this.handlerUpload({ target: { files } });
                this.setState({ dragIsActive: false });
            };

            this.handlerFile.setDrag({ area, handlerOver, handlerLeave, handlerDrop });

            this.isInitDrag = true;
        }
    }

    componentDidMount() {
        const input = this.parent.current.querySelector('.popup__uploadInput');

        this.handlerFile = new File({
            typeOfFiles: 'excel',
            input,
            checkDisabledDrag: () => this.state.isLoad,
        });

        this.initDrag();

        document.addEventListener('mouseup', this.handlerMissClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handlerMissClick);
    }

    render() {
        const { files, isLoad, preview, dragIsActive } = this.state;
        const { state } = this.props;
        const findDelete = files.filter((file) => file.isDelete);

        return (
            <div ref={this.parent} className={`body__popup ${state === 1 ? '_show' : ''} `}>
                <div
                    className={`popup _col _uploadTemplate ${preview.state === 1 ? '_showPreview' : ''}`}
                >
                    <div className="popup__inner _col">
                        <i
                            className="popup__close _click"
                            onClick={() => {
                                handlerPopup({ action: 'hide', name: 'popupOrderUploadTemplate' });
                            }}
                        >
                            <Icon name="delete-circle" />
                        </i>
                        <div className="popup__innerBox _col">
                            <h3 className="popup__title">Оформите заказ из одного файла</h3>
                            <p className="popup__description">
                                Скачайте наш шаблон, заполните в нём все данные и загрузите
                                заполненный файл сюда — больше не придётся вводить данные заказа
                                вручную
                            </p>
                            <a
                                href={this.exampleLink}
                                download={this.exampleLink}
                                className="popup__button _row _click"
                            >
                                Скачать шаблон для заполнения
                                <i className="popup__buttonIcon">
                                    <Icon name="file-type-doc" />
                                </i>
                            </a>
                            <div className="popup__actions">
                                <div
                                    className={`popup__actionsInner ${
                                        findDelete ? '_isDelete' : ''
                                    }`}
                                >
                                    <label
                                        className={`popup__upload ${dragIsActive ? '_drag' : ''}`}
                                    >
                                        <input
                                            type="file"
                                            className="popup__uploadInput"
                                            onChange={this.handlerUpload}
                                            disabled={isLoad}
                                            multiple
                                        />
                                        <div className="popup__uploadBack">
                                            <BorderDashed className="_upload" />
                                        </div>
                                        <Animate
                                            className="popup__uploadView _col _click"
                                            isShow={!dragIsActive}
                                        >
                                            <div className="popup__uploadIcon">
                                                <AnimateChange
                                                    prop={isLoad}
                                                    className={`popup__uploadIconItem`}
                                                    type="_loader"
                                                >
                                                    <>
                                                        {isLoad ? (
                                                            <div className="popup__uploadIconItem _isLoad">
                                                                <Loader className="_main" />
                                                            </div>
                                                        ) : (
                                                            <i className="popup__uploadIconItem">
                                                                <Icon name="file-upload" />
                                                            </i>
                                                        )}
                                                    </>
                                                </AnimateChange>
                                            </div>
                                            <p className="popup__uploadDescription">
                                                Загрузите
                                                <br />
                                                или перетащите <br />
                                                сюда <span>заполненный файл</span>
                                            </p>
                                        </Animate>
                                        <Animate
                                            className="popup__uploadDrag _col"
                                            isShow={dragIsActive}
                                        >
                                            <div className="popup__uploadIcon">
                                                <i className="popup__uploadIconItem">
                                                    <Icon name="file-drag" />
                                                </i>
                                            </div>
                                            <p className="popup__uploadDescription">
                                                <span>Отпустите</span> <br />
                                                курсор мыши <br />
                                                для загрузки файла
                                            </p>
                                        </Animate>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(PopupCargoImages);

PopupCargoImages.propTypes = {
    state: PropTypes.number,
    type: PropTypes.string,
    callback: PropTypes.func,
};
