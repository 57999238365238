import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import createChat from '../../../functions/app/createChat';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';
import LinkBtn from '../LinkBtn.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import getFormatPrice from '../../../functions/getFormatPrice';
import getFormatedDate from '../../../functions/getFormatedDate';
import setSpacesInText from '../../../functions/setSpacesInText';
import changePage from '../../../functions/changePage';

class Balance extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
        this.openChat = this.openChat.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appBalancePopup', isShow: false });
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    openChat() {
        const { card } = this.props;
        const { otherInfo } = card;
        const { idOfOrder } = otherInfo;

        return new Promise((resolve) => {
            this.handlerLoadingKey('chat').then(() => {
                createChat({ idOfOrder }).then(
                    () => {
                        this.handlerLoadingKey(null).then(() => {
                            this.hide();

                            resolve();
                        });
                    },
                    () => null,
                );
            });
        });
    }

    render() {
        const { loadingKey } = this.state;
        const { card } = this.props;
        const { date, balanceMove, nameContent, typeContent, info } = card;
        let content = nameContent;

        if (info) {
            Object.keys(info).forEach((key) => {
                if (content.indexOf(key) !== -1) {
                    const regExp = new RegExp(`\\|${key}\\|`, 'gim');
                    let replacer = info[key];

                    if (['date', 'startPeriod', 'endPeriod'].includes(key)) {
                        replacer = getFormatedDate({ date: new Date(info[key]) });
                    }

                    if (key === 'number') {
                        replacer = `№${replacer}`;
                    }

                    content = content.replace(regExp, replacer);
                }
            });
        }

        if (info?.number) {
            content = content.replace('|number|', info?.number);
        }

        let numberSupport = '';

        if (card.logType === 'pay') {
            numberSupport = 'акта';
        } else {
            numberSupport = 'заказа';
        }

        return (
            <div ref={this.parent} className="appPopup _col _balance">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className={`appPopup__title ${balanceMove < 0 ? '_minus' : ''}`}>
                                {balanceMove > 0 ? '+' : ''}
                                {getFormatPrice(balanceMove)}₽
                            </div>
                            <p
                                className="appPopup__description"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(content) }}
                            ></p>
                            <div className="appPopup__balance">
                                <div className="appPopup__balanceRow _row">
                                    <div className="appPopup__balanceCol">Дата операции:</div>
                                    <div className="appPopup__balanceCol _value">
                                        {getFormatedDate({ date: new Date(date), type: 'full' })}
                                    </div>
                                </div>
                                <div className="appPopup__balanceRow _row">
                                    <div className="appPopup__balanceCol">Тип оплаты:</div>
                                    <div className="appPopup__balanceCol _value">{typeContent}</div>
                                </div>
                                <div className="appPopup__balanceRow _row">
                                    <div className="appPopup__balanceCol">
                                        Номер {numberSupport}:
                                    </div>
                                    <div className="appPopup__balanceCol _value">
                                        №{info?.number}
                                    </div>
                                </div>
                            </div>
                            {card.logType === 'pay' ? (
                                <>
                                    <div className="appPopup__button">
                                        <Button
                                            onClick={() => {
                                                changePage(
                                                    {
                                                        href:
                                                            card.name === 'pay-of-act'
                                                                ? 'pays-process'
                                                                : 'pays-completed',
                                                    },
                                                    () => null,
                                                );

                                                this.hide();
                                            }}
                                        >
                                            Перейти к акту
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="appPopup__button">
                                        <Button
                                            onClick={() => {
                                                changePage({
                                                    href: 'order-details-inner',
                                                    id: card.otherInfo.idOfOrder,
                                                });

                                                this.hide();
                                            }}
                                        >
                                            Детали заказа №{info?.number}
                                        </Button>
                                    </div>
                                </>
                            )}

                            <div className="appPopup__link">
                                <LinkBtn
                                    className="_alert _bold"
                                    showLoader={loadingKey === 'chat'}
                                    handler={this.openChat}
                                >
                                    Нужна помощь
                                </LinkBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(Balance);

Balance.propTypes = {
    heightWindow: PropTypes.number,
    card: PropTypes.object,
};
