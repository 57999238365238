import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../../Button.jsx';
import Field from '../../../Field.jsx';
import ListAbsoluteMain from '../../../ListAbsoluteMain.jsx';

class OrderDocsCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: {
                value: '',
            },
        };

        this.handlerCode = this.handlerCode.bind(this);
        this.renderError = this.renderError.bind(this);
    }

    getErrors() {
        const { errors } = this.props;
        const resultErrors = [];

        errors
            .filter((error) => error && error.indexOf('code') !== -1)
            .forEach((error) => {
                resultErrors.push(error);
            });

        return resultErrors.map((error) => ({ key: error }));
    }

    errors = {
        'code-empty': 'Пустой код закрытия',
        'code-incorrect': 'Неверный код закрытия',
    };

    renderError({ prop: key }) {
        const errorContent = this.errors[key];

        return <div className="appOrderDocs__errorItem">{errorContent}</div>;
    }

    handlerCode({ action, value }) {
        const { handlerErrors } = this.props;

        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const code = JSON.parse(JSON.stringify(newState.code));

                        code.value = value;
                        code.error = null;

                        newState.code = code;

                        return newState;
                    },
                    () => {
                        handlerErrors({});

                        resolve();
                    },
                );
            } else {
                resolve();
            }
        });
    }

    closePoint() {
        const { code } = this.state;
        const { handlerPoint } = this.props;

        handlerPoint({ status: 'complete', code: code.value });
    }

    render() {
        const { code } = this.state;
        const { loadingKey, setUpdateKey, order } = this.props;

        return (
            <div className="appOrderDocs _col">
                {order.deliveryType === 'delivery' ? (
                    <>
                        <div className="appOrderDocs__title">
                            Теперь введите
                            <br />
                            код закрытия
                        </div>
                        <div className="appOrderDocs__description">
                            Возьмите код закрытия у клиента
                            <br />и введите его в поле ниже
                        </div>
                        <div className="appOrderDocs__field">
                            <Field
                                type="app"
                                keyName="pointCode"
                                name="pointCode"
                                value={code?.value}
                                error={this.getErrors().length > 0}
                                callback={this.handlerCode}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="appOrderDocs__title">
                            Теперь
                            <br />
                            закройте адрес
                        </div>
                        <div className="appOrderDocs__description">После получения груза</div>
                    </>
                )}

                <ListAbsoluteMain
                    className="appOrderDocs__error"
                    items={this.getErrors()}
                    renderItem={this.renderError}
                    classNameItem="appOrderDocs__errorItem"
                    prop="key"
                    styles={['height']}
                    callback={({ type, isInit }) => {
                        if (type === 'parent' || isInit) {
                            setUpdateKey();
                        }
                    }}
                    isNotParamsItem={true}
                />
                <div className="appOrderDocs__button">
                    <Button
                        onClick={() => {
                            this.closePoint();
                        }}
                        showLoader={loadingKey === 'complete'}
                    >
                        Закрыть адрес
                    </Button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(OrderDocsCode);

OrderDocsCode.propTypes = {
    user: PropTypes.object,
    order: PropTypes.object,
    setUpdateKey: PropTypes.func,
    name: PropTypes.string,
    handlerCurrentBlock: PropTypes.func,
    handlerPoint: PropTypes.func,
    loadingKey: PropTypes.string,
    errors: PropTypes.array,
    handlerErrors: PropTypes.func,
};
