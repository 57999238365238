export default function getWidthText({
    text,
    size = 16,
    family = 'PTRoot',
    weight = 500,
    letterSpacing = 0,
    isInput = false,
}) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    let width;

    context.font = `${weight} ${size}px ${family}`;

    // console.log(context);

    width = context.measureText(text).width;

    if (letterSpacing) {
        width += (text.length - 1) * letterSpacing;
    }

    return Math.round(width) + ((isInput && 2) || 0);
}
