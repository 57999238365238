export default function getCurrentCorporation({ user, isInfo = true }) {
    if (user) {
        const { idOfCurrentCorporation } = user;

        if (!isInfo || process.env.REACT_APP_SYSTEM === 'app') {
            return user.corporations.find((corp) => corp.id === idOfCurrentCorporation);
        }

        return user.corporationsInfo.find((corp) =>
            idOfCurrentCorporation === 'admin' ? corp.isAdmin : corp._id === idOfCurrentCorporation,
        );
    }

    return null;
}
