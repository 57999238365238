import { dispatcher } from '../redux/redux';

export default class WindowFix {
    constructor() {
        this.fixFlag = false;
        this.scroll = window.pageYOffset;

        document.addEventListener('scroll', () => {
            if (!this.fixFlag) {
                this.scroll = window.pageYOffset;
            }
        });
    }

    add() {
        this.fixFlag = true;
        dispatcher({ type: 'isFix', data: true });

        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                navigator.userAgent,
            )
        ) {
            // document.querySelector('body').style.top = `${-this.scroll}px`;
            // document.querySelector('body').style.position = `fixed`;
        } else {
            document.querySelector('body').style.overflow = `hidden`;
            document.querySelector('body').style.height = `100vh`;
            document.querySelector('body').scrollTo(0, this.scroll);
        }
    }

    remove() {
        this.fixFlag = false;
        dispatcher({ type: 'isFix', data: false });

        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                navigator.userAgent,
            )
        ) {
            // document.querySelector('body').style.position = `static`;
            // document.querySelector('body').style.top = `unset`;
            // document.querySelector('body').scrollTo(0, this.scroll);
            // document.querySelector('html').scrollTo(0, this.scroll);
        } else {
            document.querySelector('body').style.overflow = `visible`;
            document.querySelector('body').style.height = `auto`;
        }
    }
}
