import { dispatcher, store } from '../redux/redux';

export default function handlerPopup({ action, ...props }) {
    const { name } = props;
    const popup = store.getState()[name];
    const { windowFix } = store.getState();

    switch (action) {
        case 'show':
            windowFix.add();
            dispatcher({ type: name, data: { state: 0, ...props } }).then(() => {
                setTimeout(() => {
                    dispatcher({ type: name, data: { state: 1 } });
                }, 10);
            });
            break;
        case 'hide':
            windowFix.remove();
            dispatcher({ type: name, data: { state: 0 } }).then(() => {
                setTimeout(() => {
                    dispatcher({ type: name, data: { state: -1 }, isClear: true });
                }, 500);
            });
            break;
        default:
            if (popup.state === 1) {
                handlerPopup({ action: 'hide', ...props });
            } else {
                handlerPopup({ action: 'show', ...props });
            }
            break;
    }
}
