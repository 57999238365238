import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import getHeaders from '../../../functions/getHeaders';

import Button from '../../Button.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import DragBox from '../../DragBox.jsx';
import setHeightItems from '../../../functions/setHeightItems';
import Point from './orderPoint/Point.jsx';
import removeTransition from '../../../functions/removeTransition.ts';
import Icon from '../../Icon.jsx';

class OrderPoint extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: 'start',
        };

        this.renderBlock = this.renderBlock.bind(this);
        this.handlerLoadingKey = this.handlerLoadingKey.bind(this);
        this.save = this.save.bind(this);
        this.setHeightItems = this.setHeightItems.bind(this);
        this.callbackOrder = this.callbackOrder.bind(this);

        this.hide = this.hide.bind(this);
    }

    offset = 3;

    classItem = '.appPopupPoint__point';

    classItemStatic = '.appPopupPoint__pointWrapper';

    cancelReasons = ['Груз в ненадлежащем виде', 'Клиент отменил', 'Отсутствуют документы'];

    setHeightItems() {
        const { points } = this.state;

        setHeightItems.call(this, {
            parent: this.parent.current,
            items: points,
            classItem: this.classItem,
            classItemStatic: this.classItemStatic,
            prop: '_id',
        });
    }

    callbackOrder({ newOrder }) {
        this.setState((state) => {
            const newState = { ...state };
            const points = JSON.parse(JSON.stringify(newState.points));
            const newRoute = [];
            let isChange = false;

            newOrder.forEach((newIndex, key) => {
                newRoute[newIndex] = points[key];

                if (newIndex !== key) {
                    isChange = true;
                }
            });

            newState.points = newRoute;

            if (isChange) {
                newState.updateKey = new Date().getTime();
            }

            return newState;
        });
    }

    setReason(reason) {
        this.setState({ currentReason: this.state.currentReason === reason ? null : reason });
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appOrderPointPopup', isShow: false });
    }

    getBlocks() {
        const { currentBlock } = this.state;

        return [{ key: currentBlock }];
    }

    renderBlock({ prop: key }) {
        const { heightItems, updateKey, points, currentReason } = this.state;
        const { point } = this.props;

        return (
            <div className="appPopupPoint__block _col">
                {key === 'start' ? (
                    <>
                        <div className="appPopupPoint__title">Перенос адреса:</div>
                        <div className="appPopupPoint__description">
                            {point?.address}
                            <br />
                            <span>
                                Точка {point?.index === 0 ? 'погрузки' : 'выгрузки'}{' '}
                                {point?.sk || point?.ttk ? <>· {point.sk ? 'СК' : 'ТТК'}</> : <></>}
                            </span>
                        </div>
                        <div className="appPopupPoint__buttons">
                            <div
                                className="appPopupPoint__button _row"
                                onClick={() => {
                                    this.setState({
                                        currentBlock: 'replace',
                                    });
                                }}
                            >
                                Перенести доставку
                            </div>
                            <div
                                className="appPopupPoint__button _row _alert"
                                onClick={() => {
                                    this.setState({
                                        currentBlock: 'delete',
                                    });
                                }}
                            >
                                Удалить этот адрес
                            </div>
                        </div>
                    </>
                ) : key === 'replace' ? (
                    <>
                        <div className="appPopupPoint__title">Перенос доставки</div>
                        <div className="appPopupPoint__description _row _sub">
                            Перетащите точку, используя иконку
                            <div className="appPopupPoint__descriptionDrag _col" />
                        </div>

                        <div className="appPopupPoint__points">
                            <DragBox
                                className="appPopupPoint__pointsInner"
                                classItem={this.classItem}
                                classItemStatic={this.classItemStatic}
                                key={updateKey}
                                items={points}
                                setHeightItems={this.setHeightItems}
                                heightItems={heightItems}
                                offset={this.offset}
                                prop="_id"
                                callbackInit={() => {
                                    removeTransition({
                                        item: '.appPopupPoint__pointsInner,.appPopupPoint__point',
                                    });
                                }}
                            >
                                {points.map((innerPoint, pointIndex) => {
                                    const isCurrent = innerPoint._id === point?._id;

                                    return (
                                        <div
                                            className={`appPopupPoint__point ${
                                                isCurrent ? '_current' : ''
                                            }`}
                                            data-id={innerPoint._id}
                                            key={innerPoint._id}
                                        >
                                            <Point
                                                point={innerPoint}
                                                isCurrent={isCurrent}
                                                offset={this.offset}
                                                index={pointIndex}
                                                callbackOrder={this.callbackOrder}
                                            />
                                        </div>
                                    );
                                })}
                            </DragBox>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="appPopupPoint__title">Выберите причину</div>
                        <div className="appPopupPoint__description _row _sub">
                            Чтобы удалить адрес из маршрута —<br />
                            выберите причину удаления
                        </div>
                        <div className="appPopupPoint__reasons">
                            {this.cancelReasons.map((reason) => (
                                <div
                                    className={`appPopupPoint__reason ${
                                        currentReason === reason ? '_current' : ''
                                    }`}
                                    key={reason}
                                    onClick={() => {
                                        this.setReason(reason);
                                    }}
                                >
                                    <div className="appPopupPoint__reasonInner _row">
                                        {reason}
                                        <div className="appPopupPoint__reasonPoint _col">
                                            <i className="appPopupPoint__reasonPointIcon">
                                                <Icon name="done" />
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        );
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    save() {
        const { currentReason, currentBlock, savedPoints, points } = this.state;
        const { order } = this.props;

        if (currentReason) {
            this.handlerLoadingKey('cancel').then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/order`,
                        {
                            type: 'cancel-point',
                            id: order._id,
                            reason: currentReason,
                        },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (!success) {
                                this.hide();
                            }
                        },
                        () => null,
                    );
            });
        } else if (currentBlock === 'replace') {
            let isChange = false;
            const pointsOrder = [];

            points.forEach((point, key) => {
                const savedPointKey = savedPoints.findIndex(
                    (innerPoint) => innerPoint._id === point._id,
                );

                pointsOrder.push(point._id);

                if (savedPointKey !== key) {
                    isChange = true;
                }
            });

            if (isChange) {
                this.handlerLoadingKey('replace').then(() => {
                    axios
                        .patch(
                            `${process.env.REACT_APP_API}/order`,
                            {
                                type: 'replace-points',
                                id: order._id,
                                pointsOrder,
                            },
                            { headers: getHeaders() },
                        )
                        .then(
                            (res) => {
                                const { success } = res.data;

                                if (!success) {
                                    this.hide();
                                }
                            },
                            () => null,
                        );
                });
            } else {
                this.hide();
            }
        } else {
            this.hide();
        }
    }

    componentDidMount() {
        super.componentDidMount();

        const { order, point } = this.props;

        const points =
            order?.route
                .map((innerPoint, pointKey) => ({ ...innerPoint, index: pointKey }))
                .filter((innerPoint, pointKey) => pointKey >= point?.index) || [];

        this.setState({ points, savedPoints: JSON.parse(JSON.stringify(points)) });
    }

    render() {
        const { loadingKey, currentBlock } = this.state;

        return (
            <div ref={this.parent} className="appPopup _col _withFixFoot">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appPopup__point">
                                <div className="appPopupPoint">
                                    <ListAbsoluteMain
                                        className="appPopupPoint__blocks"
                                        items={this.getBlocks()}
                                        renderItem={this.renderBlock}
                                        classNameItem="appPopupPoint__block"
                                        prop="key"
                                        paramsParent={{ width: true }}
                                        styles={['height']}
                                        isNotParamsItem={true}
                                        currentItemKey={currentBlock}
                                        allItems={['start', 'replace', 'delete']}
                                    />
                                </div>
                            </div>
                            <div className="appPopup__foot _col">
                                <div className="appPopup__button">
                                    <Button
                                        isDisabled={false}
                                        onClick={this.save}
                                        showLoader={!!loadingKey}
                                    >
                                        Сохранить изменения
                                    </Button>
                                </div>
                                <div className="appPopup__link">
                                    <div
                                        className="link _click _alert _bold"
                                        onClick={() => {
                                            if (currentBlock !== 'start') {
                                                this.setState({ currentBlock: 'start' });
                                            } else {
                                                this.hide();
                                            }
                                        }}
                                    >
                                        Отменить
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(OrderPoint);

OrderPoint.propTypes = {
    heightWindow: PropTypes.number,
    order: PropTypes.object,
};
