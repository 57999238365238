import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Icon from './Icon.jsx';

import handlerSupportCursor from '../functions/handlerSupportCursor';
import handlerPopup from '../functions/handlerPopup';
import AnimateChange from './AnimateChange.jsx';
import Button from './Button.jsx';
import ImageLazy from './ImageLazy.jsx';
import download from '../functions/download';
import checkImageFile from '../functions/checkImageFile';

import Loader from './Loader.jsx';
import Animate from './Animate.jsx';

import getHeaders from '../functions/getHeaders';

class Galery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rotates: {},
        };

        this.handlerMove = this.handlerMove.bind(this);

        this.parent = React.createRef();
    }

    renderArrow(dir) {
        const name = dir === 'prev' ? 'Назад' : 'Вперёд';

        return (
            <div
                className="galery__contentAction _col _click _arrow"
                onMouseEnter={(e) => handlerSupportCursor({ action: 'enter', content: name, e })}
                onMouseLeave={(e) => handlerSupportCursor({ action: 'leave', content: name, e })}
                onClick={() => this.handlerMove({ dir })}
            >
                <i className="galery__contentActionIcon">
                    <Icon name={`arrow-${dir}`} />
                </i>
            </div>
        );
    }

    handlerMove({ key, dir }) {
        const { loadingKey } = this.state;
        let { currentIndex } = this.state;
        const { popupGalery } = this.props;
        const { images } = popupGalery;

        if (!loadingKey) {
            if (dir) {
                switch (dir) {
                    case 'prev':
                        if (currentIndex === 0) {
                            currentIndex = images.length - 1;
                        } else {
                            currentIndex -= 1;
                        }
                        break;
                    case 'next':
                        if (currentIndex === images.length - 1) {
                            currentIndex = 0;
                        } else {
                            currentIndex += 1;
                        }
                        break;
                    default:
                        break;
                }
            } else {
                currentIndex = key;
            }

            this.setCurrent({ currentIndex });
        }
    }

    setCurrent({ currentIndex }) {
        const { popupGalery } = this.props;
        const { images } = popupGalery;

        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.currentIndex = currentIndex;

                    return newState;
                },
                () => {
                    this.checkUploadImage({ image: images[currentIndex] });

                    resolve();
                },
            );
        });
    }

    checkUploadImage({ image }) {
        return new Promise((resolve) => {
            if (checkImageFile(image)) {
                const newImage = new Image();

                newImage.onload = resolve;

                newImage.src = image.path;
            } else {
                resolve();
            }
        });
    }

    checkUploadedImages() {
        const { popupGalery } = this.props;
        const { images } = popupGalery;

        Promise.all(
            images
                .filter((image) => image.type && checkImageFile(image))
                .map((image) => this.checkUploadImage({ image })),
        );
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    download({ folder, src, isAll }) {
        const { popupGalery } = this.props;
        const { images } = popupGalery;
        const files = isAll
            ? images.map((image) => ({
                  folder: image.folder,
                  src: image.src,
              }))
            : [{ folder, src }];
        const loadingKey = isAll ? 'downloadAll' : src;

        this.handlerLoadingKey(loadingKey).then(() => {
            download({ files }).then(
                () => {
                    this.handlerLoadingKey(null);
                },
                () => null,
            );
        });
    }

    rotateRequest({ key }) {
        const { popupGalery } = this.props;
        const { images, rotateCallback, modelName } = popupGalery;
        const { id, src } = images[key];

        return new Promise((resolve, reject) => {
            this.handlerLoadingKey(`rotate-${key}`).then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/image`,
                        { name: modelName, src, id },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                const { file } = data;

                                if (rotateCallback) {
                                    rotateCallback({ file });
                                }

                                resolve();
                            } else {
                                reject();
                            }

                            this.handlerLoadingKey(null);
                        },
                        () => null,
                    );
            });
        });
    }

    rotate({ key }) {
        this.rotateRequest({ key }).then(
            () => {
                this.setState((state) => {
                    const newState = { ...state };
                    const rotates = { ...newState.rotates };

                    if (!rotates[key]) {
                        rotates[key] = {
                            deg: 0,
                            scale: 1,
                        };
                    }

                    rotates[key].deg -= 90;
                    rotates[key].scale = 1;

                    if (-rotates[key].deg % 180 === 90) {
                        const content = this.parent.current.querySelector('.galery__content');
                        const imageClass = `.galery__contentImage[data-key="${key}"]`;
                        const image = this.parent.current.querySelector(imageClass);
                        const { offsetWidth: imageWidth, offsetHeight: imageHeight } = image;
                        const { offsetWidth: contentWidth, offsetHeight: contentHeight } = content;
                        const realWidth = imageHeight;
                        const realHeight = imageWidth;
                        let scale = 1;

                        if (realHeight > contentHeight) {
                            scale = contentHeight / realHeight;
                        }

                        if (realWidth > contentWidth) {
                            scale = contentWidth / realWidth;
                        }

                        rotates[key].scale = scale;
                    }

                    newState.rotates = rotates;

                    return newState;
                });
            },
            () => null,
        );
    }

    componentDidMount() {
        const { popupGalery } = this.props;
        const { currentIndex } = popupGalery;

        this.setCurrent({ currentIndex: currentIndex || 0 });
    }

    render() {
        const { currentIndex, loadingKey, rotates } = this.state;
        const { popupGalery } = this.props;
        const { state, title, images } = popupGalery;

        return (
            <div
                className={`body__popup ${state === 1 ? '_show' : ''} _galery`}
                onMouseUp={(e) => e.stopPropagation()}
            >
                <div ref={this.parent} className="galery _col">
                    <div className="galery__inner _col">
                        <div className="galery__head _row">
                            <div className="galery__name _row">
                                {title}
                                {images.length > 1 ? (
                                    <>
                                        :{' '}
                                        <div className="galery__nameCounter _row">
                                            <AnimateChange
                                                className="galery__nameCounterItem"
                                                prop={currentIndex}
                                                type="_translateMedium"
                                            >
                                                {`${currentIndex + 1}`}
                                            </AnimateChange>
                                            /{images.length}
                                        </div>
                                    </>
                                ) : (
                                    <div className="galery__nameCounter _row">{images[0].name}</div>
                                )}
                            </div>
                            <div className="galery__actions _row">
                                <div className="galery__actionsButtons _row">
                                    {images.length > 1 &&
                                        images.every((image) => image.src && image.folder) && (
                                            <div className="galery__actionsButton">
                                                <Button
                                                    className="_mainNotBorder _normalSize"
                                                    onClick={() => this.download({ isAll: true })}
                                                    showLoader={loadingKey === 'downloadAll'}
                                                >
                                                    Скачать все
                                                </Button>
                                            </div>
                                        )}
                                </div>
                                <i
                                    className="galery__close _click"
                                    onClick={() => {
                                        handlerPopup({
                                            action: 'hide',
                                            name: 'popupGalery',
                                        });
                                    }}
                                >
                                    <Icon name="close" />
                                </i>
                            </div>
                        </div>
                        <div className="galery__content _col">
                            <div className="galery__contentActions _row">
                                {images.length > 1 && (
                                    <>
                                        {this.renderArrow('prev')}
                                        {this.renderArrow('next')}
                                    </>
                                )}
                            </div>
                            <div className="galery__contentInner _col">
                                {images.map((image, key) => {
                                    const path =
                                        image.isLocal === true
                                            ? require(`../img/${image.path}`)
                                            : image.path;

                                    return (
                                        <div
                                            className={`galery__contentImage _col ${
                                                key === currentIndex ? '_current' : ''
                                            } ${!checkImageFile(image) ? '_doc' : ''}`}
                                            key={key}
                                            data-key={key}
                                        >
                                            <div className="galery__contentImageActions _row">
                                                <div className="galery__contentImageAction">
                                                    <a
                                                        className="action _col _click _rotate"
                                                        target="_blank"
                                                        href={path}
                                                        rel="noreferrer"
                                                    >
                                                        <div className="action__icon">
                                                            <Icon name="link" />
                                                        </div>
                                                    </a>
                                                </div>
                                                {checkImageFile(image) && !image.notRotate && (
                                                    <div className="galery__contentImageAction">
                                                        <div
                                                            className="action _col _click _rotate"
                                                            onClick={() => {
                                                                this.rotate({ key });
                                                            }}
                                                        >
                                                            <Animate
                                                                className="action__loader _loader"
                                                                isShow={
                                                                    loadingKey === `rotate-${key}`
                                                                }
                                                            >
                                                                <div className="action__loaderItem _loaderItem">
                                                                    <Loader className="_main" />
                                                                </div>
                                                            </Animate>
                                                            <div className="action__icon">
                                                                <Icon name="rotate" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {image.src && image.folder && (
                                                    <div className="galery__contentImageAction">
                                                        <div
                                                            className="action _col _click"
                                                            onClick={() => this.download(image)}
                                                        >
                                                            <Animate
                                                                className="action__loader _loader"
                                                                isShow={loadingKey === image.src}
                                                            >
                                                                <div className="action__loaderItem _loaderItem">
                                                                    <Loader className="_main" />
                                                                </div>
                                                            </Animate>
                                                            <div className="action__icon">
                                                                <Icon name="download" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            {checkImageFile(image) ? (
                                                <ImageLazy
                                                    src={path}
                                                    alt=""
                                                    className="galery__contentImageItem"
                                                    style={{
                                                        transform: `rotate(${
                                                            rotates[key]?.deg || 0
                                                        }deg) scale(${rotates[key]?.scale || 1})`,
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    <i className="galery__contentImageIcon">
                                                        <Icon name="file-type-doc" />
                                                    </i>
                                                    <div className="galery__contentImageSupport">
                                                        Предпросмотр
                                                        <br />
                                                        невозможен
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {images.length > 1 && (
                            <div className="galery__items">
                                <div className="galery__itemsInner">
                                    {images.map((image, key) => {
                                        const path =
                                            image.isLocal === true
                                                ? require(`../img/${image.path}`)
                                                : image.path;
                                        return (
                                            <div
                                                className={`galery__item _col _click ${
                                                    key === currentIndex ? '_current' : ''
                                                } ${!checkImageFile(image) ? '_doc' : ''}`}
                                                key={key}
                                                onClick={() => this.handlerMove({ key })}
                                            >
                                                {checkImageFile(image) ? (
                                                    <ImageLazy
                                                        src={path}
                                                        className="galery__itemImage"
                                                    />
                                                ) : (
                                                    <>
                                                        <i className="galery__itemIcon">
                                                            <Icon name="file-type-doc" />
                                                        </i>
                                                    </>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        popupGalery: state.popupGalery,
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(Galery);

Galery.propTypes = {
    popupGalery: PropTypes.object,
    heightWindow: PropTypes.number,
};
