import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';

import changePage from '../../../functions/changePage';

import handlerPopup from '../../../functions/app/handlerPopup';
import JoinSmzInstruction from '../../JoinSmzInstruction.jsx';
import Button from '../../Button.jsx';

class SmzInstruction extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appSmzInstructionPopup', isShow: false });
    }

    render() {
        const { appSmzInstructionPopup } = this.props;
        const { callback } = appSmzInstructionPopup;

        return (
            <div ref={this.parent} className="appPopup _col">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <JoinSmzInstruction />
                            <div className="appPopup__button">
                                <Button
                                    onClick={() => {
                                        this.hide();

                                        if (callback) {
                                            callback();
                                        }

                                        changePage({ href: 'settings-fns' });
                                    }}
                                >
                                    Всё получилось, продолжить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
        appSmzInstructionPopup: state.appSmzInstructionPopup,
    };
}

export default connect(mapStateToProps)(SmzInstruction);

SmzInstruction.propTypes = {
    heightWindow: PropTypes.number,
    hideCallback: PropTypes.func,
    appSmzInstructionPopup: PropTypes.object,
};
