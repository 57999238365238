import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListAbsoluteMain from '../../../ListAbsoluteMain.jsx';
import Button from '../../../Button.jsx';
import Icon from '../../../Icon.jsx';
import handlerPopup from '../../../../functions/app/handlerPopup';

class OrderDocsQr extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderFile = this.renderFile.bind(this);
    }

    getFiles() {
        const { point } = this.props;
        const { docs } = point;
        const doc = docs.find((innerDoc) => innerDoc.key === 'qr-code');

        return doc.files;
    }

    renderFile({ item, key }) {
        const { order } = this.props;
        const src = `${process.env.REACT_APP_STATIC}/docs-of-point/${item.path}`;

        return (
            <div
                className="appOrderDocs__file"
                onClick={() => {
                    handlerPopup({
                        name: 'appGalery',
                        isShow: true,
                        src,
                    });
                }}
            >
                <div className="appOrderDocs__fileInner">
                    <div className="appOrderDocs__fileNumber _col">{key + 1}</div>
                    <div className="appOrderDocs__filePreview">
                        <img src={src} alt="" className="appOrderDocs__filePreviewImage" />
                        {order.deliveryType !== 'sampling' && (
                            <i className="appOrderDocs__filePreviewDone">
                                <Icon name="app-done" />
                            </i>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { handlerCurrentBlock } = this.props;

        return (
            <div className="appOrderDocs _col">
                <div className="appOrderDocs__title">
                    Покажите QR-code
                    <br />
                    менеджеру
                </div>
                <div className="appOrderDocs__blocks _static">
                    <div className="appOrderDocs__block _col _show">
                        <p className="appOrderDocs__description">Чтобы забрать груз</p>
                        <div className="appOrderDocs__files">
                            <ListAbsoluteMain
                                className="appOrderDocs__filesInner"
                                items={this.getFiles()}
                                renderItem={this.renderFile}
                                classNameItem="appOrderDocs__file"
                                prop="_id"
                                styles={['width']}
                            />
                        </div>
                        <div className="appOrderDocs__button">
                            <Button
                                icon={{ type: 'end', name: 'arrow-next-2' }}
                                onClick={() => {
                                    handlerCurrentBlock({ currentBlock: 'code' });
                                }}
                            >
                                Далее
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(OrderDocsQr);

OrderDocsQr.propTypes = {
    user: PropTypes.object,
    setUpdateKey: PropTypes.func,
    name: PropTypes.string,
    handlerCurrentBlock: PropTypes.func,
    point: PropTypes.object,
    order: PropTypes.object,
    loadingKey: PropTypes.string,
    handlerLoading: PropTypes.func,
};
