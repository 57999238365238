import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import Icon from '../../Icon.jsx';
import Button from '../../Button.jsx';
import LinkBtn from '../LinkBtn.jsx';

class NewVersion extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    checkHideDisabled() {
        return true;
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appNewVersionPopup', isShow: false });
    }

    render() {
        const { isLoading = false } = this.state;
        const { user } = this.props;
        const textVersion = user.systemVersion?.toString().split('').join('.');

        return (
            <div ref={this.parent} className="appPopup _col _full _disabled">
                <div className="appPopup__inner _col">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <i className="appPopup__icon">
                                <Icon name="app-new-version" />
                            </i>
                            <div className="appPopup__title">
                                Доступна новая
                                <br />
                                версия приложения
                            </div>
                            <p className="appPopup__description">
                                Обновите приложение до последней
                                <br />
                                версии, чтобы получить доступ
                                <br />
                                к новым функциям и избежать
                                <br />
                                возможных ошибок в работе
                            </p>
                            <p className="appPopup__description">
                                <span>Версия обновления: {textVersion}</span>
                            </p>
                            <div className="appPopup__button">
                                <Button
                                    onClick={() => {
                                        this.setState({ isLoading: true }, () => {
                                            window.location.reload(true);
                                        });
                                    }}
                                    showLoader={isLoading}
                                >
                                    Обновить приложение сейчас
                                </Button>
                            </div>
                            <div className="appPopup__link">
                                <LinkBtn
                                    handler={() => {
                                        if (!isLoading) {
                                            this.hide();

                                            setTimeout(() => {
                                                handlerPopup({
                                                    name: 'appNewVersionPopup',
                                                    isShow: true,
                                                });
                                            }, 60_000 * 10);
                                        }
                                    }}
                                >
                                    Обновить позже
                                </LinkBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
        user: state.user,
    };
}

export default connect(mapStateToProps)(NewVersion);

NewVersion.propTypes = {
    heightWindow: PropTypes.number,
    hideCallback: PropTypes.func,
    user: PropTypes.object,
};
