export default function setHeightItems({
    parent,
    items,
    classItem,
    classItemStatic,
    prop = 'uniqKey',
}) {
    return new Promise((resolve) => {
        const heightItems = {};

        if (parent && items) {
            items?.forEach((item) => {
                const uniqKey = item[prop];
                const itemElem = parent.querySelector(`${classItem}[data-id="${uniqKey}"]`);
                const { clientHeight: height } = itemElem.querySelector(classItemStatic);

                heightItems[uniqKey] = {
                    height,
                };
            });
        }

        this.setState((state) => {
            const newState = { ...state };

            newState.heightItems = heightItems;

            return newState;
        }, resolve);
    });
}
