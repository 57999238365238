import React from 'react';
import PropTypes from 'prop-types';

import handlerSupportCursor from '../../../functions/handlerSupportCursor';
import Avatar from '../../Avatar.jsx';

class CorporationInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { logo, name, className = '', short } = this.props;

        return name ? (
            <div
                className={`manualCorporationInfo ${className} ${short ? '_short' : ''}`}
                onMouseEnter={(e) => {
                    if (short) {
                        handlerSupportCursor({
                            action: 'enter',
                            content: `Корпорация<br/>${name}`,
                            e,
                            data: { className: '_normalCase _center' },
                        });
                    }
                }}
                onMouseLeave={(e) => {
                    handlerSupportCursor({ action: 'leave', e });
                }}
            >
                <Avatar
                    src={logo?.path && `${process.env.REACT_APP_STATIC}/corporations/${logo.path}`}
                    holder={{
                        text: name?.[0]?.toUpperCase(),
                    }}
                    className="manualCorporationInfo__logo"
                    withText={true}
                    iconType={logo?.type}
                />
                {!short && <div className="manualCorporationInfo__name">{name}</div>}
            </div>
        ) : null;
    }
}

export default CorporationInfo;

CorporationInfo.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.object,
    name: PropTypes.string,
    short: PropTypes.bool,
};
