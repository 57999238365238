import getVariables from '../functions/getVariables';

export default class MobilePopup {
    constructor({ name, parent, handler, getCondForChange }) {
        this.name = name;
        this.moveFlag = false;
        this.startY = 0;
        this.moveY = 0;
        this.getCondForChange = getCondForChange;

        if (!this.getCondForChange) {
            this.getCondForChange = () => true;
        }

        this.parent = parent;
        this.handler = handler;

        this.handlerSwipeStart = this.handlerSwipeStart.bind(this);
        this.handlerSwipeMove = this.handlerSwipeMove.bind(this);
        this.handlerSwipeEnd = this.handlerSwipeEnd.bind(this);

        this.handlerView = this.handlerView.bind(this);
    }

    getOffset() {
        const variables = getVariables();

        return window.widthValue >= 440 ? variables.popupOffsetTablet.value : 4;
    }

    hide() {
        this.parent.style.transition = `.5s ease-in-out`;
        this.parent.style.transform = `translate3d(0,calc(100% + ${this.getOffset()}px),0)`;
    }

    handlerView(e) {
        if (
            this.getCondForChange() &&
            this.parent &&
            e.target !== this.parent &&
            !this.parent.contains(e.target)
        ) {
            this.handler('hide');
            this.hide();
        }
    }

    handlerSwipeStart(e) {
        if (this.getCondForChange()) {
            this.moveFlag = true;
            this.startY = e.changedTouches[0].pageY;
        }
    }

    handlerSwipeStartBind = this.handlerSwipeStart.bind(this);

    handlerSwipeMove(e) {
        if (this.getCondForChange() && this.moveFlag === true) {
            this.parent.style.transition = `0s`;
            this.moveY = e.changedTouches[0].pageY - this.startY;
            
            if (this.moveY > 0) {
                e.preventDefault();
                this.parent.style.transform = `translate3d(0,${this.moveY}px,0)`;
            }
        }
    }

    handlerSwipeMoveBind = this.handlerSwipeMove.bind(this);

    handlerSwipeEnd() {
        if (this.getCondForChange() && this.moveFlag === true) {
            this.moveFlag = false;
            if (this.parent && this.moveY !== 0) {
                const height = this.parent.clientHeight;

                this.parent.style.transition = `.5s ease-in-out`;
                if (this.moveY > height / 3) {
                    this.parent.style.transform = `translate3d(0,calc(100% + ${this.getOffset()}px),0)`;
                    this.handler('hide');
                } else {
                    this.parent.style.transform = `translate3d(0,0,0)`;
                }
            }
        }
    }

    handlerSwipeEndBind = this.handlerSwipeEnd.bind(this);

    init() {
        setTimeout(() => {
            document.addEventListener('click', this.handlerView);
            document.addEventListener('touchstart', this.handlerView);
        }, 1);

        this.parent.addEventListener('touchstart', this.handlerSwipeStartBind);

        document.addEventListener('touchmove', this.handlerSwipeMoveBind, { passive: false });
        document.addEventListener('touchend', this.handlerSwipeEndBind);
    }

    remove() {
        document.removeEventListener('click', this.handlerView);
        document.removeEventListener('touchstart', this.handlerView);

        this.parent.removeEventListener('touchstart', this.handlerSwipeStartBind);

        document.removeEventListener('touchmove', this.handlerSwipeMoveBind);
        document.removeEventListener('touchend', this.handlerSwipeEndBind);
    }
}
