import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import Icon from '../../Icon.jsx';
import getFormatedDate from '../../../functions/getFormatedDate';
import Link from '../../Link.jsx';

class ChangeOrder extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appCancelCrewOrderPopup', isShow: false });
    }

    render() {
        const { order } = this.props;
        const { dateOfOrder } = order;
        const date = `${getFormatedDate({ date: new Date(dateOfOrder) })} (${getFormatedDate({
            date: new Date(dateOfOrder),
            type: 'time',
        })})`;

        return (
            <div ref={this.parent} className="appPopup _col _changeOrder">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <i className="appPopup__icon">
                                <Icon name="app-change-order-cancel-crew" />
                            </i>
                            <div className="appPopup__title">
                                Заказ ID{order.number}:
                                <br />
                                назначен другому водителю
                            </div>
                            <p className="appPopup__description">
                                Заказ ID{order.number} на {date} <br />
                                был&nbsp;назначен другому водителю
                            </p>
                            <div className="appPopup__button">
                                <Button
                                    onClick={() => {
                                        this.hide();
                                    }}
                                >
                                    Понятно
                                </Button>
                            </div>
                            <div className="appPopup__link">
                                <Link className="link _click _alert" href="chat-index">
                                    Поддержка
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(ChangeOrder);

ChangeOrder.propTypes = {
    heightWindow: PropTypes.number,
    order: PropTypes.object,
};
