import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import File from '../../../classes/File';

import getUpdateFormData from '../../../functions/getUpdateFormData';
import getHeaders from '../../../functions/getHeaders';
import getCurrentCar from '../../../functions/app/getCurrentCar';

import Button from '../../Button.jsx';
import Animate from '../../Animate.jsx';
import BorderDashed from '../../BorderDashed.jsx';

import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';

import Popup from './Popup.jsx';

import Pagination from '../Pagination.jsx';
import Back from '../Back.jsx';
import Loader from '../../Loader.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';

class CarImages extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: localStorage.getItem('carImagesCurrentBlock') || 'start',
            files: {},
        };

        this.renderBlock = this.renderBlock.bind(this);
        this.renderFile = this.renderFile.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.hide = this.hide.bind(this);
    }

    blocksOrder = ['start', 'step1', 'step2'];

    checkHideDisabled() {
        return true;
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appCarImagesPopup', isShow: false });

        localStorage.setItem('appCarImagesComplete', true);
        localStorage.removeItem('carImagesCurrentBlock');
    }

    getBlocks() {
        const { currentBlock } = this.state;

        return [{ name: currentBlock }];
    }

    blocks = {
        start: {
            render() {
                return (
                    <>
                        <div className="appPopup__title">
                            Пройдите
                            <br />
                            фотоконтроль
                        </div>
                        <p className="appPopup__description">
                            Сфотографируйте ваш рабочий автомобиль <b>со всех сторон</b> —<br />
                            это требуется для оценки
                            <br />
                            внешнего вида машины.
                        </p>
                        <div className="appPopup__button">
                            <Button
                                icon={{ type: 'end', name: 'arrow-next-2' }}
                                onClick={() => {
                                    this.setCurrentBlock('step1');
                                }}
                            >
                                Пройти фотоконтроль
                            </Button>
                        </div>
                        <div className="appPopup__link">
                            <div className="link _click _alert">Поддержка</div>
                        </div>
                    </>
                );
            },
        },
        step1: {
            render() {
                const { user } = this.props;
                const car = getCurrentCar({ user });
                const isDisabled = !['forward', 'back'].every(
                    (name) => car.files.find((file) => file.name === name)?.file?.path,
                );

                return (
                    <>
                        <div className="appPopup__title">
                            Фото машины:
                            <br />
                            спереди и сзади
                        </div>
                        <p className="appPopup__description">
                            Сфотографируйте ваш рабочий
                            <br />
                            автомобиль <b>сзади и спереди</b> так,
                            <br />
                            чтобы было видно грузовой отсек, <br />
                            лобовое стекло и фары
                        </p>
                        <div className="appPopup__files _row">
                            {['back', 'forward'].map(this.renderFile)}
                        </div>
                        <div className="appPopup__button">
                            <Button
                                icon={{ type: 'end', name: 'arrow-next-2' }}
                                isDisabled={isDisabled}
                                onClick={() => {
                                    this.setCurrentBlock('step2');
                                }}
                            >
                                Далее
                            </Button>
                        </div>
                        <div className="appPopup__link">
                            <div className="link _click _alert">Поддержка</div>
                        </div>
                    </>
                );
            },
        },
        step2: {
            render() {
                const { user } = this.props;
                const car = getCurrentCar({ user });
                const isDisabled = !['left', 'right'].every(
                    (name) => car.files.find((file) => file.name === name)?.file?.path,
                );

                return (
                    <>
                        <div className="appPopup__title">
                            Фото машины:
                            <br />
                            слева и справа
                        </div>
                        <p className="appPopup__description">
                            Сфотографируйте ваш рабочий
                            <br />
                            автомобиль <b>слева и справа</b> так,
                            <br />
                            чтобы кузов целиком (не впритык)
                            <br /> помещался в кадр
                        </p>
                        <div className="appPopup__files _row">
                            {['left', 'right'].map(this.renderFile)}
                        </div>
                        <div className="appPopup__button">
                            <Button
                                isDisabled={isDisabled}
                                onClick={() => {
                                    this.hide();
                                }}
                            >
                                Завершить фотоконтроль
                            </Button>
                        </div>
                        <div className="appPopup__link">
                            <div className="link _click _alert">Поддержка</div>
                        </div>
                    </>
                );
            },
        },
    };

    setCurrentBlock(currentBlock) {
        return new Promise((resolve) => {
            this.setState({ currentBlock }, () => {
                localStorage.setItem('carImagesCurrentBlock', currentBlock);

                resolve();
            });
        });
    }

    renderBlock({ prop: name }) {
        const block = this.blocks[name];

        return <div className="appPopup__block _col">{block?.render.call(this)}</div>;
    }

    files = {
        back: {
            icon: 'car-images-back.svg',
            content: 'Машина сзади',
        },
        forward: {
            icon: 'car-images-forward.svg',
            content: 'Машина спереди',
        },
        left: {
            icon: 'car-images-left.svg',
            content: 'Машина слева',
        },
        right: {
            icon: 'car-images-right.svg',
            content: 'Машина справа',
        },
    };

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    uploadFile({ target, name }) {
        const { user } = this.props;
        const car = getCurrentCar({ user });

        this.handlerLoadingKey(name).then(() => {
            this.handlerFile.uploadFiles({ target }).then(({ resultFiles }) => {
                const [file] = resultFiles;

                const formData = new FormData();

                formData.set(`${name}-image`, file.object);

                formData.set('id', car._id);
                formData.set('action', 'upload-images');

                axios
                    .patch(`${process.env.REACT_APP_API}/car`, getUpdateFormData(formData), {
                        headers: getHeaders(),
                    })
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                console.log('s');
                            } else {
                                this.handlerLoadingKey(null);
                            }
                        },
                        () => null,
                    );
            });
        });
    }

    renderFile(name) {
        const { loadingKey } = this.state;
        const { user } = this.props;
        const car = getCurrentCar({ user });
        const info = this.files[name];
        const file = car.files?.find((innerFile) => innerFile.name === name)?.file;

        return (
            <label
                className={`appPopup__file ${loadingKey === name ? '_isLoading' : ''} ${
                    file?.path ? '_upload' : ''
                }`}
                key={name}
            >
                <input
                    type="file"
                    className="appPopup__fileInput"
                    onChange={({ target }) => this.uploadFile({ target, name })}
                    disabled={!!loadingKey}
                    accept="image/*"
                />
                <div className="appPopup__fileView">
                    <Animate className="appPopup__fileLoader _loader" isShow={loadingKey === name}>
                        <div className="appPopup__fileLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <div className="appPopup__fileBorder">
                        <BorderDashed className="_app" rx={18} ry={18} />
                    </div>
                    <div className="appPopup__fileInfo _col">
                        <img
                            src={require(`../../../img/app/carImages/${info.icon}`)}
                            alt=""
                            className="appPopup__fileInfoIcon"
                        />
                        <p className="appPopup__fileInfoName">{info.content}</p>
                    </div>
                    <Animate className="appPopup__filePreview" isShow={!!file.path}>
                        <img
                            src={`${process.env.REACT_APP_STATIC}/cars/${file?.path}`}
                            alt=""
                            className="appPopup__filePreviewImage"
                        />
                    </Animate>
                </div>
            </label>
        );
    }

    handlerFile = new File({});

    changeUser({ detail: { initiatorUserId, fields } }) {
        const { loadingKey } = this.state;
        const { user } = this.props;

        if (initiatorUserId === user._id && fields.infoCars) {
            const car = fields.infoCars.find((innerCar) => innerCar._id === user.car);

            if (car) {
                const file = car.files.find((innerFile) => innerFile.name === loadingKey)?.file;

                if (file?.path) {
                    this.handlerLoadingKey(null);
                }
            }
        }
    }

    componentDidMount() {
        document.addEventListener('changeUser', this.changeUser);
    }

    componentWillUnmount() {
        document.addEventListener('changeUser', this.changeUser);
        localStorage.removeItem('carImagesCurrentBlock');
    }

    render() {
        const { currentBlock } = this.state;

        return (
            <div ref={this.parent} className="appPopup _col _isDisabled">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appPopup__top _row">
                                <Animate
                                    className="appPopup__topBack"
                                    isShow={currentBlock !== 'start'}
                                >
                                    <Back
                                        handler={() => {
                                            this.setCurrentBlock(
                                                currentBlock === 'step2' ? 'step1' : 'start',
                                            );
                                        }}
                                    />
                                </Animate>
                                <div
                                    className={`appPopup__topName ${
                                        currentBlock === 'start' ? '_show' : ''
                                    }`}
                                >
                                    Фотоконтроль
                                </div>
                                <div className="appPopup__topPagination">
                                    <Pagination
                                        items={this.blocksOrder}
                                        currentName={currentBlock}
                                    />
                                </div>
                            </div>
                            <ListAbsoluteMain
                                className="appPopup__blocks"
                                items={this.getBlocks()}
                                renderItem={this.renderBlock}
                                classNameItem="appPopup__block"
                                prop="name"
                                paramsParent={{ width: true }}
                                styles={['height']}
                                isNotParamsItem={true}
                                currentItemKey={currentBlock}
                                allItems={this.blocksOrder}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
        user: state.user,
    };
}

export default connect(mapStateToProps)(CarImages);

CarImages.propTypes = {
    heightWindow: PropTypes.number,
    user: PropTypes.object,
};
