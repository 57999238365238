import axios from 'axios';
import getHeaders from '../functions/getHeaders';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function getTaxes({ params }) {
    let query = '';

    params.forEach((param) => {
        query += `${param.key}=${param.value}&`;
    });

    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_API}/executor-tax?${query}`, {
                headers: getHeaders(),
            })
            .then((res) => {
                if (res?.data?.data && typeof res?.data?.data === 'object') {
                    const { success, data } = res.data;

                    if (success === true) {
                        const { taxes, month, isLimit, counter } = data;

                        resolve({ taxes, month, isLimit, counter });
                    } else {
                        handlerErrorRequest(res);
                    }
                }
            }, reject);
    });
}
