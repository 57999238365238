import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from './Icon.jsx';
import handlerTheme from '../functions/handlerTheme';
import { dispatcher } from '../redux/redux';

class Theme extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localTheme: this.props.theme === 'dark' ? 'light' : 'dark',
            changeTheme: this.props.theme,
        };

        this.changeTheme = this.changeTheme.bind(this);
    }

    changeTheme(theme) {
        this.setState(
            (state) => {
                const newState = { ...state };

                newState.localTheme = theme;

                return newState;
            },
            () => {
                dispatcher({ type: 'theme' });
                setTimeout(() => {
                    handlerTheme('hide');
                }, 1000);
            },
        );
    }

    componentDidMount() {
        setTimeout(() => {
            this.changeTheme(this.props.theme);
        }, 1000);
    }

    render() {
        const { localTheme, changeTheme } = this.state;
        const { stateTheme } = this.props;

        return (
            <div className={`body__theme ${stateTheme === 1 ? '_show' : ''}`}>
                <div className={`theme _col _${localTheme}`}>
                    <div className="theme__inner">
                        <div className="theme__icon">
                            <i className="theme__iconItem _light">
                                <Icon name="theme-sun" />
                            </i>
                            <i className="theme__iconItem _dark">
                                <Icon name="theme-moon" />
                            </i>
                        </div>
                        <div className="theme__content _col">
                            <h3 className="theme__title">Смена цветовой темы</h3>
                            <p className="theme__description">
                                {changeTheme === 'light' ? 'Включаем ночь…' : 'Включаем день…'}
                            </p>
                            <img
                                src={require('../img/theme/icon-moon.svg').default}
                                alt=""
                                className="theme__item _light"
                            />
                            <img
                                src={require('../img/theme/icon-sun.svg').default}
                                alt=""
                                className="theme__item _dark"
                            />
                            <img
                                src={require('../img/theme/town-dark.svg').default}
                                alt=""
                                className="theme__image _light"
                            />
                            <img
                                src={require('../img/theme/town-light.svg').default}
                                alt=""
                                className="theme__image _dark"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme,
        stateTheme: state.stateTheme,
    };
}

export default connect(mapStateToProps)(Theme);

Theme.propTypes = {
    theme: PropTypes.string,
    stateTheme: PropTypes.number,
};
