import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import ListAbsoluteMain from '../../components/ListAbsoluteMain.jsx';
import Icon from '../../components/Icon.jsx';
import Move from '../../components/app/order/Move.jsx';
import Docs from '../../components/app/order/Docs.jsx';
import Final from '../../components/app/order/Final.jsx';
import { addRoute } from '../../functions/handlerYmap';
import Ymaps from '../../components/Ymaps.jsx';
import getOrders from '../../requests/getOrders';
import getHeaders from '../../functions/getHeaders';
import clearPhone from '../../functions/clearPhone';
import changePage from '../../functions/changePage';
import Loader from '../../components/Loader.jsx';
import Animate from '../../components/Animate.jsx';
import Popup from '../../components/app/popups/Popup.jsx';
import Details from '../../components/app/order/Details.jsx';
import CounterNotRead from '../../components/chat/CounterNotRead.jsx';
import createChat from '../../functions/app/createChat';
import checkAuth from '../../functions/app/checkAuth';
import handlerPopup from '../../functions/app/handlerPopup';
import { dispatcher } from '../../redux/redux';
import AnimateChangeUp from '../../components/AnimateChangeUp.jsx';
import OrderStatus from '../../components/app/OrderStatus.jsx';
import { getTimeToOrder } from '../../functions/app/getTimeToOrder';
import handlerOrderPopup from '../../functions/app/handlerOrderPopup';
import getExecutorMapIcon from '../../functions/getExecutorMapIcon';
import getPageLink from '../../functions/getPageLink';

class Order extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            currentId: 1,
        };

        this.renderBlock = this.renderBlock.bind(this);
        this.setUpdateKey = this.setUpdateKey.bind(this);
        this.renderActions = this.renderActions.bind(this);
        this.handlerCurrentBlock = this.handlerCurrentBlock.bind(this);
        this.setRoute = this.setRoute.bind(this);
        this.mapBootstrap = this.mapBootstrap.bind(this);
        this.handlerPoint = this.handlerPoint.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.updateOrder = this.updateOrder.bind(this);
        this.setCall = this.setCall.bind(this);
        this.openChat = this.openChat.bind(this);
        this.handlerErrors = this.handlerErrors.bind(this);
        this.getGps = this.getGps.bind(this);
        this.setGps = this.setGps.bind(this);
        this.addPoint = this.addPoint.bind(this);
        this.handlerLoading = this.handlerLoading.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);
        this.setCoordsInterval = this.setCoordsInterval.bind(this);
    }

    name = 'order';

    getMapInfo() {
        const { order } = this.state;

        return {
            center: order.route[0].coords || [55.879541, 37.66556],
            zoom: 16,
            controls: [],
        };
    }

    checkActualRoute() {
        const { currentPrevRoute, map, order } = this.state;
        const { appCoords } = this.props;

        if (
            order &&
            appCoords &&
            currentPrevRoute &&
            ['choice-crew', 'in-proccess'].includes(order.status) &&
            ['wait', 'move-to-point'].includes(this.getCurrentPoint()?.status)
        ) {
            const area = [0.00793063050180662 / 10, 0.005645845345165546 / 10]; // 50м
            const paths = currentPrevRoute.getPaths();
            let findPoint = null;

            for (let i = 0; !findPoint && i < paths.getLength(); i++) {
                const way = paths.get(i);
                const segments = way.getSegments();

                for (let j = 0; !findPoint && j < segments.length; j++) {
                    const street = segments[j].getCoordinates();

                    street.forEach((coords) => {
                        if (
                            coords[0] + area[0] >= appCoords[0] &&
                            coords[0] - area[0] <= appCoords[0] &&
                            coords[1] + area[1] >= appCoords[1] &&
                            coords[1] - area[1] <= appCoords[1]
                        ) {
                            findPoint = coords;
                        }
                    });
                }
            }

            if (!findPoint) {
                if (this.state.currentPrevRoute) {
                    map.geoObjects.remove(this.state.currentPrevRoute);
                }

                this.setState({ currentPrevRoute: null }, () => {
                    this.setCurrentRoute({ withSetCenter: false }).then((newRoute) => {
                        this.setState({ currentPrevRoute: newRoute });
                    });
                });
            }
        }
    }

    setCurrentRoute({ withSetCenter }) {
        const { order, ymaps, map } = this.state;
        const { appCoords } = this.props;
        const currentPointIndex = order.route.findIndex(
            (point) => point._id === order.idOfCurrentPoint,
        );
        const currentPoints = [appCoords, order.route[currentPointIndex].coords];

        return new Promise((resolve) => {
            addRoute({
                ymaps,
                map,
                points: currentPoints,
                withSetCenter,
                isPointHide: true,
                isNew: false,
                strokeWidth: 4,
            }).then(({ routePrev }) => {
                resolve(routePrev);
            });
        });
    }

    setRoute(withSetCenter = true) {
        const { order, ymaps, map } = this.state;
        const { appCoords } = this.props;

        if (ymaps && map && order && appCoords) {
            const points = order.route.map((point) => point.coords);

            if (this.state.routePrev) {
                map.geoObjects.remove(this.state.routePrev);
            }

            if (this.state.currentPrevRoute) {
                map.geoObjects.remove(this.state.currentPrevRoute);
            }

            addRoute({
                ymaps,
                map,
                points,
                withSetCenter,
                strokeWidth: 1,
                isNew: false,
            }).then(({ routePrev }) => {
                this.setCurrentRoute({
                    withSetCenter,
                }).then((currentPrevRoute) => {
                    this.setState({ routePrev, currentPrevRoute });

                    this.setCoordsInterval();
                });
            });
        }
    }

    mapBootstrap({ ymaps, map }) {
        this.setState({ ymaps, map }, () => {
            this.getGps();
            this.setTimer();

            map.events.add('actiontick', () => {
                this.setCoordsInterval(true);
            });
        });
    }

    getCurrent() {
        const {
            order,
            currentBlock: stateCurrentBlock,
            isCancelPoint,
            isReplacePoints,
        } = this.state;

        if (order) {
            const { idOfCurrentPoint, status } = order;
            let currentBlock = 'move';

            if (idOfCurrentPoint) {
                const currentPointIndex = order.route.findIndex(
                    (point) => point._id === idOfCurrentPoint,
                );
                const currentPoint =
                    currentPointIndex !== -1 ? order.route[currentPointIndex] : null;

                if (status === 'choice-crew' || currentPoint.status === 'wait') {
                    currentBlock = 'move';
                }

                if (currentPoint.status === 'on-place') {
                    currentBlock = 'docs';
                }

                if (
                    currentPointIndex !== 0 &&
                    currentPoint.status === 'wait' &&
                    stateCurrentBlock !== 'move'
                ) {
                    currentBlock = 'final';
                }

                if ((isCancelPoint || isReplacePoints) && currentPoint.status === 'wait') {
                    currentBlock = 'final';
                }

                if (currentPoint.status === 'complete' && order.isAcceptChangeRoute) {
                    currentBlock = 'final';
                }
            }

            if (['wait-close', 'complete'].includes(status)) {
                currentBlock = 'final';
            }

            return { id: `${currentBlock}-${idOfCurrentPoint}`, block: currentBlock };
        }

        return {};
    }

    handlerCurrentBlock({ currentBlock }) {
        this.setState({
            isCancelPoint: false,
            isReplacePoints: false,
            addPointsLen: null,
            currentBlock,
        });
    }

    getBlocks() {
        const { order } = this.state;
        const { id, block } = this.getCurrent();
        let point;

        if (block) {
            if (order) {
                const pointIndex = order.route.findIndex(
                    (innerPoint) => innerPoint._id === order.idOfCurrentPoint,
                );

                if (pointIndex !== -1) {
                    point = { ...order.route[pointIndex], index: pointIndex };
                }
            }

            return [{ id, key: block, point }];
        }

        return [];
    }

    getCurrentPoint() {
        const { order } = this.state;

        return order?.route.find((point) => point._id === order.idOfCurrentPoint);
    }

    getBlocksOrder() {
        const { order } = this.state;
        const blocksOrder = [];

        if (order) {
            order.route.forEach((point, pointKey) => {
                ((order.isAcceptChangeRoute && order.route.length === 1) ||
                (pointKey === order.route.length - 1 && point.status === 'complete')
                    ? ['move', 'docs', 'final']
                    : ['final', 'move', 'docs']
                ).forEach((block) => {
                    blocksOrder.push(`${block}-${point._id}`);
                });
            });
        }

        return blocksOrder;
    }

    blocks = {
        move: {
            render({ point }) {
                const { order, loadingKey, roadInfo } = this.state;

                return (
                    <Move
                        setUpdateKey={this.setUpdateKey}
                        renderActions={this.renderActions}
                        handlerCurrentBlock={this.handlerCurrentBlock}
                        order={order}
                        point={point}
                        handlerPoint={this.handlerPoint}
                        loadingKey={loadingKey}
                        roadInfo={roadInfo}
                    />
                );
            },
        },
        docs: {
            render({ point }) {
                const { order, loadingKey, errors } = this.state;

                return (
                    <Docs
                        setUpdateKey={this.setUpdateKey}
                        renderActions={this.renderActions}
                        handlerCurrentBlock={this.handlerCurrentBlock}
                        order={order}
                        point={point}
                        handlerPoint={this.handlerPoint}
                        loadingKey={loadingKey}
                        errors={errors}
                        handlerErrors={this.handlerErrors}
                        handlerLoading={this.handlerLoading}
                    />
                );
            },
        },
        final: {
            render({ point }) {
                const { order, isCancelPoint, isReplacePoints, addPointsLen } = this.state;
                const nextPoint = point;

                return (
                    <Final
                        order={order}
                        point={
                            isCancelPoint ||
                            isReplacePoints ||
                            (order.route.length === 1 &&
                                order.isAcceptChangeRoute &&
                                point.status === 'complete')
                                ? point
                                : point
                                ? { ...order.route[point?.index - 1], index: point?.index - 1 }
                                : {}
                        }
                        renderActions={this.renderActions}
                        nextPoint={nextPoint}
                        handlerCurrentBlock={this.handlerCurrentBlock}
                        isCancelPoint={isCancelPoint}
                        isReplacePoints={isReplacePoints}
                        addPoint={this.addPoint}
                        addPointsLen={addPointsLen}
                    />
                );
            },
        },
    };

    renderBlock({ item }) {
        const { key } = item;
        const block = this.blocks[key];

        if (!block) {
            return <div className="appOrder__block"></div>;
        }

        return <div className="appOrder__block">{block.render.call(this, item)}</div>;
    }

    addPoint({ point }) {
        const { order } = this.state;

        handlerPopup({
            name: 'appNewPointsPopup',
            isShow: true,
            point,
            orderId: order._id,
            lastPoint: order.route[order.route.length - 1],
        });
    }

    setUpdateKey() {
        this.setState({ updateKey: new Date().getTime() });
    }

    actions = {
        call: {
            icon: 'app-order-call',
            title: 'Позвонить',
            getDescription({ number }) {
                return `Клиенту точки ${number}`;
            },
        },
        chat: {
            icon: 'app-order-chat',
            title: 'Поддержка',
            getDescription() {
                return 'Написать в чат';
            },
        },
    };

    setCall() {
        const { order } = this.state;

        axios.patch(
            `${process.env.REACT_APP_API}/order`,
            {
                type: 'set-call',
                id: order._id,
            },
            { headers: getHeaders() },
        );
    }

    openChat() {
        const { order } = this.state;
        const { idOfChat } = order;

        return new Promise((resolve) => {
            this.handlerLoading('chat').then(() => {
                createChat({ id: idOfChat, idOfOrder: order._id }).then(
                    (id) => {
                        this.updateOrder({ fields: { idOfChat: id } });
                        this.handlerLoading(null).then(resolve);
                    },
                    () => null,
                );
            });
        });
    }

    renderAction({ name, className = '', point }) {
        const { order, loadingKey } = this.state;
        const action = this.actions[name];
        const { icon, title } = action;
        const description = action.getDescription({ number: point.index + 1 || 1 });
        const phone = point?.addressatInfo?.phone?.value;
        let isDisabled = false;

        if (name === 'call' && !phone) {
            isDisabled = true;
        }

        const ActionTag = name === 'call' ? (isDisabled ? 'div' : 'a') : 'div';
        const actionProps =
            name === 'call'
                ? isDisabled
                    ? {}
                    : {
                          target: '_blank',
                          href: `tel:+7${clearPhone(phone)}`,
                          onClick: this.setCall,
                      }
                : {
                      onClick: this.openChat,
                  };

        return (
            <ActionTag
                className={`appOrder__action _row ${className} _${name} ${
                    isDisabled ? '_disabled' : ''
                }`}
                key={name}
                {...actionProps}
            >
                {name === 'chat' && (
                    <>
                        <div className="appOrder__actionCounter">
                            <CounterNotRead id={order.idOfChat} />
                        </div>
                        <Animate
                            className="appOrder__actionLoader _loader"
                            isShow={loadingKey === 'chat'}
                        >
                            <div className="appOrder__actionLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                )}

                <div className="appOrder__actionInner">
                    <i className="appOrder__actionIcon">
                        <Icon name={icon} />
                    </i>
                    <div className="appOrder__actionTitle">{title}</div>
                    <div className="appOrder__actionDescription">{description}</div>
                </div>
            </ActionTag>
        );
    }

    renderActions({ className, point, isFinal }) {
        return (
            <div className={`appOrder__actions _row ${isFinal ? '_final' : ''}`}>
                {(isFinal ? ['chat'] : ['call', 'chat']).map((name) =>
                    this.renderAction({ name, className, point }),
                )}
            </div>
        );
    }

    loadingTimer;

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, () => {
                if (this.loadingTimer) {
                    clearTimeout(this.loadingTimer);
                }

                if (loadingKey) {
                    this.loadingTimer = setTimeout(
                        () => {
                            this.getOrder(true);
                        },
                        loadingKey.includes('-docs') ? 20_000 : 15_000,
                    );
                }

                resolve();
            });
        });
    }

    handlerErrors({ message }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                let errors = [];

                if (message) {
                    let error;

                    if (message === 'Code is empty') {
                        error = 'code-empty';
                    }

                    if (message === 'Code is incorrect') {
                        error = 'code-incorrect';
                    }

                    if (error && !errors.includes(error)) {
                        errors.push(error);
                    }
                } else {
                    errors = [];
                }

                newState.errors = errors;

                return newState;
            }, resolve);
        });
    }

    handlerPoint({ status, ...props }) {
        const { order } = this.state;
        const { appCoords } = this.props;
        const otherProps = {};

        if (['on-place', 'complete'].includes(status)) {
            otherProps.coords = appCoords;
        }

        return new Promise((resolve) => {
            this.handlerLoading(status).then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/order`,
                        {
                            type: 'actions',
                            action: 'change-status',
                            id: order._id,
                            status,
                            ...props,
                            ...otherProps,
                        },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (!success) {
                                const { message } = data;

                                this.handlerErrors({ message });

                                if (message === 'You must be within the point') {
                                    handlerPopup({
                                        name: 'appCoordsOrderPopup',
                                        isShow: true,
                                        idOfOrder: order._id,
                                    });
                                }

                                this.handlerLoading(null);
                            }

                            resolve();
                        },
                        () => null,
                    );
            });
        });
    }

    updateOrder({ fields, otherData, initiatorUserId }) {
        const { user } = this.props;

        if (['complete', 'cancel'].includes(fields.status)) {
            checkAuth(false);

            changePage({
                href: getPageLink({ name: 'orders-completed' }),
            });

            if (user._id !== initiatorUserId && fields.status === 'complete') {
                handlerPopup({
                    name: 'appCompleteOrderPopup',
                    isShow: true,
                    order: {
                        _id: this.state.order._id,
                        number: fields.number,
                        dateOfOrder: fields.dateOfOrder,
                    },
                });
            }
        } else {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const stateOrder = JSON.parse(JSON.stringify(newState.order));

                    Object.keys(fields).forEach((key) => {
                        stateOrder[key] = fields[key];
                    });

                    const currentPoint = stateOrder.route.find(
                        (point) => point._id === stateOrder.idOfCurrentPoint,
                    );

                    if (currentPoint?.status === 'move-to-point') {
                        newState.currentBlock = null;
                        newState.errors = [];
                    }

                    if (
                        currentPoint?.status === 'move-to-point' ||
                        currentPoint?.status === 'on-place' ||
                        currentPoint?.status === 'complete' ||
                        fields.idOfCurrentPoint
                    ) {
                        this.moveY = 0;
                        this.lastY = 0;

                        this.move(false);
                    }

                    if (fields.idOfCurrentPoint) {
                        newState.roadInfo = null;
                    }

                    if (otherData?.isCancelPoint) {
                        newState.isCancelPoint = true;
                    }

                    if (otherData?.isReplacePoints) {
                        newState.isReplacePoints = true;
                    }

                    if (otherData?.addPointsLen) {
                        newState.addPointsLen = otherData.addPointsLen;
                    }

                    newState.order = stateOrder;

                    return newState;
                },
                () => {
                    this.setUpdateKey();

                    if (fields.status === 'in-proccess') {
                        this.setTimer();
                    }

                    if (fields.idOfCurrentPoint) {
                        handlerPopup({ name: 'appOrderPointPopup', isShow: false });
                    }

                    if (user._id === initiatorUserId) {
                        this.handlerLoading(null);
                    }

                    if (fields.actionChangeRoute || fields.idOfCurrentPoint) {
                        handlerPopup({ name: 'appNewPointPopup', isShow: false, point: undefined });
                        handlerPopup({ name: 'appNewPointsPopup', isShow: false });

                        this.setRoute();
                    }
                },
            );
        }
    }

    getRouteInfo({ points }) {
        const { ymaps, map } = this.state;

        return new Promise((resolve) => {
            try {
                ymaps.route(points).then(
                    (allRoute) => {
                        try {
                            allRoute.getPaths().options.set({
                                strokeWidth: 0,
                                opacity: 0,
                            });

                            map.geoObjects.add(allRoute);

                            const distance = +allRoute.getLength().toFixed(0);
                            const time = +allRoute.getJamsTime().toFixed(0);

                            map.geoObjects.remove(allRoute);

                            resolve({ distance, time });
                        } catch (error) {
                            resolve();
                        }
                    },
                    () => null,
                );
            } catch (error) {
                resolve();
            }
        });
    }

    getRoadInfo({ prevPoint, point }) {
        const { order } = this.state;

        this.getRouteInfo({ points: [prevPoint.coords, point.coords] }).then((allRoute) => {
            const { appCoords } = this.props;

            this.getRouteInfo({ points: [appCoords, point.coords] }).then((currentRoute) => {
                let timeToPoint;

                if (currentRoute) {
                    const date = new Date();

                    date.setSeconds(currentRoute.time);

                    timeToPoint = date.toString();
                }

                axios
                    .patch(
                        `${process.env.REACT_APP_API}/order`,
                        {
                            id: order._id,
                            type: 'set-gps',
                            coords: appCoords,
                            timeToPoint,
                        },
                        { headers: getHeaders() },
                    )
                    .then(
                        () => {
                            this.timerId = setTimeout(() => {
                                this.setTimer();
                            }, 10_000);
                        },
                        () => null,
                    );

                this.setState({
                    roadInfo: {
                        allRoute: allRoute || this.state.roadInfo?.allRoute,
                        currentRoute: currentRoute || this.state.roadInfo?.currentRoute,
                    },
                });
            });
        });
    }

    timerId;

    setTimer() {
        const { order } = this.state;
        const { appCoords } = this.props;

        if (order && appCoords && this.appCoords && order.status === 'in-proccess') {
            if (this.timerId) {
                clearTimeout(this.timerId);
            }

            const currentPointIndex = order.route.findIndex(
                (point) => point._id === order.idOfCurrentPoint,
            );
            const currentPoint = order.route[currentPointIndex];
            const prevPoint =
                currentPointIndex === 0
                    ? { coords: this.appCoords }
                    : order.route[currentPointIndex - 1];

            this.getRoadInfo({ prevPoint, point: currentPoint });

            this.isStartSendCoords = true;
        }
    }

    getOrderId() {
        const { levels } = this.props;

        return levels[1];
    }

    setCoordsInterval(isTouch) {
        const { ymaps, map } = this.state;
        const { appCoords } = this.props;

        if (ymaps && map && appCoords) {
            if (this.coordsIntervalId) {
                clearInterval(this.coordsIntervalId);
            }

            if (this.coordsTimerId) {
                clearTimeout(this.coordsTimerId);
            }

            const isShow = ymaps.util.bounds.containsPoint(map.getBounds(), this.props.appCoords);

            this.coordsTimerId = setTimeout(
                () => {
                    this.coordsIntervalId = setInterval(() => {
                        map.setCenter(this.props.appCoords, 17, {
                            checkZoomRange: true,
                        });
                    }, 3_000);
                },
                isTouch ? (isShow ? 10_000 : 5_000) : 0,
            );
        }
    }

    getOrder(isAgain) {
        const { user } = this.props;
        const id = this.getOrderId();
        const savedOrder = !!this.state.order;

        getOrders({ id }).then(
            ({ order }) => {
                if (['cancel', 'complete'].includes(order.status)) {
                    changePage({ href: getPageLink({ name: 'orders-completed' }) });
                } else {
                    const crewExecutor = order.crew.find((item) => item.id === user._id);

                    if (!crewExecutor.isStart) {
                        changePage({ href: '' });
                        handlerOrderPopup({ action: 'add', id: order._id });
                    } else {
                        this.setState({ order, updateKey: new Date().getTime() }, () => {
                            if (savedOrder) {
                                this.setRoute(false);
                            }

                            handlerPopup({ name: 'appCarImagesPopup', isShow: false });

                            if (!isAgain) {
                                this.setTimer();
                            }

                            setTimeout(() => {
                                this.setState({ isInit: true });

                                setTimeout(() => {
                                    if (!this.state.map) {
                                        this.setState({ isUpdateMap: true });
                                    }
                                }, 2000);
                            }, 300);
                        });
                    }
                }
            },
            (err) => {
                if (err?.data?.actionCode === 404) {
                    changePage({ href: getPageLink({ name: 'orders-completed' }) });
                }
            },
        );
    }

    checkReady() {
        const { windowIsReady } = this.props;

        if (windowIsReady && windowIsReady !== this.windowIsReady) {
            this.windowIsReady = windowIsReady;

            this.setUpdateKey();
        }
    }

    checkHideDisabled() {
        if (this.getCurrent().block === 'move') {
            return false;
        }

        return true;
    }

    setGps() {
        const { ymaps, map } = this.state;
        const { appCoords } = this.props;

        if (ymaps && map && appCoords) {
            this.getGps();

            map.setCenter(appCoords, 17, {
                checkZoomRange: true,
            });
        }
    }

    getGps() {
        const { ymaps, map } = this.state;
        const { user, appCoords } = this.props;

        if (ymaps && map) {
            if (!this.isCoordsInit && appCoords) {
                this.isCoordsInit = true;

                this.appCoords = appCoords;

                this.setRoute();

                this.setTimer();
            }

            if (this.executorPoint) {
                map.geoObjects.remove(this.executorPoint);
            }

            this.checkActualRoute();

            this.executorPoint = new ymaps.Placemark(
                appCoords,
                {},
                {
                    iconLayout: 'default#image',
                    iconImageHref: require(`../../img/${getExecutorMapIcon({ type: user.type })}`),
                    iconImageSize: [38, 38],
                    iconImageOffset: [-16, -16],
                },
            );

            map.geoObjects.add(this.executorPoint);
        }
    }

    visibilityDocChange({ detail: { isActive } }) {
        if (isActive) {
            if (process.env.REACT_APP_ENV !== 'local') {
                this.getOrder();
            }
        }
    }

    handlerSocket({ detail }) {
        const { order } = this.state;
        const { name, data } = detail;

        if (order && name === 'order' && data?.idOfOrder === order._id) {
            const { fields, initiatorUserId, type, otherData } = data;

            if (type === 'changeInfo') {
                this.updateOrder({ fields, initiatorUserId, otherData });
            }

            if (type === 'cancelFromCrew') {
                changePage({ href: 'orders' });
            }
        }

        if (order && name === 'needOrderGps' && data?.fields?.idOfOrder === order._id) {
            this.setTimer();
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.getOrder();
        this.checkReady();

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('getGps', this.getGps);
        document.addEventListener('visibilityDocChange', this.visibilityDocChange);

        dispatcher({ type: 'isAcceptGetGeo', data: true });

        localStorage.setItem('isAcceptGetGeo', true);
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        this.checkReady();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('getGps', this.getGps);
        document.removeEventListener('visibilityDocChange', this.visibilityDocChange);

        clearTimeout(this.timerId);
    }

    render() {
        const { updateKey, order, isInit, moveDirection, isUpdateMap } = this.state;

        return (
            <div ref={this.parent} className="appPopup _col _order">
                <div
                    className={`appPopup__back ${moveDirection === 'end' ? '_hide' : ''} ${
                        this.getCurrent().block === 'docs' ? '_dark' : ''
                    }`}
                >
                    <div className="appPopup__backMap">
                        <AnimateChangeUp
                            className="appPopup__backMapStatus"
                            renderKey={order?.status === 'choice-crew' ? 'choice-crew' : null}
                        >
                            {order?.status === 'choice-crew' ? (
                                <div className="appPopup__backMapStatusInner">
                                    <OrderStatus name="wait" className="_active">
                                        ДО ПОДАЧИ:{' '}
                                        {getTimeToOrder({
                                            order,
                                            isShort: true,
                                        })}
                                    </OrderStatus>
                                </div>
                            ) : (
                                <></>
                            )}
                        </AnimateChangeUp>
                        <div className="appPopup__backMapGeo _col" onClick={this.setGps}>
                            <i className="appPopup__backMapGeoIcon">
                                <Icon name="app-geo" />
                            </i>
                        </div>
                        <div
                            className="appPopup__backMapBox"
                            onTouchStart={() => {
                                clearTimeout(this.coordsTimerId);
                                clearInterval(this.coordsIntervalId);
                            }}
                            onTouchEnd={() => {
                                this.setCoordsInterval(true);
                            }}
                        >
                            {isInit && (
                                <>
                                    <Ymaps
                                        {...this.getMapInfo()}
                                        callback={this.mapBootstrap}
                                        key={isUpdateMap}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appOrder _col">
                                <Animate className="appOrder__loader _loader" isShow={!order}>
                                    <div className="appOrder__loaderItem _loaderItem">
                                        <Loader className="_main" />
                                    </div>
                                </Animate>
                                <ListAbsoluteMain
                                    className="appOrder__blocks"
                                    items={this.getBlocks()}
                                    renderItem={this.renderBlock}
                                    classNameItem="appOrder__block"
                                    prop="id"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    isNotParamsItem={true}
                                    keyRender={updateKey}
                                    currentItemKey={this.getCurrent().id}
                                    allItems={this.getBlocksOrder()}
                                    keyUpdateItem={updateKey}
                                    minHeight={300}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {order && (
                    <div className="appPopup__details">
                        <Details order={order} />
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        levels: state.levels,
        windowIsReady: state.windowIsReady,
        heightWindow: state.heightWindow,
        appCoords: state.appCoords,
    };
}

export default connect(mapStateToProps)(Order);

Order.propTypes = {
    user: PropTypes.object,
    levels: PropTypes.array,
    windowIsReady: PropTypes.bool,
    heightWindow: PropTypes.number,
    appCoords: PropTypes.array,
};
