import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import OrderDetails from '../../../views/app/OrderDetails.jsx';

class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { id, deliveryType } = this.props;

        return <OrderDetails id={id} deliveryType={deliveryType} type="popup" />;
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(Order);

Order.propTypes = {
    heightWindow: PropTypes.number,
    hideCallback: PropTypes.func,
    id: PropTypes.string,
    deliveryType: PropTypes.string,
};
