import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import Link from '../../components/Link.jsx';

class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { theme } = this.props;

        return (
            <div className="start">
                <div className="start__inner _col">
                    <img
                        src={require(`../../img/logo-icon-${
                            theme === 'dark' ? 'light' : 'dark'
                        }.svg`)}
                        alt=""
                        className="start__logo"
                    />
                    <div className="start__content _col">
                        <img
                            src={require('../../img/emodzi/victory-hand.png')}
                            alt=""
                            className="start__icon"
                        />
                        <div className="start__title">
                            Добро пожаловать
                            <br />в LIVECARGO!
                        </div>
                        <p className="start__description">
                            Грузоперевозки в режиме
                            <br />
                            реального времени
                        </p>
                        <Link className="start__button" href="auth">
                            <Button icon={{ type: 'end', name: 'arrow-next-2' }}>
                                Начать работу
                            </Button>
                        </Link>
                    </div>
                    <p className="start__date">{new Date().getFullYear()}</p>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme,
    };
}

export default connect(mapStateToProps)(Start);

Start.propTypes = {
    theme: PropTypes.string,
};
