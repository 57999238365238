import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import setSpacesInText from '../../../functions/setSpacesInText';
import Icon from '../../Icon.jsx';

class Gps extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    // checkHideDisabled() {
    //     return this.isFull;
    // }

    hide() {
        super.hide();

        handlerPopup({ name: 'appGpsPopup', isShow: false });
    }

    render() {
        return (
            <div ref={this.parent} className="appPopup _col _appGps">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appPopup__boxIcon _icon">
                                <i className="appPopup__boxIconItem">
                                    <Icon name="app-accept-get" />
                                </i>
                            </div>
                            <div className="appPopup__title">
                                Функционал приложения
                                <br />
                                будет ограничен
                            </div>
                            <p
                                className="appPopup__description"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(
                                        '<b>Вы запретили доступ к местоположению.</b> Без доступа к этим данным вы не сможете использовать большинство функций приложения, включая принятие заказов. Чтобы продолжить использование приложения дайте доступ к местоположению',
                                    ),
                                }}
                            ></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(Gps);

Gps.propTypes = {
    heightWindow: PropTypes.number,
    order: PropTypes.object,
};
