export default function getStartUrl(path) {
    if (process.env.REACT_APP_SYSTEM === 'app') {
        return path;
    }

    if (
        !(
            ['admin', 'auth', 'profile', 'support', 'wait', 'pep', 'pdf'].includes(
                path.split('/')[0],
            ) || path.split('/')[0]?.length === 24
        )
    ) {
        path = `/${path}`;
    }

    return path;
}
