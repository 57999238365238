export default function getDataForImage({ path, name }) {
    return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();

        xhr.onload = () => {
            const reader = new FileReader();

            reader.onloadend = () => {
                resolve({ path: reader.result, name, type: xhr.response.type });
            };

            reader.readAsDataURL(xhr.response);
        };

        xhr.open('GET', path);
        xhr.responseType = 'blob';
        xhr.send();
    });
}
