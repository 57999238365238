import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';

import changePage from '../../../functions/changePage';

class FnsActivateInfo extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appFnsActivateInfoPopup', isShow: false });
    }

    render() {
        return (
            <div ref={this.parent} className="appPopup _col _fns">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appPopup__icon _col">
                                <img
                                    src={require('../../../img/fns-logo.svg').default}
                                    alt=""
                                    className="appPopup__iconLogo"
                                />
                            </div>
                            <div className="appPopup__title">
                                Необходимо предоставить доступ к «Мой налог»
                            </div>
                            <p className="appPopup__description">
                                Чтобы получать денежное вознаграждение, необходимо предоставить
                                доступ к «Мой налог»
                            </p>
                            <div className="appPopup__button">
                                <Button
                                    icon={{ type: 'end', name: 'arrow-next-2' }}
                                    onClick={() => {
                                        changePage({ href: 'settings-fns' });

                                        this.hide();

                                        if (typeof this.props.prevHide === 'function') {
                                            this.props.prevHide();
                                        }
                                    }}
                                >
                                    Предоставить доступ
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(FnsActivateInfo);

FnsActivateInfo.propTypes = {
    heightWindow: PropTypes.number,
    hideCallback: PropTypes.func,
};
