import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import removeTransition from '../functions/removeTransition.ts';

class DragBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    setHeightItems() {
        const { setHeightItems } = this.props;

        setHeightItems();
    }

    init() {
        const { className, callbackInit } = this.props;
        removeTransition({ item: className });
        this.setHeightItems();

        this.observer = new MutationObserver(() => {
            this.setHeightItems();
        });

        this.observer.observe(this.parent.current, {
            childList: true,
            subtree: true,
            characterData: true,
            characterDataOldValue: true,
        });

        if (callbackInit) {
            callbackInit();
        }
    }

    childWithProps({ child, key }) {
        const { items, heightItems, offset = 0, limit, sort, prop = 'uniqKey' } = this.props;
        const styleLimit = {};

        const uniqKey = child.props['data-id'];

        let cardTop =
            (heightItems &&
                items
                    ?.filter(
                        (card, cardKey) => !card.isDelete && (sort ? sort[cardKey] : cardKey) < key,
                    )
                    .reduce(
                        (prev, curCard) => heightItems[curCard[prop]]?.height + offset + prev,
                        0,
                    )) ||
            0;

        if (items) {
            const indexDelete = items.findIndex((item) => item.isDelete);
            const delta = indexDelete !== -1 && indexDelete < key ? -1 : 0;

            if (limit && items.filter((item) => !item.isDelete).length > limit) {
                if (key + delta + 1 >= limit) {
                    cardTop -= (key + delta + 2 - limit) * heightItems?.[uniqKey]?.height + offset;
                    styleLimit.opacity = 0;
                }

                if (key + delta === items.filter((item) => !item.isDelete).length - 1) {
                    cardTop += heightItems?.[uniqKey]?.height + offset;
                    styleLimit.opacity = 1;
                }
                if (key + delta > limit - 2 && key + delta < limit) {
                    // styleLimit.opacity = 0;
                }
            }
        }

        const props = {
            style: {
                top: `${cardTop}px`,
                height: `${heightItems?.[uniqKey]?.height}px`,
                ...styleLimit,
            },
        };

        return React.cloneElement(child, props);
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate() {
        // this.setHeightItems();
    }

    render() {
        const {
            className,
            children,
            items,
            heightItems,
            offset = 0,
            limit,
            sort,
            prop = 'uniqKey',
        } = this.props;

        let heightAll =
            (heightItems &&
                items
                    ?.filter(
                        (item, key) =>
                            !item.isDelete &&
                            ((limit &&
                                ((limit < items.filter((el) => !el.isDelete).length &&
                                    key +
                                        (items.findIndex((el) => el.isDelete) !== -1 &&
                                        items.findIndex((el) => el.isDelete) < key
                                            ? -1
                                            : 0) <
                                        limit) ||
                                    limit >= items.filter((el) => !el.isDelete).length)) ||
                                !limit),
                    )
                    .reduce(
                        (prev, curCard) => heightItems[curCard[prop]]?.height + offset + prev,
                        0,
                    )) ||
            0;

        if (heightAll !== 0 && offset) {
            heightAll -= offset;
        }

        return (
            <div
                ref={this.parent}
                className={`${className || ``}`}
                style={{ height: `${heightAll}px` }}
            >
                {React.Children.map(children, (child, key) =>
                    this.childWithProps({ child, key: sort ? sort[key] : key }),
                )}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(DragBox);

DragBox.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    items: PropTypes.array,
    classItem: PropTypes.string,
    classItemStatic: PropTypes.string,
    setHeightItems: PropTypes.func,
    heightItems: PropTypes.object,
    offset: PropTypes.number,
    limit: PropTypes.number,
    sort: PropTypes.array,
    callbackInit: PropTypes.func,
    prop: PropTypes.string,
};
