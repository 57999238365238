import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getOrders from '../../../requests/getOrders';

import ListScroll from '../../../components/ListScroll.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';
import OrderPreview from '../../../components/app/orders/OrderPreview.jsx';
import Table from '../../../components/crm/manual/Table.jsx';
import Animate from '../../../components/Animate.jsx';
import Loader from '../../../components/Loader.jsx';
import removeTransition from '../../../functions/removeTransition.ts';

class Orders extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderCard = this.renderCard.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.changeOrders = this.changeOrders.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 8;

    renderCard({ item: order }) {
        return (
            <div className="orders__pagePreview">
                <div className="orders__pagePreviewInner">
                    <OrderPreview {...order} />
                </div>
            </div>
        );
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    getItems(isForce = false) {
        const { name } = this.props;
        const query = this.getQueryForRequest();
        const params = query.params.concat(
            ...(name === 'completed'
                ? [{ key: 'isActive', value: false }]
                : name === 'free'
                ? [
                      { key: 'isFree', value: true },
                      { key: 'isWait', value: true },
                  ]
                : [{ key: 'isWait', value: true }]),
        );

        return new Promise((resolve) => {
            getOrders({ params }).then(
                ({ orders, isLimit }) => {
                    if (!this.state.isInit) {
                        setTimeout(() => {
                            this.setState({ isInit: true });
                        }, 300);
                    }

                    this.setItems(orders, isForce, isLimit).then(() => {
                        removeTransition({ item: '.orders__pagePreview', isCurrent: true });

                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    changeOrders() {
        this.getItems(true);
    }

    componentDidMount() {
        this.getItems();

        document.addEventListener('changeOrders', this.changeOrders);
    }

    componentWillUnmount() {
        document.removeEventListener('changeOrders', this.changeOrders);
    }

    render() {
        const {
            isInit,
            isDisabledScroll,
            items = [],
            isLimit,
            isShowLoaderList = false,
        } = this.state;

        return (
            <div ref={this.parent} className={`orders__pageInner ${isInit ? '_init' : ''}`}>
                <Animate className="orders__pageLoader _loader" isShow={!isInit}>
                    <div className="orders__pageLoaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate className="orders__pageScrollLoader _loaderScroll" isShow={isShowLoaderList}>
                    <div className="orders__pageScrollLoaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate className="orders__pageEmpty" isShow={isInit && items.length === 0}>
                    <div className="empty _col _notBack _block">
                        <div className="empty__inner">
                            <div className="empty__title">На этом пока всё</div>
                            <p className="empty__content">У вас нет заказов</p>
                        </div>
                    </div>
                </Animate>
                <div className="orders__pageBox">
                    <ListScroll
                        isInit={isInit}
                        getParent={() => this.parent.current?.querySelector('.orders__pageBox')}
                        callback={this.getMoreItems}
                        startCounter={this.stepCounter}
                        stepCounter={this.stepCounter}
                        maxCounter={Infinity}
                        lengthCurrent={items.length}
                        handlerLoaderList={this.handlerLoaderList}
                        isLimit={isLimit}
                        isDisabledScroll={isDisabledScroll || !isInit}
                    >
                        <ListAbsoluteMain
                            className="orders__pagePreviews _col"
                            items={items}
                            renderItem={this.renderCard}
                            classNameItem="orders__pagePreview"
                            prop="_id"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            isSmoothShow={true}
                        />
                    </ListScroll>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Orders);

Orders.propTypes = {
    user: PropTypes.object,
    name: PropTypes.string,
};
