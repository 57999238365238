import React from 'react';
import PropTypes from 'prop-types';

import Animate from '../Animate.jsx';
import AnimateChange from '../AnimateChange.jsx';
import Icon from '../Icon.jsx';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderStars({ isEdit = false, rate = 0 }) {
        const { message, setRate } = this.props;
        const stars = [1, 2, 3, 4, 5];

        return (
            <div className="chatV2Rate__stars _row">
                {stars.map((star) => (
                    <i
                        className={`chatV2Rate__star ${star <= rate ? '_complete' : ''} ${
                            isEdit === true ? '_click' : ''
                        }`}
                        key={star}
                        onClick={() => {
                            if (isEdit === true) {
                                setRate({ _id: message._id, result: star });
                            }
                        }}
                    >
                        <Icon name="star" />
                    </i>
                ))}
            </div>
        );
    }

    render() {
        const { chat, message, openChat, setRateCallback, loadingKey } = this.props;
        const info = {};

        if (message?.rate) {
            info.icon = 'handshake.png';

            if (process.env.REACT_APP_SYSTEM === 'crm') {
                if (chat.entity.type === 'client') {
                    info.title = 'Клиент оценил работу';
                } else if (chat.entity.type === 'executor') {
                    info.title = 'Водитель оценил работу';
                } else {
                    info.title = 'Пользователь оценил работу';
                }
            } else {
                info.title = 'Вы оценили работу';
            }

            info.description = `Оценка: ${message.rate} из 5`;
        } else if (process.env.REACT_APP_SYSTEM === 'crm') {
            info.icon = 'victory-hand.png';
            info.title = 'Здесь появится оценка';

            if (chat.entity.type === 'executor') {
                info.description = 'Водитель оценивает обслуживание';
            } else if (chat.entity.type === 'client') {
                info.description = 'Клиент оценивает обслуживание';
            } else {
                info.description = 'Пользователь оценивает обслуживание';
            }
        } else {
            info.icon = 'victory-hand.png';
            info.title = 'Оцените обслуживание';
            info.description = 'Это поможет нам стать лучше';
        }

        return (
            <>
                <div className="chatV2Rate _col">
                    <div className="chatV2Rate__content _col">
                        <AnimateChange
                            className="chatV2Rate__contentInner _col"
                            prop={message?.rate}
                            type="_translateMedium _centerX"
                        >
                            <>
                                <img
                                    src={require(`../../img/emodzi/${info.icon}`)}
                                    alt=""
                                    className={`chatSession__resultIcon ${
                                        message?.rate === null ? '' : '_complete'
                                    }`}
                                />
                                <h3 className="chatV2Rate__title">{info.title}</h3>
                                <p className="chatV2Rate__description">{info.description}</p>
                            </>
                        </AnimateChange>
                    </div>
                    {this.renderStars({
                        isEdit: true,
                        rate: message?.rate,
                    })}
                </div>
                {process.env.REACT_APP_SYSTEM !== 'crm' && !1 && (
                    <Animate
                        className={`chatV2Rate__btn _col ${loadingKey === 'open' ? '_disabled' : ''}`}
                        isShow={!message?.rate}
                        actionPrevRemove={setRateCallback}
                    >
                        Вопрос не решен?{' '}
                        <span className="_click" onClick={openChat}>
                            Откройте обращение снова
                        </span>
                    </Animate>
                )}
            </>
        );
    }
}

export default Pagination;

Pagination.propTypes = {
    chat: PropTypes.object,
    message: PropTypes.object,
    style: PropTypes.object,
    openChat: PropTypes.func,
    setRate: PropTypes.func,
    setRateCallback: PropTypes.func,
    loadingKey: PropTypes.string,
};
