import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AnimateChange from '../AnimateChange.jsx';
import Icon from '../Icon.jsx';

class OptimizationBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            points,
            isOptimize,
            handler,
            isProccessOptimization,
            className = '',
            isForce,
            isDisabled,
        } = this.props;
        const condForOptimization =
            isForce ||
            (points &&
                points.filter((point) => !point.isDelete).length > 2 &&
                !points
                    .filter((point) => !point.isDelete)
                    .find((point) => point.isComplete === false) &&
                !isDisabled);
        const optimizationContent = isOptimize ? 'Отменить оптимизацию' : 'Оптимизировать маршрут';

        return (
            <div
                className={`orderOptimizationBtn _row _click ${className} ${isOptimize ? '_complete' : ''} ${
                    isProccessOptimization ? '_isProccessOptimization' : ''
                } ${!condForOptimization ? '_disabled' : ''}`}
                onClick={() => {
                    if (condForOptimization) {
                        handler();
                    }
                }}
            >
                <i className="orderOptimizationBtn__icon">
                    <Icon name="optimization" />
                </i>
                <AnimateChange
                    classNameParent="orderOptimizationBtn__inner"
                    prop={optimizationContent}
                    type="_translate"
                >
                    <div className="orderOptimizationBtn__innerItem _row">{optimizationContent}</div>
                </AnimateChange>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(OptimizationBtn);

OptimizationBtn.propTypes = {
    points: PropTypes.array,
    handler: PropTypes.func,
    isOptimize: PropTypes.bool,
    isProccessOptimization: PropTypes.bool,
    className: PropTypes.string,
    isForce: PropTypes.bool,
    isDisabled: PropTypes.bool,
};
