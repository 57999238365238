import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import setSpacesInText from '../../../functions/setSpacesInText';

class Logout extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appLogoutPopup', isShow: false });
    }

    render() {
        return (
            <div ref={this.parent} className="appPopup _col">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <img
                                src={require('../../../img/emodzi/man-shrugging.png')}
                                alt=""
                                className="appPopup__icon"
                            />
                            <div className="appPopup__title">
                                Вы вошли с&nbsp;другого
                                <br />
                                устройства
                            </div>
                            <p
                                className="appPopup__description"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(
                                        'Вы не можете войти в аккаунт одновременно с нескольких устройств. На этом устройстве аккаунт был закрыт.',
                                    ),
                                }}
                            ></p>
                            <div className="appPopup__button">
                                <Button
                                    onClick={() => {
                                        this.hide();
                                    }}
                                >
                                    Понятно
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(Logout);

Logout.propTypes = {
    heightWindow: PropTypes.number,
    order: PropTypes.object,
};
