import axios from 'axios';
import { dispatcher } from '../redux/redux';

import getHeaders from './getHeaders';
import handlerErrorRequest from './handlerErrorRequest';

export default function getServerData(name) {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API}/data?name=${name}`, { headers: getHeaders() }).then(
            (res) => {
                const { success, data } = res.data;

                if (success) {
                    dispatcher({
                        type: 'serverData',
                        data: {
                            [name]: data.data,
                        },
                    }).then(() => {
                        resolve(data.data);
                    });
                } else {
                    handlerErrorRequest(res);

                    reject();
                }
            },
            () => null,
        );
    });
}