import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getFormatPrice from '../../functions/getFormatPrice';
import getFormatedDate from '../../functions/getFormatedDate';

import Back from '../../components/app/Back.jsx';
import changePage from '../../functions/changePage';
import showStartPopups from '../../functions/app/showStartPopups';

import HeadPage from '../../components/app/HeadPage.jsx';
import Loader from '../../components/Loader.jsx';
import Animate from '../../components/Animate.jsx';
import Link from '../../components/Link.jsx';
import List from './balance/List.jsx';

class Balance extends HeadPage {
    constructor(props) {
        super(props);
        this.state = {};

        this.setInit = this.setInit.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
    }

    setInit(isReady) {
        this.setState({ isReady });
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    componentDidMount() {
        super.componentDidMount();

        showStartPopups();
    }

    render() {
        const { isReady, isShowLoaderList = false } = this.state;
        const { user } = this.props;

        return (
            <div ref={this.parent} className="headPage">
                <div className="headPage__head">
                    <div className="pageHead _col">
                        <div className="pageHead__top _row">
                            <div className="pageHead__back">
                                <Back
                                    handler={() => {
                                        changePage({ href: '' });
                                    }}
                                />
                            </div>
                            <div className="pageHead__title">Баланс</div>
                            <div className="pageHead__link">
                                <Link className="link _click" href="chat-index">
                                    Поддержка
                                </Link>
                            </div>
                        </div>
                        <div className="pageHead__balance">
                            {getFormatPrice(user?.balance || 0)}₽
                        </div>
                        <p className="pageHead__description">
                            Ваш баланс на {getFormatedDate({ date: new Date() })}
                        </p>
                    </div>
                </div>
                <div className="headPage__inner">
                    <Animate className="headPage__loader _loader" isShow={!isReady}>
                        <div className="headPage__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <div className="headPage__innerBox">
                        <List handlerLoaderList={this.handlerLoaderList} setInit={this.setInit} />
                    </div>
                    <Animate className="headPage__innerScrollLoader _loaderScroll" isShow={isShowLoaderList}>
                        <div className="headPage__innerScrollLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Balance);

Balance.propTypes = {
    user: PropTypes.object,
};
