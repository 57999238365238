import axios from 'axios';

import getHeaders from '../functions/getHeaders';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function getArticles(type, { id, params = [], signal }) {
    let query = '';

    if (id) {
        query += `id=${id}&`;
    }

    if (type) {
        query += `type=${type}&`;
    }

    params.forEach((param) => {
        query += `${param.key}=${param.value}&`;
    });

    let apipoint = process.env.REACT_APP_API;

    if (process.env.REACT_APP_ENV === 'dev' && type === 'info') {
        apipoint = 'https://crm.livecargo.ru/server-api/api';
    }

    return new Promise((resolve, reject) => {
        axios.get(`${apipoint}/article?${query}`, { signal, headers: getHeaders() }).then((res) => {
            if (res?.data?.data && typeof res?.data?.data === 'object') {
                const { success, data } = res.data;
                const {
                    articles = [],
                    article,
                    isLimit,
                    counter,
                    sections,
                    notReadCounter,
                    shortList,
                } = data;

                if (success) {
                    resolve({
                        articles,
                        article,
                        isLimit,
                        counter,
                        sections,
                        notReadCounter,
                        shortList,
                    });
                } else {
                    handlerErrorRequest(res);

                    reject(res.data);
                }
            } else {
                reject(res.data);
            }
        }, reject);
    });
}
