import checkActualCar from './checkActualCar';
import handlerPopup from './handlerPopup';

export default function showStartPopups() {
    if (!localStorage.getItem('appShowStartPopup') && 0) {
        handlerPopup({
            name: 'appStartPopup',
            isShow: true,
            hideCallback() {
                checkActualCar({ withLocal: false });
            },
        });
    } else {
        checkActualCar({});
    }
}
