import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import getUserInfo from '../../../functions/getUserInfo';
import getEndText from '../../../functions/getEndText';
import getHeaders from '../../../functions/getHeaders';
import getUpdateFormData from '../../../functions/getUpdateFormData';

import Icon from '../../../components/Icon.jsx';
import getCurrentCar from '../../../functions/app/getCurrentCar';
import getCurrentCorporation from '../../../functions/app/getCurrentCorporation';
import Button from '../../../components/Button.jsx';
import Link from '../../../components/Link.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';
import Loader from '../../../components/Loader.jsx';
import Animate from '../../../components/Animate.jsx';
import Contract from '../../../components/app/Contract.jsx';

const selects = require('../../../infos/selects.json');

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderAvatar = this.renderAvatar.bind(this);
        this.renderAvatarInfo = this.renderAvatarInfo.bind(this);
        this.changeAvatar = this.changeAvatar.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.uploadAvatar = this.uploadAvatar.bind(this);
    }

    avatarItems = ['image', 'empty'];

    getAvatarItems() {
        const { user } = this.props;

        return user.avatar?.path ? [{ key: 'image', path: user.avatar.path }] : [{ key: 'empty' }];
    }

    renderAvatar({ item, prop }) {
        return (
            <div className="settings__avatarPreviewItem _col">
                {prop === 'image' && (
                    <>
                        <label className="settings__avatarPreviewImage">
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={this.uploadAvatar}
                                accept="image/*"
                            />
                            <img
                                src={`${process.env.REACT_APP_STATIC}/executors/${item.path}`}
                                alt=""
                                className="settings__avatarPreviewImageItem"
                            />
                        </label>
                    </>
                )}
                {prop === 'empty' && (
                    <>
                        <i className="settings__avatarPreviewIcon">
                            <Icon name="app-settings-profile" />
                        </i>
                    </>
                )}
            </div>
        );
    }

    formData = new FormData();

    uploadAvatar(e) {
        const { loadingKey } = this.state;

        if (!loadingKey) {
            const [file] = e.target.files;

            e.target.value = null;

            this.formData.set('image', file);

            this.changeAvatar();
        }
    }

    changeAvatar(isDelete) {
        const { loadingKey } = this.state;

        if (!loadingKey) {
            this.handlerLoading('avatar').then(() => {
                this.formData.set('action', 'upload-avatar');

                if (isDelete) {
                    this.formData.set('isDelete', true);
                }

                setTimeout(() => {
                    axios
                        .patch(
                            `${process.env.REACT_APP_API}/executor`,
                            getUpdateFormData(this.formData),
                            {
                                headers: getHeaders(),
                            },
                        )
                        .then(
                            () => {
                                // const { success } = res.data;

                                this.formData = new FormData();

                                this.handlerLoading(null);
                            },
                            () => null,
                        );
                }, 1000);
            });
        }
    }

    renderAvatarInfo({ prop }) {
        return (
            <div className="settings__infoAvatarItem _col">
                {prop === 'empty' && (
                    <>
                        <p className="settings__infoDescription _row">Фото не установлено</p>
                        <label className="settings__infoLink">
                            <input
                                type="file"
                                className="settings__infoLinkInput"
                                onChange={this.uploadAvatar}
                                accept="image/*"
                            />
                            Установить фото
                        </label>
                    </>
                )}
                {prop === 'image' && (
                    <>
                        <p className="settings__infoDescription _row">Фото установлено</p>
                        <div
                            className="settings__infoLink _alert"
                            onClick={() => {
                                this.changeAvatar(true);
                            }}
                        >
                            Удалить фото
                        </div>
                    </>
                )}
            </div>
        );
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    changeUser({ detail: { initiatorUserId, fields } }) {
        const { user } = this.props;

        if (initiatorUserId === user._id && fields.avatar) {
            this.handlerLoading(null);
        }
    }

    componentDidMount() {
        document.addEventListener('changeUser', this.changeUser);
    }

    componentWillUnmount() {
        document.removeEventListener('changeUser', this.changeUser);
    }

    render() {
        const { loadingKey } = this.state;
        const { user } = this.props;
        const type = selects.executorTypes.items.find((item) => item.key === user.type)?.content;
        const organization = selects.executorOrganizations.items.find(
            (item) => item.key === user.organization,
        )?.content;
        const car = getCurrentCar({ user });
        const corporation = getCurrentCorporation({ user });
        const { contract } = user;

        return (
            <div className="settings _col">
                <div className="settings__blocks">
                    <div
                        className={`settings__avatar _row ${
                            loadingKey === 'avatar' ? '_isLoading' : ''
                        }`}
                    >
                        <div className="settings__avatarPreview">
                            <Animate
                                className="settings__avatarLoader _loader"
                                isShow={loadingKey === 'avatar'}
                            >
                                <div className="settings__avatarLoaderItem _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                            <ListAbsoluteMain
                                className="settings__avatarPreviewInner"
                                items={this.getAvatarItems()}
                                renderItem={this.renderAvatar}
                                classNameItem="settings__avatarPreviewItem"
                                prop="key"
                                paramsParent={{ width: true }}
                                styles={[]}
                                isNotParamsItem={true}
                                propsForUpdate={['path']}
                            />
                        </div>

                        <div className="settings__avatarContent">
                            <div className="settings__info _inner">
                                <div className="settings__infoTitle">Ваше фото:</div>
                                <ListAbsoluteMain
                                    className="settings__infoAvatar"
                                    items={this.getAvatarItems()}
                                    renderItem={this.renderAvatarInfo}
                                    classNameItem="settings__infoAvatarItem"
                                    prop="key"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    isNotParamsItem={true}
                                    currentItemKey={this.getAvatarItems()[0].key}
                                    allItems={this.avatarItems}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="settings__info">
                        <div className="settings__infoTitle">Ваши данные:</div>
                        <div className="settings__infoDescription _row">
                            <span>ФИО:</span>
                            <div className="settings__infoDescriptionItem">
                                {getUserInfo({ user, type: 'name' })}
                            </div>
                        </div>
                        <div className="settings__infoDescription _row">
                            <span>Телефон:</span>
                            <div className="settings__infoDescriptionItem">
                                {user.phone}
                            </div>
                        </div>
                        <div className="settings__infoDescription _row">
                            <span>Форма работы:</span>
                            <div className="settings__infoDescriptionItem">{type}</div>
                        </div>
                        <div className="settings__infoDescription _row">
                            <span>Тип:</span>
                            <div className="settings__infoDescriptionItem">{organization}</div>
                        </div>
                        <div className="settings__infoDescription _row">
                            <span>ИНН:</span>
                            <div className="settings__infoDescriptionItem">
                                {user.inn || '–'}
                            </div>
                        </div>
                    </div>
                    {car && (
                        <div className="settings__info">
                            <div className="settings__infoTitle">Данные автомобиля:</div>
                            <div className="settings__infoDescription _row">
                                <span>Марка:</span>
                                <div className="settings__infoDescriptionItem">{car.mark}</div>
                            </div>
                            <div className="settings__infoDescription _row">
                                <span>Модель:</span>
                                <div className="settings__infoDescriptionItem">{car.model}</div>
                            </div>
                            <div className="settings__infoDescription _row">
                                <span>Государственный номер:</span>
                                <div className="settings__infoDescriptionItem">{car.number}</div>
                            </div>
                            <div className="settings__infoDescription _row">
                                <span>Габариты авто:</span>
                                <div className="settings__infoDescriptionItem">
                                    {car.long}x{car.width}x{car.height}см
                                </div>
                            </div>
                            <div className="settings__infoDescription _row">
                                <span>Тоннаж:</span>
                                <div className="settings__infoDescriptionItem">
                                    {+(car.tonnage / 1000).toFixed(2)}{' '}
                                    {getEndText(+Math.floor(car.tonnage / 1000).toFixed(0), [
                                        'тонна',
                                        'тонны',
                                        'тонн',
                                        'тонн',
                                    ])}
                                </div>
                            </div>
                        </div>
                    )}
                    {corporation && corporation.id !== 'admin' && (
                        <div className="settings__info">
                            <div className="settings__infoTitle">Данные компании:</div>
                            <div className="settings__infoDescription _row">
                                <span>Название:</span>
                                <div className="settings__infoDescriptionItem">
                                    {corporation.name}
                                </div>
                            </div>
                            <div className="settings__infoDescription _row">
                                <span>ИНН:</span>
                                <div className="settings__infoDescriptionItem">
                                    {corporation.inn}
                                </div>
                            </div>
                            {contract && (
                                <div className="settings__infoContract">
                                    <Contract contract={contract} />
                                </div>
                            )}

                            {user.corporations.filter((item) => item.id !== 'admin').length > 1 && (
                                <Link className="settings__infoButton" href="corporations-list">
                                    <Button className="_blue">Сменить компанию</Button>
                                </Link>
                            )}
                        </div>
                    )}
                </div>

                <div className="settings__link">
                    <Link className="link _click _alert" href="chat-index">
                        Сообщить об ошибке
                    </Link>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        windowIsReady: state.windowIsReady,
        pagesStore: state.pages,
    };
}

export default connect(mapStateToProps)(Profile);

Profile.propTypes = {
    user: PropTypes.object,
    windowIsReady: PropTypes.bool,
    pagesStore: PropTypes.object,
};
