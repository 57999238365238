import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import copyInBuffer from '../../../functions/copyInBuffer';
import getFormatedDate from '../../../functions/getFormatedDate';

class PayInfo extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    copyLink({ link }) {
        if (!this.state.isCopyShow) {
            copyInBuffer(link);

            this.setState({ isCopyShow: true });

            setTimeout(() => {
                this.setState({ isCopyShow: false });
            }, 4_000);
        }
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appPayInfoPopup', isShow: false });
    }

    render() {
        const { appPayInfoPopup } = this.props;
        const { link, payNumber, payDate, payId, withCancel, status } = appPayInfoPopup;
        const resultLink =
            status === 'offline'
                ? `${process.env.REACT_APP_STATIC}/corporations-docs/${link}`
                : link;

        return (
            <div ref={this.parent} className="appPopup _col">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appPopup__title">
                                Акт №{payNumber} <br />
                                от&nbsp;
                                {getFormatedDate({ date: new Date(payDate) })}
                            </div>
                            <div className="appPopup__description">
                                Вы можете скопировать ссылку на чек, перейти к чеку или аннулировать
                                чек
                            </div>
                            <div className="appPopup__button">
                                <Button
                                    className="_blue"
                                    onClick={() => {
                                        this.copyLink({ link: resultLink });
                                    }}
                                    icon={{ name: 'copy-for-btn', type: 'end' }}
                                >
                                    Скопировать ссылку на чек
                                </Button>
                            </div>
                            <a
                                className="appPopup__button"
                                href={resultLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button
                                    className="_blue"
                                    icon={{ name: 'arrow-next-2', type: 'end' }}
                                >
                                    Перейти к чеку
                                </Button>
                            </a>
                            {withCancel && (
                                <div className="appPopup__button _margin">
                                    <Button
                                        className="_alertLight _notShadow"
                                        onClick={() => {
                                            handlerPopup({
                                                name: 'appPayCancelPopup',
                                                isShow: true,
                                                payId,
                                                payNumber,
                                                payDate,
                                                prevHide: this.hide,
                                            });
                                        }}
                                    >
                                        Аннулировать чек
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
        appPayInfoPopup: state.appPayInfoPopup,
    };
}

export default connect(mapStateToProps)(PayInfo);

PayInfo.propTypes = {
    heightWindow: PropTypes.number,
    appPayInfoPopup: PropTypes.object,
};
